import axios from 'axios'
import { getCfg } from '../env.config'
/* global localStorage */
const address = `https://${window.location.href.split('/')[2]}`
// console.log('addr:', address)
const config = getCfg(address)
const rootAddress = config.appRoot
// const rootAddress = process.env.REACT_APP_ROOT_PROD // prod url
const logRoot = config.categoryRoot
// const logRoot = 'https://api-eu-np.takeda.com/dev/entyvio-fywy-sf-api/'
// console.log('this is config', config)
export const header = {
  'x-client': 'mobile',
  'x-okta-idToken': localStorage.getItem('token'),
  client_id: config.clientId,
  client_secret: config.clientSecret
}

export const get = (path, params, setValues) => {
  axios
    .get(rootAddress + path, {
      params: params,
      headers: header
    })
    .then(function (response) {
      setValues({
        loaded: true,
        data: response.data
      })
    })
    .catch(function (error) {
      setValues({
        loaded: true,
        data: error.response
      })
      if (error.response.status !== 504) window.location.href = '/login'
    })
}

export const post = (path, data, callback) => {
  axios
    .post(rootAddress + path, data, { headers: header })
    .then(function (response) {
      callback(response.data)
    })
    .catch(function (error) {
      callback(error.response)
      window.location.href = '/login'
    })
}

export const deleteC = (path, config, callback) => {
  axios
    .delete(rootAddress + path, { params: config, headers: header })
    .then(function (response) {
      callback(response)
    })
    .catch(function (error) {
      callback(error.response)
      window.location.href = '/login'
    })
}

export const logAction = async (path, data, header, callback) => {
  // map data and remove null
  Object.keys(data).forEach(key => {
    if (data[key] === 'null') {
      delete data[key]
    }
  })
  axios
    .post(logRoot + path, data, {
      headers:
      {
        ...header,
        client_id: config.clientId,
        client_secret: config.clientSecret
      }
    })
    .then((res) => (callback ? callback(res) : console.log(res.data)))
    .catch((err) => (callback ? callback(err) : console.log(err.response?.data)))
}

export const logActionUserRecord = async (data) => {
  axios
    .post(config.userLogRecord, data, {
      headers:
      {
        'x-client': 'web',
        'x-okta-idToken': localStorage.getItem('token'),
        client_id: config.clientId,
        client_secret: config.clientSecret
      }
    })
    .then((res) => console.log(res.data))
    .catch((err) => console.log(err.response?.data))
}

// get recovery token and user based on email
export const getRecoveryToken = (path, _userEmail, headers) => {
  return new Promise((resolve, reject) => {
    axios.post(path, {
      username: _userEmail
    },
    {
      headers: headers
    }).then(response => {
      resolve(response.data)
    }).catch(err => {
      reject(err)
    })
  })
}
// mulesoft call
export const handleMuleSoft = (path, userEmail, headers) => {
  return new Promise((resolve, reject) => {
    // setTimeout(() => {
    //   resolve({
    //     // created in okta at a ceratian time
    //     Registered: 'dsa',
    //     // activation in okta
    //     Activated: null,
    //     // acoount  in okta
    //     Validated: false,
    //     // expires in okta
    //     // ValidationExpiry: null,
    //     ValidationExpiry: '2022-02-19T11:28:48.000Z',
    //     // account enroled in salseforce
    //     Enrolled: true,
    //     // salsforce status
    //     EnrollmentStatus: null,
    //     // dont care
    //     UserType: null,
    //     // unsubscribed from salesforce
    //     Unsubscribed: null
    //   })
    // }, 300)
    axios.get(`${path}${userEmail}`, {
      headers: headers
    }).then(response => {
      resolve(response.data)
    }).catch((error) => {
      reject(error)
    })
  })
}
// send email to validate account on btn click
export const sendEmail = (path, details, headers) => {
  return new Promise((resolve, reject) => {
    axios
      .post(path,
        {
          messages: [
            {
              to: [
                {
                  email: details.userEmail,
                  name: ''
                }
              ],
              templateData: [
                {
                  field: 'emailAddress',
                  content: details.userEmail
                },
                {
                  field: 'firstName',
                  content: details.firstName
                },
                {
                  field: 'lastName',
                  content: details.lastName
                },
                {
                  field: 'country',
                  content: details.country
                },
                {
                  field: 'language',
                  content: details.language
                },
                {
                  field: 'queryParam',
                  content: `?recoveryToken=${details.recoveryToken}&culture=${details.language}-${details.country}&emailAddress=${details.userEmail}`
                }
              ]
            }
          ],
          country: details.country,
          templateId: config.templateID,
          queryParam: null,
          source: 'patientWebsite'
        },
        {
          headers: headers
        })
      .then(response => {
        resolve(response)
      })
      .catch(err => { console.log(err.response); reject(err) })
  })
}
