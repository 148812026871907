import React, { useEffect, useState } from 'react'
import { Select, Row, Col, Form, DatePicker, Button, Spin, message, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import styles from './InjectionTracker.module.css'
import { CloseCircleOutlined, LoadingOutlined, InfoCircleOutlined } from '@ant-design/icons'
import * as moment from 'moment'
import { post, get } from '../../ApiCall'
import { getCfg } from '../../../env.config'
import SharingModal from '../../SharingModal'

const InjectionTracker = ({ setVisible, value, setValue, ID, injections, callback, loaded, setLoaded }) => {
  const { t } = useTranslation()
  const { Option } = Select
  const [follow, setFollow] = useState({ loaded: false, data: [] })
  const [open, setOpen] = useState(false)
  useEffect(() => {
    if (ID !== null) {
      const filteredState = injections.filter((item) => {
        return item.Id === ID
      })
      setValue({
        date: moment(filteredState[0].Date_Injected__c),
        place: filteredState[0].Location__c
      })
    }
  }, [ID, injections, setValue])

  useEffect(() => {
    if (!follow.loaded) {
      get('follow/status', {}, setFollow)
    }
  }, [follow.loaded])

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

  const postData = {
    Date_Injected: moment(value.date).format('YYYY-MM-DD'),
    Location: value.place,
    Status: 'Confirmed'
  }
  const editData = {
    Id: ID,
    Date_Injected: moment(value.date).format('YYYY-MM-DD'),
    Location: value.place,
    Status: 'Confirmed'
  }
  const address = `https://${window.location.href.split('/')[2]}`
  const env = getCfg(address)

  const onChange = (value, name) => {
    const insertValue = value === undefined ? '' : value
    setValue((oldState) => ({
      ...oldState,
      [name]: insertValue
    }))
  }

  const validate = () => {
    if (value.date !== null && value.place !== null) {
      return true
    } else {
      return false
    }
  }

  const disabledDate = (current) => {
    return current > moment().endOf('day')
  }

  const getLocation = (value) => {
    switch (value) {
      case 'LEFT_ARM':
        return env.injectionTracker.leftarm
      case 'RIGHT_ARM':
        return env.injectionTracker.rightarm
      case 'UPPER_THIGHS_LEFT':
        return env.injectionTracker.leftleg
      case 'UPPER_THIGHS_RIGHT':
        return env.injectionTracker.rightleg
      case 'BELLY_LEFT':
        return env.injectionTracker.leftabdomen
      case 'BELLY_RIGHT':
        return env.injectionTracker.rightabdomen
      default:
        return env.injectionTracker.default
    }
  }

  const getMethods = () => {
    if (ID !== null) {
      setLoaded(true)
      post('injectionTracker/', editData, callback)
    } else {
      if (follow.data[0]) {
        if (follow.data[0].Follow_patient__pc) {
          setOpen(true)
        } else {
          setLoaded(true)
          post('injectionTracker/', postData, callback)
        }
      } else {
        message.info('something went wrong')
      }
    }
  }

  const closeModal = () => {
    setOpen(false)
    setLoaded(true)
    post('injectionTracker/', postData, callback)
  }

  const isChecked = follow.data[0] ? follow.data[0].Share_Injection_Tracker__pc : false
  const imageName = value.place === null ? 'Default' : value.place === undefined ? 'Default' : value.place

  return (
    <Row align='top' justify='left' className={styles.modalRoot}>
      <Col span={24}>
        <CloseCircleOutlined className={styles.closeIcon} onClick={setVisible} style={{ color: env.colors.primaryColor }} />
      </Col>
      <Col span={24} className={styles.root}>
        <Row>
          <Col xs={24} sm={24} md={13} lg={13} xl={13} xxl={13} className={styles.rootColLeft}>
            <Row>
              <Col span={24}>
                <h1
                  className={styles.injectionTitle}
                  style={{
                    color: env.colors.primaryColor
                  }}>
                  {t('injection.tracker.title')}
                </h1>
              </Col>
              <Col span={24} className={styles.formClass}>
                <Form name='basic'>
                  <Form.Item>
                    <DatePicker
                      className={styles.datePicker}
                      format='DD/MM/YYYY'
                      onChange={(date) => onChange(date, 'date')}
                      disabledDate={env.injectionTracker.pickerValidation ? disabledDate : env.injectionTracker.pickerValidation}
                      value={value.date}
                      inputReadOnly
                      style={{
                        width: '100%',
                        border: '1px solid',
                        borderColor: env.colors.borderColor,
                        borderRadius: 10,
                        fontSize: 18
                      }}
                      dropdownClassName={env.countryClass}
                      placeholder={t('injection.tracker.date_label')}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Select
                      allowClear
                      size='large'
                      bordered={false}
                      className={styles.trackerSelect}
                      value={value.place}
                      style={{
                        fontSize: '18px',
                        width: '100%',
                        backgroundColor: '#fff'
                      }}
                      placeholder={t('injection.tracker.location_label')}
                      onChange={(value) => onChange(value, 'place')}
                      dropdownClassName={env.countryClass}>
                      <Option value='LEFT_ARM'>{t('injection.tracker.options.1')}</Option>
                      <Option value='RIGHT_ARM'>{t('injection.tracker.options.2')}</Option>
                      <Option value='BELLY_LEFT'>{t('injection.tracker.options.3')}</Option>
                      <Option value='BELLY_RIGHT'>{t('injection.tracker.options.4')}</Option>
                      <Option value='UPPER_THIGHS_LEFT'>{t('injection.tracker.options.5')}</Option>
                      <Option value='UPPER_THIGHS_RIGHT'>{t('injection.tracker.options.6')}</Option>
                    </Select>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={11} lg={11} xl={11} xxl={11} className={styles.rootColRight} style={{ textAlign: 'right' }}>
            <img src={getLocation(imageName)} alt='injection location' />
            <Tooltip
              placement='leftTop'
              title={t('injection.tracker.info')}
              trigger='click'
              overlayClassName='injection-info'
              overlayStyle={{
                fontSize: 16,
                lineHeight: '20px',
                paddingRight: 0
              }}>
              <InfoCircleOutlined className={styles.infoIcon} style={value.place !== 'LEFT_ARM' && value.place !== 'RIGHT_ARM' ? { display: 'none' } : null} />
            </Tooltip>
          </Col>
          <Col span={24} className={styles.buttonClass}>
            <Spin indicator={antIcon} spinning={loaded}>
              <Button
                style={
                  validate()
                    ? {
                        backgroundColor: env.colors.primaryColor,
                        color: '#fff'
                      }
                    : {
                        backgroundColor: '#ccc',
                        color: '#fff'
                      }
                }
                className={styles.button}
                onClick={getMethods}
                disabled={!validate()}>
                {t('injection.reminder.button')}
              </Button>
            </Spin>
          </Col>
        </Row>
      </Col>
      <SharingModal follow={follow} visible={open} close={closeModal} field='INJECTION_TRACKER' isChecked={isChecked} />
    </Row>
  )
}

export default InjectionTracker
