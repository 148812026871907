import React from 'react'
import { Row, Col, Form, Select, DatePicker } from 'antd'
const OtherView = ({
  form,
  styles,
  values,
  handleSelectValue,
  t,
  doctors,
  hospitals,
  condition,
}) => {
  const { Option } = Select
  return (
    <Row>
      <Col xs={24} sm={24} md={24} lg={11} xl={11} xxl={11}>
        <Form form={form}>
          <p className={styles.label}>
            {t('profile.treatment.labels.condition')}
          </p>
          <Form.Item name='condition' className={styles.formItem}>
            <Select
              size='large'
              className={styles.profileSelect}
              allowClear
              defaultValue={values.condition}
              style={{
                width: '100%',
                backgroundColor: '#fff',
              }}
              onChange={(value) => handleSelectValue(value, 'condition')}>
              {condition.map((val) => {
                return (
                  <Option key={val.apiname} value={val.apiname}>
                    {val.name}
                  </Option>
                )
              })}
            </Select>
          </Form.Item>
          <p className={styles.label}>{t('profile.treatment.labels.doctor')}</p>
          <Form.Item
            name='doctor'
            className={styles.formItem}
            rules={[
              {
                required: true,
                message: 'Please input your doctor!',
              },
            ]}>
            <Select
              size='large'
              className={styles.profileSelect}
              allowClear
              defaultValue={values.doctor === '' ? null : values.doctor}
              onChange={(value) => handleSelectValue(value, 'doctor')}
              style={{
                fontSize: '18px',
                width: '100%',
                backgroundColor: '#fff',
              }}>
              {Array.isArray(doctors.data)
                ? doctors.data.map((val, i) => {
                    return (
                      <Option key={i} value={val.Id}>
                        {val.FirstName + ' ' + val.LastName}
                      </Option>
                    )
                  })
                : null}
            </Select>
          </Form.Item>
          <p className={styles.label}>
            {t('profile.treatment.labels.hospital')}
          </p>
          <Form.Item
            name='hospital'
            className={styles.formItem}
            rules={[
              {
                required: true,
                message: 'Please input your hospital!',
              },
            ]}>
            <Select
              size='large'
              defaultValue={values.hospital === '' ? null : values.hospital}
              className={styles.profileSelect}
              allowClear
              onChange={(value) => handleSelectValue(value, 'hospital')}
              style={{
                fontSize: '18px',
                width: '100%',
                backgroundColor: '#fff',
              }}>
              {Array.isArray(hospitals.data)
                ? hospitals.data.map((val, i) => {
                    return (
                      <Option key={i} value={val.Id}>
                        {val.Name}
                      </Option>
                    )
                  })
                : null}
            </Select>
          </Form.Item>
        </Form>
      </Col>
      <Col span={2} />
      <Col
        className={styles.otherDesktop}
        xs={24}
        sm={24}
        md={24}
        lg={11}
        xl={11}
        xxl={11}>
        <Form form={form}>
          <p className={styles.label}>
            {t('profile.treatment.labels.treatment')}
          </p>
          <Form.Item name='treatment' className={styles.formItem}>
            <Select
              size='large'
              className={styles.profileSelect}
              allowClear
              style={{
                width: '100%',
                backgroundColor: '#fff',
              }}
              onChange={(value) => handleSelectValue(value, 'treatment')}>
              <Option value='subcutaneous'>Subcutaneous</Option>
              <Option value='intravenous'>Intravenous</Option>
            </Select>
          </Form.Item>
          <p className={styles.label}>
            {t('profile.treatment.labels.start-date')}
          </p>
          <Form.Item name='date' className={styles.formItem}>
            <DatePicker
              className={styles.datepicker}
              name='start_date'
              format='DD-MM-YYYY'
              placeholder=''
              style={{
                width: '100%',
                borderRadius: 15,
              }}
              onChange={(date) => handleSelectValue(date, 'start_date')}
            />
          </Form.Item>
        </Form>
      </Col>
    </Row>
  )
}

export default OtherView
