import React from 'react'
import { Menu, Collapse } from 'antd'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styles from './../Items.module.css'
import { getCfg } from '../../../../env.config'
const { Panel } = Collapse

const MobileItems = ({ treatment, handleClick, menuItems, supportedCulture, medicationStyle }) => {
  const { t } = useTranslation()
  const address = `https://${window.location.href.split('/')[2]}`
  const env = getCfg(address)

  const condition = treatment.data[0] ? treatment.data[0].Condition__c : 'CD'

  const categories =
    condition === 'CD'
      ? [
          {
            id: '1',
            name: t('navigation.categories.category1.code'),
            icon: t('navigation.categories.category1.icon'),
            text: t('navigation.categories.category1.name'),
            route: '/cd-me'
          },
          {
            id: '2',
            name: t('navigation.categories.category2.code'),
            icon: t('navigation.categories.category2.icon'),
            text: t('navigation.categories.category2.name'),
            route: '/wellbeing'
          },
          {
            id: '3',
            name: t('navigation.categories.category3.code'),
            icon: t('navigation.categories.category3.icon'),
            text: t('navigation.categories.category3.name'),
            route: '/nutrition'
          },
          {
            id: '4',
            name: t('navigation.categories.category4.code'),
            icon: t('navigation.categories.category4.icon'),
            text: t('navigation.categories.category4.name'),
            route: '/recipes'
          },
          {
            id: '5',
            name: t('navigation.categories.category5.code'),
            icon: t('navigation.categories.category5.icon'),
            text: t('navigation.categories.category5.name'),
            route: '/travel'
          }
        ]
      : [
          {
            id: '1',
            name: t('navigation.categories.category7.code'),
            icon: t('navigation.categories.category7.icon'),
            text: t('navigation.categories.category7.name'),
            route: '/uc-me'
          },
          {
            id: '2',
            name: t('navigation.categories.category2.code'),
            icon: t('navigation.categories.category2.icon'),
            text: t('navigation.categories.category2.name'),
            route: '/wellbeing'
          },
          {
            id: '3',
            name: t('navigation.categories.category3.code'),
            icon: t('navigation.categories.category3.icon'),
            text: t('navigation.categories.category3.name'),
            route: '/nutrition'
          },
          {
            id: '4',
            name: t('navigation.categories.category4.code'),
            icon: t('navigation.categories.category4.icon'),
            text: t('navigation.categories.category4.name'),
            route: '/recipes'
          },
          {
            id: '5',
            name: t('navigation.categories.category5.code'),
            icon: t('navigation.categories.category5.icon'),
            text: t('navigation.categories.category5.name'),
            route: '/travel'
          }
        ]

  const trackerItems =
    env.countryClass === 'canada'
      ? [
          {
            id: '0',
            icon: '/images/CA/enrollment@2x.png',
            text: t('sidebar.items.0'),
            route: '/enrolment-tracker'
          },
          {
            id: '1',
            icon: '/images/CA/bradshaw@2x.png',
            text: treatment.data[0] ? (treatment.data[0].Condition__c === 'CD' ? t('sidebar.items.8') : t('sidebar.items.7')) : t('sidebar.items.8'),
            route: treatment.data[0] ? (treatment.data[0].Condition__c === 'CD' ? '/harvey-bradshaw-index' : '/partial-mayo-index') : '/harvey-bradshaw-index'
          },
          {
            id: '2',
            icon: '/images/CA/appointments@2x.png',
            text: t('sidebar.items.2'),
            route: '/appointments'
          },
          {
            id: '3',
            icon: '/images/CA/medication@2x.png',
            text: t('sidebar.items.3'),
            route: '/medications'
          },
          {
            id: '4',
            icon: '/images/CA/injection-tracker@2x.png',
            text: t('sidebar.items.4'),
            route: '/injections'
          },
          {
            id: '5',
            icon: '/images/CA/injection-reminder@2x.png',
            text: t('sidebar.items.5'),
            route: '/injection-reminder'
          }
        ]
      : [
          {
            id: '1',
            icon: '/images/icon-IBD-disk@2x.png',
            text: t('sidebar.items.1.main'),
            route: '/ibd-disk'
          },
          {
            id: '2',
            icon: '/images/appointment_tracker_2x.png',
            text: t('sidebar.items.2'),
            route: '/appointments'
          },
          {
            id: '3',
            icon: '/images/appointment_tracker_2x.png',
            text: t('sidebar.items.3'),
            route: '/medications'
          },
          {
            id: '4',
            icon: '/images/icon-injection-tracker@2x.png',
            text: t('sidebar.items.4'),
            route: '/injections'
          },
          {
            id: '5',
            icon: '/images/icon-injection-reminder@2x.png',
            text: t('sidebar.items.5'),
            route: '/injection-reminder'
          }
        ]

  const CustomMenu = (props) => {
    return <Menu.Item {...props}>{props.children}</Menu.Item>
  }

  const getStyle = (data, route) => {
    if (data[0]) {
      if (data[0].Medication__c !== 'Entyvio') {
        if (route === '/injections' || route === '/injection-reminder') return { display: 'none' }
        return null
      } else {
        if (route === '/medications') return { display: 'none' }
        return null
      }
    } else {
      return null
    }
  }

  return (
    <Menu mode='inline' className={styles.menuStyle}>
      <Collapse
        bordered={false}
        accordion={false}
        expandIconPosition='right'
        style={{ border: 'none', backgroundColor: 'transparent', fontSize: 18, borderRadius: 0 }}
        className='mobile-menu'>
        <Panel
          header={t('navigation.panel.articles')}
          key='1'
          style={{
            border: 'none',
            backgroundColor: 'transparent',
            paddingBottom: 10,
            fontWeight: 500,
            borderBottom: '1px solid #fff'
          }}
          className={styles.panel}>
          {categories.map((item) => (
            <CustomMenu
              key={item.id}
              className={window.location.pathname === '/articles' + item.route ? `${styles.item} ant-menu-item-selected` : styles.itemW}
              title={item.text}
              onClick={() => handleClick(item.text)}
              onItemHover={() => {}}
              style={!menuItems.includes(item.name) ? { display: 'none' } : null}>
              <div style={{ fontSize: 16, fontWeight: 400 }}>
                <Link
                  to={`/articles${item.route}?culture=${supportedCulture()}`}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    color: env.colors.mobileNavigationColor
                  }}>
                  <img style={{ width: 32, marginRight: 10 }} src={item.icon} alt='icon' />
                  <span>{item.text}</span>
                </Link>
              </div>
            </CustomMenu>
          ))}
          <CustomMenu
            className={window.location.pathname === '/articles/videos' ? `${styles.item} ant-menu-item-selected` : styles.itemW}
            title={t('navigation.items.6')}
            onClick={() => handleClick(t('navigation.items.6'))}
            onItemHover={() => {}}
            style={!menuItems.includes('videos') ? { display: 'none' } : null}>
            <div style={{ fontSize: 16, fontWeight: 400 }}>
              <Link
                to={`/articles/videos?culture=${supportedCulture()}`}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  color: env.colors.mobileNavigationColor
                }}>
                <img
                  src={t('navigation.categories.category8.icon')}
                  alt={t('navigation.categories.category8.name')}
                  style={{ width: 32, verticalAlign: 'middle', marginRight: 10 }}
                />
                <span>{t('navigation.items.6')}</span>
              </Link>
            </div>
          </CustomMenu>
          <CustomMenu
            className={window.location.pathname === '/articles/sexuality' ? `${styles.item} ant-menu-item-selected` : styles.itemW}
            title={t('navigation.categories.category6.name')}
            onClick={() => handleClick(t('navigation.categories.category6.name'))}
            onItemHover={() => {}}
            style={!menuItems.includes(t('navigation.categories.category6.code')) ? { display: 'none' } : null}>
            <div style={{ fontSize: 16, fontWeight: 400 }}>
              <Link
                to={`/articles/sexuality?culture=${supportedCulture()}`}
                style={{
                  color: env.colors.mobileNavigationColor
                }}>
                <img
                  src={t('navigation.categories.category6.icon')}
                  alt={t('navigation.categories.category6.name')}
                  style={{ width: 32, verticalAlign: 'middle', marginRight: 10 }}
                />
                <span>{t('navigation.categories.category6.name')}</span>
              </Link>
            </div>
          </CustomMenu>
          <CustomMenu
            className={window.location.pathname === '/articles/patient-organization' ? `${styles.item} ant-menu-item-selected` : styles.itemW}
            title={t('navigation.categories.category11.name')}
            onClick={() => handleClick(t('navigation.categories.category11.name'))}
            onItemHover={() => {}}
            style={!menuItems.includes(t('navigation.categories.category11.code')) ? { display: 'none' } : null}>
            <div style={{ fontSize: 16, fontWeight: 400 }}>
              <Link
                to={`/articles/patient-organization?culture=${supportedCulture()}`}
                style={{
                  color: env.colors.mobileNavigationColor
                }}>
                <img
                  src={t('navigation.categories.category11.icon')}
                  alt={t('navigation.categories.category11.name')}
                  style={{ width: 32, verticalAlign: 'middle', marginRight: 10 }}
                />
                <span>{t('navigation.categories.category11.name')}</span>
              </Link>
            </div>
          </CustomMenu>

          {/* this is the faq */}
          <CustomMenu
            className={window.location.pathname === '/articles/faq' ? `${styles.item} ant-menu-item-selected` : styles.itemW}
            title={t('navigation.categories.category12.name')}
            onClick={() => handleClick(t('navigation.categories.category12.name'))}
            onItemHover={() => {}}
            style={!menuItems.includes(t('navigation.categories.category12.code')) ? { display: 'none' } : null}>
            <div style={{ fontSize: 16, fontWeight: 400 }}>
              <Link
                to={`/articles/faq?culture=${supportedCulture()}`}
                style={{
                  color: env.colors.mobileNavigationColor
                }}>
                <img
                  src={t('navigation.categories.category12.icon')}
                  alt={t('navigation.categories.category12.name')}
                  style={{ width: 32, verticalAlign: 'middle', marginRight: 10 }}
                />
                <span>{t('navigation.categories.category12.name')}</span>
              </Link>
            </div>
          </CustomMenu>

          {/* this is the privacy */}
          <CustomMenu
            className={window.location.pathname === '/articles/privacy' ? `${styles.item} ant-menu-item-selected` : styles.itemW}
            title={t('navigation.categories.category13.name')}
            onClick={() => handleClick(t('navigation.categories.category13.name'))}
            onItemHover={() => {}}
            style={!menuItems.includes(t('navigation.categories.category13.code')) ? { display: 'none' } : null}>
            <div style={{ fontSize: 16, fontWeight: 400 }}>
              <Link
                to={`/articles/privacy?culture=${supportedCulture()}`}
                style={{
                  color: env.colors.mobileNavigationColor
                }}>
                <img
                  src={t('navigation.categories.category13.icon')}
                  alt={t('navigation.categories.category13.name')}
                  style={{ width: 32, verticalAlign: 'middle', marginRight: 10 }}
                />
                <span>{t('navigation.categories.category13.name')}</span>
              </Link>
            </div>
          </CustomMenu>

          <CustomMenu
            title={t('navigation.categories.category10.name')}
            onClick={() => handleClick(t('navigation.categories.category10.name'))}
            onItemHover={() => {}}
            style={
              env.enhancements.includes('opf')
                ? {
                    marginBottom: 15,
                    justifyContent: 'left',
                    display: 'flex'
                  }
                : { display: 'none' }
            }>
            <div style={{ fontSize: 16, fontWeight: 400 }}>
              <a
                href='https://stag-fywyforum.hartehanks.com/'
                target='_blank'
                rel='noopener noreferrer'
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  color: env.colors.mobileNavigationColor
                }}>
                <img
                  src={t('navigation.categories.category10.icon')}
                  alt={t('navigation.categories.category10.name')}
                  style={{ width: 32, verticalAlign: 'middle', marginRight: 10 }}
                />
                <span>{t('navigation.categories.category10.name')}</span>
              </a>
            </div>
          </CustomMenu>
          <div className={styles.entyvioContainer} style={medicationStyle}>
            <Link to={`/articles/entyvio?culture=${supportedCulture()}`}>
              <img src={env.entyvioLogo} alt='entyvio' className={styles.entyvio} />
            </Link>
          </div>
          <div className={styles.onepathContainer} style={!env.onepathButton ? { display: 'none' } : null}>
            <a href='https://onepath.ca' target='_blank' rel='noopener noreferrer'>
              <span style={{ fontWeight: 500, fontSize: 18 }}>Onepath</span>
              <span style={{ fontSize: 12 }}>website</span>
            </a>
          </div>
        </Panel>
        <Panel
          header={t('navigation.panel.trackers')}
          key='2'
          style={{
            border: 'none',
            backgroundColor: 'transparent',
            paddingTop: 10,
            fontWeight: 500
          }}
          className={styles.panel}>
          {trackerItems.map((item) => (
            <CustomMenu
              key={item.id}
              className={window.location.pathname === item.route ? `${styles.item} ant-menu-item-selected` : styles.itemW}
              title={item.text}
              onClick={() => handleClick(item.text)}
              onItemHover={() => {}}
              style={getStyle(treatment.data, item.route)}>
              <div style={{ fontSize: 16, fontWeight: 400 }}>
                <Link
                  to={`${item.route}?culture=${supportedCulture()}`}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    color: env.colors.mobileNavigationColor
                  }}>
                  <img style={{ width: 30, marginRight: 13 }} src={item.icon} alt='icon' />
                  <span>{item.text}</span>
                </Link>
              </div>
            </CustomMenu>
          ))}
          <CustomMenu
            className={window.location.pathname === '/survey' ? `${styles.item} ant-menu-item-selected` : styles.itemW}
            title={t('sidebar.items.6')}
            onClick={() => handleClick(t('sidebar.items.6'))}
            onItemHover={() => {}}>
            <div style={{ fontSize: 16, fontWeight: 400, display: 'flex', alignItems: 'center' }}>
              <Link
                to={`/survey?culture=${supportedCulture()}`}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  color: env.colors.mobileNavigationColor
                }}>
                <img style={{ width: 30, marginRight: 13 }} src={env.surveyIcon} alt='icon' />
                <span>{t('sidebar.items.6')}</span>
              </Link>
            </div>
          </CustomMenu>
        </Panel>
      </Collapse>
    </Menu>
  )
}

export default MobileItems
