import React, { useEffect } from 'react'
import {
  Row,
  Col,
  Button,
  Form,
  Checkbox,
  DatePicker,
  TimePicker,
  Select,
  Tooltip,
  Spin,
} from 'antd'
import {
  AlertOutlined,
  CloseCircleOutlined,
  BulbOutlined,
  LoadingOutlined,
} from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import styles from './MedicationReminder.module.css'
import * as moment from 'moment'
import { post } from '../../ApiCall'
import { getCfg } from '../../../env.config'

const MedicationReminder = ({
  values,
  setValues,
  setVisible,
  medication,
  callback,
  loaded,
  setLoaded,
}) => {
  const { t } = useTranslation()
  const dateFormat = 'DD/MM/YYYY'
  const { Option } = Select

  const address = `https://${window.location.href.split('/')[2]}`
  const env = getCfg(address)
  const antIcon = (
    <LoadingOutlined
      style={{ fontSize: 24, color: env.colors.primaryColor }}
      spin
    />
  )

  useEffect(() => {
    if (medication.data[0]) {
      if (medication.data[0].Id) {
        setValues((oldValues) => ({
          ...oldValues,
          id: medication.data[0].Id,
          medicationDate: moment(medication.data[0].Next_Reminder_Date__pc),
          frequency: medication.data[0].Medication_frequency__pc
            ? medication.data[0].Medication_frequency__pc
            : null,
          emailNotification: medication.data[0].Reminder_Email_Notification__pc,
          reminderTime:
            medication.data[0].Medication_frequency__pc > 1
              ? moment(medication.data[0].Reminder_Datetime__pc)
              : null,
          appNotification: medication.data[0].Reminder_App_Notification__pc,
          morning: medication.data[0].Morning_reminder__pc,
          midday: medication.data[0].Midday_Reminder__pc,
          evening: medication.data[0].Evening_Reminder__pc,
          morningTime: medication.data[0].Morning_reminder__pc
            ? moment(medication.data[0].Morning_Reminder_DateTime__pc)
            : null,
          middayTime: medication.data[0].Midday_Reminder__pc
            ? moment(medication.data[0].Midday_Reminder_Datetime__pc)
            : null,
          eveningTime: medication.data[0].Evening_Reminder__pc
            ? moment(medication.data[0].Evening_Reminder_Datetime__pc)
            : null,
        }))
      }
    }
  }, [medication.data, setValues])

  const getDateTime = (date) => {
    const DATE = moment(values.medicationDate).format('YYYY-MM-DD')
    let hour
    if (date === null) {
      hour = new Date()
    } else {
      hour = date
    }

    const TIME = moment(hour).format('HH:mm')
    const combinedDateTime = moment(DATE + ' ' + TIME, 'YYYY-MM-DD HH:mm')
    return combinedDateTime.format('YYYY-MM-DD HH:mm')
  }

  const postData =
    values.frequency === 1
      ? {
          nextMedicationDate: moment(values.medicationDate).format(
            'YYYY-MM-DD'
          ),
          reminderTime: moment(values.medicationDate).format('YYYY-MM-DD'),
          medicationFrequency: values.frequency,
          medicationAppNotification: values.appNotification,
          medicationEmailNotification: values.emailNotification,
          morningReminder: values.morning,
          morningTime: new Date(getDateTime(values.morningTime)),
          middayReminder: values.midday,
          middayTime: new Date(getDateTime(values.middayTime)),
          eveningReminder: values.evening,
          eveningTime: new Date(getDateTime(values.eveningTime)),
        }
      : {
          nextMedicationDate: moment(values.medicationDate).format(
            'YYYY-MM-DD'
          ),
          reminderTime: new Date(getDateTime(values.reminderTime)),
          medicationFrequency: values.frequency,
          medicationAppNotification: values.appNotification,
          medicationEmailNotification: values.emailNotification,
          morningReminder: values.morning,
          middayReminder: values.midday,
          eveningReminder: values.evening,
        }

  const handlePost = () => {
    setLoaded(true)
    post('medicationReminder', postData, callback)
  }

  const handleChange = (value, name) => {
    setValues((oldState) => ({
      ...oldState,
      [name]: value,
    }))
  }

  const handleClose = () => {
    if (setVisible) {
      setVisible()
    }
    setValues({
      frequency: null,
      medicationDate: null,
      appNotification: false,
      emailNotification: false,
      morning: false,
      midday: false,
      evening: false,
      reminderTime: null,
      selectedValues: [],
      id: null,
    })
  }

  const handleCheckBoxChange = (value) => {
    switch (value.target.name) {
      case 'morning':
        return setValues((oldState) => ({
          ...oldState,
          [value.target.name]: !values.morning,
        }))
      case 'midday':
        return setValues((oldState) => ({
          ...oldState,
          [value.target.name]: !values.midday,
        }))
      default:
        return setValues((oldState) => ({
          ...oldState,
          [value.target.name]: !values.evening,
        }))
    }
  }

  const validate = () => {
    if (values.frequency !== null && values.medicationDate !== null) {
      if (values.frequency === 1) {
        if (values.morning && values.midday && values.evening) {
          if (values.morningTime && values.middayTime && values.eveningTime) {
            return false
          } else {
            return true
          }
        } else if (values.morning && values.midday) {
          if (values.morningTime && values.middayTime) {
            return false
          } else {
            return true
          }
        } else if (values.morning && values.evening) {
          if (values.morningTime && values.eveningTime) {
            return false
          } else {
            return true
          }
        } else if (values.evening && values.midday) {
          if (values.eveningTime && values.middayTime) {
            return false
          } else {
            return true
          }
        } else if (values.morning) {
          if (values.morningTime) {
            return false
          } else {
            return true
          }
        } else if (values.midday) {
          if (values.middayTime) {
            return false
          } else {
            return true
          }
        } else if (values.evening) {
          if (values.eveningTime) {
            return false
          } else {
            return true
          }
        } else {
          return true
        }
      } else {
        if (values.reminderTime && values.reminderTime !== null) {
          return false
        } else {
          return true
        }
      }
    } else {
      return true
    }
  }
  const disabledDate = (current) => {
    return (
      current < moment().subtract(1, 'days').endOf('day') ||
      current > moment().add(12, 'months')
    )
  }

  const getCheckboxView = () => {
    return (
      <div>
        <div className={styles.reminder}>
          <Checkbox
            onChange={handleCheckBoxChange}
            // checked={values.morning}
            defaultChecked={values.morning}
            className={styles.checkbox}
            name='morning'
          />
          <span
            className={styles.reminderText}
            style={{ color: env.colors.cardTitle }}>
            {t('medication.reminder.settings.timecheck.1')}
          </span>
          <TimePicker
            className={styles.timePicker}
            placeholder={t('medication.reminder.settings.label')}
            onChange={(date) => handleChange(date, 'morningTime')}
            value={values.morningTime}
            format='HH:mm'
            suffixIcon
            size='large'
            style={{
              border: '1px solid',
              borderColor: env.colors.borderColor,
              borderRadius: 10,
              fontSize: '18px',
              height: '35px',
            }}
            dropdownClassName={env.countryClass}
            disabledHours={() => [
              12,
              13,
              14,
              15,
              16,
              17,
              18,
              19,
              20,
              21,
              22,
              23,
            ]}
          />
        </div>
        <div className={styles.reminder}>
          <Checkbox
            onChange={handleCheckBoxChange}
            checked={values.midday}
            className={styles.checkbox}
            name='midday'
          />
          <span
            className={styles.reminderText}
            style={{ color: env.colors.cardTitle }}>
            {t('medication.reminder.settings.timecheck.2')}
          </span>
          <TimePicker
            className={styles.timePicker}
            placeholder={t('medication.reminder.settings.label')}
            onChange={(date) => handleChange(date, 'middayTime')}
            value={values.middayTime}
            format='HH:mm'
            size='large'
            suffixIcon
            style={{
              border: '1px solid',
              borderColor: env.colors.borderColor,
              borderRadius: 10,
              fontSize: '18px',
              height: '35px',
            }}
            dropdownClassName={env.countryClass}
            disabledHours={() => [
              0,
              1,
              2,
              3,
              4,
              5,
              6,
              7,
              8,
              9,
              10,
              11,
              19,
              20,
              21,
              22,
              23,
            ]}
          />
        </div>
        <div className={styles.reminder}>
          <Checkbox
            onChange={handleCheckBoxChange}
            checked={values.evening}
            className={styles.checkbox}
            name='evening'
          />
          <span
            className={styles.reminderText}
            style={{ color: env.colors.cardTitle }}>
            {t('medication.reminder.settings.timecheck.3')}
          </span>
          <TimePicker
            className={styles.timePicker}
            placeholder={t('medication.reminder.settings.label')}
            onChange={(date) => handleChange(date, 'eveningTime')}
            value={values.eveningTime}
            format='HH:mm'
            size='large'
            suffixIcon
            style={{
              border: '1px solid',
              borderColor: env.colors.borderColor,
              borderRadius: 10,
              fontSize: '18px',
              height: '35px',
            }}
            dropdownClassName={env.countryClass}
            disabledHours={() => [
              0,
              1,
              2,
              3,
              4,
              5,
              6,
              7,
              8,
              9,
              10,
              11,
              12,
              13,
              14,
              15,
              16,
              17,
              18,
            ]}
          />
        </div>
      </div>
    )
  }
  const getRadioButtonView = () => {
    return (
      <div className={styles.reminder}>
        <TimePicker
          className={styles.singletimePicker}
          placeholder={t('medication.reminder.settings.label')}
          onChange={(date) => handleChange(date, 'reminderTime')}
          format='HH:mm'
          suffixIcon
          size='large'
          value={values.reminderTime}
          style={{
            border: '1px solid',
            borderColor: env.colors.borderColor,
            borderRadius: 10,
            fontSize: '18px',
          }}
          dropdownClassName={env.countryClass}
        />
      </div>
    )
  }
  const daysArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]
  const weeksArray = [14, 21, 28, 35, 42, 49, 56, 63, 70, 77, 84]

  return (
    <Row align='top' className={styles.modalRoot}>
      <Col span={24}>
        <CloseCircleOutlined
          className={styles.closeIcon}
          onClick={handleClose}
          style={{ color: env.colors.primaryColor }}
        />
      </Col>
      <Col span={24} className={styles.root}>
        <Row>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={11}
            xl={11}
            xxl={11}
            className={styles.rootColLeft}>
            <Row>
              <Col span={24}>
                <p
                  className={styles.paragraph}
                  style={{ color: env.colors.primaryColor }}>
                  {t('medication.reminder.title.2')}
                </p>
              </Col>
              <Col span={24}>
                <Form name='basic' id='injection-reminder-form'>
                  <Form.Item style={{ marginBottom: 10 }}>
                    <p className={styles.frequency}>
                      {t('medication.reminder.frequency.text')}
                    </p>
                    <Select
                      size='large'
                      bordered={false}
                      placeholder={t('medication.reminder.frequency.label')}
                      className={styles.medicationSelect}
                      allowClear
                      onChange={(value) => handleChange(value, 'frequency')}
                      value={values.frequency}
                      style={{
                        fontSize: '18px',
                        width: '100%',
                        backgroundColor: '#fff',
                        height: 38,
                      }}>
                      {daysArray.map((number, i) => {
                        const everyDay = `${t(
                          'medication.reminder.frequency.options.every'
                        )} ${t('medication.reminder.frequency.options.day')}`
                        const restOf = `${t(
                          'medication.reminder.frequency.options.every'
                        )} ${number} ${t(
                          'medication.reminder.frequency.options.days'
                        )}`
                        const title = i === 0 ? everyDay : restOf
                        return (
                          <Option key={number} value={number}>
                            {title}
                          </Option>
                        )
                      })}
                      {weeksArray.map((number, i) => {
                        return (
                          <Option key={number} value={number}>
                            {t('medication.reminder.frequency.options.every')}{' '}
                            {i + 2}{' '}
                            {t('medication.reminder.frequency.options.weeks')}
                          </Option>
                        )
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item>
                    <p className={styles.date}>
                      {t('medication.reminder.date.text')}
                    </p>
                    <DatePicker
                      className={styles.datePicker}
                      onChange={(date) => handleChange(date, 'medicationDate')}
                      format={dateFormat}
                      value={values.medicationDate}
                      disabledDate={disabledDate}
                      inputReadOnly
                      style={{
                        width: '100%',
                        border: '1px solid',
                        borderColor: env.colors.borderColor,
                        height: 38,
                        borderRadius: 10,
                        fontSize: 18,
                      }}
                      dropdownClassName={env.countryClass}
                      placeholder={t('medication.reminder.date.label')}
                    />
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={13}
            xl={13}
            xxl={13}
            className={styles.rootColRight}>
            <div
              className={styles.title}
              style={{ color: env.colors.cardTitle }}>
              <AlertOutlined
                className={styles.icon}
                style={{ color: env.colors.primaryColor }}
              />
              <span>{t('medication.reminder.settings.title')}</span>
            </div>
            <p
              className={`${styles.typeHeader} ${styles.appNotification}`}
              style={{
                color: env.colors.cardTitle,
                borderColor: env.colors.primaryColor,
              }}>
              {t('medication.reminder.settings.type')}
              <Tooltip
                placement='rightTop'
                title={t('medication.reminder.download')}
                overlayStyle={{ maxWidth: 280 }}>
                <BulbOutlined style={{ color: env.colors.primaryColor }} />
              </Tooltip>
            </p>
            <div className={styles.type}>
              <Checkbox
                checked={values.emailNotification}
                className={styles.checkbox}
                onChange={(e) =>
                  handleChange(e.target.checked, 'emailNotification')
                }
              />
              <span
                className={styles.text}
                style={{ color: env.colors.cardTitle }}>
                {t('injection.reminder.email')}
              </span>
            </div>

            <p
              className={styles.timeHeader}
              style={{ color: env.colors.cardTitle }}>
              {t('medication.reminder.settings.time')}
            </p>
            {values.frequency !== 1 ? getRadioButtonView() : getCheckboxView()}
          </Col>
          <Col span={24} className={styles.buttonCol}>
            <Spin indicator={antIcon} spinning={loaded}>
              <Button
                style={
                  !validate()
                    ? {
                        backgroundColor: env.colors.primaryColor,
                        color: '#fff',
                      }
                    : {
                        backgroundColor: '#ccc',
                        color: '#fff',
                      }
                }
                className={styles.button}
                onClick={handlePost}
                disabled={validate()}>
                {t('injection.reminder.button')}
              </Button>
            </Spin>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default MedicationReminder
