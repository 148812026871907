import React, { useState } from 'react'
import { Row, Col, Button, Checkbox, Spin, message } from 'antd'
import { CloseCircleOutlined, LoadingOutlined } from '@ant-design/icons'
import axios from 'axios'
import * as qs from 'query-string'

const Consent = ({ setVisible, t, styles, getCfg, values, consent }) => {
  const [valid, setIsValid] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const address = `https://${window.location.href.split('/')[2]}`
  const env = getCfg(address)

  const [loaded, setLoaded] = useState(false)
  const antIcon = <LoadingOutlined style={{ fontSize: 24, color: env.colors.primaryColor }} spin />
  const onChange = (e) => {
    setIsValid(e.target.checked)
  }

  const handleScroll = (e) => {
    const element = e.target
    if (element.scrollHeight - element.scrollTop <= 400) {
      setDisabled(false)
    }
  }
  const header = {
    'x-client': 'mobile',
    client_id: env.clientId,
    client_secret: env.clientSecret
  }

  const search = window.location.search
  const queryString = qs.parse(search)
  let lang
  let country
  if (queryString.culture !== undefined) {
    lang = queryString.culture.split('-')[0]
    country = queryString.culture.split('-')[1]
  } else {
    lang = env.language
    country = env.country
  }

  // console.log(lang)
  // console.log(country)
  const post = (path, data, callback) => {
    axios
      .post(path, data, { headers: header })
      .then(function (response) {
        callback(response)
      })
      .catch(function (error) {
        // console.log(error.response)
        // console.log(error)
        callback(error.response)
        window.alert(error.response ? error.response.toString() : 'The response is undefined, it is possible to be CORS.. Check the console')
        // window.location.href = '/login'
      })
  }

  const postData = {
    salutation: values.salutation,
    firstName: values.firstname,
    lastName: values.lastname,
    language: lang,
    country: country,
    emailAddress: values.confirmedEmail,
    password: values.confirmedPassword,
    source: 'patientWebsite'
  }

  const handlePost = () => {
    setLoaded(true)
    post(env.registerRoot, postData, (val) => {
      // console.log('this is the response', val)
      if (val) {
        setLoaded(false)
        if (val.status === 200) {
          setVisible((oldValues) => ({
            ...oldValues,
            consent: false,
            confirmation: true
          }))
        } else {
          message.error('There was some issue, please check console')
        }
      } else {
        setLoaded(false)
        message.error('There was some issue, please check console')
      }
    })
  }

  return (
    <Row>
      <Col xs={24} sm={24} md={24} lg={14} xl={14} xxl={14} className={styles.consentRoot}>
        <Row justify='center'>
          <Col span={24} className={styles.closeDiv}>
            <CloseCircleOutlined className={styles.closeIcon} style={{ color: env.colors.primaryColor }} onClick={() => setVisible(false)} />
          </Col>
          <Col xs={24} sm={20} md={20} lg={21} xl={21} xxl={21}>
            <h3 style={{ fontSize: 20, color: '#202124' }}>{t('consent.title')}</h3>
          </Col>
          <Col xs={24} sm={20} md={20} lg={21} xl={21} xxl={21} className={styles.consentPlaceholder} onScroll={handleScroll}>
            <p
              dangerouslySetInnerHTML={{
                __html: consent.data.Digital_ID_Consent__c.replace(/\n/g, '<br />')
              }}
            />
          </Col>
          <Col xs={24} sm={20} md={20} lg={21} xl={21} xxl={21}>
            <hr className={styles.line} />
            <div className={styles.checkbox}>
              <Checkbox disabled={disabled} style={{ marginRight: 15 }} className={env.countryClass} onChange={onChange} />
              <span style={{ fontSize: 16, color: env.colors.primaryColor }}>{t('consent.text')}</span>
            </div>
          </Col>
          <Col xs={24} sm={14} md={14} lg={14} xl={14} xxl={14} className={styles.buttonRegister} style={valid ? {} : { cursor: 'no-drop' }}>
            <Spin indicator={antIcon} spinning={loaded}>
              <Button
                htmlType='submit'
                style={
                  valid
                    ? {
                        backgroundColor: env.colors.primaryColor,
                        border: '1px solid',
                        borderColor: env.colors.primaryColor,
                        color: '#fff'
                      }
                    : {
                        backgroundColor: '#ccc',
                        border: '1px solid #ccc',
                        pointerEvents: 'none',
                        color: '#fff'
                      }
                }
                onClick={handlePost}>
                {t('register.form.button.2')}
              </Button>
            </Spin>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default Consent
