import React, { useEffect, useState } from 'react'
import { Row, Col, Form, Button, Checkbox, Spin } from 'antd'
import { getCfg } from '../../../../env.config'
import { InfoCircleOutlined } from '@ant-design/icons'

const QuestionForm = ({ styles, handleClick, t, icon, loaded, values, setValues }) => {
  const [form] = Form.useForm()
  const address = `https://${window.location.href.split('/')[2]}`
  const env = getCfg(address)
  const [, forceUpdate] = useState()

  useEffect(() => {
    forceUpdate({})
  }, [])

  const handleChange = (name, value) => {
    const insertedValue = value !== undefined ? value : null
    setValues({
      ...values,
      [name]: insertedValue
    })
  }

  const validateForm = () => {
    if (values.stoolFrequency !== null && values.rectalBleeding !== null) {
      return true
    }
    return false
  }

  return (
    <Col span={20} className='survey'>
      <Row>
        <Col xs={24} sm={24} md={24} lg={16} xl={13} xxl={12}>
          <p className={styles.description} style={{ color: '#4C4948' }}>
            {t('mayo.description')}
          </p>
        </Col>
      </Row>

      <Row>
        <Col>
          <h3
            className={styles.questionSubTitle}
            style={{
              color: env.colors.subtitle,
              borderColor: env.colors.primaryColor
            }}>
            {t('mayo.subtitle')}
          </h3>
        </Col>
      </Row>

      <Row>
        <Col xs={24} sm={24} md={24} lg={16} xl={13} xxl={12}>
          <p className={styles.description} style={{ color: '#4C4948', padding: '10px 0' }}>
            {t('mayo.questions.text')}
          </p>
        </Col>
      </Row>

      {/* <Row>
        <Col className={styles.questionContent}>
          <p className={styles.communicationLabel} style={{ color: env.colors.title, fontWeight: 500, fontSize: 18 }}>
            <span>{t('mayo.questions.3.bold')}</span> <span style={{ fontWeight: 'normal' }}>{t('mayo.questions.3.regular')}</span>
            <InfoCircleOutlined style={{ color: env.colors.primaryColor, marginLeft: 10 }} />
          </p>
          <Checkbox
            checked={values.PhysiciansGlobalAssessment === 0}
            onChange={() => handleChange('PhysiciansGlobalAssessment', 0)}
            className={env.countryClass}>
            {t('mayo.questions.3.options.1')}
          </Checkbox>
          <Checkbox
            checked={values.PhysiciansGlobalAssessment === 1}
            onChange={() => handleChange('PhysiciansGlobalAssessment', 1)}
            className={env.countryClass}>
            {t('mayo.questions.3.options.2')}
          </Checkbox>
          <Checkbox
            checked={values.PhysiciansGlobalAssessment === 2}
            onChange={() => handleChange('PhysiciansGlobalAssessment', 2)}
            className={env.countryClass}>
            {t('mayo.questions.3.options.3')}
          </Checkbox>
          <Checkbox
            checked={values.PhysiciansGlobalAssessment === 3}
            onChange={() => handleChange('PhysiciansGlobalAssessment', 3)}
            className={env.countryClass}>
            {t('mayo.questions.3.options.4')}
          </Checkbox>
        </Col>
      </Row> */}

      <Row>
        <Col className={styles.questionContent}>
          <p className={styles.communicationLabel} style={{ color: env.colors.title, fontWeight: 500, fontSize: 18 }}>
            <span>{t('mayo.questions.1.bold')}</span> <span style={{ fontWeight: 'normal' }}>{t('mayo.questions.1.regular')}</span>
            <InfoCircleOutlined style={{ color: env.colors.primaryColor, marginLeft: 10 }} />
          </p>
          <Checkbox checked={values.stoolFrequency === 0} onChange={() => handleChange('stoolFrequency', 0)} className={env.countryClass}>
            {t('mayo.questions.1.options.1')}
          </Checkbox>
          <Checkbox checked={values.stoolFrequency === 1} onChange={() => handleChange('stoolFrequency', 1)} className={env.countryClass}>
            {t('mayo.questions.1.options.2')}
          </Checkbox>
          <Checkbox checked={values.stoolFrequency === 2} onChange={() => handleChange('stoolFrequency', 2)} className={env.countryClass}>
            {t('mayo.questions.1.options.3')}
          </Checkbox>
          <Checkbox checked={values.stoolFrequency === 3} onChange={() => handleChange('stoolFrequency', 3)} className={env.countryClass}>
            {t('mayo.questions.1.options.4')}
          </Checkbox>
        </Col>
      </Row>
      <Row>
        <Col className={styles.questionContent}>
          <p className={styles.communicationLabel} style={{ color: env.colors.title, fontWeight: 500, fontSize: 18 }}>
            <span>{t('mayo.questions.2.bold')}</span> <span style={{ fontWeight: 'normal' }}>{t('mayo.questions.2.regular')}</span>
            <InfoCircleOutlined style={{ color: env.colors.primaryColor, marginLeft: 10 }} />
          </p>
          <Checkbox checked={values.rectalBleeding === 0} onChange={() => handleChange('rectalBleeding', 0)} className={env.countryClass}>
            {t('mayo.questions.2.options.1')}
          </Checkbox>
          <Checkbox checked={values.rectalBleeding === 1} onChange={() => handleChange('rectalBleeding', 1)} className={env.countryClass}>
            {t('mayo.questions.2.options.2')}
          </Checkbox>
          <Checkbox checked={values.rectalBleeding === 2} onChange={() => handleChange('rectalBleeding', 2)} className={env.countryClass}>
            {t('mayo.questions.2.options.3')}
          </Checkbox>
          <Checkbox checked={values.rectalBleeding === 3} onChange={() => handleChange('rectalBleeding', 3)} className={env.countryClass}>
            {t('mayo.questions.2.options.4')}
          </Checkbox>
        </Col>
      </Row>

      <Row>
        <Col xs={24} sm={24} md={24} lg={10} xl={8} xxl={6}>
          <Form form={form}>
            <Form.Item shouldUpdate>
              {() => (
                <Spin indicator={icon} spinning={loaded}>
                  <Button
                    htmlType='submit'
                    className={styles.button}
                    onClick={handleClick}
                    disabled={!validateForm()}
                    style={
                      validateForm()
                        ? {
                            backgroundColor: env.colors.primaryColor,
                            color: '#fff'
                          }
                        : {
                            backgroundColor: '#ccc',
                            color: '#fff'
                          }
                    }>
                    {t('mayo.button')}
                  </Button>
                </Spin>
              )}
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Col>
  )
}

export default QuestionForm
