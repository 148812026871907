import React from 'react'
import { Row, Col, Modal } from 'antd'
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import styles from './Card.module.css'
import * as moment from 'moment'
import { getCfg } from '../../../../env.config'
import { deleteC } from '../../../ApiCall'

const Card = (props) => {
  const { confirm } = Modal
  const address = `https://${window.location.href.split('/')[2]}`
  const env = getCfg(address)
  const { t, Created_Date__c, HBI_score__c, Id, setResults } = props
  const config = {
    hbiId: Id
  }
  const showConfirm = () => {
    confirm({
      title: 'Are you sure you want to delete this item?',
      icon: <ExclamationCircleOutlined />,
      onOk () {
        return new Promise((resolve, reject) => {
          deleteC('hbi/change', config, (val) => {
            Modal.destroyAll()
            setResults(oldValues => ({ ...oldValues, loaded: false }))
          })
        }).catch(() => console.log('Oops errors!'))
      },
      onCancel () { },
      centered: true,
      className: env.countryClass
    })
  }

  return (
    <Row
      align='middle'
      className={styles.root}
      style={{
        backgroundColor: env.colors.cardColor
      }}
    >
      <Col span={12} className={styles.detailColumn}>
        <Row>
          <Col span={24}>
            <span>{moment(Created_Date__c).format('DD, MMMM YYYY')}</span>
          </Col>
          <Col span={24}>
            <span style={{ fontWeight: 500 }}>{t('harvey.main.score')}</span><span style={{ fontWeight: 500 }}>{HBI_score__c}</span><span>{t('harvey.main.points')}</span>
          </Col>
        </Row>
      </Col>
      <Col span={10} style={{ textAlign: 'center' }}>
        <img src='/images/CA/questionare@2x.png' alt='disk' style={{ width: 56, height: 56 }} />
      </Col>
      <Col span={2} className={styles.buttonColumn}>
        <DeleteOutlined
          className={styles.delete}
          onClick={showConfirm}
        />
      </Col>
    </Row>
  )
}

export default Card
