import React, { useState, useEffect, useRef } from 'react'
import { Row, Col, Button } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'
import styles from './IBDHistory.module.css'
import Radar from './../RadarChart'
import * as moment from 'moment'
import { useTranslation } from 'react-i18next'
import * as qs from 'query-string'

const ComparedDate = ({ color, date, dateItem, dateCircle, dateInfo }) => {
  return (
    <div className={dateItem}>
      <div className={dateCircle} style={{ backgroundColor: color }} />
      <span className={dateInfo}>{date}</span>
    </div>
  )
}

const IBDHistory = ({ setVisible, values, clear }) => {
  const [size, setSize] = useState(0)
  const targetRef = useRef()
  const { t } = useTranslation()
  const updateSize = () => {
    setSize(targetRef.current.offsetWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])

  const getSize = () => {
    if (size < 577) {
      return size + 15
    } else {
      return size
    }
  }

  const search = window.location.search
  const queryString = qs.parse(search)
  const lang = queryString
    ? queryString.culture !== '' && queryString.culture !== undefined
      ? queryString.culture.split('-')[0]
      : localStorage.getItem('i18nextLng').split('-')[0]
    : localStorage.getItem('i18nextLng').split('-')[0]

  const getImage = () => {
    if (lang === 'nl') return '/images/disk-DU.png'
    if (lang === 'fr') return '/images/disk-FR.png'
    if (lang === 'hr') return '/images/disk-HR.png'
    return '/images/questionare@2x.png'
  }
  return (
    <Row align='top' className={styles.modalRoot}>
      <Col span={24}>
        <CloseCircleOutlined
          className={styles.closeIcon}
          onClick={() => {
            setVisible('visible', false)
            clear()
          }}
        />
      </Col>
      <Col span={24} className={styles.root}>
        <Row justify='center'>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} className={styles.title}>
            <p>{t('disk.questionare.history.title')}</p>
          </Col>
        </Row>
        <div className={styles.datesPlaceholder}>
          {values.map((val, i) => {
            const color = i === 0 ? '#ED1C24' : i === 1 ? '#000' : '#1C34ED'
            return (
              <ComparedDate
                key={i}
                color={color}
                date={moment(val.Created_Date__c).format('DD/MM/YYYY')}
                dateItem={styles.dateItem}
                dateCircle={styles.dateCircle}
                dateInfo={styles.dateInfo}
              />
            )
          })}
        </div>
        <Row justify='center'>
          <Col xs={24} sm={24} md={18} lg={20} xl={16} xxl={16} className={styles.diskPlaceholder}>
            <img src={getImage()} alt='questionare' className={styles.disk} ref={targetRef} />
            {values.map((val, i) => {
              const color = i === 0 ? '#ED1C24' : i === 1 ? '#000' : '#1C34ED'
              const radarData = [
                {
                  data: {
                    abdominal: val.Abdominal_Pain__c * 0.1 + 0.05,
                    regulating: val.Regulating_Defecation__c * 0.1 + 0.05,
                    interpersonal: val.Interpersonal_Interactions__c * 0.1 + 0.05,
                    education: val.Education_and_Work__c * 0.1 + 0.05,
                    sleep: val.Sleep__c * 0.1 + 0.05,
                    energy: val.Energy__c * 0.1 + 0.05,
                    emotions: val.Emotions__c * 0.1 + 0.05,
                    body: val.Body_Image__c * 0.1 + 0.05,
                    sexual: val.Sexual_Functions__c * 0.1 + 0.05,
                    joint: val.Joint_Pain__c * 0.1 + 0.05
                  },
                  meta: { color: color }
                }
              ]
              const dotStyle = i === 0 ? styles.dot1 : i === 1 ? styles.dot2 : styles.dot3
              return (
                <div key={i} className={styles.radar}>
                  <Radar getSize={getSize} data={radarData} classDot={dotStyle} />
                </div>
              )
            })}
          </Col>
        </Row>
        <Row justify='center'>
          <Col xs={24} sm={24} md={19} lg={21} xl={16} xxl={16} className={styles.buttonModal}>
            <Button
              type='primary'
              className={styles.button}
              onClick={() => {
                setVisible('visible', false)
                clear()
              }}>
              {t('disk.questionare.history.button')}
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default IBDHistory
