import React, { useState, useContext, useEffect } from 'react'
import { Row, Col, Skeleton } from 'antd'
import styles from './Main.module.css'
import { useTranslation } from 'react-i18next'
import Card from './Card'
import { AppContext } from '../../../contexts/AppContext'
import { get, post } from '../../ApiCall'
import { getCfg } from '../../../env.config'

const Survey = ({ survey, profile, setProfile }) => {
  const { t } = useTranslation()
  const address = `https://${window.location.href.split('/')[2]}`
  const env = getCfg(address)
  const context = useContext(AppContext)
  const [results, setResults] = useState({ loaded: false, data: [] })

  useEffect(() => {
    setResults(survey)
  }, [survey, setResults])

  useEffect(() => {
    const profileBody = {
      Country: profile.data.Country,
      Region: profile.data.Region,
      PersonHasOptedOutOfFax: profile.data.PersonHasOptedOutOfFax,
      PersonHasOptedOutOfEmail: profile.data.PersonHasOptedOutOfEmail,
      PersonDoNotCall: profile.data.PersonDoNotCall,
      Patient_unsubscribed: profile.data.Patient_unsubscribed,
      Language: profile.data.Language,
      Salutation: profile.data.Salutation,
      FirstName: profile.data.FirstName,
      LastName: profile.data.LastName,
      PersonEmail: profile.data.PersonEmail,
      PersonMobilePhone: profile.data.PersonMobilePhone,
      Date_of_Birth: profile.data.Date_of_Birth,
      Consent_Checkbox: profile.data.Consent_Checkbox,
      PersonMailingStreet: profile.data.PersonMailingStreet,
      PersonMailingCity: profile.data.PersonMailingCity,
      PersonMailingState: profile.data.PersonMailingState,
      PersonMailingPostalCode: profile.data.PersonMailingPostalCode,
      PersonMailingCountry: profile.data.PersonMailingCountry,
      DoctorID: profile.data.DoctorID,
      HospitalID: profile.data.HospitalID,
      Latest_Device_Token: profile.data.Latest_Device_Token,
      Latest_Device_Platform: profile.data.Latest_Device_Platform,
      ContactPreferencesTimeFrame: profile.data.ContactPreferencesTimeFrame,
      ContactPreferencesDays: profile.data.ContactPreferencesDays,
      NotificationAgreement: profile.data.NotificationAgreement,
      SurveyAssigned: false
    }
    if (profile.data.SurveyAssigned) {
      post('profile2', profileBody, () => {
        get('profile2/all', {}, setProfile)
      })
    }
  }, [profile.data, setProfile])

  useEffect(() => {
    const config = {
      offset: 0,
      limit: 1000
    }
    if (!results.loaded) {
      get('survey/all', config, setResults)
    }
  }, [results.loaded])

  const getData = () => {
    if (Array.isArray(results.data) && results.loaded) {
      const filteredState = results.data.filter((survey) => {
        return survey.Taken === null
      })
      // console.log(results.data)
      if (filteredState.length > 0) {
        return filteredState.map((val) => {
          return (
            <Col key={val.Id} xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <Card id={val.Id} {...val} setResults={setResults} context={context} />
            </Col>
          )
        })
      } else {
        return (
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <div className={styles.noticeRoot} style={{ backgroundColor: env.colors.cardColor }}>
              {t('survey.main.warning')}
            </div>
          </Col>
        )
      }
    } else {
      return <Skeleton active loading />
    }
  }

  return (
    <Row align='bottom' justify='center' className={styles.diskRoot}>
      <Col xs={23} sm={24} md={24} lg={24} xl={24} xxl={24}>
        <div className={styles.text}>{t('survey.main.description')}</div>
      </Col>
      <Col xs={23} sm={24} md={24} lg={24} xl={24} xxl={24} className={styles.contentRoot}>
        <Row gutter={16}>{getData()}</Row>
      </Col>
    </Row>
  )
}

export default Survey
