import React, { useEffect, useState } from 'react'
import { Layout, Col, Row, Spin } from 'antd'
import { useTranslation } from 'react-i18next'
import styles from './Validation.module.css'
import { Link, useLocation } from 'react-router-dom'
import { getCfg } from '../../../env.config'
import axios from 'axios'
import * as qs from 'query-string'
import NotAllowed from '../../NotAllowed'
import { logAction, handleMuleSoft } from '../../ApiCall'

/* global localStorage */
const Validation = () => {
  const { t } = useTranslation()
  const { Content } = Layout
  const location = useLocation()
  const address = `https://${window.location.href.split('/')[2]}`
  const env = getCfg(address)
  // loaded must be false initial
  const [state, setState] = useState({ loaded: false, error: false, data: {} })
  const [countdown, setCountDown] = useState(5)
  const [message, setMessage] = useState({
    registerBtn: false,
    error: false,
    content: ''
  })
  const queryString = qs.parse(window.location.search)

  let culture
  if (queryString.culture !== undefined) {
    culture = queryString.culture
  } else {
    culture = env.supported_cultures[0]
  }

  useEffect(() => {
    const address = `https://${window.location.href.split('/')[2]}`
    const env = getCfg(address)
    const queryString = qs.parse(window.location.search)
    let country
    if (queryString.culture !== undefined) {
      country = queryString.culture.split('-')[1]
    } else {
      country = env.country
    }
    const payload = {
      LogTitle: 'Account Validation Clicked',
      okta_recovery_token__c: queryString.recoveryToken,
      country__c: country,
      account_validation_clicked__c: new Date().toISOString(),
    }
    logAction('user/log', payload, {})

    if (queryString.culture === undefined && queryString.recoveryToken === undefined) {
      localStorage.setItem('i18nextLng', `${env.supported_cultures[0]}`)
      window.location.href = window.location.pathname + '?culture=' + env.supported_cultures[0]
    } else if (queryString.culture === 'hr' && queryString.recoveryToken === undefined) {
      localStorage.setItem('i18nextLng', 'hr-HR')
      window.location.href = window.location.pathname + '?culture=hr-HR'
    } else if (queryString.recoveryToken !== undefined && queryString.culture === undefined) {
      localStorage.setItem('i18nextLng', `${env.supported_cultures[0]}`)
      window.location.href = window.location.pathname + window.location.search + '&culture=' + env.supported_cultures[0]
    } else if (queryString.recoveryToken !== undefined && queryString.culture === 'hr') {
      localStorage.setItem('i18nextLng', 'hr-HR')
      window.location.href = window.location.pathname + '?recoveryToken=' + queryString.recoveryToken + '&culture=hr-HR'
    }
  }, [])

  useEffect(() => {
    const address = `https://${window.location.href.split('/')[2]}`
    const env = getCfg(address)
    const tempArray = location.search.substring(1, location.search.length).split('&')
    const queryString = qs.parse(window.location.search)
    let country
    if (queryString.culture !== undefined) {
      country = queryString.culture.split('-')[1]
    } else {
      country = env.country
    }
    const tempObject = {}
    tempArray.map((value) => {
      const param = value.split('=')
      tempObject[param[0]] = param[1]
      return null
    })
    const headers = {
      client_id: env.loginClientId,
      client_secret: env.loginClientSecret,
      'Content-Type': 'application/json'
    }

    const successPayload = {
      LogTitle: 'Account Validation Completed',
      okta_recovery_token__c: tempObject.recoveryToken,
      country__c: country,
      account_validation_completed__c: new Date().toISOString(),
      okta_activated__c: new Date().toISOString(),
      okta_status__c: 'VALIDATED',
      account_validation_error__c: 'null',
      account_validation_error_msg__c: 'null'
    }

    const errorPayload = {
      LogTitle: 'Account Validation Error',
      okta_recovery_token__c: tempObject.recoveryToken,
      country__c: country,
      account_validation_error__c: new Date().toISOString()
    }
    const headersMule = {
      'x-country': queryString.culture.split('-')[1],
      client_id: env.mulesoft.clientID,
      client_secret: env.mulesoft.clientSecret
    }
    // need a muleSoft call here
    handleMuleSoft(env.mulesoft.baseURI, queryString.emailAddress, headersMule).then((res) => {
      if (!res.Registered) {
        // case 1 registration expired
        // link to register
        setMessage({ error: true, registerBtn: true, content: 'validation.muleSoft.notValidatedInTime' })
        setState({ loaded: true, error: false, data: {} })
      } else if (res.Registered && !res.Validated && (Date.parse(res.ValidationExpiry) > Date.parse(new Date().toISOString()))) {
        // scenario 2
        // validate and I think this should trigger a new call to validate
        // then set message according to what happend on the okta call
        // setMessage({ showBtn: false, sendEmailBtn: false, content: 'reset_password.mulesoft.matchNotFound' })
        // i thin 2 and 3 are the same but different due to okta response
        // axios call to verify user
        // should happen only on muleSoft call success
        axios
          .post(
            env.verifyUser,
            {
              recoveryToken: tempObject.recoveryToken
            },
            {
              headers: headers
            }
          )
          .then(function (response) {
            console.log(response)
            if (response.status >= 200 && response.status <= 299) {
              logAction('user/log', successPayload, {})
              setState({ loaded: true, error: false, data: tempObject })
              setMessage({ error: false, registerBtn: false, content: '' })
            } else {
              logAction('user/log', { ...errorPayload, account_validation_error_msg__c: response.data?.message }, {})
              // state.error true, message to rediredirect to register
              setState({ loaded: true, error: false, data: tempObject })
              setMessage({ error: true, registerBtn: false, content: 'validation.muleSoft.generic' })
            }
          })
          .catch(function (error) {
            logAction('user/log', { ...errorPayload, account_validation_error_msg__c: error.response?.data?.errorSummary }, {})
            setState({ loaded: true, error: false, data: tempObject })
            setMessage({ error: true, registerBtn: false, content: 'validation.muleSoft.generic' })
          })
      } else if (res.Registered && !res.Validated && (Date.parse(res.ValidationExpiry) < Date.parse(new Date().toISOString()))) {
        // scenario 4
        // link to register
        setMessage({ error: true, registerBtn: true, content: 'validation.muleSoft.notValidatedInTime' })
        setState({ loaded: true, error: false, data: {} })
      } else if (res.Registered && res.Validated) {
        // scenario 5
        // already validated, do nothing
        setMessage({ error: false, registerBtn: false, content: '' })
        setState({ loaded: true, error: false, data: {} })
      }
    }).catch(() => {
      setMessage({ error: true, registerBtn: false, content: 'validation.muleSoft.generic' })
      setState({ loaded: true, error: false, data: {} })
    })
  }, [location.search])
  // dont fogert to uncomment in prod
  useEffect(() => {
    let interval
    if (state.loaded) {
      if (state.error) {
        interval = setInterval(() => {
          if (countdown > 1) {
            setCountDown(countdown - 1)
          } else {
            window.location.href = `/register?culture=${culture}`
          }
        }, 1000)
      }
    }
    return () => clearInterval(interval)
  }, [countdown, state, culture])

  const promoCode = t('validation.text.4').valueOf() !== 'validation.text.4' ? <p className={styles.promo}>{t('validation.text.4')}</p> : null
  const semicolon = t('validation.text.4').valueOf() === 'validation.text.4' ? `${state.data.firstname !== undefined ? ' ' + state.data.firstname.replaceAll('+', ' ') : ''},` : '!'

  const registerBtn = (content) => {
    return (
      <>
        {
          t(`${content}.1`)
        }
        <Link to={`/register?culture=${culture}`} className={styles.link}>
          {t(`${content}.2`)}
        </Link>
        {
          t(`${content}.3`)
        }
      </>
    )
  }
  if (!env.supported_cultures.includes(queryString.culture) && queryString.culture !== undefined) {
    return <NotAllowed env={env} />
  } else if (!state.loaded) {
    return (
      <Layout
        style={{
          minHeight: '100%',
          display: 'flex',
          justifyContent: 'center'
        }}>
        <Spin className={env.countryClass} tip={t('app.load')} />
      </Layout>
    )
  } else if (state.error) {
    return (
      <Layout>
        <Content
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          <Row className={env.countryClass}>
            <Col span={24}>
              <h3>{t('app.validation.title')}</h3>
              <h4>
                {t('app.validation.text.1')} {countdown} {t('app.validation.text.2')}
              </h4>
            </Col>
          </Row>
        </Content>
      </Layout>
    )
  }
  return (
    <Layout>
      <Content className={styles.root}>
        <Row className={env.countryClass}>
          <Col span={24}>
            <Row className={styles.headerRoot} type='flex' justify='center'>
              <Col xs={22} sm={22} md={22} lg={18} xl={18} xxl={16}>
                {env.countryClass === 'canada' ? (
                  <img src={env.logo} alt='.' className={styles.canadaLogo} />
                ) : (
                  <div
                    className={styles.logo}
                    style={{
                      borderColor: env.colors.primaryColor
                    }}>
                    <img src='/images/fywy-text@2x.png' alt='.' />
                  </div>
                )}
              </Col>
            </Row>
            <Row type='flex' justify='center'>
              <Col xs={22} sm={22} md={22} lg={18} xl={18} xxl={16} className={styles.content}>
                {
                  message.error ?
                    <div className={styles.text}> {
                      message.registerBtn ? registerBtn(message.content) : t(message.content)
                    }
                    </div>
                    : (
                      <div className={styles.text}>
                        <div className={styles.title}>{t('validation.title')}</div>
                        <p>
                          {t('validation.salutation')}
                          {semicolon}
                        </p>
                        <p>
                          {t('validation.text.1')}
                          <Link to={`/login?culture=${culture}`} className={styles.link}>
                            {t('validation.text.2')}
                          </Link>
                        </p>
                        {promoCode}
                      </div>)
                }
              </Col>
            </Row>
          </Col>
        </Row>
      </Content>
    </Layout>
  )
}

export default Validation
