import React, { useState, useContext, useEffect } from 'react'
import { Row, Col, Button, Skeleton, Empty } from 'antd'
import styles from './IBDDisk.module.css'
import { useTranslation } from 'react-i18next'
import DiskCard from './DiskCard'
import IBDQuestionare from './IBDQuestionare'
import Compare from './Compare'
import IBDHistory from './IBDQuestionare/IBDHistory'
import { AppContext } from '../../contexts/AppContext'
import { get } from '../ApiCall'
import { getCfg } from '../../env.config'
import InfoModal from './InfoModal'
import * as qs from 'query-string'

const IBDDisk = ({ disk, setDisk }) => {
  const { t } = useTranslation()
  const context = useContext(AppContext)
  const [visible, setVisible] = useState(false)
  const [compareVisible, setCompareVisible] = useState(false)
  const [values, setValues] = useState([])
  const [loaded, setLoaded] = useState(false)
  const [diskT, setDiskT] = useState({ loaded: false, data: [] })
  const [isVisible, setIsVisible] = useState(false)
  const [ibdStartTime, setIbdStartTime] = useState('')

  const address = `https://${window.location.href.split('/')[2]}`
  const env = getCfg(address)
  useEffect(() => {
    setDiskT(disk)
  }, [disk, setDisk])
  useEffect(() => {
    const config = {
      offset: 0,
      limit: 1000
    }
    if (!diskT.loaded) {
      get('ibd/all', config, setDiskT)
    }
  }, [diskT.loaded, setDiskT])

  const callbackFunc = (value) => {
    if (value.Id) {
      context.setContextData((oldState) => ({ ...oldState, showResult: true }))
      setLoaded(false)
      setDiskT({
        ...diskT,
        loaded: false
      })
    } else {
      setLoaded(false)
      window.alert('it is not working')
    }
  }

  const handleChange = (event, val) => {
    if (event.target.checked) {
      setValues([...values, val])
    } else {
      const tempArray = values.filter((v) => {
        return v.Id !== val.Id
      })
      setValues(tempArray)
    }
  }

  const setContext = (name, value) => {
    context.setContextData((old) => ({
      ...old,
      [name]: value
    }))
  }

  const clearSelection = () => {
    setValues([])
    setContext('ibdModal', true)
  }
  const getData = () => {
    if (Array.isArray(diskT.data) && diskT.loaded) {
      if (diskT.data.length > 0) {
        return diskT.data.map((val) => {
          return <DiskCard key={val.Id} id={val.Id} {...val} handleChange={handleChange} callback={() => setDiskT({ ...diskT, loaded: false })} t={t} />
        })
      } else {
        return <Empty />
      }
    } else {
      return <Skeleton active loading />
    }
  }

  const search = window.location.search
  const queryString = qs.parse(search)
  const lang = queryString
    ? queryString.culture !== '' && queryString.culture !== undefined
      ? queryString.culture.split('-')[0]
      : localStorage.getItem('i18nextLng').split('-')[0]
    : localStorage.getItem('i18nextLng').split('-')[0]

  const getImage = () => {
    if (lang === 'nl') return '/images/disk-DU.png'
    if (lang === 'fr') return '/images/disk-FR.png'
    if (lang === 'hr') return '/images/disk-HR.png'
    return '/images/questionare@2x.png'
  }

  // console.log('tralallala', t('disk.main.moreinfo'))
  if (context.contextData.visible) {
    return <IBDHistory setVisible={setContext} clear={clearSelection} values={values} />
  } else if (visible) {
    return <IBDQuestionare ibdStartTime={ibdStartTime} setVisible={setVisible} callbackFunc={callbackFunc} loaded={loaded} setLoaded={setLoaded} />
  } else if (compareVisible) {
    return <Compare setVisible={setCompareVisible} callbackFunc={callbackFunc} loaded={loaded} setLoaded={setLoaded} entries={diskT} />
  } else {
    return (
      <>
        <Row align='bottom' justify='center' className={styles.diskRoot}>
          <Col xs={23} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <div className={styles.text}>
              {t('disk.main.text')}{' '}
              <span onClick={() => setIsVisible(true)} style={env.diskModal ? { color: env.colors.primaryColor } : { display: 'none' }}>
                {t('disk.main.moreinfo')}
              </span>
            </div>
          </Col>
          <Col xs={23} sm={24} md={24} lg={24} xl={24} xxl={24} className={env.enhancements.includes('ibd_chart') ? styles.enhanceRoot : styles.contentRoot}>
            <Row gutter={24}>
              <Col
                xs={24}
                sm={24}
                md={11}
                lg={11}
                xl={11}
                xxl={11}
                className={env.enhancements.includes('ibd_chart') ? styles.enhanceDisk : styles.diskPlaceholder}>
                <Row justify='center' className={env.enhancements.includes('ibd_chart') ? styles.enhanceRow : styles.diskRow}>
                  <Col span={24} style={{ height: '90%' }}>
                    <img src={getImage()} alt='questionare' className={styles.disk} />
                  </Col>
                  <Col span={24} className={styles.buttonRoot} style={{ height: '13%' }}>
                    <Button
                      type='primary'
                      className={styles.button}
                      onClick={() => {
                        // start click on ibdisk
                        setIbdStartTime(new Date())
                        setVisible(true)
                        setContext('ibdModal', 'questionnaire')
                      }}>
                      {t('disk.main.disk_button')}
                    </Button>
                  </Col>
                </Row>
                <Row className={env.enhancements.includes('ibd_chart') ? styles.enhanceButton : styles.buttonRow}>
                  <Col span={24}>
                    <Button
                      type='primary'
                      className={styles.button}
                      onClick={() => {
                        setCompareVisible(true)
                        setContext('ibdModal', 'compare')
                      }}>
                      {t('disk.main.compare_button')}
                    </Button>
                  </Col>
                </Row>
              </Col>

              <Col xs={24} sm={24} md={13} lg={13} xl={13} xxl={13}>
                <Row className={env.enhancements.includes('ibd_chart') ? styles.enhanceCard : styles.cardRow}>
                  <Col span={24} className={env.enhancements.includes('ibd_chart') ? styles.enhancePlaceholder : styles.cardPlaceholder}>
                    {getData()}
                  </Col>
                  <Col span={24} className={styles.cardButton}>
                    <p className={styles.label}>{t('disk.main.label')}</p>
                    <Button
                      disabled={values.length < 1 || values.length > 3}
                      type='primary'
                      className={styles.button}
                      onClick={() => {
                        setContext('visible', true)
                        setContext('ibdModal', 'history')
                      }}>
                      {t('disk.main.card_button')}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <InfoModal visible={isVisible} close={() => setIsVisible(false)} />
      </>
    )
  }
}

export default IBDDisk
