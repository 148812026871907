import React from 'react'
import { Row, Col, Form, Select } from 'antd'
import { getCfg } from '../../../env.config'
const DefaultView = ({ form, styles, values, handleSelectValue, t, setModalVisible, doctors, hospitals, condition, matrix, handleSelectDoctor }) => {
  const { Option } = Select

  const address = `https://${window.location.href.split('/')[2]}`
  const env = getCfg(address)

  const ask_hcp = matrix.data.Ask_HCP !== undefined ? matrix.data.Ask_HCP : true
  const ask_hospital = matrix.data.Ask_Hospital !== undefined ? matrix.data.Ask_Hospital : true

  return (
    <Row>
      <Col xs={24} sm={24} md={24} lg={11} xl={11} xxl={11}>
        <Form form={form}>
          <p className={styles.label}>{t('profile.treatment.labels.condition')}</p>
          <Form.Item name='condition' className={styles.formItem}>
            <Select
              size='large'
              className={styles.profileSelect}
              defaultValue={values.condition}
              allowClear
              style={{
                width: '100%',
                backgroundColor: '#fff'
              }}
              onChange={(value) => handleSelectValue(value, 'condition')}
              dropdownClassName={env.countryClass}>
              {condition.map((val) => {
                return (
                  <Option key={val.apiname} value={val.apiname} title={val.name}>
                    {val.name}
                  </Option>
                )
              })}
            </Select>
          </Form.Item>
          <p className={styles.label} style={!ask_hcp ? { display: 'none' } : null}>
            {t('profile.treatment.labels.doctor')}
          </p>
          <Form.Item
            name='doctor'
            className={`${styles.formItem} ${env.profile.search ? 'autoComplete' : ''}`}
            rules={[
              {
                required: true,
                message: t('errors.v_doctor')
              }
            ]}
            style={!ask_hcp ? { display: 'none' } : null}>
            <Select
              size='large'
              className={`${env.profile.search ? 'search' : styles.profileSelect}`}
              showSearch={env.profile.search ? true : false}
              allowClear
              defaultValue={values.doctor === '' ? null : values.doctor}
              onChange={(value) => (env.profile.search ? handleSelectDoctor(value) : handleSelectValue(value, 'doctor'))}
              filterOption={(input, option) => {
                return option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }}
              style={{
                fontSize: '18px',
                width: '100%',
                backgroundColor: '#fff'
              }}
              dropdownClassName={env.countryClass}>
              {Array.isArray(doctors.data)
                ? doctors.data
                    .sort((a, b) => {
                      return a.LastName.localeCompare(b.LastName)
                    })
                    .map((val, i) => {
                      return (
                        <Option key={i} value={val.Id} title={val.LastName + ' ' + val.FirstName}>
                          {val.LastName + ' ' + val.FirstName}
                        </Option>
                      )
                    })
                : null}
            </Select>
          </Form.Item>
          <p className={styles.label} style={!ask_hospital ? { display: 'none' } : null}>
            {t('profile.treatment.labels.hospital')}
          </p>
          <Form.Item
            name='hospital'
            className={styles.formItem}
            rules={[
              {
                required: true,
                message: t('errors.v_hospital')
              }
            ]}
            style={!ask_hospital ? { display: 'none' } : null}>
            <Select
              size='large'
              defaultValue={values.hospital === '' ? null : values.hospital}
              className={styles.profileSelect}
              allowClear
              onChange={(value) => handleSelectValue(value, 'hospital')}
              style={{
                fontSize: '18px',
                width: '100%',
                backgroundColor: '#fff'
              }}
              dropdownClassName={env.countryClass}>
              {Array.isArray(hospitals.data)
                ? hospitals.data
                    .sort((a, b) => {
                      return a.Name.localeCompare(b.Name)
                    })
                    .map((val, i) => {
                      return (
                        <Option key={i} value={val.Id} title={val.Name}>
                          {val.Name}
                        </Option>
                      )
                    })
                : null}
            </Select>
          </Form.Item>
        </Form>
      </Col>
      <Col span={2} />
      <Col className={styles.hcpColumn} xs={24} sm={24} md={24} lg={11} xl={11} xxl={11}>
        <div
          className={`${styles.otherLink}`}
          style={{ color: env.colors.primaryColor, ...env.hcpStyle }}
          onClick={() =>
            setModalVisible((oldState) => ({
              ...oldState,
              accessCode: true
            }))
          }>
          {t('profile.treatment.hcp')}
        </div>
      </Col>
    </Row>
  )
}

export default DefaultView
