import React, { useState, useEffect } from 'react'
import ItemsCarousel from 'react-items-carousel'
import { Card } from 'antd'
import styles from './../MainContent/MainContent.module.css'
import { getCfg } from '../../env.config'

const Carousel = ({ articles, setArticles, visible, setVisible }) => {
  const [activeItemIndex, setActiveItemIndex] = useState(0)
  const { Meta } = Card
  const [size, setSize] = useState([0, 0])
  const address = `https://${window.location.href.split('/')[2]}`
  const env = getCfg(address)
  const updateSize = () => {
    setSize([window.innerWidth, window.innerHeight])
  }
  useEffect(() => {
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])

  if (size[0] < 567) {
    return (
      <ItemsCarousel
        infiniteLoop
        gutter={40}
        activePosition='center'
        chevronWidth={60}
        disableSwipe={false}
        alwaysShowChevrons
        numberOfCards={1}
        slidesToScroll={1}
        outsideChevron={false}
        showSlither
        firstAndLastGutter
        activeItemIndex={activeItemIndex}
        requestToChangeActive={value => setActiveItemIndex(value)}
      >
        {Array.isArray(articles.data) ? articles.data.filter(article => {
          return article.Image_URL__c !== undefined
        }).map((val) => {
          return (
            <Card
              style={{ background: 'transparent' }}
              className={styles.antCardCover}
              key={val.Id}
              cover={<img style={{ minHeight: 220, height: '100%', objectFit: 'cover' }} alt='article' src={val.Image_URL__c ? val.Image_URL__c : 'http://placeimg.com/300/200/any'} />}
              bordered={false}
              onClick={() => setVisible(val.UrlName)}
            >
              <Meta className={styles.meta} title={val.Title} />
            </Card>
          )
        }
        ) : null}
      </ItemsCarousel>
    )
  } else {
    return (
      <ItemsCarousel
        infiniteLoop
        gutter={20}
        activePosition='center'
        chevronWidth={60}
        disableSwipe={false}
        alwaysShowChevrons
        numberOfCards={3}
        slidesToScroll={2}
        outsideChevron
        showSlither={false}
        firstAndLastGutter={false}
        activeItemIndex={activeItemIndex}
        requestToChangeActive={value => setActiveItemIndex(value)}
        rightChevron={
          <img src={env.rightArrow} alt='Right' style={visible ? { display: 'none' } : { width: 20 }} />
        }
        leftChevron={
          <img src={env.leftArrow} alt='Left' style={visible ? { display: 'none' } : { width: 20 }} />
        }
      >
        {Array.isArray(articles.data) ? articles.data.filter(article => {
          return article.Image_URL__c !== undefined
        }).map((val) => {
          return (
            <Card
              style={{ background: 'transparent', cursor: 'pointer' }}
              className={styles.antCardCover}
              key={val.Id}
              cover={<img style={{ minHeight: 195, maxHeight: 195, objectFit: 'cover' }} alt='article' src={val.Image_URL__c ? val.Image_URL__c : 'http://placeimg.com/300/200/any'} />}
              bordered={false}
              onClick={() => setVisible(val.UrlName)}
            >
              <Meta className={styles.meta} title={val.Title} />
            </Card>
          )
        }
        ) : null}
      </ItemsCarousel>
    )
  }
}

export default Carousel
