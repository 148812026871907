// supported languages for search route
// Arabic: ar
// Bulgarian: bg
// Croatian: hr
// Czech: cs
// English (UK): en_GB
// Greek: el
// Hebrew: iw
// Hungarian: hu
// Indonesian: in
// Polish: pl
// Portuguese (European): pt_PT
// Romanian: ro
// Slovak: sk
// Slovenian: sl
// Turkish: tr
// Ukrainian: uk
// Vietnamese: vi
// Serbia:  sr
export const SEARCH_LANGUAGES = {
  'en-CH': 'en_US',
  'en-GB': 'en_GB',
  'bg-BG': 'bg',
  'hr-HR': 'hr',
  'cs-CZ': 'cs',
  'el-GR': 'el',
  'hu-HU': 'hu',
  'pl-PL': 'pl',
  'pt-PT': 'pt_PT',
  'ro-RO': 'ro',
  'sk-SK': 'sk',
  'sl-SI': 'sl',
  'tr-TR': 'tr',
  'uk-UA': 'uk',
  'vi-VN': 'vi',
  'nl-BE': null,
  'fr-BE': null,
  'sr-RS': 'sr'
}
