import React, { useRef, useEffect, forwardRef, useImperativeHandle } from 'react'
import * as d3 from 'd3'
import styles from './Disk.module.css'

const Wheel = forwardRef((props, ref) => {
  // extract data from props
  const donutData = props.data

  const spinState = props.spinState

  const setWheelValues = props.setWheelValues
  // creat refs to append the sgv
  const ch = useRef(null)
  // wait for component to mount
  useEffect(
    () => {
      // width and hight used to create an arc
      const width = 900
      const height = 900
      // set margin of the svg
      const margin = { left: 0, top: 0, right: 0, bottom: 0 }
      // chech if ref exist
      if (ch.current !== null) {
        // create the svg element and append it to 'ch'(this is defined as ref)
        const svg = d3
          .select(ch.current)
          .append('svg')
          .attr('width', '100%')
          .attr('height', '100%')
          .attr('xmlns', 'http://www.w3.org/2000/svg')
          .attr('viewBox', '0 0 1000 1000')
          .append('g')
          .attr('id', 'wrapper')
          .attr(
            'transform',
            'translate(' + (width / 1.8 + margin.left) + ',' + (height / 1.8 + margin.top) + ')'
          )
        // Create an arc function
        const arc = d3.arc().innerRadius(width * 0.95 / 2).outerRadius(width * 0.95 / 2 + 35)
        // Turn the pie chart 90 degrees counter clockwise, so it starts at the right
        const pie = d3
          .pie()
          .startAngle(90 * Math.PI / 180)
          .endAngle(90 * Math.PI / 180 + 2 * Math.PI)
          .value(function (d) {
            return d.value
          })
          .padAngle(0.01)
          .sort(null)
        /// ///////////////////////////////////////////////////////////
        /// ///////////////// Create Donut Chart //////////////////////
        /// ///////////////////////////////////////////////////////////
        // Create the donut slices and also the invisible arcs for the text
        const argv = svg
          .selectAll('.donutArcs')
          .data(pie(donutData))
          .enter()
          .append('g')
          .attr('id', function (d) {
            return `${d.data.name.replace(/\s/g, '')}`
          })
        argv.append('text')
          .attr('y', 10)
          .attr('id', 'inner-wheel-text')
          .attr('class', styles.innerWheelText)
          .style('text-anchor', 'middle')
          .text('')
        // set values used to draw inner circle, will update on each step of the for element
        let cwidth = 0.87
        // set an index used to create gradient background
        let index = 1
        // loop to create all ineer element, loop from 10 to 0 to have 0 value in the center of the wheel
        for (let i = 10; i >= 0; i--) {
          // create new arc for inner elements
          const arcs = d3.arc().innerRadius(width * cwidth / 2).outerRadius(width * cwidth / 2 + 32)
          // draw inner arc
          const step = index * 6
          argv
            .append('path')
            .attr('class', function (d) {
              if (d.data.id === 'energy') {
                return styles.activeSlice
              }
            })
            // set path to draw
            .attr('d', function (d, i, j) {
              return arcs(d)
            })
            // set id
            .attr('id', function (d) {
              return `${d.data.id}`
            })
            // set value
            .attr('value', function (d) {
              return `${i}`
            })
            // set name
            .attr('name', function (d) {
              return `${d.data.name}`
            })
            // bind function to detect element clicked and once clicked, add active class
            .on('click', function (e) {
              let activeSlice = false
              // get all path tags for each 'pie' slice
              const activeEl = this.parentElement.getElementsByTagName('path')
              for (let j = 0; j < activeEl.length; j++) {
                if (activeEl[j].classList.contains(styles.activeSlice)) {
                  activeSlice = true
                }
              }

              if (activeSlice) {
                // call function used for wheel clicked elements state
                wheelValuesHandler(this)
                // loop through all path elements
                for (let j = 0; j < activeEl.length; j++) {
                  // if has a class active, remove it
                  if (activeEl[j].classList.contains(styles.active)) {
                    activeEl[j].classList.remove(styles.active)
                  }
                }
                // set class to active for the clicked element
                this.setAttribute('class', styles.active)
              }
            })
            // fill shape with a gradient backgorund
            .style('fill', function (d, i) {
              // get rgb values from the converColor function
              const col = convertColor(d.data.color)
              // create a gradient background from color defined in data
              return `rgb(${col[0] + step}, ${col[1] + step}, ${col[2] + step})`
            })
          // set text for inner arc
          argv
            .append('text')
            // move text in the middle of the drawed path
            .attr('transform', function (d) {
              return 'translate(' + arcs.centroid(d) + ')'
            })
            // align text in the middle (values allowed: start, middle, end)
            .attr('text-anchor', 'middle')
            // set text color to black
            .attr('fill', 'black')
            // display  numbers on wheel
            .text(function (d) {
              return i
            })
            // bind function to detect element clicked and once clicked, add active class to previous sibbling
            .on('click', function () {
              let activeSlice = false
              // get all path tags for each 'pie' slice
              const activeEl = this.parentElement.getElementsByTagName('path')
              for (let j = 0; j < activeEl.length; j++) {
                if (activeEl[j].classList.contains(styles.activeSlice)) {
                  activeSlice = true
                }
              }

              if (activeSlice) {
                // call function used for wheel clicked elements state
                wheelValuesHandler(this.previousElementSibling)
                // loop through all path elements
                for (let j = 0; j < activeEl.length; j++) {
                  // if has a class active, remove it
                  if (activeEl[j].classList.contains(styles.active)) {
                    activeEl[j].classList.remove(styles.active)
                  }
                }
                // set class to active for previous sibling
                this.previousElementSibling.setAttribute('class', styles.active)
              }
            })
          // for each step, change arc radius
          cwidth = cwidth - 0.08
          // used to control value of the gradient bg for each arc
          index = index + 1
        }
        // end for loop
        // draw paths for categories (each pie name/category)
        argv
          .append('path')
          // add a class
          .attr('class', 'donutArcs')
          // set draw path
          .attr('d', arc)
          // fill with rgb value of the provided color in data
          .style('fill', function (d, i) {
            // call function to convert color defined in data from hex to rgb
            const col = convertColor(d.data.color)
            return `rgb(${col[0]}, ${col[1]}, ${col[2]})`
          })
          .each(function (d, i) {
            // Search pattern for everything between the start and the first capital L
            const firstArcSection = /(^.+?)L/
            // Grab everything up to the first Line statement
            let newArc = firstArcSection.exec(d3.select(this).attr('d'))[1]
            // Replace all the comma's so that IE can handle it
            newArc = newArc.replace(/,/g, ' ')
            // If the end angle lies beyond a quarter of a circle (90 degrees or pi/2)
            // flip the end and start position
            if (d.endAngle > 90 * Math.PI / 180) {
              const startLoc = /M(.*?)A/ // Everything between the first capital M and first capital A
              const middleLoc = /A(.*?)0 0 1/ // Everything between the first capital A and 0 0 1
              const endLoc = /0 0 1 (.*?)$/ // Everything between the first 0 0 1 and the end of the string (denoted by $)
              // Flip the direction of the arc by switching the start en end point (and sweep flag)
              // of those elements that are below the horizontal line
              const newStart = endLoc.exec(newArc)[1]
              const newEnd = startLoc.exec(newArc)[1]
              const middleSec = middleLoc.exec(newArc)[1]
              // Build up the new arc notation, set the sweep-flag to 0
              newArc = 'M' + newStart + 'A' + middleSec + '0 0 0 ' + newEnd
            }
            // if
            // Create a new invisible arc that the text can flow along
            svg
              .append('path')
              .attr('class', 'hiddenDonutArcs')
              .attr('id', 'donutArc' + i)
              .attr('d', newArc)
              .style('fill', 'none')
          })
        // Append the label names on the outside
        svg
          .selectAll('.donutText')
          .data(pie(donutData))
          .enter()
          .append('text')
          .attr('class', 'donutText')
          // Move the labels below the arcs for those slices with an end angle greater than 90 degrees
          .attr('dy', function (d, i) {
            return d.endAngle > 90 * Math.PI / 180 ? -12 : 18
          })
          .append('textPath')
          .attr('startOffset', '50%')
          .attr('fill', 'white')
          .style('text-anchor', 'middle')
          .attr('xlink:href', function (d, i) {
            return '#donutArc' + i
          })
          .text(function (d) {
            return d.data.name
          })

        // save wheel values using state (set this way because we get an empty array when destructuring state, wheelValues is an empty array when called from function)
        const wheelValuesHandler = (e) => {
          // get name attribute of clicked element
          const name = e.getAttribute('name')
          // get value attribute of clicked element
          const value = e.getAttribute('value')

          const id = e.getAttribute('id')
          // upddate the center of the wheel with the value
          // document.getElementById('inner-wheel-text').textContent = value
          // set state using old values
          setWheelValues((oldVal) => {
            // destructuring state
            const element = [...oldVal]
            // find index of the element using an id (name in this case)
            const elementIndex = element.findIndex((el) => {
              return el.name === name
            })
            // check if previous name exist
            if (elementIndex >= 0) {
              // if previous name exist, update value
              element[elementIndex].value = value
              // update state
              setWheelValues([...element])
            } else {
              // if previous name not found, add a new object to state
              return [...oldVal, { name, value, id }]
            }
          })
        }
      }
    },
    [donutData, setWheelValues]
  )
  // convert color passed in data from hex to rgb values, make sure hex has 7 digits including #
  const convertColor = (hex) => {
    let r = 0
    let g = 0
    let b = 0
    // replace #
    hex = hex.replace('#', '')
    // extract r value from the first 2 digits
    r = parseInt(hex.substring(0, 2), 16)
    // extract g value from the middle digits (2,4) digits
    g = parseInt(hex.substring(2, 4), 16)
    // extract b value from the last 2 digits
    b = parseInt(hex.substring(4, 6), 16)
    // return rgb values as an arry for latter use
    return [r, g, b]
  }
  // spin wheel function, gets a parameter which indicates how many time the wheel should spin to left with 36deg
  const spinHandler = (id) => {
    const arrIdx = [2, 1, 0, 9, 8, 7, 6, 5, 4, 3]
    const activeG = document.getElementById('wrapper').getElementsByTagName('g')[arrIdx[id % 10]]
    const allG = document.getElementById('wrapper').getElementsByTagName('g')
    for (let i = 0; i < allG.length; i++) {
      const activeEl = allG[i].getElementsByTagName('path')
      for (let j = 0; j < activeEl.length; j++) {
        activeEl[j].classList.remove(styles.activeSlice)
      }
    }
    const activeEl = activeG.getElementsByTagName('path')
    for (let i = 0; i < activeEl.length; i++) {
      activeEl[i].classList.add(styles.activeSlice)
    }

    // set style to wheel based on parameter
    d3.select(ch.current).style('transform', 'rotate(' + id * 36 + 'deg)').style('transition', '0.8s all')
    document.getElementById('inner-wheel-text').setAttribute('transform', 'rotate(0)')
    // get all text nodes and style based on passed parameter
    for (let el = 0; el < document.getElementById('wrapper').getElementsByTagName('text').length; el++) {
      // get old transform value, is used to position label
      const oldTr = document.getElementById('wrapper').getElementsByTagName('text')[el].getAttribute('transform')
      // there are text nodes without transform attribute and we want to exclude them
      if (oldTr) {
        // rotate text based on passed parameter
        const rot = 'rotate(-' + id * 36 + ')'
        // get only translate from transform attirbute
        const olTr = oldTr.split(' ')
        // set new transform attribute containing old translate and rotate
        document.getElementById('wrapper').getElementsByTagName('text')[el].setAttribute('transform', `${olTr[0]} ${rot}`)
        document.getElementById('wrapper').getElementsByTagName('text')[el].style.transition = '0.8s all'
      }
    }
  }
  // use imperative handler to make spin function accessible from parent
  useImperativeHandle(ref, () => ({
    // function accessible from parent

    spin (value) {
      if (value === 'increment') {
        // set spin state, increment by 1
        spinHandler(spinState + 1)
        props.setSpinState(spinState + 1)
      } else if (value === 'decrement') {
        // set spin state, decrement by -1
        if (spinState !== 0) {
          spinHandler(spinState - 1)
          props.setSpinState(spinState - 1)
        }
      }
    }
  }))

  return (
    // div used to append svg element please don't remove it
    <div className={styles.chart} ref={ch} />
  )
})

export default Wheel
