import React, { useState, useEffect } from 'react'
import { Row, Col, Button, Modal, Input, Form, Spin } from 'antd'
import styles from './AccessCodeModal.module.css'
import ConsentModal from './../ConsentModal'
import { get } from '../../ApiCall'
import { getCfg } from '../../../env.config'
import * as qs from 'query-string'

const AccessCodeModal = ({
  visible,
  close,
  handleChange,
  style,
  code,
  t,
  setOpenStatus,
  setValues,
  error,
  setError,
  profile,
}) => {
  const [openConsent, setOpenConsent] = useState(false)
  const [size, setSize] = useState(0)
  const [checked, setChecked] = useState(false)
  const [start, setStart] = useState(false)
  const [codeResponse, setCodeResponse] = useState({ loaded: false, data: {} })
  const [consent, setConsent] = useState({ loaded: false, data: {} })
  const address = `https://${window.location.href.split('/')[2]}`
  const env = getCfg(address)
  const config = {
    code: code,
  }

  useEffect(() => {
    // const country = profile.data.Country !== null ? profile.data.Country : 'CH'
    // const language = profile.data.Language !== null ? profile.data.Language : 'en'
    const address = `https://${window.location.href.split('/')[2]}`
    const env = getCfg(address)
    const search = window.location.search
    const queryString = qs.parse(search)
    let lang
    let country
    if (queryString.culture !== undefined) {
      lang = queryString.culture.split('-')[0]
      country = queryString.culture.split('-')[1]
    } else {
      lang = env.language
      country = env.country
    }

    const config = {
      language: lang,
      country: country,
      medication: 'entyvio',
    }
    if (!consent.loaded) {
      get('consent/patient', config, setConsent)
    }
  }, [consent.loaded, profile.data])

  const updateSize = () => {
    setSize(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])

  const handleAcceptConsent = () => {
    setOpenConsent(false)
    close()
    setOpenStatus(true)
    setChecked(false)
    if (codeResponse.data.medications.toLowerCase() === 'entyvio') {
      setValues((oldState) => ({
        ...oldState,
        entyvio: true,
        medication: 'entyvio',
      }))
    } else {
      setValues((oldState) => ({
        ...oldState,
        otherMedicine: true,
        medication: '',
      }))
    }
  }

  const resetModal = () => {
    close()
    setError(false)
  }

  const getButton = () => {
    if (error) {
      return (
        <Button
          className={styles.modalButton}
          style={
            code
              ? {
                  backgroundColor: env.colors.primaryColor,
                  color: '#fff',
                }
              : {
                  backgroundColor: '#ccc',
                  color: '#fff',
                  border: '1px solid #ccc',
                }
          }
          onClick={action}
          disabled={!code}>
          {t('profile.modal.status.text.button_error')}
        </Button>
      )
    } else {
      return (
        <Button
          className={styles.modalButton}
          style={
            code
              ? {
                  backgroundColor: env.colors.primaryColor,
                  color: '#fff',
                }
              : {
                  backgroundColor: '#ccc',
                  color: '#fff',
                  border: '1px solid #ccc',
                }
          }
          onClick={action}
          disabled={!code}>
          {t('profile.modal.code.button')}
        </Button>
      )
    }
  }
  useEffect(() => {
    if (codeResponse.loaded) {
      setStart(false)
    }
    if (!start && codeResponse.loaded) {
      if (codeResponse.data.medications) {
        if (codeResponse.data.medications === 'Entyvio') {
          setOpenConsent(true)
          setError(false)
        }
      } else {
        setError(true)
      }
    }
  }, [codeResponse, start, setError])

  const action = () => {
    setCodeResponse({
      ...codeResponse,
      loaded: false,
    })
    setStart(!start)
    get('unlock/test', config, setCodeResponse)
  }

  const modalBodyStyle =
    size < 576 ? { padding: '0 20px 25px' } : { padding: '0 50px 25px' }
  return (
    <Modal
      title={
        error
          ? t('profile.modal.status.title_error')
          : t('profile.modal.code.title')
      }
      visible={visible}
      onCancel={resetModal}
      footer={null}
      width={651}
      maskClosable={false}
      bodyStyle={modalBodyStyle}
      className={styles.modal}
      centered>
      <Spin spinning={start} className={env.countryClass}>
        <Row className={`${styles.modalRoot} ${env.countryClass}`}>
          <Col span={23} className={styles.modalDescription}>
            <p>
              {error
                ? t('profile.modal.status.text.error')
                : t('profile.modal.code.subtitle')}
            </p>
          </Col>
          <Col
            xs={24}
            sm={18}
            md={18}
            lg={16}
            xl={16}
            xxl={16}
            className={styles.modalText}>
            <Form
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  action()
                }
              }}>
              <Input
                name='accessCode'
                className={style}
                onChange={handleChange}
                placeholder={t('profile.modal.code.placeholder')}
                value={code}
                allowClear
              />
            </Form>
          </Col>
          <Col span={24}>
            <Row>
              <Col xs={24} sm={18} md={18} lg={16} xl={16} xxl={16}>
                {getButton()}
              </Col>
            </Row>
          </Col>
        </Row>
      </Spin>
      <ConsentModal
        visible={openConsent}
        close={() => setOpenConsent(false)}
        bodyStyle={modalBodyStyle}
        accept={handleAcceptConsent}
        checked={checked}
        setChecked={setChecked}
        getCfg={getCfg}
        modalText={consent.data.Small_consent__c}
      />
    </Modal>
  )
}

export default AccessCodeModal
