import React, { useState, useContext } from 'react'
import { Row, Col, Button, Spin, Checkbox, Select, DatePicker, message } from 'antd'
import { CloseCircleOutlined, LoadingOutlined, ArrowRightOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import styles from './Compare.module.css'
import ChartResult from '../ChartResult'
import { AppContext } from '../../../contexts/AppContext'
import { getCfg } from '../../../env.config'
import moment from 'moment'

const Compare = ({ setVisible, callbackFunc, loaded, setLoaded, entries }) => {
  const { t } = useTranslation()
  const context = useContext(AppContext)
  const [state, setState] = useState({
    date: false,
    entries: false,
    checked: [],
    from: null,
    to: null,
    selected: null
  })
  const { Option } = Select
  const closeModals = () => {
    setVisible(false)
    context.setContextData((oldState) => ({ ...oldState, ibdModal: false }))
    context.setContextData((oldState) => ({ ...oldState, showChartView: false }))
  }
  const [series, setSeries] = useState([])
  const [xLabel, setXLabel] = useState([])
  const address = `https://${window.location.href.split('/')[2]}`
  const env = getCfg(address)

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

  const CheckboxGroup = Checkbox.Group
  const capitalize = (string) => {
    return string.toLowerCase().charAt(0).toUpperCase() + string.slice(1).toLowerCase()
  }
  // console.log(entries)
  const plainOptions = [
    { label: capitalize(t('disk.questionare.wheel.abdominal')), value: 'Abdominal_Pain__c' },
    { label: capitalize(t('disk.questionare.wheel.defecation')), value: 'Regulating_Defecation__c' },
    { label: capitalize(t('disk.questionare.wheel.interactions')), value: 'Interpersonal_Interactions__c' },
    { label: capitalize(t('disk.questionare.wheel.education')), value: 'Education_and_Work__c' },
    { label: capitalize(t('disk.questionare.wheel.sleep')), value: 'Sleep__c' },
    { label: capitalize(t('disk.questionare.wheel.energy')), value: 'Energy__c' },
    { label: capitalize(t('disk.questionare.wheel.emotions')), value: 'Emotions__c' },
    { label: capitalize(t('disk.questionare.wheel.body')), value: 'Body_Image__c' },
    { label: capitalize(t('disk.questionare.wheel.joint')), value: 'Joint_Pain__c' },
    { label: capitalize(t('disk.questionare.wheel.sexual')), value: 'Sexual_Functions__c' }
  ]

  const onChange = (list) => {
    setState({
      ...state,
      checked: list
    })
  }
  const onChangeCheckbox = (e, name) => {
    if (name === 'date') {
      setState({
        ...state,
        [name]: e.target.checked,
        entries: false
      })
    } else {
      setState({
        ...state,
        [name]: e.target.checked,
        date: false
      })
    }
  }

  const handleSelectDate = (date, name) => {
    setState({
      ...state,
      [name]: date
    })
  }

  const handleSelectEntries = (value) => {
    setState({
      ...state,
      selected: value || null
    })
  }

  const handleClickSave = () => {
    let filterEntries
    if (state.date) {
      filterEntries = entries.data.filter((entry) => {
        const start = moment(state.from).subtract(1, 'days')
        const end = moment(state.to).add(1, 'days')
        return moment(entry.Created_Date__c).isBetween(start, end, 'day')
      })
    } else {
      filterEntries = entries.data.filter((_, index) => {
        return index + 1 <= state.selected
      })
    }
    const tempArray = []
    const tempDate = []

    filterEntries
      .sort((a, b) => new Date(a.Created_Date__c) - new Date(b.Created_Date__c))
      .map((entry) => {
        tempDate.push(moment(entry.Created_Date__c).format('YYYY-MM-DD'))
        return state.checked.map((item) => {
          if (entry[item] !== undefined) return tempArray.push({ name: item, data: entry[item] })
          return null
        })
      })
    const tempSeries = []
    state.checked.map((item) => {
      const dataTemp = []
      tempArray.map((val) => {
        if (val.name === item) return dataTemp.push(val.data)
        return null
      })
      const selectedObject = plainOptions.filter((option) => {
        return option.value === item
      })[0]
      const formattedName = selectedObject.label
      return tempSeries.push({ name: formattedName, data: dataTemp })
    })
    setSeries(tempSeries)
    setXLabel(tempDate)
    if (tempSeries[0].data.length === 0) {
      message.info(t('disk.compare.message'))
    } else {
      context.setContextData((oldState) => ({ ...oldState, showChartView: true }))
    }
  }

  const disableButton = () => {
    if (state.checked.length === 0 && !state.date && !state.entries) {
      return true
    } else if (state.checked.length === 0) {
      return true
    } else if (state.checked.length > 0) {
      if (!state.date && !state.entries) {
        return true
      } else {
        if (state.date) {
          if (state.from !== null && state.to !== null) return false
          return true
        } else if (state.entries) {
          if (state.selected !== null) return false
          return true
        } else {
          return true
        }
      }
    } else {
      return false
    }
  }

  if (context.contextData.showChartView) {
    return <ChartResult setVisible={closeModals} series={series} xLabel={xLabel} fromDate={state.from} toDate={state.to} />
  } else {
    return (
      <Row align='top' className={styles.modalRoot}>
        <Col span={24} className={styles.text}>
          {t('disk.compare.text')}
        </Col>
        <Col span={24}>
          <CloseCircleOutlined className={styles.closeIcon} onClick={closeModals} />
        </Col>
        <Col span={24} className={styles.root}>
          <Row justify='center'>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <h3 className={styles.title}>{t('disk.compare.1.title')}</h3>
              <CheckboxGroup options={plainOptions} onChange={onChange} className={styles.checkbox} />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <h3 className={styles.title}>{t('disk.compare.2.title')}</h3>
              <Checkbox onChange={(e) => onChangeCheckbox(e, 'date')} checked={state.date} className={styles.label}>
                {t('disk.compare.2.label_time')}
              </Checkbox>
              <div className={styles.datepickerRoot}>
                <div className={styles.datePickerItem}>
                  <p>{t('disk.compare.2.from')}</p>
                  <DatePicker
                    className={styles.datepicker}
                    name='from'
                    format='DD/MM/YYYY'
                    style={{
                      borderColor: env.colors.borderColor
                    }}
                    onChange={(val) => handleSelectDate(val, 'from')}
                    placeholder={t('disk.compare.2.datepicker')}
                    dropdownClassName={env.countryClass}
                  />
                </div>
                <ArrowRightOutlined className={styles.arrow} />
                <div className={styles.datePickerItem}>
                  <p>{t('disk.compare.2.to')}</p>
                  <DatePicker
                    className={styles.datepicker}
                    name='to'
                    format='DD/MM/YYYY'
                    style={{
                      borderColor: env.colors.borderColor
                    }}
                    onChange={(val) => handleSelectDate(val, 'to')}
                    placeholder={t('disk.compare.2.datepicker')}
                    dropdownClassName={env.countryClass}
                  />
                </div>
              </div>
              <p className={styles.paragrapf}>{t('disk.compare.2.or')}</p>
              <Checkbox onChange={(e) => onChangeCheckbox(e, 'entries')} checked={state.entries} className={styles.label}>
                {t('disk.compare.2.label_entry')}
              </Checkbox>
              <div>
                <Select
                  size='large'
                  showArrow
                  placeholder={t('disk.compare.2.placeholder')}
                  className={styles.select}
                  onChange={handleSelectEntries}
                  allowClear
                  dropdownClassName={env.countryClass}>
                  {entries.data.map((_, index) => {
                    if (index < 32) {
                      return (
                        <Option key={index} value={index + 1}>
                          {index + 1}
                        </Option>
                      )
                    } else {
                      return null
                    }
                  })}
                </Select>
              </div>
            </Col>
          </Row>
          <Row justify='center' className={styles.buttonRow}>
            <Col xs={24} sm={24} md={16} lg={15} xl={14} xxl={13} className={styles.buttonModal}>
              <Spin indicator={antIcon} spinning={loaded}>
                <Button type='primary' className={styles.button} onClick={handleClickSave} disabled={disableButton()}>
                  {t('disk.questionare.button.save')}
                </Button>
              </Spin>
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }
}

export default Compare
