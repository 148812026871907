import React, { useContext, useState } from 'react'
import { Row, Col } from 'antd'
import { useTranslation } from 'react-i18next'
import styles from './Header.module.css'
import SearchBox from '../SearchBox'
import ProfileLink from '../Dropdown'
import { AppContext } from '../../contexts/AppContext'
import { InfoCircleOutlined, UserOutlined, PhoneOutlined } from '@ant-design/icons'
import DisclaimerModal from './../ErrorHandlerModal'
import { Link } from 'react-router-dom'
import { getCfg } from '../../env.config'
import MessageBoard from '../MessageBoard'
import SubCategorySelect from '../SubCategory'

/* global localStorage */
const Header = (props) => {
  const { t } = useTranslation()
  const contextValue = useContext(AppContext)
  const [modal, setModal] = useState(false)
  const [isVisible, setIsVisible] = useState(false)

  const { showBoard, setShowBoard, content } = contextValue

  const toggle = () => {
    contextValue.setCollapsed(!contextValue.collapsed)
  }

  const { matrix } = contextValue
  const address = `https://${window.location.href.split('/')[2]}`
  const env = getCfg(address)
  const { search, setSearch, value, setValue, setLoaded, loaded } = props

  const supportedCulture = () => {
    if (localStorage.getItem('i18nextLng') !== '') return localStorage.getItem('i18nextLng')
    return env.supported_cultures[0]
  }

  const getHeaderTitle = () => {
    const path = window.location.pathname.toLowerCase()
    if (path.includes('articles/')) {
      if (content.board) {
        return t('header.board')
      } else if (path.includes('/cd-me')) {
        return t('navigation.items.1')
      } else if (path.includes('/wellbeing')) {
        return t('navigation.items.2')
      } else if (path.includes('/nutrition')) {
        return t('navigation.items.3')
      } else if (path.includes('/recipes')) {
        return t('navigation.items.4')
      } else if (path.includes('/travel')) {
        return t('navigation.items.5')
      } else if (path.includes('/videos')) {
        return t('navigation.items.6')
      } else if (path.includes('/entyvio')) {
        return t('navigation.items.7')
      } else if (path.includes('/sexuality')) {
        return t('navigation.categories.category6.name')
      } else if (path.includes('/uc-me')) {
        return t('navigation.items.8')
      } else if (path.includes('/patient-organization')) {
        return t('navigation.categories.category11.name')
      } else if (path.includes('/faq')) {
        return t('navigation.categories.category12.name')
      } else if (path.includes('/privacy')) {
        return t('navigation.categories.category13.name')
      }
    } else {
      if (content.board && path !== '/') {
        return t('header.board')
      } else if (path === '/ibd-disk') {
        if (contextValue.contextData.ibdModal === 'questionnaire') {
          return t('sidebar.items.1.questionnaire')
        } else if (contextValue.contextData.ibdModal === 'history') {
          return t('sidebar.items.1.history')
        } else if (contextValue.contextData.ibdModal === 'compare') {
          return t('sidebar.items.1.compare')
        } else {
          return t('sidebar.items.1.main')
        }
      } else if (path === '/appointments') {
        return t('sidebar.items.2')
      } else if (path === '/injections') {
        return t('sidebar.items.4')
      } else if (path === '/medications') {
        return t('sidebar.items.3')
      } else if (path === '/injection-reminder') {
        return t('sidebar.items.5')
      } else if (path === '/surveys') {
        return t('sidebar.items.6')
      } else if (path === '/harvey-bradshaw-index') {
        return t('sidebar.items.8')
      } else if (path === '/partial-mayo-index') {
        return t('sidebar.items.7')
      } else if (path === '/enrolment-tracker') {
        return t('sidebar.items.0')
      } else if (path === '/survey') {
        return t('sidebar.items.6')
      } else {
        return ''
      }
    }
  }

  const handleSelect = (option) => {
    localStorage.setItem('i18nextLng', option)
    window.location.href = `${window.location.pathname}?culture=${option}`
  }

  const MobileContent = () => {
    return (
      <Col span={24} className={styles.mobile}>
        {matrix.data.Country !== 'CA' ? (
          <div style={showBoard || !env.enhancements.includes('message_board') ? { display: 'none' } : null} onClick={() => setShowBoard(true)}>
            <MessageBoard env={env} t={t} />
          </div>
        ) : null}

        <Row justify='center' align='middle'>
          <Col span={20}>
            <Row align='middle'>
              <Col span={24} className={styles.profile}>
                <Row align='middle'>
                  <Col span={8}>
                    {React.createElement('img', {
                      src: contextValue.collapsed ? '/images/burgericon-bluegreen.png' : '/images/burgericon-white.png',
                      className: `trigger ${styles.triggerIcon}`,
                      style: contextValue.collapsed
                        ? { backgroundColor: env.colors.menuColor, color: env.colors.primaryColor }
                        : { backgroundColor: env.colors.primaryColor },
                      onClick: toggle
                    })}
                  </Col>
                  <Col
                    span={16}
                    className={env.enhancements.includes('message_board') || matrix.data.Country === 'CA' ? styles.enhanceDropdown : styles.profileDropdown}>
                    <ProfileLink setProfile={props.setProfile} />
                    {matrix.data.Country !== 'CA' ? (
                      <span
                        className={styles.boardTrigger}
                        style={!showBoard || !env.enhancements.includes('message_board') ? { display: 'none' } : { color: env.colors.title }}
                        onClick={() => setShowBoard(false)}>
                        {t('message-board.title')}
                      </span>
                    ) : (
                      <div style={{ color: env.colors.title }} className={styles.contactUs} onClick={() => setIsVisible(true)}>
                        {t('header.contact')}
                      </div>
                    )}
                  </Col>
                </Row>
              </Col>
              <Col span={18} className={styles.searchBox}>
                <SearchBox setSearchValue={setValue} search={search} setSearch={setSearch} setLoaded={setLoaded} />
              </Col>
              <Col
                span={18}
                className={styles.subcategoryRoot}
                style={
                  props.categories && window.location.pathname.toLowerCase().includes('/articles/') && env.enhancements.includes('subcategories')
                    ? null
                    : { display: 'none' }
                }>
                <SubCategorySelect categories={props.categories} />
              </Col>
            </Row>
          </Col>
          <Col span={20} className={styles.title}>
            <Row>
              {env.countryClass === 'canada' ? (
                <Col span={24}>
                  <Row align='middle'>
                    <Col span={24}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          height: 60,
                          marginTop: 20
                        }}>
                        <Link to={`/?culture=${supportedCulture()}`}>
                          <img
                            src={env.logo}
                            alt='onepath'
                            style={{
                              width: '100%',
                              maxWidth: '160px'
                            }}
                          />
                        </Link>
                      </div>
                    </Col>
                  </Row>
                  <Row className={styles.mobileSelectorRoot} style={env.hideSelector ? { display: 'none' } : null}>
                    <Col span={24}>
                      <div className={styles.mobileLanguageSelector} style={!env.landing.selector ? { display: 'none' } : null}>
                        <div
                          className={styles.languageButton}
                          style={
                            localStorage.getItem('i18nextLng') === env.landing.languages.one.culture
                              ? {
                                  borderColor: env.colors.primaryColor,
                                  backgroundColor: env.colors.primaryColor,
                                  color: '#fff'
                                }
                              : {
                                  borderColor: env.colors.primaryColor
                                }
                          }
                          onClick={() => handleSelect(env.landing.languages.one.culture)}>
                          {env.landing.languages.one.code}
                        </div>
                        <div className={styles.buttonDivider} style={{ backgroundColor: env.colors.primaryColor }} />
                        <div
                          className={styles.languageButton}
                          style={
                            localStorage.getItem('i18nextLng') === env.landing.languages.two.culture
                              ? {
                                  borderColor: env.colors.primaryColor,
                                  backgroundColor: env.colors.primaryColor,
                                  color: '#fff'
                                }
                              : {
                                  borderColor: env.colors.primaryColor
                                }
                          }
                          onClick={() => handleSelect(env.landing.languages.two.culture)}>
                          {env.landing.languages.two.code}
                        </div>
                      </div>
                    </Col>
                  </Row>
                  {getHeaderTitle() !== '' ? (
                    <Row style={search.loaded ? { display: 'none' } : {}}>
                      <Col span={24}>
                        <h1 className={styles.articleHeading} style={{ color: env.colors.title }}>
                          {getHeaderTitle()}{' '}
                          <InfoCircleOutlined
                            onClick={() => setModal(true)}
                            style={contextValue.contextData.ibdModal !== 'questionnaire' ? { display: 'none' } : {}}
                            className={styles.infoIcon}
                          />
                        </h1>
                      </Col>
                    </Row>
                  ) : (
                    <div />
                  )}
                  {search.loaded ? (
                    <p className={styles.results}>
                      <span className={styles.count}>{search.data.length}</span> {t('header.result')} <span className={styles.value}>"{value}"</span>
                    </p>
                  ) : null}
                </Col>
              ) : (
                <Col span={24}>
                  <Row align='middle'>
                    <Col span={4}>
                      <Link to={`/?culture=${supportedCulture()}`}>
                        <img src='/images/fywy-app-icon-1024x2.png' alt='takeda logo' style={{ width: '40px' }} />
                      </Link>
                    </Col>
                    <Col span={12}>
                      <h1 className={styles.heading}>
                        <p>{t('header.1')},</p>
                        <p>{t('header.2')}</p>
                      </h1>
                    </Col>
                  </Row>
                  <Row className={styles.mobileSelectorRoot} style={env.hideSelector ? { display: 'none' } : null}>
                    <Col span={24}>
                      <div className={styles.mobileLanguageSelector} style={!env.landing.selector ? { display: 'none' } : null}>
                        <div
                          className={styles.languageButton}
                          style={
                            localStorage.getItem('i18nextLng') === env.landing.languages.one.culture
                              ? {
                                  borderColor: env.colors.primaryColor,
                                  backgroundColor: env.colors.primaryColor,
                                  color: '#fff'
                                }
                              : {
                                  borderColor: env.colors.primaryColor
                                }
                          }
                          onClick={() => handleSelect(env.landing.languages.one.culture)}>
                          {env.landing.languages.one.code}
                        </div>
                        <div className={styles.buttonDivider} style={{ backgroundColor: env.colors.primaryColor }} />
                        <div
                          className={styles.languageButton}
                          style={
                            localStorage.getItem('i18nextLng') === env.landing.languages.two.culture
                              ? {
                                  borderColor: env.colors.primaryColor,
                                  backgroundColor: env.colors.primaryColor,
                                  color: '#fff'
                                }
                              : {
                                  borderColor: env.colors.primaryColor
                                }
                          }
                          onClick={() => handleSelect(env.landing.languages.two.culture)}>
                          {env.landing.languages.two.code}
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <h3 className={styles.msubheading}>{t('header.3')}</h3>
                    </Col>
                  </Row>
                  {getHeaderTitle() !== '' ? (
                    <Row style={search.loaded ? { display: 'none' } : {}}>
                      <Col span={24}>
                        <h1 className={styles.articleHeading} style={{ color: env.colors.title }}>
                          {getHeaderTitle()}{' '}
                          <InfoCircleOutlined
                            onClick={() => setModal(true)}
                            style={contextValue.contextData.ibdModal !== 'questionnaire' ? { display: 'none' } : {}}
                            className={styles.infoIcon}
                          />
                        </h1>
                      </Col>
                    </Row>
                  ) : (
                    <div />
                  )}
                  {search.loaded ? (
                    <p className={styles.results}>
                      <span className={styles.count}>{search.data.length}</span> {t('header.result')}{' '}
                      <span className={styles.value} style={{ color: env.colors.primaryColor }}>
                        "{value}"
                      </span>
                    </p>
                  ) : null}
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Col>
    )
  }
  const TabletContent = () => {
    return (
      <Col span={24} className={styles.tablet}>
        <Row justify='center' align='middle'>
          <Col span={19}>
            <Row style={{ height: 32 }} />
            <Row align='middle' justify='start'>
              <Col xs={24} sm={10} md={12} lg={13} xl={14} xxl={14} className={styles.profile}>
                <Row align='middle'>
                  <Col span={5} lg={4}>
                    {React.createElement('img', {
                      src: contextValue.collapsed ? '/images/burgericon-bluegreen.png' : '/images/burgericon-white.png',
                      className: `trigger ${styles.triggerIcon}`,
                      style: contextValue.collapsed
                        ? { backgroundColor: env.colors.menuColor, color: env.colors.primaryColor }
                        : { backgroundColor: env.colors.primaryColor },
                      onClick: toggle
                    })}
                  </Col>
                  <Col span={19} className={styles.searchBox}>
                    <SearchBox setSearchValue={setValue} search={search} setSearch={setSearch} setLoaded={setLoaded} />
                  </Col>
                </Row>
              </Col>
              <Col
                xs={24}
                sm={14}
                md={12}
                lg={11}
                xl={10}
                xxl={10}
                className={env.enhancements.includes('message_board') || matrix.data.Country === 'CA' ? styles.enhanceDropdown : styles.profileDropdown}>
                <div className={styles.profileLanguageSelector}>
                  <ProfileLink setProfile={props.setProfile} />
                  <div
                    className={styles.tabletLanguageSelector}
                    style={
                      env.hideSelector
                        ? { display: 'none' }
                        : !env.landing.selector || !window.location.pathname.toLowerCase().includes('/articles/')
                        ? { display: 'none' }
                        : null
                    }>
                    <div
                      className={styles.languageButton}
                      style={
                        localStorage.getItem('i18nextLng') === env.landing.languages.one.culture
                          ? {
                              borderColor: env.colors.primaryColor,
                              backgroundColor: env.colors.primaryColor,
                              color: '#fff'
                            }
                          : {
                              borderColor: env.colors.primaryColor
                            }
                      }
                      onClick={() => handleSelect(env.landing.languages.one.culture)}>
                      {env.landing.languages.one.code}
                    </div>
                    <div className={styles.buttonDivider} style={{ backgroundColor: env.colors.primaryColor }} />
                    <div
                      className={styles.languageButton}
                      style={
                        localStorage.getItem('i18nextLng') === env.landing.languages.two.culture
                          ? {
                              borderColor: env.colors.primaryColor,
                              backgroundColor: env.colors.primaryColor,
                              color: '#fff'
                            }
                          : {
                              borderColor: env.colors.primaryColor
                            }
                      }
                      onClick={() => handleSelect(env.landing.languages.two.culture)}>
                      {env.landing.languages.two.code}
                    </div>
                  </div>
                </div>
                {matrix.data.Country !== 'CA' ? (
                  <div style={!env.enhancements.includes('message_board') ? { display: 'none' } : null}>
                    <MessageBoard env={env} t={t} />
                  </div>
                ) : (
                  <div style={{ color: env.colors.title }} className={styles.contactUs} onClick={() => setIsVisible(true)}>
                    {t('header.contact')}
                  </div>
                )}
              </Col>
            </Row>
            <Row
              align='middle'
              justify='start'
              style={
                props.categories && window.location.pathname.toLowerCase().includes('/articles/') && env.enhancements.includes('subcategories')
                  ? null
                  : { display: 'none' }
              }>
              <Col xs={24} sm={10} md={12} lg={13} xl={14} xxl={14} className={styles.subcategoryRoot}>
                <Row align='middle'>
                  <Col span={5} lg={4} />
                  <Col span={19} className={styles.searchBox}>
                    <SubCategorySelect categories={props.categories} />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row
              className={styles.tabletSelectorRow}
              style={!env.landing.selector || window.location.pathname.toLowerCase().includes('/articles/') ? { display: 'none' } : null}>
              <Col span={24} style={env.hideSelector ? { display: 'none' } : null}>
                <div className={styles.tabletLanguageSelector}>
                  <div
                    className={styles.languageButton}
                    style={
                      localStorage.getItem('i18nextLng') === env.landing.languages.one.culture
                        ? {
                            borderColor: env.colors.primaryColor,
                            backgroundColor: env.colors.primaryColor,
                            color: '#fff'
                          }
                        : {
                            borderColor: env.colors.primaryColor
                          }
                    }
                    onClick={() => handleSelect(env.landing.languages.one.culture)}>
                    {env.landing.languages.one.code}
                  </div>
                  <div className={styles.buttonDivider} style={{ backgroundColor: env.colors.primaryColor }} />
                  <div
                    className={styles.languageButton}
                    style={
                      localStorage.getItem('i18nextLng') === env.landing.languages.two.culture
                        ? {
                            borderColor: env.colors.primaryColor,
                            backgroundColor: env.colors.primaryColor,
                            color: '#fff'
                          }
                        : {
                            borderColor: env.colors.primaryColor
                          }
                    }
                    onClick={() => handleSelect(env.landing.languages.two.culture)}>
                    {env.landing.languages.two.code}
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col span={19} className={styles.title}>
            <Row>
              <Col span={24}>
                {env.countryClass === 'canada' ? (
                  <Row align='middle' style={{ paddingBottom: 20 }}>
                    <Col span={24}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          height: 120,
                          marginTop: 20
                        }}>
                        <Link to={`/?culture=${supportedCulture()}`}>
                          <img
                            src={env.logo}
                            alt='onepath'
                            style={{
                              width: '100%',
                              maxWidth: '300px'
                            }}
                          />
                        </Link>
                      </div>
                    </Col>
                  </Row>
                ) : (
                  <Row align='middle' style={{ paddingBottom: 20 }}>
                    <Col xs={24} sm={4} md={4} lg={3} xl={2} xxl={2}>
                      <Link to={`/?culture=${supportedCulture()}`}>
                        <img src='/images/fywy-app-icon-1024x2.png' className={styles.takedaImg} alt='takeda logo' />
                      </Link>
                    </Col>
                    <Col xs={24} sm={20} md={20} lg={21} xl={22} xxl={22}>
                      <h1 className={styles.heading}>
                        <span>{t('header.1')},</span> <span>{t('header.2')}</span>
                      </h1>
                      <h3 className={styles.subheading}>{t('header.3')}</h3>
                    </Col>
                  </Row>
                )}

                {getHeaderTitle() !== '' ? (
                  <Row style={search.loaded ? { display: 'none' } : {}}>
                    <Col span={24}>
                      <h1 className={styles.articleHeading} style={{ color: env.colors.title }}>
                        {getHeaderTitle()}{' '}
                        <InfoCircleOutlined
                          onClick={() => setModal(true)}
                          style={contextValue.contextData.ibdModal !== 'questionnaire' ? { display: 'none' } : {}}
                          className={styles.infoIcon}
                        />
                      </h1>
                    </Col>
                  </Row>
                ) : (
                  <div />
                )}
                {search.loaded ? (
                  <p className={styles.results}>
                    <span className={styles.count}>{search.data.length}</span> {t('header.result')}{' '}
                    <span className={styles.value} style={{ color: env.colors.primaryColor }}>
                      "{value}"
                    </span>
                  </p>
                ) : null}
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    )
  }

  const DesktopContent = () => {
    return (
      <Col span={24} className={styles.desktop}>
        <Row>
          <Col span={24}>
            <Row align='middle'>
              <Col xs={24} sm={18} md={18} lg={17} xl={17}>
                <Row align='end'>
                  <Col span={23} style={{ paddingLeft: 15, display: 'flex' }}>
                    <SearchBox setSearchValue={setValue} search={search} setSearch={setSearch} setLoaded={setLoaded} />
                    <div className={styles.languageSelector} style={env.hideSelector ? { display: 'none' } : null}>
                      <div
                        className={styles.languageButton}
                        style={
                          localStorage.getItem('i18nextLng') === env.landing.languages.one.culture
                            ? {
                                borderColor: env.colors.primaryColor,
                                backgroundColor: env.colors.primaryColor,
                                color: '#fff'
                              }
                            : {
                                borderColor: env.colors.primaryColor
                              }
                        }
                        onClick={() => handleSelect(env.landing.languages.one.culture)}>
                        {env.landing.languages.one.code}
                      </div>
                      <div className={styles.buttonDivider} style={{ backgroundColor: env.colors.primaryColor }} />
                      <div
                        className={styles.languageButton}
                        style={
                          localStorage.getItem('i18nextLng') === env.landing.languages.two.culture
                            ? {
                                borderColor: env.colors.primaryColor,
                                backgroundColor: env.colors.primaryColor,
                                color: '#fff'
                              }
                            : {
                                borderColor: env.colors.primaryColor
                              }
                        }
                        onClick={() => handleSelect(env.landing.languages.two.culture)}>
                        {env.landing.languages.two.code}
                      </div>
                    </div>
                    <div
                      style={
                        props.categories && window.location.pathname.toLowerCase().includes('/articles/') && env.enhancements.includes('subcategories')
                          ? {
                              flexGrow: 1,
                              textAlign: 'end',
                              paddingRight: 32
                            }
                          : {
                              display: 'none'
                            }
                      }>
                      <SubCategorySelect categories={props.categories} />
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col xs={24} sm={6} md={6} lg={7} xl={7} style={{ position: 'relative' }}>
                {matrix.data.Country !== 'CA' ? (
                  <ProfileLink setProfile={props.setProfile} />
                ) : (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <UserOutlined className={styles.pictureBorder} style={{ borderColor: env.colors.title, color: env.colors.title }} />
                    <ProfileLink setProfile={props.setProfile} />
                  </div>
                )}

                {matrix.data.Country !== 'CA' ? (
                  <div style={!env.enhancements.includes('message_board') ? { display: 'none' } : null}>
                    <MessageBoard env={env} t={t} />
                  </div>
                ) : (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <PhoneOutlined className={styles.pictureBorder} style={{ borderColor: env.colors.title, color: env.colors.title }} />
                    <div style={{ color: env.colors.title }} className={styles.contactUs} onClick={() => setIsVisible(true)}>
                      {t('header.contact')}
                    </div>
                  </div>
                )}
              </Col>
            </Row>
          </Col>
          <Col span={24} style={search.loaded ? { display: 'none' } : {}}>
            <Row align='end'>
              <Col span={23}>
                {getHeaderTitle() === '' ? (
                  env.countryClass === 'canada' ? (
                    <div>
                      <h1
                        className={styles.heading}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          height: 120
                        }}>
                        <span
                          style={{
                            color: env.colors.title,
                            textTransform: 'initial'
                          }}>
                          {t('header.1')}
                        </span>
                      </h1>
                    </div>
                  ) : (
                    <div>
                      <h1 className={styles.heading}>
                        <span>{t('header.1')},</span> <span>{t('header.2')}</span>
                      </h1>
                      <h3 className={styles.subheading}>{t('header.3')}</h3>
                    </div>
                  )
                ) : (
                  <h1 className={styles.articleHeading} style={{ color: env.colors.title }}>
                    {getHeaderTitle()}{' '}
                    <InfoCircleOutlined
                      onClick={() => setModal(true)}
                      style={contextValue.contextData.ibdModal !== 'questionnaire' ? { display: 'none' } : {}}
                      className={styles.infoIcon}
                    />
                  </h1>
                )}
              </Col>
            </Row>
          </Col>
          <Col span={24} style={!search.loaded ? { display: 'none' } : {}}>
            <Row align='end'>
              <Col span={23}>
                {search.loaded && !loaded ? (
                  <p className={styles.results}>
                    <span className={styles.count}>{search.data.length}</span> {t('header.result')}{' '}
                    <span className={styles.value} style={{ color: env.colors.primaryColor }}>
                      "{value}"
                    </span>
                  </p>
                ) : (
                  <p className={styles.results}>Loading...</p>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    )
  }

  return (
    <Row justify='end'>
      {MobileContent()}
      {TabletContent()}
      {DesktopContent()}
      <DisclaimerModal
        visible={modal}
        title={t('disk.confirmation.title')}
        text={<div className={styles.disclaimerModalStyle}>{t('disk.confirmation.text')}</div>}
        button={t('disk.confirmation.button')}
        close={() => setModal(false)}
      />
      <DisclaimerModal
        visible={isVisible}
        title={t('header.info.title')}
        text={
          <div className={styles.contactUsModal}>
            <span>
              {t('header.info.data') !== null ? t('header.info.data.1.key') + ': ' : ''}
              {t('header.info.data') !== null ? t('header.info.data.1.value') : ''}
            </span>
            <span>
              {t('header.info.data') !== null ? t('header.info.data.2.key') + ': ' : ''}
              <a href={`tel:${t('header.info.data') !== null ? t('header.info.data.2.value') : ''}`}>
                {t('header.info.data') !== null ? t('header.info.data.2.value') : ''}
              </a>
            </span>
            <span>
              {t('header.info.data') !== null ? t('header.info.data.3.key') + ': ' : ''}
              {t('header.info.data') !== null ? t('header.info.data.3.value') : ''}
            </span>
            <span>
              {t('header.info.data') !== null ? t('header.info.data.4.key') + ': ' : ''}
              {t('header.info.data') !== null ? t('header.info.data.4.value') : ''}
            </span>
            <span>
              {t('header.info.data') !== null ? t('header.info.data.5.key') + ': ' : ''}
              <a style={{ textDecoration: 'underline' }} href={`mailto:${t('header.info.data') !== null ? t('header.info.data.5.value') : ''}`}>
                {t('header.info.data') !== null ? t('header.info.data.5.value') : ''}
              </a>
            </span>
          </div>
        }
        button='Close'
        close={() => setIsVisible(false)}
        width={875}
      />
    </Row>
  )
}

export default Header
