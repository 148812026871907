export const DevConfig = {
  /**
   * Allowed Values
   * ooa (order of articles), subcategories, still_entyvio,
   * ibd_chart, tutorial, message_board, opf (online patient forum)
   * * tested : subcategories, message_board, opf, tutorial, still_entyvio, ibd_chart
   */
  enhancements: ['ooa', 'subcategories', 'still_entyvio', 'ibd_chart', 'tutorial', 'message_board', 'opf'],
  // loginRedirect: 'https://stag-fywypatientportal.hartehanks.com',
  // loginRoot: 'https://api-us-np.takeda.com/dev/security-takedaid-api',
  // oktaClientId: '0oarslgx4eeFN9LWT0h7',
  // appRoot: 'https://api-eu-np.takeda.com/dev/entyvio-fywy-sf-api/api/',
  // registerRoot: 'https://api-eu-np.takeda.com/dev/entyvio-fywy-sf-api/user/register',
  // loginClientId: '6f4f27221ad449f591238fef21f18ae1',
  // loginClientSecret: '2f93e83F0e814B5E9C4Ae146b30d6610',
  // clientId: '35114b55a0d54f66b49d27b0caf04dfb',
  // clientSecret: '679fd4F26d7A49B98eB455F53B3A9F5f',
  // grantType: 'authorization_code',
  // categoryRoot: 'https://api-eu-np.takeda.com/dev/entyvio-fywy-sf-api/',
  // state: 'state-296bc9a0-a2a2-4a57-be1a-d0e2fd9bb00601',
  // nonce: 'nonce-285shshkiooo989',
  // xClient: 'mobile',
  // redirectRoot: 'https://takedaext.oktapreview.com/oauth2/default/v1/authorize',
  // activeSession: 'https://takedaext.oktapreview.com/api/v1/sessions/me',
  // sendRoot: 'https://api-eu-np.takeda.com/dev/entyvio-sendgrid-api/api/email/send',
  // recoveryPasswordRoot: 'https://api-us-np.takeda.com/dev/security-takedaid-api/v1/user/recoveryPassword',
  // relayStateRecovery: 'https://dev-fywypatientportal.hartehanks.com/password_reset/init',
  // sendMailClientID: '35114b55a0d54f66b49d27b0caf04dfb',
  // sendMailClientSecret: '679fd4F26d7A49B98eB455F53B3A9F5f',
  // verifyToken: 'https://api-us-np.takeda.com/dev/security-takedaid-api/v1/user/verifyToken',
  // resetPassword: 'https://api-us-np.takeda.com/dev/security-takedaid-api/v1/user/resetPassword',
  templateID: 'd-5a0fb044c61943baa8b0e879b52c66b7', //this is from BE
  // templateID: 'd-4b8459da5825462b94d15fa5afbdfa1f',
  cookieOneTrust: 'https://cdn.cookielaw.org/consent/155491e9-a3db-439b-8a89-c9276d4259b9/OtAutoBlock.js',
  cookieCdnScript: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
  cookieId: '155491e9-a3db-439b-8a89-c9276d4259b9',
  // https://dev-fywypatientportal.hartehanks.com/login
  loginRedirect: 'https://dev-fywypatientportal.hartehanks.com/login',
  loginRoot: 'https://api-us-np.takeda.com/uat/security-takedaid-api',
  oktaClientId: '0oarslgx4eeFN9LWT0h7',
  appRoot: 'https://api-us-aws-dev2.takeda.com/ddb-entyvio-fywy-sf-pr-exp/v1/api/',
  registerRoot: 'https://api-us-aws-dev2.takeda.com/ddb-entyvio-fywy-exp/v1/user/register',
  loginClientId: 'e6d44a4b0d584677a7d5579629642a0d',
  loginClientSecret: '38B4108b71b2402A90E81af4ed91699A',
  clientId: '13bf2c7446b9456da588c673c1ee870c',
  clientSecret: 'a92030196a0D49Df9b4A392210BE6adE',
  categoryRoot: 'https://api-us-aws-dev2.takeda.com/ddb-entyvio-fywy-exp/v1/',
  grantType: 'authorization_code',
  state: 'state-296bc9a0-a2a2-4a57-be1a-d0e2fd9bb00601',
  nonce: 'nonce-285shshkiooo989',
  xClient: 'mobile',
  redirectRoot: 'https://takedaext.oktapreview.com/oauth2/default/v1/authorize',
  activeSession: 'https://takedaext.oktapreview.com/api/v1/sessions/me',
  sendRoot: 'https://api-us-aws-dev2.takeda.com/ddb-entyvio-fywy-exp/v1/email/send',
  recoveryPasswordRoot: 'https://api-us-np.takeda.com/uat/security-takedaid-api/v1/user/recoveryPassword',
  relayStateRecovery: 'https://stag-be-fywypatientportal.hartehanks.com/password_reset/init',
  sendMailClientID: 'bfcb04bbff624656bbf4bf6d061b0ab5',
  sendMailClientSecret: '66DE0c58EEc747a9B9D68F12AFe7b1f5',
  verifyToken: 'https://api-us-np.takeda.com/uat/security-takedaid-api/v1/user/verifyToken',
  resetPassword: 'https://api-us-np.takeda.com/uat/security-takedaid-api/v1/user/resetPassword',
  verifyUser: 'https://api-us-np.takeda.com/dev/security-takedaid-api/v1/user/verify',
  userLogRecord: 'https://api-us-aws-dev2.takeda.com/ddb-entyvio-fywy-exp/v1/user/log/record',

  supported_cultures: ['en-CH', 'en-CA', 'fr-BE', 'nl-BE', 'en-IE', 'bg-BG', 'hr-HR', 'lv-LV', 'hu-HU', 'fr-LU', 'ro-RO', 'lt-LT', 'sr-RS','pl-PL'],
  country: 'BE',
  language: 'nl',
  mulesoft: {
    baseURI: 'https://api-us-aws-dev2.takeda.com/ddb-entyvio-fywy-exp/v1/user/status?emailAddress=',
    clientID: 'bfcb04bbff624656bbf4bf6d061b0ab5',
    clientSecret: '66DE0c58EEc747a9B9D68F12AFe7b1f5'
  },
  // countryClass: 'canada',
  // logo: '/images/CA/onepath@2x.png',
  // entyvioLogo: '/images/CA/entyvio-logo@2x.png',
  // leftArrow: '/images/CA/left.png',
  // rightArrow: '/images/CA/right.png',
  // pinImage: '/images/CA/pinned.png',
  // medicationIcon: '/images/CA/injection-reminder@2x.png',
  // injectionIcon: '/images/CA/injection-tracker@2x.png',
  // surveyIcon: '/images/CA/Survey@2x.png',
  // homepageCustomStyle: { minHeight: 515 },
  // // country: 'CH',
  // // language: 'en',
  // colors: {
  //   primaryColor: '#839458',
  //   borderColor: '#839458',
  //   footer: '#F2F4EE',
  //   iconColor: '#CDD8B1',
  //   title: '#000',
  //   subtitle: '#4C4948',
  //   siderColor: '#82945833',
  //   reminderTitle: '#839458',
  //   reminderIcon: '#000',
  //   cardColor: '#82945833',
  //   cardTitle: '#4C4948',
  //   cardIcon: '#839458',
  //   menuColor: '#F2F4EE',
  //   mobileNavigationColor: '#4C4948',
  //   messageBoard: '#cdd8b170',
  //   messageCard: '#E2F5F7',
  //   mobileMessageBoard: '#cdd8b170',
  //   mobileMessageCard: '#F5FEFF',
  //   headerLandingText: '#000000',
  //   hcpLandingText: '#839458'
  // },
  // medicationDateFormat: 'HH:mm a',
  // entyvioLeaflets: true,
  // hideSelector: true,
  // diskModal: true,
  // onepathButton: true,
  // profile: {
  //   treatmentFormat: 'YYYY-MM-DD',
  //   contactDetails: false,
  //   mobilePhone: false,
  //   address: false
  // },
  // landing: {
  //   selector: false,
  //   button: true,
  //   hcpportal: true,
  //   disclaimer: false,
  //   promoCode: null,
  //   video: {
  //     visible: false,
  //     image: {
  //       en: '/images/landing/video-snip-fr.png',
  //       fr: '/images/landing/video-snip-fr.png',
  //       nl: '/images/landing/video-snip-nl.png'
  //     }
  //   },
  //   googlePlay: '#',
  //   appleStore: '#',
  //   hcpLink: 'https://foryouwithyou.hartehanks.com/sessions/new?culture=en-CA',
  //   logo: '/images/landing/onepath.png',
  //   background: '/images/landing/ca-landing@2x.png',
  //   phone: {
  //     en: '/images/landing/ca-mobile@2x.png',
  //     fr: '/images/landing/ca-mobile@2x.png'
  //   },
  //   icons: {
  //     appointment: '/images/landing/ca-appointment@2x.png',
  //     disk: '/images/landing/ca-disk@2x.png',
  //     map: '/images/landing/ca-map@2x.png',
  //     recipes: '/images/landing/ca-recipes@2x.png',
  //     videos: '/images/landing/ca-videos@2x.png'
  //   },
  //   languages: {
  //     one: {
  //       code: 'EN',
  //       culture: 'en-CA'
  //     },
  //     two: {
  //       code: 'FR',
  //       culture: 'fr-CA'
  //     }
  //   }
  // },
  // footer: {
  //   privacy: '#',
  //   terms: '#'
  // },
  // cardImages: {
  //   gastro: '/images/CA/gastro@2x.png',
  //   test: '/images/CA/medical-test.png',
  //   call: '/images/CA/nurse_call@2x.png',
  //   visit: '/images/CA/Nurse-Visit.png',
  //   practitioner: '/images/CA/Doctor.png',
  //   intravenous: '/images/CA/infusion@2x.png',
  //   default: '/images/CA/Doctor.png'
  // },
  // injectionCard: {
  //   leftabdomen: '/images/CA/leftabdomen.png',
  //   rightabdomen: '/images/CA/rightabdomen.png',
  //   leftarm: '/images/CA/leftarm.png',
  //   rightarm: '/images/CA/rightarm.png',
  //   leftleg: '/images/CA/leftleg@3x.png',
  //   rightleg: '/images/CA/rightleg@3x.png',
  //   default: '/images/CA/noarea.png'
  // },
  // injectionTracker: {
  //   pickerValidation: false,
  //   leftabdomen: '/images/CA/human-leftabdomen.png',
  //   rightabdomen: '/images/CA/human-rightabdomen.png',
  //   leftarm: '/images/CA/human-backofleftarm.png',
  //   rightarm: '/images/CA/human-backofrightarm.png',
  //   leftleg: '/images/CA/human-leftleg.png',
  //   rightleg: '/images/CA/human-rightleg.png',
  //   default: '/images/CA/human.png'
  // },
  // goHomeStyle: {
  //   color: '#000',
  //   fontWeight: 500,
  //   position: 'absolute',
  //   top: '5%',
  //   left: '33%'
  // },
  // hcpStyle: {
  //   display: 'none'
  // },

  countryClass: '',
  logo: '/images/fywy-app-icon-1024.png',
  entyvioLogo: '/images/entyvio@2x.png',
  leftArrow: '/images/left.png',
  rightArrow: '/images/right.png',
  pinImage: '/images/pinned.png',
  medicationIcon: '/images/icon-injection-reminder@2x.png',
  injectionIcon: '/images/icon-injection-tracker@2x.png',
  surveyIcon: '/images/survey_2x.png',
  homepageCustomStyle: {},
  colors: {
    primaryColor: '#51B1BF',
    borderColor: '#51B1BF',
    footer: 'rgb(81 177 191 / 30%)',
    iconColor: '#51B1BF',
    title: '#51B1BF',
    siderColor: '#e2f5f7',
    reminderTitle: '#0e6a7f',
    reminderIcon: '#ecaa00',
    cardColor: '#E2F5F7',
    cardTitle: '#0E6A7F',
    cardIcon: '#84d8e0',
    subtitle: '#4C4948',
    menuColor: '#E2F5F7',
    mobileNavigationColor: '#51B1BF',
    messageBoard: '#E2F5F7D9',
    mobileMessageBoard: '#E2F5F7',
    messageCard: '#E2F5F7',
    mobileMessageCard: '#F5FEFF',
    headerLandingText: '#106A7F',
    hcpLandingText: '#4B9AA9'
  },
  medicationDateFormat: 'HH:mm',
  entyvioLeaflets: true,
  hideSelector: true,
  diskModal: true,
  redirectToNothing: false,
  addLanguageParam: true,
  profile: {
    treatmentFormat: 'DD-MM-YYYY',
    contactDetails: true,
    mobilePhone: true,
    address: false,
    search: true
  },
  landing: {
    selector: true,
    button: true,
    hcpportal: true,
    disclaimer: false,
    promoCode: null,
    video: {
      visible: true,
      // image: null
      image: {
        en: '/images/landing/video-snip-ie.png',
        fr: '/images/landing/video-snip-fr.png',
        nl: '/images/landing/video-snip-nl.png',
        hr: '/images/landing/video-snip-hr.png',
        ie: '/images/landing/video-snip-ie.png',
        sr: '/images/landing/video-snip-sr.png',
        pl: '/images/landing/video-snip-pl.png'
      }
    },
    googlePlay: 'https://play.google.com/store/apps/details?id=com.takeda.foryouwithyou.pl',
    appleStore: 'https://apps.apple.com/pl/app/twojeibd/id1568278429',
    hcpLink: 'https://foryouwithyou.hartehanks.com/sessions/new?culture=',
    logo: '/images/landing/fywy-logo@2x.png',
    background: '/images/landing/default-banner@2x.png',
    mobileBackground: '/images/landing/mobile_header@2x.png',
    phone: {
      en: '/images/landing/en-mobile@2x.png',
      fr: '/images/landing/fr-mobile@2x.png',
      nl: '/images/landing/nl-mobile@2x.png',
      ro: '/images/landing/ro-mobile@2x.png',
      hr: '/images/landing/hr-mobile@2x.png',
      hu: '/images/landing/hu-mobile@2x.png',
      lt: '/images/landing/lt-mobile@2x.png',
      lv: '/images/landing/lv-mobile@2x.png',
      ca: '/images/landing/ca-mobile@2x.png',
      bg: '/images/landing/bg-mobile@2x.png',
      sr: '/images/landing/sr-mobile@2x.png',
      pl: '/images/landing/pl-mobile@2x.png'
    },
    icons: {
      appointment: '/images/appointment_tracker_2x.png',
      disk: '/images/icon-IBD-disk@2x.png',
      // disk: null,
      map: '/images/map-menu-icon@2x.png',
      recipes: '/images/Recipes@2x.png',
      videos: '/images/Videos@2x.png',
      clipboard: '/images/landing/pl-clipboard.png'
    },
    languages: {
      one: {
        code: 'FR',
        culture: 'fr-BE'
      },
      two: {
        code: 'NL',
        culture: 'nl-BE'
      }
    }
  },
  promo: {
    video: true,
    forum: false,
    stayConnected: true,
    images: {
      en: {
        welcome: {
          picture: '/images/promo/desktop/desktop-1.png',
          mobile: '/images/promo/desktop/iphone.png'
        },
        content: '/images/promo/desktop/content.png',
        disk: '/images/promo/desktop/disk.png',
        trackers: '/images/promo/desktop/trackers.png',
        forum: '/images/promo/desktop/forum.png',
        connected: '/images/promo/desktop/connected.png'
      },
      fr: {
        welcome: {
          picture: '/images/promo/desktop/BE/welcome-fr.png',
          mobile: '/images/promo/desktop/BE/iphone-fr.png'
        },
        content: '/images/promo/desktop/BE/content-fr.png',
        disk: '/images/promo/desktop/BE/disk-fr.png',
        trackers: '/images/promo/desktop/BE/trackers-fr.png',
        forum: null,
        connected: '/images/promo/desktop/BE/connected-fr.png'
      },
      nl: {
        welcome: {
          picture: '/images/promo/desktop/BE/welcome-nl.png',
          mobile: '/images/promo/desktop/BE/iphone-nl.png'
        },
        content: '/images/promo/desktop/BE/content-nl.png',
        disk: '/images/promo/desktop/BE/disk-nl.png',
        trackers: '/images/promo/desktop/BE/trackers-nl.png',
        forum: null,
        connected: '/images/promo/desktop/BE/connected-nl.png'
      },
      hr: {
        welcome: {
          picture: '/images/promo/desktop/HR/welcome-hr.png',
          mobile: '/images/promo/desktop/HR/iphone-hr.png'
        },
        content: '/images/promo/desktop/HR/content-hr.png',
        disk: '/images/promo/desktop/HR/disk-hr.png',
        trackers: '/images/promo/desktop/HR/trackers-hr.png',
        forum: null,
        connected: '/images/promo/desktop/HR/connected-hr.png'
      },
    }
  },
  footer: {
    privacy: 'https://www.takeda.com/pl-pl/polityka-prywatnoci',
    terms: 'https://www.takeda.com/pl-pl/regulamin-serwisu'
  },
  cardImages: {
    gastro: '/images/Gastro_Doctor.png',
    test: '/images/medical_test.png',
    call: '/images/nurse_call.png',
    visit: '/images/nurse_visit.png',
    practitioner: '/images/Doctor.png',
    intravenous: '/images/IV.png',
    default: '/images/appointment_tracker_2x.png'
  },
  injectionCard: {
    leftabdomen: '/images/injection-tracker/HB_SMALL_Track_RSA.jpg',
    rightabdomen: '/images/injection-tracker/HB_SMALL_Track_LSA.jpg',
    leftarm: '/images/injection-tracker/HB_SMALL_Track_BRA.jpg',
    rightarm: '/images/injection-tracker/HB_SMALL_Track_BLA.jpg',
    leftleg: '/images/injection-tracker/HB_SMALL_Track_FRT.jpg',
    rightleg: '/images/injection-tracker/HB_SMALL_Track_FLT.jpg',
    default: '/images/injection-tracker/HB_SMALL_Track_Unconfirmed.jpg'
  },
  injectionTracker: {
    pickerValidation: true,
    leftabdomen: '/images/injection-tracker/HB_Track_RSA.jpg',
    rightabdomen: '/images/injection-tracker/HB_Track_LSA.jpg',
    leftarm: '/images/injection-tracker/HB_Track_BRA.jpg',
    rightarm: '/images/injection-tracker/HB_Track_BLA.jpg',
    leftleg: '/images/injection-tracker/HB_Track_FRT.jpg',
    rightleg: '/images/injection-tracker/HB_Track_FLT.jpg',
    default: '/images/injection-tracker/HB_Track_Default.jpg'
  },
  goHomeStyle: {
    display: 'none'
  },
  hcpStyle: {},

  types: {
    intravenous: [
      { code: 'INTRAVENOUS_INFUSION' },
      { code: 'GASTRO_SPECIALIST' },
      { code: 'GENERAL_PRACTITIONER' },
      { code: 'MEDICAL_TREATMENT_AT_HOSPITAL' },
      { code: 'COUNCELOR_OR_PSYCHOLOGIST_VISIT' },
      { code: 'NURSE_SUPPORT_CALL' },
      { code: 'NURSE_SUPPORT_VISIT' },
      { code: 'NUTRITIONIST_VISIT' },
      { code: 'MEDICAL_TEST' },
      { code: 'RADIOLOGY_EXAMINATION_SCOPIC_EXAMINATION' }
    ],
    subcutaneous: [
      { code: 'GASTRO_SPECIALIST' },
      { code: 'GENERAL_PRACTITIONER' },
      { code: 'MEDICAL_TREATMENT_AT_HOSPITAL' },
      { code: 'COUNCELOR_OR_PSYCHOLOGIST_VISIT' },
      { code: 'NURSE_SUPPORT_CALL' },
      { code: 'NURSE_SUPPORT_VISIT' },
      { code: 'NUTRITIONIST_VISIT' },
      { code: 'MEDICAL_TEST' },
      { code: 'RADIOLOGY_EXAMINATION_SCOPIC_EXAMINATION' }
    ],
    other: [
      { code: 'GASTRO_SPECIALIST' },
      { code: 'GENERAL_PRACTITIONER' },
      { code: 'MEDICAL_TREATMENT_AT_HOSPITAL' },
      { code: 'COUNCELOR_OR_PSYCHOLOGIST_VISIT' },
      { code: 'NURSE_SUPPORT_CALL' },
      { code: 'NURSE_SUPPORT_VISIT' },
      { code: 'NUTRITIONIST_VISIT' },
      { code: 'MEDICAL_TEST' },
      { code: 'RADIOLOGY_EXAMINATION_SCOPIC_EXAMINATION' }
    ]
  }
}
