import React, { useState, useContext } from 'react'
import { Layout, Row, Col } from 'antd'
import styles from './Questions.module.css'
import { useTranslation } from 'react-i18next'
import Wrapper from './../../LayoutWrapper'
import { post } from '../../ApiCall'
import { LoadingOutlined } from '@ant-design/icons'
import Form from './Form'
import FormResult from './ResultModal'
import { getCfg } from '../../../env.config'
import { AppContext } from '../../../contexts/AppContext'

const Questions = ({ state, setState }) => {
  const { t } = useTranslation()
  const { Content } = Layout
  const context = useContext(AppContext)
  const [visible, setVisible] = useState(false)
  const address = `https://${window.location.href.split('/')[2]}`
  const env = getCfg(address)
  const [values, setValues] = useState({
    abdominalMass: null,
    abdominalPain: null,
    complications: [],
    stools: null,
    wellbeing: null
  })
  const [result, setResult] = useState({
    score: null,
    date: ''
  })
  const [loaded, setLoaded] = useState(false)
  const antIcon = <LoadingOutlined style={{ fontSize: 24, color: env.colors.primaryColor }} spin />

  const postData = {
    abdominalMass: values.abdominalMass,
    abdominalPain: values.abdominalPain,
    complications: values.complications.join(';'),
    stools: values.stools,
    wellBeing: values.wellbeing,
    createdDate: new Date()
  }

  const handleClick = () => {
    setLoaded(true)
    post('hbi', postData, (val) => {
      console.log('this is the response', val)
      setLoaded(false)
      if (val.Id) {
        setResult({
          score: val.HBI_score__c,
          data: val.Created_Date__c
        })
        setVisible(true)
      }
    })
  }

  return (
    <Layout>
      <Wrapper>
        <Content>
          <Row
            type='flex'
            align='top'
            justify='center'
            className={env.countryClass}
          >
            <Col span={20}>
              <Row gutter={24} type='flex' align='middle' className={styles.header}>
                <Col span={24}>
                  <img
                    src='/images/CA/onepath@2x.png'
                    className={styles.onepath}
                    alt='Onepath'
                    onClick={() => {
                      context.setShow({ ...context.show, hbi: false })
                      setState({ ...state, loaded: false })
                    }}
                  />
                </Col>
              </Row>

              <Row>
                <Col xs={24} sm={24} md={24} lg={16} xl={14} xxl={12}>
                  <h1
                    className={styles.questionTitle}
                    style={{ color: env.colors.title }}
                  >
                    {t('harvey.title').toLowerCase()}
                  </h1>
                </Col>
              </Row>
            </Col>
            {
              !visible ? (
                <Form
                  handleClick={handleClick}
                  styles={styles}
                  t={t}
                  icon={antIcon}
                  loaded={loaded}
                  values={values}
                  setValues={setValues}
                />
              ) : (
                <FormResult
                  styles={styles}
                  t={t}
                  context={context}
                  result={result}
                  state={state}
                  setState={setState}
                />
              )
            }

          </Row>
        </Content>
      </Wrapper>
    </Layout>
  )
}

export default Questions
