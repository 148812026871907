import React from 'react'
import { Row, Col } from 'antd'
import styles from './TrackerCard.module.css'
import moment from 'moment'
import { getCfg } from '../../../env.config'
import { useTranslation } from 'react-i18next'

const TrackerCard = (props) => {
  const { t } = useTranslation()

  const address = `https://${window.location.href.split('/')[2]}`
  const env = getCfg(address)

  const showDate = () => {
    if (props.data[0].Medication_frequency__pc > 1) {
      return moment(props.data[0].Reminder_Datetime__pc).format(
        env.medicationDateFormat
      )
    } else {
      if (props.data[0].Morning_reminder__pc) {
        return moment(props.data[0].Morning_Reminder_DateTime__pc).format(
          env.medicationDateFormat
        )
      } else if (props.data[0].Midday_Reminder__pc) {
        return moment(props.data[0].Midday_Reminder_Datetime__pc).format(
          env.medicationDateFormat
        )
      } else if (props.data[0].Evening_Reminder__pc) {
        return moment(props.data[0].Evening_Reminder_Datetime__pc).format(
          env.medicationDateFormat
        )
      }
    }
  }

  return (
    <Row
      align='middle'
      className={styles.root}
      style={{ backgroundColor: env.colors.cardColor }}>
      <Col span={24}>
        <Row>
          <Col span={24} className={styles.medicationRoot}>
            <img
              src={env.medicationIcon}
              alt='icon'
              className={styles.medicationIcon}
            />
            <div>
              <p
                className={styles.title}
                style={{
                  color: env.colors.cardTitle,
                }}>
                {t('medication.card.nextReminder')}
              </p>
              <p className={styles.date}>
                {moment(props.data[0].Reminder_Datetime__pc).format(
                  'dddd DD/MM/YYYY'
                )}
              </p>
              <p className={styles.time}>{showDate()}</p>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default TrackerCard
