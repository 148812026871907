import React, { useState } from 'react'
import { Input, message } from 'antd'
import styles from './SearchBox.module.css'
import { useTranslation } from 'react-i18next'
import { SearchOutlined } from '@ant-design/icons'
import { getCfg } from '../../env.config'

const SearchBox = ({ setSearchValue, setSearch, search, setLoaded }) => {
  const { t } = useTranslation()
  const [value, setValue] = useState('')
  const [tempState, setTempState] = useState('')
  const address = `https://${window.location.href.split('/')[2]}`
  const handleSearch = (event) => {
    setValue(event.target.value)
    if (search.loaded && event.target.value === '') {
      setTempState('')
      setSearchValue('')
      setSearch({ loaded: false, data: [] })
    }
  }
  const handleGet = () => {
    if (tempState !== value) {
      if (value.length < 3) {
        message.warning(t('errors.v_search_minlength'), 2)
      } else {
        setTempState(value)
        setSearchValue(value)
        setLoaded(true)
      }
    }
  }
  return (
    <Input
      className={styles.searchbox}
      prefix={<SearchOutlined className={styles.searchIcon} onClick={handleGet} />}
      placeholder={t('main.search')}
      allowClear
      onChange={handleSearch}
      onPressEnter={handleGet}
      style={{ borderColor: getCfg(address).colors.borderColor }}
    />
  )
}

export default SearchBox
