import React, { useState, useEffect } from 'react'
import {
  Row,
  Col,
  Button,
  DatePicker,
  Checkbox,
  Select,
  TimePicker,
  Spin,
} from 'antd'
import {
  AlertOutlined,
  CalendarOutlined,
  CloseCircleOutlined,
  LoadingOutlined,
} from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import * as moment from 'moment'
import styles from './../Appointment.module.css'
import { post } from '../../ApiCall'
import { getCfg } from '../../../env.config'
import { useLocation } from 'react-router-dom'

const AppointmentReminder = ({
  values,
  setValues,
  setClose,
  callback,
  loaded,
  setStart,
  appointments,
  treatment,
}) => {
  const { Option } = Select
  const { t } = useTranslation()
  const dateFormat = 'DD/MM/YYYY'
  const [details, setDetails] = useState([])
  const address = `https://${window.location.href.split('/')[2]}`
  const env = getCfg(address)
  const antIcon = (
    <LoadingOutlined
      style={{ fontSize: 24, color: env.colors.primaryColor }}
      spin
    />
  )
  const location = useLocation()

  useEffect(() => {
    if (values.id !== null) {
      const tempState = appointments.data.filter((appointment) => {
        return appointment.Id === values.id
      })
      setDetails(tempState)
    }
  }, [values.id, appointments.data])

  const getAppointmentDateTime = () => {
    const DATE = moment(values.appointmentDate).format('YYYY-MM-DD')
    const TIME = moment(values.appointmentHour).format('HH:mm')
    const combinedDateTime = moment(DATE + ' ' + TIME, 'YYYY-MM-DD HH:mm')
    return combinedDateTime
  }

  const getReminderDateTime = () => {
    const DATE = moment(values.reminderDate).format('YYYY-MM-DD')
    const TIME = moment(values.reminderHour).format('HH:mm')
    const combinedDateTime = moment(DATE + ' ' + TIME, 'YYYY-MM-DD HH:mm')
    return combinedDateTime
  }

  const postData = values.notification
    ? {
        Appointment_DateTime: getAppointmentDateTime(),
        Appointment_Type: values.appointmentType,
        Reminder_DateTime:
          values.reminderDate !== null ? getReminderDateTime() : '',
        Reminder_Push_Enabled: 'false',
        Reminder_Email_Enabled: values.notification,
      }
    : {
        Appointment_DateTime: getAppointmentDateTime(),
        Appointment_Type: values.appointmentType,
        Reminder_Push_Enabled: 'false',
        Reminder_Email_Enabled: values.notification,
      }

  const editData = values.notification
    ? {
        Id: values.id,
        Appointment_DateTime: getAppointmentDateTime(),
        Appointment_Type: values.appointmentType,
        Reminder_DateTime:
          values.reminderDate !== null ? getReminderDateTime() : '',
        Reminder_Push_Enabled: 'false',
        Reminder_Email_Enabled: values.notification,
      }
    : {
        Id: values.id,
        Appointment_DateTime: getAppointmentDateTime(),
        Appointment_Type: values.appointmentType,
        Reminder_Push_Enabled: 'false',
        Reminder_Email_Enabled: values.notification,
      }

  useEffect(() => {
    if (Array.isArray(details)) {
      if (details.length > 0) {
        setValues((oldState) => ({
          ...oldState,
          appointmentDate: moment(details[0].Appointment_DateTime__c),
          appointmentType: details[0].Appointment_Type__c.toUpperCase(),
          appointmentHour: moment(details[0].Appointment_DateTime__c),
          reminderDate: details[0].Reminder_DateTime__c
            ? moment(details[0].Reminder_DateTime__c)
            : null,
          notification: details[0].Reminder_Email_Enabled__c,
          reminderHour: details[0].Reminder_DateTime__c
            ? moment(details[0].Reminder_DateTime__c)
            : null,
        }))
      }
    }
  }, [details, setValues])

  const handleChange = (value, name) => {
    setValues((oldValue) => ({
      ...oldValue,
      [name]: value,
    }))
  }

  const validateFields = () => {
    if (values.notification) {
      if (
        values.appointmentDate !== null &&
        values.appointmentType !== null &&
        values.appointmentHour !== null &&
        values.reminderDate !== null &&
        values.reminderHour !== null
      ) {
        return true
      } else {
        return false
      }
    } else {
      if (
        values.appointmentDate !== null &&
        values.appointmentType !== null &&
        values.appointmentHour !== null
      ) {
        return true
      } else {
        return false
      }
    }
  }

  const disabledDate = (current) => {
    return (
      current < moment().subtract(1, 'days').endOf('day') ||
      current > moment().add(18, 'months')
    )
  }

  const handleClick = () => {
    if (values.id !== null) {
      setStart(true)
      post('appointments', editData, callback)
    } else {
      setStart(true)
      post('appointments', postData, callback)
    }
  }

  const getTypes = () => {
    const treatmentType = treatment.data[0]
      ? treatment.data[0].Treatment__c
        ? treatment.data[0].Treatment__c
        : 'OTHER'
      : 'OTHER'
    if (treatmentType === 'SC') return env.types.subcutaneous
    if (treatmentType === 'IV') return env.types.intravenous
    return env.types.other
  }

  return (
    <Row align='top' className={styles.modalRoot}>
      <Col span={24}>
        <CloseCircleOutlined
          className={styles.closeIcon}
          onClick={setClose}
          style={{ color: env.colors.primaryColor }}
        />
      </Col>
      <Col span={24} className={styles.root}>
        <Row>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            xxl={12}
            className={styles.rootColLeft}>
            <Row>
              <Col span={24}>
                <div className={styles.title}>
                  <CalendarOutlined
                    className={styles.icon}
                    style={{ color: env.colors.reminderIcon }}
                  />
                  <span style={{ color: env.colors.reminderTitle }}>
                    {t('appointment.reminder.appointment_settings')}
                  </span>
                </div>
              </Col>
              <Col span={24}>
                <DatePicker
                  className={styles.datepicker}
                  name='appointmentDate'
                  value={values.appointmentDate}
                  onChange={(date) => handleChange(date, 'appointmentDate')}
                  format={dateFormat}
                  disabledDate={disabledDate}
                  inputReadOnly
                  style={{
                    marginBottom: 25,
                    width: '100%',
                    border: '1px solid',
                    borderColor: env.colors.borderColor,
                    borderRadius: 10,
                    fontSize: 18,
                  }}
                  placeholder={t('appointment.reminder.label_date')}
                  dropdownClassName={env.countryClass}
                  disabled={values.appointmentType === 'INFUSION_CLINIC'}
                />
              </Col>
              <Col span={24}>
                <TimePicker
                  onChange={(value) => handleChange(value, 'appointmentHour')}
                  className={styles.timePicker}
                  value={values.appointmentHour}
                  placeholder={t('appointment.reminder.label_time')}
                  format='HH:mm'
                  size='large'
                  style={{
                    marginBottom: 25,
                    border: '1px solid',
                    borderColor: env.colors.borderColor,
                    borderRadius: 10,
                    fontSize: '18px',
                    width: '100%',
                    height: '38px',
                  }}
                  dropdownClassName={env.countryClass}
                  disabled={values.appointmentType === 'INFUSION_CLINIC'}
                />
              </Col>
              <Col span={24}>
                <Select
                  size='large'
                  bordered={false}
                  placeholder={t('appointment.reminder.type')}
                  name='appointmentType'
                  onChange={(value) => handleChange(value, 'appointmentType')}
                  className={styles.typeSelect}
                  value={
                    values.appointmentType === 'INFUSION_CLINIC'
                      ? t(`appointment.reminder.select.INFUSION_CLINIC`)
                      : values.appointmentType
                  }
                  allowClear
                  style={{
                    marginBottom: 25,
                    fontSize: '18px',
                    width: '100%',
                    height: '38px',
                  }}
                  dropdownClassName={env.countryClass}
                  disabled={values.appointmentType === 'INFUSION_CLINIC'}>
                  {getTypes().map((val) => {
                    return (
                      <Option
                        key={val.code}
                        value={val.code}
                        style={
                          val.code === 'CALPROTECTIN_TEST' &&
                          !location.search.includes('da-DK')
                            ? { display: 'none' }
                            : null
                        }>
                        {t(`appointment.reminder.select.${val.code}`)}
                      </Option>
                    )
                  })}
                </Select>
              </Col>
            </Row>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            xxl={12}
            className={styles.rootColRight}>
            <div className={styles.title}>
              <AlertOutlined
                className={styles.icon}
                style={{ color: env.colors.reminderIcon }}
              />
              <span style={{ color: env.colors.reminderTitle }}>
                {t('appointment.reminder.reminder_settings')}
              </span>
            </div>
            <p
              style={{ color: env.colors.reminderTitle }}
              className={styles.typeHeader}>
              {t('appointment.reminder.reminder_type')}
            </p>
            <div className={styles.type}>
              <Checkbox
                checked={values.notification}
                className={styles.checkbox}
                onChange={(e) => handleChange(e.target.checked, 'notification')}
              />
              <span
                style={{ color: env.colors.reminderTitle }}
                className={styles.label}>
                {t('appointment.reminder.notification')}
              </span>
            </div>
            <DatePicker
              className={styles.datepicker}
              name='reminderDate'
              value={values.reminderDate}
              onChange={(date) => handleChange(date, 'reminderDate')}
              format={dateFormat}
              disabledDate={disabledDate}
              inputReadOnly
              style={{
                marginBottom: 25,
                width: '100%',
                border: '1px solid',
                borderColor: env.colors.borderColor,
                borderRadius: 10,
                fontSize: 18,
              }}
              disabled={!values.notification}
              placeholder={t('appointment.reminder.label_date')}
              dropdownClassName={env.countryClass}
            />
            <TimePicker
              onChange={(value) => handleChange(value, 'reminderHour')}
              className={styles.timePicker}
              value={values.reminderHour}
              placeholder={t('appointment.reminder.label_time')}
              format='HH:mm'
              size='large'
              style={{
                marginBottom: 25,
                border: '1px solid',
                borderColor: env.colors.borderColor,
                borderRadius: 10,
                fontSize: '18px',
                width: '100%',
                height: '38px',
              }}
              disabled={!values.notification}
              dropdownClassName={env.countryClass}
            />
          </Col>
          <Col
            span={24}
            className={styles.buttonModal}
            style={validateFields() ? {} : { cursor: 'no-drop' }}>
            <Spin indicator={antIcon} spinning={loaded}>
              <Button
                className={styles.button}
                style={
                  validateFields()
                    ? {
                        backgroundColor: env.colors.primaryColor,
                        color: '#fff',
                      }
                    : {
                        backgroundColor: '#ccc',
                        border: 'none',
                        pointerEvents: 'none',
                        color: '#fff',
                      }
                }
                onClick={handleClick}>
                {t('appointment.reminder.button')}
              </Button>
            </Spin>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default AppointmentReminder
