import React, { useState, useEffect } from 'react'
import { Row, Col, Button, Skeleton } from 'antd'
import Calendar from '../Calendar'
import styles from './MedicationTracker.module.css'
import MedicationCard from './TrackerCard'
import MedicationReminder from './MedicationReminder'
import { useTranslation } from 'react-i18next'
import { get } from '../ApiCall'
import moment from 'moment'
import { getCfg } from '../../env.config'

const Medication = ({ medication, setMedication }) => {
  const { t } = useTranslation()
  const [visible, setVisible] = useState(false)
  const [state, setState] = useState({
    currentMonth: moment(new Date()),
    open: false,
    content: {}
  })

  const [values, setValues] = useState({
    frequency: null,
    medicationDate: null,
    appNotification: false,
    emailNotification: false,
    morning: false,
    midday: false,
    evening: false,
    reminderTime: null,
    selectedValues: [],
    morningTime: null,
    middayTime: null,
    eveningTime: null,
    id: null
  })

  const [dates, setDates] = useState([])
  const [loaded, setLoaded] = useState(false)
  const [medicationT, setMedicationT] = useState({ loaded: false, data: [] })
  const address = `https://${window.location.href.split('/')[2]}`
  const env = getCfg(address)
  useEffect(() => {
    setMedicationT(medication)
  }, [medication, setMedicationT])

  useEffect(() => {
    const config = {
      offset: 0,
      limit: 1000
    }
    if (!medicationT.loaded) {
      get('medicationReminder/detail', config, setMedicationT)
    }
  }, [medicationT.loaded])
  useEffect(() => {
    if (Array.isArray(medicationT.data)) {
      const array = []
      medicationT.data.map((val) => {
        return array.push(moment(val.Next_Reminder_Date__pc).format('YYYY-MM-DD'))
      })
      setDates(array)
    }
  }, [medicationT.data, setDates])

  const handleDateClick = (date) => {
    setValues((oldValues) => ({
      ...oldValues,
      medicationDate: date
    }))
  }

  const callbackFunc = (value) => {
    if (value.Id) {
      setMedicationT({
        ...medicationT,
        loaded: false
      })
      setLoaded(false)
      setVisible(false)
      setValues({
        frequency: null,
        medicationDate: null,
        appNotification: false,
        emailNotification: false,
        morning: false,
        midday: false,
        evening: false,
        reminderTime: null,
        selectedValues: [],
        id: null
      })
    } else {
      setLoaded(false)
      window.alert('problem')
    }
  }
  const getData = () => {
    if (Array.isArray(medicationT.data) && medicationT.loaded) {
      if (medicationT.data.length > 0) {
        return medicationT.data.map((val) => {
          return <MedicationCard key={val.Id} {...medicationT} t={t} />
        })
      } else {
        return <Skeleton active loading />
      }
    } else {
      return <Skeleton active loading />
    }
  }

  const getButtonName = () => {
    if (medicationT.data[0]) {
      if (medicationT.data[0].Reminder_Email_Notification__pc) {
        return t('medication.main.button.2')
      } else {
        return t('medication.main.button.1')
      }
    }
  }

  if (visible) {
    return (
      <MedicationReminder
        setVisible={setVisible}
        values={values}
        setValues={setValues}
        medication={medicationT}
        callback={(val) => callbackFunc(val)}
        loaded={loaded}
        setLoaded={setLoaded}
      />
    )
  } else {
    return (
      <Row align='bottom' justify='center' className={styles.appointmentRoot}>
        <Col xs={23} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <div className={styles.text}>{t('medication.main.text')}</div>
        </Col>
        <Col xs={23} sm={24} md={24} lg={24} xl={24} xxl={24} className={styles.appointmentTracker}>
          <Row gutter={24} className={styles.trackerRow}>
            <Col xs={24} sm={24} md={11} lg={11} xl={11} xxl={11} className={styles.calendarPlaceholder}>
              <Calendar selected={values.medicationDate} dates={dates} onDateClick={handleDateClick} state={state} setState={setState} />
            </Col>
            <Col xs={24} sm={24} md={13} lg={13} xl={13} xxl={13}>
              <Row style={{ height: '100%' }}>
                <Col span={24} className={styles.cards}>
                  {getData()}
                </Col>
                <Col span={24} className={styles.buttonCol}>
                  <Button
                    style={{
                      backgroundColor: env.colors.primaryColor,
                      color: '#fff'
                    }}
                    className={styles.trackerButton}
                    onClick={() => setVisible(true)}>
                    {getButtonName()}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row justify='center' className={styles.tabletButton}>
            <Col xs={24} sm={16} md={11} lg={11} xl={11} xxl={11}>
              <Button
                style={{
                  backgroundColor: env.colors.primaryColor,
                  color: '#fff'
                }}
                className={styles.trackerButton}
                onClick={() => setVisible(true)}>
                {getButtonName()}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }
}

export default Medication
