import React, { useEffect, useState } from 'react'
import { Layout, Col, Row, Form, Input, Button, Spin } from 'antd'
import { useTranslation } from 'react-i18next'
import styles from './MailView.module.css'
import { getCfg } from '../../../../env.config'
import { UserOutlined, LoadingOutlined } from '@ant-design/icons'
import axios from 'axios'
import * as qs from 'query-string'
import NotAllowed from '../../../NotAllowed'
import { logAction, handleMuleSoft, getRecoveryToken, sendEmail } from '../../../ApiCall'
import { Link } from 'react-router-dom'

const MailView = () => {
  const { t } = useTranslation()
  const { Content } = Layout
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState({
    showBtn: false,
    content: '',
    sendEmailBtn: false,
    showRegister: false
  })
  const [loadingResetBtn, setLoadingResetBtn] = useState(false)
  const [loading, setLoading] = useState(false)
  const address = `https://${window.location.href.split('/')[2]}`
  const env = getCfg(address)
  const antIcon = <LoadingOutlined style={{ fontSize: 24, color: env.colors.primaryColor }} spin />

  const [form] = Form.useForm()
  const [, forceUpdate] = useState()

  const queryString = qs.parse(window.location.search)
  useEffect(() => {
    const address = `https://${window.location.href.split('/')[2]}`
    const env = getCfg(address)
    const queryString = qs.parse(window.location.search)
    if (queryString.culture === undefined) {
      localStorage.setItem('i18nextLng', `${env.supported_cultures[0]}`)
      window.location.href = window.location.pathname + '?culture=' + env.supported_cultures[0]
    } else if (queryString.culture === 'hr') {
      localStorage.setItem('i18nextLng', `hr-HR`)
      window.location.href = window.location.pathname + '?culture=hr-HR'
    }
  }, [])

  useEffect(() => {
    forceUpdate({})
  }, [])

  const handleOnChange = (e) => {
    setEmail(e.target.value)
  }

  const post = (path, data, callback) => {
    const headers = {
      client_id: env.loginClientId,
      client_secret: env.loginClientSecret,
      'Content-Type': 'application/json'
    }
    axios
      .post(path, data, {
        headers: headers
      })
      .then(function (response) {
        callback(response.data)
      })
      .catch(function (error) {
        console.log(error.response)
        callback(error.response)
      })
  }
  let culture
  let language
  let country
  if (queryString.culture !== undefined) {
    culture = queryString.culture
    language = queryString.culture.split('-')[0]
    country = queryString.culture.split('-')[1]
  } else {
    culture = env.supported_cultures[0]
    language = env.language
    country = env.country
  }

  const postData = {
    username: email,
    relayState: `${env.relayStateRecovery}?culture=${culture}`
  }

  const startPayload = {
    LogTitle: 'Password Reset Init',
    username__c: email,
    country__c: country,
    password_reset_init__c: new Date().toISOString(),
    test: 'null'
  }

  // mulesoft call
  const handleSubmit = () => {
    setLoading(true)
    setMessage({ showBtn: false, content: '' })
    // dont forget to uncomment
    logAction('user/log', startPayload, {})
    // headers for muleSoft api call
    const headersMule = {
      'x-country': queryString.culture.split('-')[1],
      client_id: env.mulesoft.clientID,
      client_secret: env.mulesoft.clientSecret
    }
    // muleSoft call
    handleMuleSoft(env.mulesoft.baseURI, email, headersMule).then((res) => {
      if (!res.Registered && !res.Enrolled) {
        setMessage({ showBtn: false, sendEmailBtn: false, showRegister: true, content: 'reset_password.mulesoft.matchNotFound' })
      } else if (!res.Registered && res.Enrolled) {
        // scenario 2
        setMessage({ showBtn: false, sendEmailBtn: false, showRegister: true, content: 'reset_password.mulesoft.notValidateInTime' })
      } else if (res.Registered && !res.Enrolled && !res.Validated) {
        // scenario 4
        setMessage({ showBtn: false, sendEmailBtn: false, showRegister: true, content: 'reset_password.mulesoft.matchNotFound' })
      } else if (res.Registered && !res.Enrolled && res.Validated) {
        // scenario 5
        setMessage({ showBtn: false, sendEmailBtn: false, showRegister: true, content: 'reset_password.mulesoft.matchNotFound' })
      } else if (res.Registered && res.Enrolled && !res.Validated) {
        // scenario 6
        setMessage({ showBtn: false, sendEmailBtn: true, showRegister: false, content: 'reset_password.mulesoft.accountNotValidated' })
      } else if (res.Registered && res.Enrolled && res.Validated) {
        // button to trigger reset password
        setMessage({ showBtn: true, sendEmailBtn: false, showRegister: false, content: 'reset_password.mulesoft.noAccountError' })
      }
      setLoading(false)
    }).catch(() => setMessage({ showBtn: false, sendEmailBtn: false, content: 'reset_password.mulesoft.genericError' }))
  }
  // reset password
  const handleResetPassword = () => {
    setLoadingResetBtn(true)
    setMessage({ showBtn: true, content: '' })
    post(env.recoveryPasswordRoot, postData, (val) => {
      const successPayload = {
        LogTitle: 'Password Reset Requested',
        username__c: email,
        okta_recovery_token__c: val.recoveryToken,
        okta_recovery_token_type__c: val.recoveryType,
        okta_recovery_token_validity__c: val.expiresAt,
        country__c: country,
        password_reset_requested__c: new Date().toISOString(),
        password_reset_email_error__c: 'null',
        password_reset_email_error_msg__c: 'null',
        password_reset_clicked__c: 'null'
      }
      const errorPayload = {
        LogTitle: 'Password Reset Email Error',
        username__c: email,
        country__c: country,
        password_reset_email_error__c: new Date().toISOString(),
        password_reset_requested__c: 'null'
      }

      if (val) {
        if (val.recoveryToken) {
          const sendgridData = {
            messages: [
              {
                to: [
                  {
                    email: email,
                    name: ''
                  }
                ],
                templateData: [
                  {
                    field: 'emailAddress',
                    content: email
                  },
                  {
                    field: 'country',
                    content: country
                  },
                  {
                    field: 'language',
                    content: language
                  },
                  {
                    field: 'queryParam',
                    content: `?recoveryToken=${val.recoveryToken}&culture=${language}-${country}`
                  }
                ]
              }
            ],
            country: country,
            templateId: '13',
            queryParam: null,
            source: 'patientWebsite'
          }

          const sendgridHeader = {
            client_id: env.sendMailClientID,
            client_secret: env.sendMailClientSecret,
            'Content-Type': 'application/json',
            'account-name': 'takeda-entyvio'
          }
          axios
            .post(env.sendRoot, sendgridData, {
              headers: sendgridHeader
            })
            .then(function (response) {
              setLoadingResetBtn(false)
              if (response.status === 202) {
                logAction('user/log', successPayload, {})
                setMessage({ showBtn: false, sendEmailBtn: false, content: 'reset_password.email.text' })
              } else {
                logAction('user/log', { ...errorPayload, password_reset_email_error_msg__c: JSON.stringify(response?.data) }, {})
              }
            })
            .catch(function (error) {
              setLoadingResetBtn(false)
              logAction('user/log', { ...errorPayload, password_reset_email_error_msg__c: error.response?.data?.message }, {})
              // old error as notification
              // message.error(t('reset_password.error'))
              setMessage({ showBtn: false, sendEmailBtn: false, content: 'reset_password.mulesoft.genericError' })
            })
        } else {
          logAction('user/log', { ...errorPayload, password_reset_email_error_msg__c: val.data?.errorSummary }, {})
          // old error as notification
          // message.error(t('reset_password.error'))
          setMessage({ showBtn: false, sendEmailBtn: false, content: 'reset_password.mulesoft.genericError' })
          setLoading(false)
        }
      } else {
        logAction('user/log', { ...errorPayload, password_reset_email_error_msg__c: val.data?.errorSummary }, {})
        // old error as notification
        // message.error(t('reset_password.error'))
        setMessage({ showBtn: false, sendEmailBtn: false, content: 'reset_password.mulesoft.genericError' })
        setLoading(false)
      }
    })
  }

  const handleSendEmail = async () => {
    setLoadingResetBtn(true)
    const headers = {
      client_id: env.loginClientId,
      client_secret: env.loginClientSecret,
      'Content-Type': 'application/json'
    }
    // call to get recovery token
    const data = await getRecoveryToken(env.recoveryPasswordRoot, email, headers)
    if (data) {
      const headersMule = {
        client_id: env.sendMailClientID,
        client_secret: env.sendMailClientSecret,
        'Content-Type': 'application/json',
        'account-name': 'takeda-entyvio'
      }
      const details = {
        userEmail: email,
        firstName: data._embedded.user.profile.firstName,
        lastName: data._embedded.user.profile.lastName,
        country: country,
        language: language,
        recoveryToken: data.recoveryToken
      }
      sendEmail(env.sendRoot, details, headersMule).then(() => {
        setLoadingResetBtn(false)
        setMessage({ showBtn: false, sendEmailBtn: false, content: 'reset_password.email.text' })
      }).catch(() => {
        setLoadingResetBtn(false)
        setMessage({ showBtn: false, sendEmailBtn: false, content: 'reset_password.mulesoft.genericError' })
      })
    }
  }
  if (!env.supported_cultures.includes(queryString.culture) && queryString.culture !== undefined && queryString.culture !== 'hr') { return <NotAllowed env={env} /> }
  if (!window.location.search.includes('culture')) {
    return (
      <Layout
        style={{
          minHeight: '100%',
          display: 'flex',
          justifyContent: 'center'
        }}
      />
    )
  }
  return (
    <Layout>
      <Content className={styles.root}>
        <Row className={env.countryClass}>
          <Col span={24}>
            <Row className={styles.headerRoot} type='flex' justify='center'>
              <Col xs={22} sm={22} md={22} lg={18} xl={18} xxl={16}>
                {env.countryClass === 'canada' ? (
                  <img src={env.logo} alt='.' className={styles.canadaLogo} />
                ) : (
                  <div
                    className={styles.logo}
                    style={{
                      borderColor: env.colors.primaryColor
                    }}>
                    <img src='/images/fywy-text@2x.png' alt='.' />
                  </div>
                )}
              </Col>
            </Row>
            <Row type='flex' justify='center'>
              <Col xs={22} sm={22} md={22} lg={18} xl={18} xxl={16} className={styles.content}>
                <div className={styles.text}>
                  <div className={styles.title}>{t('reset_password.email.title')}</div>
                  <div>{t('reset_password.email.subTitle')}</div>
                  <div className={styles.goBackContainer}>
                    <Link to={`/login?culture=${culture}`}>
                      <Button
                        style={{
                          backgroundColor: env.colors.primaryColor,
                          border: '1px solid',
                          borderColor: env.colors.primaryColor,
                          color: '#fff'
                        }}
                        htmlType='submit'
                      >
                        {t('reset_password.email.goBack')}
                      </Button>
                    </Link>
                  </div>
                  <div className={styles.formRoot}>
                    <Form form={form} className={env.countryClass}>
                      <Form.Item
                        name='username'
                        rules={[
                          {
                            type: 'email',
                            message: t('errors.v_email_type')
                          },
                          {
                            max: 80,
                            message: t('errors.v_email_maxlength')
                          }
                        ]}
                        className={styles.username}>
                        <Input
                          value={email}
                          name='username'
                          onChange={handleOnChange}
                          className={styles.input}
                          style={{ borderColor: env.colors.borderColor }}
                          allowClear
                          prefix={<UserOutlined className={styles.icon} style={{ color: env.colors.iconColor }} />}
                          placeholder={t('reset_password.email.label')}
                          autoComplete='off'
                        />
                      </Form.Item>
                      <Form.Item shouldUpdate>
                        {() => (
                          <Spin indicator={antIcon} spinning={loading}>
                            <Button
                              htmlType='submit'
                              className={styles.button}
                              style={
                                form.isFieldsTouched(true) && email !== '' && !form.getFieldsError().filter(({ errors }) => errors.length).length
                                  ? {
                                    backgroundColor: env.colors.primaryColor,
                                    color: '#fff',
                                    boxShadow: '0px 3px 6px #00000029'
                                  }
                                  : {
                                    backgroundColor: 'rgba(0, 0, 0, 0.25)',
                                    color: '#fff',
                                    boxShadow: '0px 3px 6px #00000029'
                                  }
                              }
                              onClick={handleSubmit}
                              disabled={!form.isFieldsTouched(true) || email === '' || form.getFieldsError().filter(({ errors }) => errors.length).length}>
                              {t('reset_password.email.button')}
                            </Button>
                          </Spin>
                        )}
                      </Form.Item>
                    </Form>
                  </div>
                  <div className={styles.confirmRoot}>{t(message.content)}</div>
                  {
                    message.showBtn && (
                      <div className={styles.formRoot} style={{ paddingTop: 20 }}>
                        <Spin indicator={antIcon} spinning={loadingResetBtn}>
                          <Button
                            htmlType='submit'
                            className={styles.button}
                            style={
                              form.isFieldsTouched(true) && email !== '' && !form.getFieldsError().filter(({ errors }) => errors.length).length
                                ? {
                                  backgroundColor: env.colors.primaryColor,
                                  color: '#fff',
                                  boxShadow: '0px 3px 6px #00000029'
                                }
                                : {
                                  backgroundColor: 'rgba(0, 0, 0, 0.25)',
                                  color: '#fff',
                                  boxShadow: '0px 3px 6px #00000029'
                                }
                            }
                            onClick={handleResetPassword}
                            disabled={!form.isFieldsTouched(true) || email === '' || form.getFieldsError().filter(({ errors }) => errors.length).length}>
                            {t('reset_password.email.btnReset')}
                          </Button>
                        </Spin>
                      </div>
                    )
                  }
                  {
                    message.sendEmailBtn && (
                      <div className={styles.formRoot} style={{ paddingTop: 20 }}>
                        <Spin indicator={antIcon} spinning={loadingResetBtn}>
                          <Button
                            htmlType='submit'
                            className={styles.button}
                            style={
                              form.isFieldsTouched(true) && email !== '' && !form.getFieldsError().filter(({ errors }) => errors.length).length
                                ? {
                                  backgroundColor: env.colors.primaryColor,
                                  color: '#fff',
                                  boxShadow: '0px 3px 6px #00000029'
                                }
                                : {
                                  backgroundColor: 'rgba(0, 0, 0, 0.25)',
                                  color: '#fff',
                                  boxShadow: '0px 3px 6px #00000029'
                                }
                            }
                            onClick={handleSendEmail}
                            disabled={!form.isFieldsTouched(true) || email === '' || form.getFieldsError().filter(({ errors }) => errors.length).length}>
                            {t('reset_password.email.validateEmail')}
                          </Button>
                        </Spin>
                      </div>
                    )
                  }
                  {
                    message.showRegister && <>
                      <Link to={`/register?culture=${culture}`} className={styles.link}>
                        {t('login.form.register')}
                      </Link>
                    </>
                  }
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Content>
    </Layout>
  )
}

export default MailView
