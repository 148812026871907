export const prod = {
  /**
   * Allowed Values
   * ooa (order of articles), subcategories, still_entyvio,
   * ibd_chart, tutorial, message_board, opf (online patient forum)
   * * tested : subcategories, message_board, opf, tutorial, still_entyvio, ibd_chart
   */
  templateID: 'd-4b8459da5825462b94d15fa5afbdfa1f',
  enhancements: ['ooa', 'subcategories', 'still_entyvio', 'ibd_chart', 'tutorial', 'message_board', 'opf'],
  cookieOneTrust: '',
  cookieCdnScript: '',
  cookieId: '',
  loginRedirect: '',
  loginRoot: '',
  oktaClientId: '',
  appRoot: '',
  registerRoot: '',
  loginClientId: '',
  loginClientSecret: '',
  clientId: '',
  clientSecret: '',
  categoryRoot: '',
  grantType: '',
  state: '',
  nonce: '',
  xClient: 'mobile',
  redirectRoot: '',
  activeSession: '',
  sendRoot: '',
  recoveryPasswordRoot: '',
  relayStateRecovery: '',
  sendMailClientID: '',
  sendMailClientSecret: '',
  verifyToken: '', // 2
  resetPassword: '', // 2
  verifyUser: '', // 2
  userLogRecord: '', // 4
  mulesoft: {
    baseURI: '', // 4
    clientID: '',
    clientSecret: ''
  },
  supported_cultures: ['pl-PL'],
  countryClass: '',
  logo: '/images/fywy-app-icon-1024.png',
  entyvioLogo: '/images/entyvio@2x.png',
  leftArrow: '/images/left.png',
  rightArrow: '/images/right.png',
  pinImage: '/images/pinned.png',
  medicationIcon: '/images/icon-injection-reminder@2x.png',
  injectionIcon: '/images/icon-injection-tracker@2x.png',
  surveyIcon: '/images/survey_2x.png',
  homepageCustomStyle: {},
  country: 'PL',
  language: 'pl',
  colors: {
    primaryColor: '#51B1BF',
    borderColor: '#51B1BF',
    footer: 'rgb(81 177 191 / 30%)',
    iconColor: '#51B1BF',
    title: '#51B1BF',
    siderColor: '#e2f5f7',
    reminderTitle: '#0e6a7f',
    reminderIcon: '#ecaa00',
    cardColor: '#E2F5F7',
    cardTitle: '#0E6A7F',
    cardIcon: '#84d8e0',
    subtitle: '#4C4948',
    menuColor: '#E2F5F7',
    mobileNavigationColor: '#51B1BF',
    messageBoard: '#E2F5F7D9',
    mobileMessageBoard: '#E2F5F7',
    messageCard: '#E2F5F7',
    mobileMessageCard: '#F5FEFF',
    headerLandingText: '#106A7F',
    hcpLandingText: '#4B9AA9'
  },
  medicationDateFormat: 'HH:mm a',
  entyvioLeaflets: true,
  hideSelector: true,
  diskModal: false,
  profile: {
    treatmentFormat: 'YYYY-MM-DD',
    contactDetails: true,
    mobilePhone: true,
    address: true
  },
  landing: {
    selector: false,
    button: false,
    hcpportal: true,
    disclaimer: false,
    promoCode: null,
    video: {
      visible: false,
      image: {
        pl: '/images/landing/video-snip-pl.png'
      }
    },
    googlePlay: 'https://play.google.com/store/apps/details?id=com.takeda.foryouwithyou.pl',
    appleStore: 'https://apps.apple.com/pl/app/twojeibd/id1568278429',
    hcpLink: 'https://medyk.twojeibd.pl?culture=',
    logo: '/images/landing/pl-logo@2x.png',
    background: '/images/landing/default-banner@2x.png',
    mobileBackground: '/images/landing/mobile_header@2x.png',
    phone: {
      pl: '/images/landing/pl-mobile@2x.png'
    },
    icons: {
      appointment: '/images/appointment_tracker_2x.png',
      disk: '/images/icon-IBD-disk@2x.png',
      map: '/images/map-menu-icon@2x.png',
      recipes: '/images/Recipes@2x.png',
      videos: '/images/Videos@2x.png',
      clipboard: '/images/landing/pl-clipboard.png'
    },
    languages: {
      one: {
        code: '',
        culture: ''
      },
      two: {
        code: '',
        culture: ''
      }
    }
  },
  promo: {
    video: true,
    forum: true,
    stayConnected: true,
    images: {
      ro: {
        welcome: {
          picture: '/images/promo/desktop/desktop-1.png',
          mobile: '/images/promo/desktop/iphone.png'
        },
        content: '/images/promo/desktop/content.png',
        disk: '/images/promo/desktop/disk.png',
        trackers: '/images/promo/desktop/trackers.png',
        forum: '/images/promo/desktop/forum.png',
        connected: '/images/promo/desktop/connected.png'
      }
    }
  },
  footer: {
    privacy: 'https://www.takeda.com/pl-pl/polityka-prywatnoci',
    terms: 'https://www.takeda.com/pl-pl/regulamin-serwisu'
  },
  cardImages: {
    gastro: '/images/Gastro_Doctor.png',
    test: '/images/medical_test.png',
    call: '/images/nurse_call.png',
    visit: '/images/nurse_visit.png',
    practitioner: '/images/Doctor.png',
    intravenous: '/images/IV.png',
    default: '/images/appointment_tracker_2x.png'
  },
  injectionCard: {
    leftabdomen: '/images/injection-tracker/HB_SMALL_Track_RSA.jpg',
    rightabdomen: '/images/injection-tracker/HB_SMALL_Track_LSA.jpg',
    leftarm: '/images/injection-tracker/HB_SMALL_Track_BRA.jpg',
    rightarm: '/images/injection-tracker/HB_SMALL_Track_BLA.jpg',
    leftleg: '/images/injection-tracker/HB_SMALL_Track_FRT.jpg',
    rightleg: '/images/injection-tracker/HB_SMALL_Track_FLT.jpg',
    default: '/images/injection-tracker/HB_SMALL_Track_Unconfirmed.jpg'
  },
  injectionTracker: {
    pickerValidation: true,
    leftabdomen: '/images/injection-tracker/HB_Track_RSA.jpg',
    rightabdomen: '/images/injection-tracker/HB_Track_LSA.jpg',
    leftarm: '/images/injection-tracker/HB_Track_BRA.jpg',
    rightarm: '/images/injection-tracker/HB_Track_BLA.jpg',
    leftleg: '/images/injection-tracker/HB_Track_FRT.jpg',
    rightleg: '/images/injection-tracker/HB_Track_FLT.jpg',
    default: '/images/injection-tracker/HB_Track_Default.jpg'
  },
  goHomeStyle: {
    display: 'none'
  },
  hcpStyle: {},
  types: {
    intravenous: [
      {
        code: 'INTRAVENOUS_INFUSION'
      },
      {
        code: 'GASTRO_SPECIALIST'
      },
      {
        code: 'GENERAL_PRACTITIONER'
      },
      {
        code: 'MEDICAL_TEST'
      },
      {
        code: 'NURSE_SUPPORT_CALL'
      },
      {
        code: 'NURSE_SUPPORT_VISIT'
      },
      {
        code: 'CALPROTECTIN_TEST'
      }
    ],
    subcutaneous: [
      {
        code: 'GASTRO_SPECIALIST'
      },
      {
        code: 'GENERAL_PRACTITIONER'
      },
      {
        code: 'MEDICAL_TEST'
      },
      {
        code: 'NURSE_SUPPORT_CALL'
      },
      {
        code: 'NURSE_SUPPORT_VISIT'
      },
      {
        code: 'CALPROTECTIN_TEST'
      }
    ],
    other: [
      {
        code: 'GASTRO_SPECIALIST'
      },
      {
        code: 'GENERAL_PRACTITIONER'
      },
      {
        code: 'MEDICAL_TEST'
      },
      {
        code: 'CALPROTECTIN_TEST'
      }
    ]
  }
}
