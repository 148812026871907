import React, { useState, useEffect, useContext } from 'react'
import { Row, Col, Spin } from 'antd'
import Carousel from './../Carousel'
import styles from './../MainContent/MainContent.module.css'
import { LoadingOutlined, DoubleRightOutlined, DoubleLeftOutlined } from '@ant-design/icons'
import { get } from '../ApiCall'
import ArticleView from '../ArticleModal'
import ItemsCarousel from 'react-items-carousel'
import { useTranslation } from 'react-i18next'
import { getCfg } from '../../env.config'
import { AppContext } from '../../contexts/AppContext'
// /* global localStorage */
const HomePage = (props) => {
  const { t } = useTranslation()
  // const [visible, setVisible] = useState(false)
  const { visible, setVisible } = props
  const { loaded, setLoaded, content, setContent } = useContext(AppContext)
  // const [loaded, setLoaded] = useState(false)
  const [start, setStart] = useState(false)
  const [articleTime, setArticleTime] = useState('')
 
  // const [content, setContent] = useState({ loaded: false, data: [] })
  const [favorites, setFavorites] = useState({ data: [] })
  const [activeItemIndex, setActiveItemIndex] = useState(0)
  const address = `https://${window.location.href.split('/')[2]}`
  const env = getCfg(address)
  const antIcon = <LoadingOutlined style={{ fontSize: 24, color: env.colors.primaryColor }} spin />
  const config = {
    offset: 0,
    limit: 1000
  }

  useEffect(() => {
    const returnedArray = props.favorites.data.map((favorite) => {
      const filteredArray = props.categories.data.filter((category) => {
        return category.Parent.Id === favorite.Related_id__c
      })
      return filteredArray[0]
    })
    const returnedVideos = props.favorites.data.map((favorite) => {
      const filteredVideos = props.videos.data.filter((videos) => {
        return videos.Id === favorite.Related_id__c
      })
      return filteredVideos[0]
    })
    const resultedArticle = returnedArray.filter((x) => {
      return x !== undefined
    })

    const resultedVideos = returnedVideos.filter((x) => {
      return x !== undefined
    })
    const results = [...resultedArticle, ...resultedVideos]

    setFavorites((oldState) => ({
      ...oldState,
      data: results
    }))
  }, [props.favorites.data, props.categories.data, props.videos.data, setFavorites])
  // console.log(props.favorites.data)
  // console.log(favorites)

  useEffect(() => {
    if (content.loaded) {
      setVisible(true)
      setLoaded(false)
      setStart(false)
    }
  }, [content.loaded, setVisible, setLoaded])

  const handleCardClick = (urlName) => {
    console.log('card clicked')
    setArticleTime(new Date())
    setContent({ ...content, loaded: false })
    setLoaded(true)
    get(`articles/detail?UrlName=${urlName}`, config, setContent)
  }

  const handleFavoritesClick = (urlName, video) => {
    setContent({ ...content, loaded: false })
    setStart(true)
    if (video) {
      get(`videos/detail?UrlName=${urlName}`, config, setContent)
    } else {
      get(`articles/detail?UrlName=${urlName}`, config, setContent)
    }
  }
 
  const carouselView = () => {
    return (
      <div className={`${styles.carouselPlaceholder} ${styles.carouselHeight}`}>
        <p className={styles.latestArticlesTitle} style={{ color: env.colors.title }}>
          {t('main.content.latest')}
        </p>
        <Spin indicator={antIcon} spinning={loaded} className={env.countryClass}>
          <Carousel {...props} visible={visible} setVisible={handleCardClick} />
        </Spin>
      </div>
    )
  }

  const articleView = () => {
    return <ArticleView articleTime={articleTime} content={content} t={t} setVisible={() => { setVisible(false) }} visible={visible} favorites={props.favorites} setFavorites={props.setFavorites} />
  }
  return (
    <Col span={24} className={styles.columns}>
      <Row justify='center'>
        <Col xs={24} sm={19} md={19} lg={19} xl={23} xxl={23}>
          {carouselView()}
          {visible ? articleView() : null}
        </Col>
      </Row>

      <Row justify='center' className={styles.pinnedRow}>
        <p className={styles.pinnedArticlesTitle} style={{ color: env.colors.title }}>
          {t('main.content.pinned')}
        </p>
        <Col className={`${styles.pinnedCards}`} xs={24} sm={21} md={21} lg={21} xl={23} xxl={23} style={visible ? { backgroundColor: 'transparent' } : {}}>
          <Spin indicator={antIcon} spinning={start} className={env.countryClass}>
            <Row justify='center'>
              <Col xs={23} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <Row gutter={[24, 32]} className={styles.mobileContent}>
                  {Array.from(new Array(8)).map((_, i) => {
                    if (favorites.data[i]) {
                      if (favorites.data[i].Parent) {
                        return (
                          <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} key={favorites.data[i].Id}>
                            <div
                              style={{
                                width: '100%',
                                height: 118,
                                backgroundColor: '#fff',
                                boxShadow: '0px 3px 5px #00000029',
                                position: 'relative',
                                marginBottom: '15px'
                              }}>
                              <img
                                style={{ width: '100%', height: '100%', objectFit: 'cover', cursor: 'pointer' }}
                                src={favorites.data[i].Parent.Image_URL__c}
                                alt={favorites.data[i].Parent.Title}
                                onClick={() => handleFavoritesClick(favorites.data[i].Parent.UrlName)}
                              />
                              <img src={env.pinImage} alt='pin arrow' style={{ position: 'absolute', top: '-14px', left: '42%', width: 20 }} />
                              <span
                                style={{
                                  position: 'absolute',
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  width: '100%',
                                  bottom: -30,
                                  left: 0,
                                  zIndex: 1,
                                  padding: 6,
                                  color: '#4C4948',
                                  textAlign: 'center',
                                  fontSize: 12
                                }}
                                title={favorites.data[i].Parent.Title}>
                                {favorites.data[i].Parent.Title}
                              </span>
                            </div>
                          </Col>
                        )
                      } else {
                        return (
                          <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} key={favorites.data[i].Id}>
                            <div
                              style={{
                                width: '100%',
                                height: 118,
                                backgroundColor: '#fff',
                                boxShadow: '0px 3px 5px #00000029',
                                position: 'relative',
                                marginBottom: '15px'
                              }}>
                              <img
                                style={{ width: '100%', height: '100%', objectFit: 'cover', cursor: 'pointer' }}
                                src={favorites.data[i].Image_URL__c}
                                alt={favorites.data[i].Title}
                                onClick={() => handleFavoritesClick(favorites.data[i].UrlName, true)}
                              />
                              <img src={env.pinImage} alt='pin arrow' style={{ position: 'absolute', top: '-14px', left: '42%', width: 20 }} />
                              <span
                                style={{
                                  position: 'absolute',
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  width: '100%',
                                  bottom: -30,
                                  left: 0,
                                  zIndex: 1,
                                  padding: 6,
                                  color: '#4C4948',
                                  textAlign: 'center',
                                  fontSize: 12
                                }}
                                title={favorites.data[i].Title}>
                                {favorites.data[i].Title}
                              </span>
                            </div>
                          </Col>
                        )
                      }
                    } else {
                      return (
                        <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} key={i}>
                          <div style={{ width: '100%', height: 118, backgroundColor: '#fff', boxShadow: '0px 3px 5px #00000029', position: 'relative' }}>
                            <img src={env.pinImage} alt='pin arrow' style={{ position: 'absolute', top: '-14px', left: '42%', width: 20 }} />
                          </div>
                        </Col>
                      )
                    }
                  })}
                </Row>
                <Row gutter={[24, 32]} style={{ margin: 0 }} className={styles.desktopTabletContent}>
                  <div className='homepage-carousel' style={{ padding: '0px', width: '100%', margin: '0 auto' }}>
                    <ItemsCarousel
                      infiniteLoop={false}
                      gutter={18}
                      activePosition='center'
                      chevronWidth={40}
                      disableSwipe={false}
                      alwaysShowChevrons={false}
                      numberOfCards={4}
                      slidesToScroll={1}
                      firstAndLastGutter
                      outsideChevron={false}
                      showSlither
                      rightChevron={<DoubleRightOutlined style={{ color: env.colors.primaryColor }} />}
                      leftChevron={<DoubleLeftOutlined style={{ color: env.colors.primaryColor }} />}
                      activeItemIndex={activeItemIndex}
                      requestToChangeActive={(value) => setActiveItemIndex(value)}>
                      {Array.from(new Array(8)).map((_, i) => {
                        if (favorites.data[i]) {
                          if (favorites.data[i].Parent) {
                            return (
                              <div
                                key={favorites.data[i].Id}
                                style={{
                                  padding: '34px 0'
                                }}>
                                <div
                                  style={{
                                    width: '100%',
                                    height: 118,
                                    backgroundColor: '#fff',
                                    boxShadow: '0px 3px 5px #00000029',
                                    position: 'relative'
                                  }}>
                                  <img
                                    style={{ width: '100%', height: '100%', objectFit: 'cover', cursor: 'pointer' }}
                                    src={favorites.data[i].Parent.Image_URL__c}
                                    alt={favorites.data[i].Parent.Title}
                                    onClick={() => handleFavoritesClick(favorites.data[i].Parent.UrlName)}
                                  />
                                  <img src={env.pinImage} alt='pin arrow' style={{ position: 'absolute', top: '-14px', left: '42%', width: 20 }} />
                                  <span
                                    style={{
                                      position: 'absolute',
                                      left: 0,
                                      bottom: -30,
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      width: '100%',
                                      zIndex: 1,
                                      padding: 6,
                                      color: '#4C4948',
                                      textAlign: 'center'
                                    }}
                                    title={favorites.data[i].Parent.Title}>
                                    {favorites.data[i].Parent.Title}
                                  </span>
                                </div>
                              </div>
                            )
                          } else {
                            return (
                              <div
                                key={favorites.data[i].Id}
                                style={{
                                  padding: '34px 0'
                                }}>
                                <div
                                  style={{
                                    width: '100%',
                                    height: 118,
                                    backgroundColor: '#fff',
                                    boxShadow: '0px 3px 5px #00000029',
                                    position: 'relative'
                                  }}>
                                  <img
                                    style={{ width: '100%', height: '100%', objectFit: 'cover', cursor: 'pointer' }}
                                    src={favorites.data[i].Image_URL__c}
                                    alt={favorites.data[i].Title}
                                    onClick={() => handleFavoritesClick(favorites.data[i].UrlName, true)}
                                  />
                                  <img src={env.pinImage} alt='pin arrow' style={{ position: 'absolute', top: '-14px', left: '42%', width: 20 }} />
                                  <span
                                    style={{
                                      position: 'absolute',
                                      left: 0,
                                      bottom: -30,
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      width: '100%',
                                      zIndex: 1,
                                      padding: 6,
                                      color: '#4C4948',
                                      textAlign: 'center'
                                    }}
                                    title={favorites.data[i].Title}>
                                    {favorites.data[i].Title}
                                  </span>
                                </div>
                              </div>
                            )
                          }
                        } else {
                          return (
                            <div
                              key={i}
                              style={{
                                padding: '34px 0'
                              }}>
                              <div style={{ width: '100%', height: 118, backgroundColor: '#fff', boxShadow: '0px 3px 5px #00000029', position: 'relative' }}>
                                <img src={env.pinImage} alt='pin arrow' style={{ position: 'absolute', top: '-14px', left: '42%', width: 20 }} />
                              </div>
                            </div>
                          )
                        }
                      })}
                    </ItemsCarousel>
                  </div>
                </Row>
              </Col>
            </Row>
          </Spin>
        </Col>
      </Row>
    </Col>
  )
}

export default HomePage
