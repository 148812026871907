import React from 'react'
import { Layout } from 'antd'
// import * as qs from 'query-string'

export default function NotAllowed({ env }) {
  // const queryString = qs.parse(window.location.search)

  // const handleClick = () => {
  //   localStorage.setItem('i18nextLng', `${env.supported_cultures[0]}`)
  //   if (window.location.search.includes('recoveryToken')) {
  //     window.location.href = `${window.location.pathname}?recoveryToken=${queryString.recoveryToken}&culture=${env.supported_cultures[0]}`
  //   } else {
  //     window.location.href = `${window.location.pathname}?culture=${env.supported_cultures[0]}`
  //   }
  // }

  return (
    <Layout
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#fff',
        padding: '0 20px'
      }}>
      <h1 style={{ textAlign: 'center' }}>You are not allowed to see this part of the application</h1>
      <h3 style={{ textAlign: 'center' }}>
        Please close all browser windows and try again!
        {/* Click{' '}
        <span
          style={{
            color: 'blue',
            textDecoration: 'underline',
            cursor: 'pointer',
          }}
          onClick={handleClick}>
          here
        </span>{' '}
        to go to your application */}
      </h3>
    </Layout>
  )
}
