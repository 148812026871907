import React from 'react'
import { Row, Col, Button } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import styles from './ChartResult.module.css'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import moment from 'moment'
// import { AppContext } from '../../../contexts/AppContext'
// import { getCfg } from '../../../env.config'
// import { post, get } from '../../ApiCall'

const ChartResult = ({ setVisible, series, xLabel }) => {
  const { t } = useTranslation()
  const width = series[0].data.length > 7 ? 100 * series[0].data.length : null
  const formatedCategories = []
  xLabel.map(item => formatedCategories.push(moment(item).format('DD/MM/YY')))
  const options = {
    title: {
      text: ''
    },
    plotOptions: {
      series: {
        pointPlacement: 'on'
      }
    },
    chart: {
      height: 300,
      width: width
    },
    yAxis: {
      title: {
        text: ''
      },
      max: 10
    },
    xAxis: {
      categories: formatedCategories
    },
    legend: {
      align: 'left'
    },
    series: series
  }

  const fromDate = moment(xLabel[0]).format('DD MMMM YYYY')
  const toDate = moment(xLabel[xLabel.length - 1]).format('DD MMMM YYYY')
  return (
    <Row align='top' className={styles.modalRoot}>
      <Col span={24} className={styles.text}>
        {t('disk.compare.text')}
      </Col>
      <Col span={24}>
        <CloseCircleOutlined
          className={styles.closeIcon}
          onClick={setVisible}
        />
      </Col>
      <Col span={24} className={styles.root}>
        <Row justify='center' className={styles.contentRow}>
          <Col span={24}>
            <h3 className={styles.title}>{t('disk.chartView.title')}</h3>
            <HighchartsReact
              highcharts={Highcharts}
              options={options}
              containerProps={{ className: styles.chartContainer }}
            />
          </Col>
        </Row>
        <Row justify='center' className={styles.buttonRow}>
          <Col xs={24} sm={24} md={14} lg={14} xl={14} xxl={14}>
            <Row>
              <Col span={24} className={styles.label}>
                <div />
                {t('disk.chartView.label.range')} {fromDate} - {toDate}
              </Col>
              <Col span={24} className={styles.label}>
                <div />
                {t('disk.chartView.label.legend')}
              </Col>
            </Row>
          </Col>
          <Col
            xs={24} sm={24} md={10} lg={10} xl={10} xxl={10}
          >
            <Button
              type='primary'
              className={styles.button}
              onClick={setVisible}
            >
              {t('disk.chartView.button')}
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default ChartResult
