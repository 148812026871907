import React, { useContext, useState, useEffect } from 'react'
import { Row, Col, Button, Skeleton } from 'antd'
import Calendar from './../../Calendar'
import styles from './../Injection.module.css'
import { useTranslation } from 'react-i18next'
import { AppContext } from '../../../contexts/AppContext'
import ReminderForm from './InjectionReminderForm'
import ReminderCard from './InjectionReminderCard'
import { get } from '../../ApiCall'
import moment from 'moment'
import { getCfg } from '../../../env.config'

const InjectionReminder = ({ injection, setInjection }) => {
  const { t } = useTranslation()
  const contextValue = useContext(AppContext)

  const [state, setState] = useState({
    currentMonth: moment(new Date()),
    open: false,
    content: {}
  })
  const address = `https://${window.location.href.split('/')[2]}`
  const env = getCfg(address)
  const [values, setValues] = useState({
    injectionDate: null,
    frequency: 14,
    emailNotification: false,
    oneDayOption: false,
    sameDayOption: false,
    reminderTime: null,
    id: null,
    appNotification: false,
    diffFrequency: false
  })
  const [loaded, setLoaded] = useState(false)
  const [dates, setDates] = useState([])
  const [injectionReminder, setInjectionReminder] = useState({ loaded: false, data: [] })

  useEffect(() => {
    setInjectionReminder(injection)
  }, [injection, setInjectionReminder])

  useEffect(() => {
    if (!injectionReminder.loaded) {
      get('injectionReminder/detail', {}, setInjectionReminder)
    }
  }, [injectionReminder.loaded])

  const callbackFunc = (value) => {
    if (value.Id) {
      setInjectionReminder({
        ...injectionReminder,
        loaded: false
      })
      setLoaded(false)
      handleClick()
      console.log('from callback', value)
      setValues({
        injectionDate: null,
        frequency: 14,
        emailNotification: false,
        oneDayOption: false,
        sameDayOption: false,
        reminderTime: null,
        id: null,
        appNotification: false,
        diffFrequency: false
      })
    } else {
      setLoaded(false)
      handleClick()
    }
  }

  useEffect(() => {
    if (Array.isArray(injectionReminder.data)) {
      const array = []
      injectionReminder.data.map(val => {
        return array.push(moment(val.Next_Injection_Datetime__pc).format('YYYY-MM-DD'))
      })
      setDates(array)
    }
  }, [injectionReminder.data, setDates])

  const handleClick = () => {
    contextValue.setContextData(oldValue => ({
      ...oldValue,
      show: !contextValue.contextData.show
    }))
  }

  const handleDateClick = (date) => {
    setValues(oldValues => ({
      ...oldValues,
      injectionDate: date
    }))
  }

  const getData = () => {
    if (Array.isArray(injectionReminder.data) && injectionReminder.loaded) {
      if (injectionReminder.data.length > 0) {
        return injectionReminder.data.map((val) => {
          return (
            <ReminderCard key={val.Id} {...injectionReminder} />
          )
        })
      } else {
        return <Skeleton active loading />
      }
    } else {
      return <Skeleton active loading />
    }
  }

  const getButtonName = () => {
    if (injectionReminder.data[0]) {
      if (injectionReminder.data[0].Email_notification__pc) {
        return t('injection.reminder.main.button.2')
      } else {
        return t('injection.reminder.main.button.1')
      }
    }
  }

  if (contextValue.contextData.show) {
    return (
      <ReminderForm
        setVisible={handleClick}
        values={values}
        setValues={setValues}
        injection={injectionReminder}
        callback={val => callbackFunc(val)}
        loaded={loaded}
        setLoaded={setLoaded}
      />
    )
  }
  return (
    <Row align='bottom' justify='center' className={styles.injectionRoot}>
      <Col xs={23} sm={24} md={24} lg={24} xl={24} xxl={24}>
        <div className={styles.text}>
          {t('injection.reminder.main.text')}
        </div>
      </Col>
      <Col
        xs={23} sm={24} md={24} lg={24} xl={24} xxl={24}
        className={styles.injectionTracker}
      >
        <Row
          gutter={24}
          className={styles.injectionRow}
        >
          <Col
            xs={24} sm={24} md={11} lg={11} xl={11} xxl={11}
            className={styles.calendarPlaceholder}
          >
            <Calendar
              selected={values.injectionDate}
              dates={dates}
              onDateClick={handleDateClick}
              state={state}
              setState={setState}
            />
          </Col>
          <Col xs={24} sm={24} md={13} lg={13} xl={13} xxl={13}>
            <Row style={{ height: '100%' }}>
              <Col span={24} className={styles.remindercards}>
                {getData()}
              </Col>
              <Col span={24} className={styles.buttonCol}>
                <Button
                  style={{
                    backgroundColor: env.colors.primaryColor,
                    color: '#fff'
                  }}
                  className={styles.injectionButton}
                  onClick={handleClick}
                >
                  {getButtonName()}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row justify='center' className={styles.tabletButton}>
          <Col xs={24} sm={16} md={12} lg={11} xl={11} xxl={11}>
            <Button
              style={{
                backgroundColor: env.colors.primaryColor,
                color: '#fff'
              }}
              className={styles.injectionButton}
              onClick={handleClick}
            >
              {getButtonName()}
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default InjectionReminder
