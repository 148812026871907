import React, { useState, useEffect, useContext } from 'react'
import { Layout, Row, Col, Form, Input, Button, Checkbox, Collapse, Modal, Radio, Spin, message } from 'antd'
import styles from './Profile.module.css'
import { useTranslation } from 'react-i18next'
import Wrapper from './../LayoutWrapper'
import AccessCodeModal from './AccessCodeModal'
import EntyvioView from './EntyvioView'
import OtherMedicationView from './OtherView'
import DefaultView from './DefaultView'
import ConfirmModal from './../ConfirmationModal'
import StatusModal from './../ErrorHandlerModal'
import { get, post, header } from './../ApiCall'
import { Link } from 'react-router-dom'
import { LoadingOutlined, DoubleLeftOutlined } from '@ant-design/icons'
import moment from 'moment'
import { getCfg } from '../../env.config'
import axios from 'axios'
import { AppContext } from '../../contexts/AppContext'
import * as qs from 'query-string'

/* global localStorage */
const Profile = ({ profile, setProfile, treatment, setTreatment, doctors, hospitals, setHospitals, setCategories, popupSettings, config }) => {
  const { t } = useTranslation()
  const { Content } = Layout
  const { Panel } = Collapse
  const { setTree, matrix, setMatrix } = useContext(AppContext)
  const [form] = Form.useForm()
  const [values, setValues] = useState({
    firstname: '',
    lastname: '',
    mobile: '',
    street: '',
    city: '',
    zip_code: '',
    state: '',
    country: '',
    day: [],
    time: [],
    condition: null,
    medication: '',
    treatment: null,
    start_date: null,
    doctor: null,
    hospital: null,
    accessCode: '',
    entyvio: false,
    otherMedicine: false,
    treatmentID: null
  })
  const [reason, setReason] = useState('')
  const [loading, setLoading] = useState(false)
  const [size, setSize] = useState(0)
  const [openStatus, setOpenStatus] = useState(false)
  const [error, setError] = useState(false)
  const [modalVisible, setModalVisible] = useState({
    modal: false,
    reason: false,
    accessCode: false,
    confirm: false
  })
  const [loaded, setLoaded] = useState(false)
  const [condition, setCondition] = useState([])

  const [configMedication, setConfigMedication] = useState([])
  const [configTreatment, setConfigTreatment] = useState([])

  const address = `https://${window.location.href.split('/')[2]}`
  const env = getCfg(address)
  const antIcon = <LoadingOutlined style={{ fontSize: 24, color: env.colors.primaryColor }} spin />

  useEffect(() => {
    if (config.loaded) {
      const tempCondition = []
      const tempMedication = []
      const tempTreatment = []
      config.data.condition.map((x) => {
        return config.data.mapping.map((y) => {
          if (x === y.name) return tempCondition.push(y)
          return null
        })
      })
      config.data.medication.map((x) => {
        return config.data.mapping.map((y) => {
          if (x === y.name) return tempMedication.push(y)
          return null
        })
      })
      config.data.treatment.map((x) => {
        return config.data.mapping.map((y) => {
          if (x === y.name) return tempTreatment.push(y)
          return null
        })
      })

      setCondition(tempCondition.filter((v, i, a) => a.findIndex((t) => t.apiname === v.apiname) === i))
      setConfigMedication(tempMedication.filter((v, i, a) => a.findIndex((t) => t.apiname === v.apiname) === i))
      setConfigTreatment(tempTreatment.filter((v, i, a) => a.findIndex((t) => t.apiname === v.apiname) === i))
    }
  }, [config, setCondition])

  const [, forceUpdate] = useState()

  useEffect(() => {
    forceUpdate({})
  }, [])

  useEffect(() => {
    const ask_hcp = matrix.data.Ask_HCP !== undefined ? matrix.data.Ask_HCP : true
    const ask_hospital = matrix.data.Ask_Hospital !== undefined ? matrix.data.Ask_Hospital : true

    if (profile.data !== undefined) {
      if (profile.data.Id !== undefined) {
        const daysToContact = profile.data.ContactPreferencesDays !== null ? profile.data.ContactPreferencesDays.split(';') : []
        const timeToContact = profile.data.ContactPreferencesTimeFrame !== null ? profile.data.ContactPreferencesTimeFrame.split(';') : []
        setValues((oldValues) => ({
          ...oldValues,
          firstname: profile.data.FirstName,
          lastname: profile.data.LastName,
          doctor: ask_hcp ? profile.data.DoctorID : null,
          hospital: ask_hospital ? profile.data.HospitalID : null,
          mobile: profile.data.PersonMobilePhone,
          street: profile.data.PersonMailingStreet,
          city: profile.data.PersonMailingCity,
          zip_code: profile.data.PersonMailingPostalCode,
          state: profile.data.PersonMailingState,
          country: profile.data.PersonMailingCountry,
          day: daysToContact,
          time: timeToContact
        }))
      }
    }
  }, [profile, matrix.data.Ask_Hospital, matrix.data.Ask_HCP])

  useEffect(() => {
    if (Array.isArray(treatment.data)) {
      if (treatment.data.length > 0) {
        setValues((oldValues) => ({
          ...oldValues,
          condition: treatment.data[0] ? treatment.data[0].Condition__c : null,
          medication: treatment.data[0].Medication__c,
          treatmentID: treatment.data[0].Id,
          treatment: treatment.data[0].Treatment__c ? treatment.data[0].Treatment__c : null,
          start_date: treatment.data[0].Treatment_startdate__c ? moment(treatment.data[0].Treatment_startdate__c) : null,
          entyvio: !!treatment.data[0].Treatment_startdate__c
        }))
      }
    }
  }, [treatment])

  useEffect(() => {
    form.setFieldsValue({
      firstname: values.firstname,
      lastname: values.lastname,
      doctor: values.doctor,
      hospital: values.hospital,
      treatment: values.treatment,
      start_date: values.start_date,
      condition: values.condition
    })
  }, [form, values])
  const postData = {
    Country: profile.data.Country,
    Region: profile.data.Region,
    PersonHasOptedOutOfFax: profile.data.PersonHasOptedOutOfFax,
    PersonHasOptedOutOfEmail: profile.data.PersonHasOptedOutOfEmail,
    PersonDoNotCall: profile.data.PersonDoNotCall,
    Patient_unsubscribed: profile.data.Patient_unsubscribed,
    Language: profile.data.Language,
    Salutation: profile.data.Salutation,
    FirstName: values.firstname,
    LastName: values.lastname,
    PersonEmail: profile.data.PersonEmail,
    PersonMobilePhone: values.mobile,
    Date_of_Birth: profile.data.Date_of_Birth,
    Consent_Checkbox: profile.data.Consent_Checkbox,
    PersonMailingStreet: values.street,
    PersonMailingCity: values.city,
    PersonMailingState: values.state,
    PersonMailingPostalCode: values.zip_code,
    PersonMailingCountry: values.country,
    DoctorID: values.doctor,
    HospitalID: values.hospital,
    Latest_Device_Token: profile.data.Latest_Device_Token,
    Latest_Device_Platform: profile.data.Latest_Device_Platform,
    ContactPreferencesTimeFrame: values.time.join(';'),
    ContactPreferencesDays: values.day.join(';'),
    NotificationAgreement: profile.data.NotificationAgreement
  }

  const getPopupDate = () => {
    const filteredArray = popupSettings.data.filter((item) => item.Treatment__c === values.treatment)[0]
    let tempDate
    if (values.start_date !== null && filteredArray) {
      tempDate = new Date(values.start_date.format('YYYY-MM-DD'))
      tempDate = moment(tempDate).add(filteredArray.Number_of_days__c, 'd').format('YYYY-MM-DD')
    }
    return tempDate
  }

  const postDataTreatment = values.entyvio
    ? {
        id: values.treatmentID,
        condition: values.condition,
        medication: values.medication,
        treatment_startdate: values.start_date !== null ? values.start_date.format('YYYY-MM-DD') : null,
        treatment: values.treatment,
        dateNextPopUp: getPopupDate()
      }
    : {
        id: values.treatmentID,
        condition: values.condition,
        medication: 'Other'
      }

  const isTreatmentChanged = () => {
    if (treatment.data[0]) {
      if (values.entyvio) {
        if (values.condition !== treatment.data[0].Condition__c) {
          return true
        } else if (values.treatment !== treatment.data[0].Treatment__c) {
          return true
        } else if (!moment(treatment.data[0].Treatment_startdate__c).isSame(values.start_date, 'day')) {
          return true
        } else {
          return false
        }
      } else {
        if (values.start_date !== null) {
          return true
        } else if (values.condition !== treatment.data[0].Condition__c) {
          return true
        } else {
          return false
        }
      }
    } else {
      return false
    }
  }
  const getTree = () => {
    axios
      .get(env.categoryRoot + 'categories/tree', { headers: header })
      .then(function (response) {
        setTree({
          loaded: true,
          data: response.data
        })
      })
      .catch(function (error) {
        setTree({
          loaded: true,
          data: error.response
        })
      })
  }
  const handlePost = () => {
    setLoaded(true)
    post('profile2', postData, (val) => {
      console.log('this is the response', val)
      if (val.Id) {
        if (isTreatmentChanged()) {
          post('treatment', postDataTreatment, (value) => {
            console.log(value)
            if (value.Id) {
              setLoaded(false)
              message.success(t('profile.confirmation'))
              get('categories/all', {}, setCategories)
              get('profile2/all', {}, setProfile)
              get('treatment/all', {}, setTreatment)
              getTree()
              setMatrix({ loaded: false, data: {} })
            } else {
              setLoaded(false)
              message.error(t('profile.console'))
            }
          })
        } else {
          setLoaded(false)
          message.success(t('profile.confirmation'))
          get('categories/all', {}, setCategories)
          get('profile2/all', {}, setProfile)
          get('treatment/all', {}, setTreatment)
          setMatrix({ loaded: false, data: {} })
          getTree()
        }
      } else {
        setLoaded(false)
        message.error(t('profile.console'))
      }
    })
  }
  // console.log('this is matrix frate... aoleu', matrix)
  const updateSize = () => {
    setSize(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])

  useEffect(() => {
    const medication = values.medication ? values.medication.toLowerCase() : values.medication
    if (medication === 'other' && values.entyvio) {
      setModalVisible((old) => ({
        ...old,
        confirm: true
      }))
    }
  }, [values.medication, values.entyvio])

  // console.log(values)

  const handleSelectDoctor = (value) => {
    const search = window.location.search
    const queryString = qs.parse(search)
    let lang

    if (queryString.culture) {
      lang = queryString.culture.split('-')[0]
    } else {
      lang = profile.data.Language
    }

    const headerWithCountry = {
      ...header,
      'x-country': profile.data.Country,
      language: lang
    }
    const filteredDoctors = doctors.data.filter((doctor) => doctor.Id === value)
    const doctorName = filteredDoctors[0] ? filteredDoctors[0].LastName + ' ' + filteredDoctors[0].FirstName : ''
    if (value !== undefined) {
      setValues((oldValues) => ({
        ...oldValues,
        doctor: value
      }))
    } else {
      setValues((oldValues) => ({
        ...oldValues,
        doctor: null
      }))
    }
    setLoaded(true)
    console.log('here')
    axios
      .get(env.appRoot + 'hospitals/all', {
        params: { doctorName: doctorName, offset: 0, limit: 1000 },
        headers: headerWithCountry
      })
      .then(function (response) {
        console.log('this is the response for hospitals', response)
        setLoaded(false)
        setHospitals({
          loaded: true,
          data: response.data
        })
        setValues((oldValues) => ({
          ...oldValues,
          hospital: null
        }))
      })
      .catch(function (error) {
        setLoaded(false)
        console.log('api get', error.response)
      })
  }

  const handleCheck = (value, option, name) => {
    const array = option === 'day' ? values.day : values.time
    const index = array.indexOf(name)
    if (index === -1) {
      setValues((oldState) => ({
        ...oldState,
        [option]: [...array, name]
      }))
    } else {
      const tempArray = [...array]
      tempArray.splice(index, 1)
      setValues((oldState) => ({
        ...oldState,
        [option]: [...tempArray]
      }))
    }
  }
  const handleSelectValue = (value, name) => {
    setValues((oldValues) => ({
      ...oldValues,
      [name]: value
    }))
  }
  const handleChange = (event) => {
    event.persist()
    setValues((oldValues) => ({
      ...oldValues,
      [event.target.name]: event.target.value
    }))
  }
  const handleChangeMobile = (event) => {
    const number = event.target.value
    if (number === '') {
      setValues({
        ...values,
        mobile: event.target.value,
        validateStatus: 'success',
        errorMsg: null
      })
    } else if (number.length < 7) {
      setValues({
        ...values,
        mobile: number,
        validateStatus: 'error',
        errorMsg: t('profile.error.phone.length')
      })
    } else if (!isNaN(parseFloat(number)) && isFinite(number)) {
      setValues({
        ...values,
        mobile: number,
        validateStatus: 'success',
        errorMsg: null
      })
    } else {
      setValues({
        ...values,
        mobile: number,
        validateStatus: 'error',
        errorMsg: t('profile.error.phone.letters')
      })
    }
  }
  const handleChangeZipCode = (event) => {
    const number = event.target.value
    if (number === '') {
      setValues({
        ...values,
        zip_code: number,
        validateCode: 'success',
        errorCode: null
      })
    } else if (number.length < 6 || number.length > 6) {
      setValues({
        ...values,
        zip_code: number,
        validateCode: 'error',
        errorCode: t('profile.error.zipCode.length')
      })
    } else if (!isNaN(parseFloat(number)) && isFinite(number)) {
      setValues({
        ...values,
        zip_code: number,
        validateCode: 'success',
        errorCode: null
      })
    } else {
      setValues({
        ...values,
        zip_code: number,
        validateCode: 'error',
        errorCode: t('profile.error.zipCode.letters')
      })
    }
  }
  // console.log(treatment)
  const validateFields = () => {
    const ask_hcp = matrix.data.Ask_HCP !== undefined ? matrix.data.Ask_HCP : true
    const ask_hospital = matrix.data.Ask_Hospital !== undefined ? matrix.data.Ask_Hospital : true

    if (env.profile.contactDetails) {
      if (ask_hcp && ask_hospital) {
        if (values.firstname !== '' && values.lastname !== '' && values.doctor !== undefined && values.hospital !== undefined && values.hospital !== null) {
          if (values.entyvio) {
            if (values.medication !== '' && values.treatment !== null && values.treatment !== undefined && values.start_date !== null) {
              return true
            } else {
              return false
            }
          } else {
            return true
          }
        } else {
          return false
        }
      } else if (!ask_hcp && !ask_hospital) {
        if (values.firstname !== '' && values.lastname !== '') {
          if (values.entyvio) {
            if (values.medication !== '' && values.treatment !== null && values.treatment !== undefined && values.start_date !== null) {
              return true
            } else {
              return false
            }
          } else {
            return true
          }
        } else {
          return false
        }
      } else if (!ask_hcp) {
        if (values.firstname !== '' && values.lastname !== '' && values.hospital !== undefined && values.hospital !== null) {
          if (values.entyvio) {
            if (values.medication !== '' && values.treatment !== null && values.treatment !== undefined && values.start_date !== null) {
              return true
            } else {
              return false
            }
          } else {
            return true
          }
        } else {
          return false
        }
      } else if (!ask_hospital) {
        if (values.firstname !== '' && values.lastname !== '' && values.doctor !== undefined && values.doctor !== null) {
          if (values.entyvio) {
            if (values.medication !== '' && values.treatment !== null && values.treatment !== undefined && values.start_date !== null) {
              return true
            } else {
              return false
            }
          } else {
            return true
          }
        } else {
          return false
        }
      }
    } else {
      if (values.condition !== undefined && values.condition !== '' && values.condition !== null) {
        if (values.entyvio) {
          if (values.treatment !== null && values.treatment !== undefined) return true
          return false
        } else {
          return true
        }
      } else {
        return false
      }
    }
  }

  const handleOptOut = () => {
    const postData = {
      Patient_unsubscribed: true,
      Unsubscribe_Date: moment(new Date()).format('YYYY-MM-DD'),
      Unsubscribe_Reason: reason
    }
    setLoading(true)
    // console.log(postData)
    post('optout', postData, (value) => {
      console.log('opt out : ', value)
      if (Array.isArray(value)) {
        axios
          .get(env.activeSession, { withCredentials: true })
          .then((response) => {
            if (response.data.id) {
              axios
                .delete(env.activeSession, { withCredentials: true })
                .then((response) => {
                  console.log(response)
                  setLoading(false)
                  localStorage.removeItem('token')
                  setProfile({ loaded: false, data: {} })
                  window.location.href = `/login?culture=${supportedCulture()}`
                })
                .catch((error) => console.log(error.response))
            }
          })
          .catch((error) => console.log(error.response))
      } else {
        message.error(t('profile.console'))
      }
    })
  }

  const handleYes = () => {
    if (modalVisible.reason) {
      // handleNo()
      handleOptOut()
    } else {
      setModalVisible({
        ...modalVisible,
        reason: true
      })
    }
  }

  const handleNo = () => {
    setTimeout(() => {
      setModalVisible({
        ...modalVisible,
        modal: false,
        reason: false,
        accessCode: false
      })
    }, 300)
    setReason('')
    setModalVisible({
      ...modalVisible,
      modal: false,
      accessCode: false,
      confirm: false
    })
    setValues({
      ...values,
      accessCode: ''
    })
  }

  const modalBodyStyle = size < 576 ? { padding: '0 20px 25px' } : { padding: '0 50px 25px' }

  const medication = Array.isArray(treatment.data) ? (treatment.data.length > 0 ? treatment.data[0].Medication__c : 'Other') : ''

  const getText = () => {
    const radioStyle = {
      display: 'flex',
      alignItems: 'center',
      fontSize: 16,
      color: env.colors.primaryColor
    }
    if (modalVisible.reason) {
      return (
        <div className={styles.modalQuestions}>
          <p className={styles.title}>{t('profile.modal.delete.optout.text')}</p>
          <Radio.Group name='radiogroup' onChange={(e) => setReason(e.target.value)} className={env.countryClass}>
            <Radio className={styles.radioButton} style={radioStyle} value='NOT_RELEVANT'>
              {t('profile.modal.delete.optout.choice.1')}
            </Radio>
            <Radio className={styles.radioButton} style={radioStyle} value='NO_LONGER_PART'>
              {t('profile.modal.delete.optout.choice.2')}
            </Radio>
            <Radio className={styles.radioButton} style={radioStyle} value='NO_REASON'>
              {t('profile.modal.delete.optout.choice.3')}
            </Radio>
          </Radio.Group>
        </div>
      )
    } else {
      return <div className={styles.modalDescription}>{t('profile.modal.delete.subtitle')}</div>
    }
  }

  const switchViews = () => {
    if (values.otherMedicine && !modalVisible.accessCode) {
      return (
        <OtherMedicationView
          doctors={doctors}
          hospitals={hospitals}
          form={form}
          styles={styles}
          values={values}
          handleSelectValue={handleSelectValue}
          handleSelectDoctor={handleSelectDoctor}
          t={t}
          handleChange={handleChange}
          matrix={matrix}
        />
      )
    } else if (values.entyvio && !modalVisible.accessCode) {
      return (
        <EntyvioView
          doctors={doctors}
          hospitals={hospitals}
          form={form}
          styles={styles}
          values={values}
          handleSelectValue={handleSelectValue}
          handleSelectDoctor={handleSelectDoctor}
          t={t}
          handleChange={handleChange}
          condition={condition}
          configMedication={configMedication}
          configTreatment={configTreatment}
          matrix={matrix}
        />
      )
    } else {
      return (
        <DefaultView
          doctors={doctors}
          hospitals={hospitals}
          form={form}
          styles={styles}
          values={values}
          handleSelectValue={handleSelectValue}
          handleSelectDoctor={handleSelectDoctor}
          t={t}
          handleChange={handleChange}
          setModalVisible={setModalVisible}
          condition={condition}
          matrix={matrix}
        />
      )
    }
  }

  const confirmAction = () => {
    setValues({
      ...values,
      medication: 'Entyvio',
      accessCode: '',
      entyvio: false
    })
    setModalVisible({
      ...modalVisible,
      confirm: false
    })
    setError(false)
  }

  const confirmClose = () => {
    setValues({
      ...values,
      medication: 'Entyvio'
    })
    setModalVisible({
      ...modalVisible,
      confirm: false
    })
    setError(false)
  }
  const supportedCulture = () => {
    if (localStorage.getItem('i18nextLng') !== '') return localStorage.getItem('i18nextLng')
    return env.supported_cultures[0]
  }
  return (
    <Layout>
      <Wrapper>
        <Content>
          <Spin indicator={antIcon} spinning={loaded}>
            <Row type='flex' align='top' justify='center' className={env.countryClass}>
              <Col span={20}>
                {env.countryClass === 'canada' ? (
                  <Row gutter={24} type='flex' align='middle' className={styles.header}>
                    <Col span={24}>
                      <Link to={`/?culture=${supportedCulture()}`}>
                        <img src='/images/CA/onepath@2x.png' className={styles.onepath} alt='Onepath' />
                      </Link>
                    </Col>
                  </Row>
                ) : (
                  <Row gutter={24} type='flex' align='middle' className={styles.header}>
                    <Col xs={24} sm={4} md={3} lg={2} xl={2} xxl={2}>
                      <Link to={`/?culture=${supportedCulture()}`}>
                        <img src='/images/fywy-app-icon-1024x2.png' className={styles.takedaImg} alt='fywy' />
                      </Link>
                    </Col>
                    <Col xs={24} sm={20} md={21} lg={22} xl={22} xxl={22}>
                      <h1 className={styles.heading}>
                        <span>{t('header.1')},</span> <span>{t('header.2')}</span>
                      </h1>
                      <h3 className={styles.subheading}>{t('header.3')}</h3>
                    </Col>
                  </Row>
                )}

                <Row className={styles.mobile}>
                  {env.countryClass === 'canada' ? (
                    <Col span={14}>
                      <Row align='middle' style={{ paddingBottom: 20 }}>
                        <Col span={24}>
                          <Link to={`/?culture=${supportedCulture()}`}>
                            <img src='/images/CA/onepath@2x.png' className={styles.onepath} alt='Onepath' />
                          </Link>
                        </Col>
                      </Row>
                    </Col>
                  ) : (
                    <Col span={14}>
                      <Row align='middle' style={{ paddingBottom: 20 }}>
                        <Col span={8}>
                          <Link to={`/?culture=${supportedCulture()}`}>
                            <img src='/images/fywy-app-icon-1024x2.png' alt='takeda logo' style={{ width: '40px' }} />
                          </Link>
                        </Col>
                        <Col span={16}>
                          <h1 className={styles.heading}>
                            <p>{t('header.1')},</p>
                            <p>{t('header.2')}</p>
                          </h1>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <h3 className={styles.msubheading}>{t('header.3')}</h3>
                        </Col>
                      </Row>
                    </Col>
                  )}
                </Row>
                <Row>
                  <Col span={24} className={styles.goHome}>
                    <Link to={`/?culture=${supportedCulture()}`} style={{ color: env.colors.title }}>
                      <DoubleLeftOutlined /> {t('profile.home')}
                    </Link>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <h1 className={styles.profileTitle} style={{ color: env.colors.title }}>
                      {t('profile.title')}
                    </h1>
                  </Col>
                </Row>
                <Row className={styles.contentArea}>
                  <Col xs={24} sm={16} md={14} lg={17} xl={18} xxl={18}>
                    <Row>
                      <Col span={24}>
                        <Collapse
                          bordered={false}
                          expandIconPosition='right'
                          className={`${styles.dropdown} profile-dropdown`}
                          defaultActiveKey={medication === 'Other' ? ['3'] : []}>
                          <Panel
                            header={t('profile.contact.title')}
                            key='1'
                            className={styles.panel}
                            style={!env.profile.contactDetails ? { display: 'none' } : null}>
                            <Row>
                              <Col xs={24} sm={24} md={24} lg={11} xl={11} xxl={11}>
                                <Form form={form}>
                                  <p className={styles.label}>{t('profile.contact.firstname')}</p>
                                  <Form.Item
                                    name='firstname'
                                    className={styles.formItem}
                                    rules={[
                                      {
                                        required: true,
                                        message: t('errors.v_firstname_required')
                                      },
                                      {
                                        max: 40,
                                        message: t('errors.v_firstname_maxlength')
                                      },
                                      {
                                        type: 'string',
                                        message: t('errors.v_firstname_type')
                                      }
                                    ]}>
                                    <Input
                                      name='firstname'
                                      style={{
                                        borderColor: env.colors.borderColor
                                      }}
                                      defaultValue={values.firstname}
                                      className={styles.inputs}
                                      onChange={handleChange}
                                      allowClear
                                    />
                                  </Form.Item>
                                  <p className={styles.label}>{t('profile.contact.lastname')}</p>
                                  <Form.Item
                                    name='lastname'
                                    className={styles.formItem}
                                    rules={[
                                      {
                                        required: true,
                                        message: t('errors.v_lastname_required')
                                      },
                                      {
                                        max: 80,
                                        message: t('errors.v_lastname_maxlength')
                                      },
                                      {
                                        type: 'string',
                                        message: t('errors.v_lastname_type')
                                      }
                                    ]}>
                                    <Input
                                      name='lastname'
                                      style={{
                                        borderColor: env.colors.borderColor
                                      }}
                                      defaultValue={values.lastname}
                                      className={styles.inputs}
                                      onChange={handleChange}
                                      allowClear
                                    />
                                  </Form.Item>
                                  {/* hcpPortal is not available for the country if env.profile.mobilePhone is false */}
                                  <p className={styles.label} style={!env.profile.mobilePhone ? { display: 'none' } : null}>
                                    {t('profile.contact.mobile')}
                                  </p>
                                  <Form.Item
                                    name='mobile'
                                    className={styles.formItem}
                                    rules={[
                                      {
                                        max: 40,
                                        message: t('errors.v_phonenumber_maxlength')
                                      },
                                      {
                                        type: 'string',
                                        message: t('errors.v_phonenumber_type')
                                      }
                                    ]}
                                    style={!env.profile.mobilePhone ? { display: 'none' } : null}>
                                    <Input
                                      name='mobile'
                                      style={{
                                        borderColor: env.colors.borderColor
                                      }}
                                      className={styles.inputs}
                                      defaultValue={values.mobile}
                                      onChange={handleChangeMobile}
                                      allowClear
                                    />
                                  </Form.Item>
                                </Form>
                              </Col>
                              <Col span={2} />
                              {/* hcpPortal is not available for the country if env.profile.address is false */}
                              <Col xs={24} sm={24} md={24} lg={11} xl={11} xxl={11} style={!env.profile.address ? { display: 'none' } : null}>
                                <Form form={form}>
                                  <p className={styles.label}>{t('profile.contact.address.label')}</p>
                                  <Form.Item
                                    name='street'
                                    style={{ marginBottom: 20 }}
                                    rules={[
                                      {
                                        max: 255,
                                        message: t('errors.v_streetname_maxlength')
                                      },
                                      {
                                        type: 'string',
                                        message: t('errors.v_streetname_type')
                                      }
                                    ]}>
                                    <Input
                                      name='street'
                                      style={{
                                        borderColor: env.colors.borderColor
                                      }}
                                      className={styles.inputs}
                                      placeholder={t('profile.contact.address.placeholder.1')}
                                      onChange={handleChange}
                                      defaultValue={values.street}
                                      allowClear
                                    />
                                  </Form.Item>
                                  <Form.Item
                                    name='city'
                                    style={{
                                      display: 'inline-block',
                                      width: 'calc(50% - 10px)',
                                      marginRight: 20,
                                      marginBottom: 20
                                    }}
                                    rules={[
                                      {
                                        max: 40,
                                        message: t('errors.v_city_maxlength')
                                      },
                                      {
                                        type: 'string',
                                        message: t('errors.v_city_type')
                                      }
                                    ]}>
                                    <Input
                                      name='city'
                                      style={{
                                        borderColor: env.colors.borderColor
                                      }}
                                      className={styles.inputs}
                                      placeholder={t('profile.contact.address.placeholder.2')}
                                      onChange={handleChange}
                                      defaultValue={values.city}
                                      allowClear
                                    />
                                  </Form.Item>
                                  <Form.Item
                                    name='zip_code'
                                    style={{
                                      display: 'inline-block',
                                      width: 'calc(50% - 10px)',
                                      marginBottom: 20
                                    }}
                                    rules={[
                                      {
                                        max: 20,
                                        message: t('errors.v_postcode_maxlength')
                                      },
                                      {
                                        type: 'string',
                                        message: t('errors.v_postcode_type')
                                      }
                                    ]}>
                                    <Input
                                      name='zip_code'
                                      style={{
                                        borderColor: env.colors.borderColor
                                      }}
                                      className={styles.inputs}
                                      placeholder={t('profile.contact.address.placeholder.3')}
                                      onChange={handleChangeZipCode}
                                      defaultValue={values.zip_code}
                                      allowClear
                                    />
                                  </Form.Item>
                                  <Form.Item
                                    name='state'
                                    style={{ marginBottom: 20 }}
                                    rules={[
                                      {
                                        max: 80,
                                        message: t('errors.v_state_maxlength')
                                      },
                                      {
                                        type: 'string',
                                        message: t('errors.v_state_type')
                                      }
                                    ]}>
                                    <Input
                                      name='state'
                                      style={{
                                        borderColor: env.colors.borderColor
                                      }}
                                      className={styles.inputs}
                                      placeholder={t('profile.contact.address.placeholder.4')}
                                      onChange={handleChange}
                                      defaultValue={values.state}
                                      allowClear
                                    />
                                  </Form.Item>
                                  <Form.Item
                                    name='country'
                                    style={{ marginBottom: 21 }}
                                    rules={[
                                      {
                                        max: 80,
                                        message: t('errors.v_country_maxlength')
                                      },
                                      {
                                        type: 'string',
                                        message: t('errors.v_country_type')
                                      }
                                    ]}>
                                    <Input
                                      name='country'
                                      style={{
                                        borderColor: env.colors.borderColor
                                      }}
                                      className={styles.inputs}
                                      placeholder={t('profile.contact.address.placeholder.5')}
                                      onChange={handleChange}
                                      defaultValue={values.country}
                                      allowClear
                                    />
                                  </Form.Item>
                                </Form>
                              </Col>
                            </Row>
                          </Panel>
                          <Panel
                            header={t('profile.communications.title')}
                            key='2'
                            className={styles.panel}
                            style={!matrix.data.AskCommunicationPreferences ? { display: 'none' } : null}>
                            <Row>
                              <Col xs={24} sm={11} md={11} lg={11} xl={11} xxl={11} className={styles.communicationCheckbox}>
                                <p className={styles.communicationLabel} style={{ color: env.colors.primaryColor }}>
                                  {t('profile.communications.days.title')}
                                </p>
                                <Checkbox
                                  className={env.countryClass}
                                  checked={values.day.includes('Monday')}
                                  onChange={(e) => handleCheck(e.target.checked, 'day', 'Monday')}>
                                  {t('profile.communications.days.items.1')}
                                </Checkbox>
                                <Checkbox
                                  className={env.countryClass}
                                  checked={values.day.includes('Tuesday')}
                                  onChange={(e) => handleCheck(e.target.checked, 'day', 'Tuesday')}>
                                  {t('profile.communications.days.items.2')}
                                </Checkbox>
                                <Checkbox
                                  className={env.countryClass}
                                  checked={values.day.includes('Wednesday')}
                                  onChange={(e) => handleCheck(e.target.checked, 'day', 'Wednesday')}>
                                  {t('profile.communications.days.items.3')}
                                </Checkbox>
                                <Checkbox
                                  className={env.countryClass}
                                  checked={values.day.includes('Thursday')}
                                  onChange={(e) => handleCheck(e.target.checked, 'day', 'Thursday')}>
                                  {t('profile.communications.days.items.4')}
                                </Checkbox>
                                <Checkbox
                                  className={env.countryClass}
                                  checked={values.day.includes('Friday')}
                                  onChange={(e) => handleCheck(e.target.checked, 'day', 'Friday')}>
                                  {t('profile.communications.days.items.5')}
                                </Checkbox>
                                <Checkbox
                                  className={env.countryClass}
                                  checked={values.day.includes('Saturday')}
                                  onChange={(e) => handleCheck(e.target.checked, 'day', 'Saturday')}>
                                  {t('profile.communications.days.items.6')}
                                </Checkbox>
                                <Checkbox
                                  className={env.countryClass}
                                  checked={values.day.includes('Sunday')}
                                  onChange={(e) => handleCheck(e.target.checked, 'day', 'Sunday')}>
                                  {t('profile.communications.days.items.7')}
                                </Checkbox>
                              </Col>
                              <Col span={2} />
                              <Col xs={24} sm={11} md={11} lg={11} xl={11} xxl={11} className={styles.communicationCheckbox}>
                                <p className={styles.communicationLabel} style={{ color: env.colors.primaryColor }}>
                                  {t('profile.communications.time.title')}
                                </p>
                                <Checkbox
                                  className={env.countryClass}
                                  checked={values.time.includes('09_12')}
                                  onChange={(e) => handleCheck(e.target.checked, 'time', '09_12')}>
                                  {t('profile.communications.time.items.1')}
                                </Checkbox>
                                <Checkbox
                                  className={env.countryClass}
                                  checked={values.time.includes('12_15')}
                                  onChange={(e) => handleCheck(e.target.checked, 'time', '12_15')}>
                                  {t('profile.communications.time.items.2')}
                                </Checkbox>
                                <Checkbox
                                  className={env.countryClass}
                                  checked={values.time.includes('15_18')}
                                  onChange={(e) => handleCheck(e.target.checked, 'time', '15_18')}>
                                  {t('profile.communications.time.items.3')}
                                </Checkbox>
                              </Col>
                            </Row>
                          </Panel>
                          <Panel header={t('profile.treatment.title')} key='3' className={styles.panel}>
                            {switchViews()}
                          </Panel>
                        </Collapse>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={24} sm={24} md={24} lg={11} xl={11} xxl={11}>
                        <Form form={form}>
                          <Form.Item shouldUpdate>
                            {() => (
                              <Button
                                htmlType='submit'
                                className={styles.button}
                                onClick={handlePost}
                                style={
                                  validateFields() && !form.getFieldsError().filter(({ errors }) => errors.length).length
                                    ? {
                                        backgroundColor: env.colors.primaryColor,
                                        color: '#fff'
                                      }
                                    : {
                                        backgroundColor: 'rgba(0, 0, 0, 0.25)',
                                        color: '#fff'
                                      }
                                }
                                disabled={!validateFields() || form.getFieldsError().filter(({ errors }) => errors.length).length}>
                                {t('profile.button')}
                              </Button>
                            )}
                          </Form.Item>
                        </Form>
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={7}
                    xl={6}
                    xxl={6}
                    className={styles.links}
                    style={!env.profile.contactDetails ? { justifyContent: 'center' } : null}>
                    <p style={{ color: env.colors.primaryColor }} onClick={() => setModalVisible({ ...modalVisible, modal: true })}>
                      {t('profile.opt-out')}
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Modal
              title={t('profile.modal.delete.title')}
              visible={modalVisible.modal}
              onCancel={handleNo}
              footer={null}
              width={875}
              maskClosable={false}
              bodyStyle={modalBodyStyle}
              className={`${styles.modal} ${env.countryClass}`}>
              <Row className={styles.modalRoot}>
                <Col xs={24} sm={24} md={24} lg={18} xl={18} xxl={18} className={styles.modalText}>
                  {getText()}
                </Col>
                <Col span={24} className={styles.modalConfirm}>
                  {t('profile.modal.delete.confirmationText')}
                </Col>
                <Col span={24}>
                  <Row gutter={24}>
                    <Col xs={24} sm={18} md={18} lg={12} xl={12} xxl={12}>
                      <Spin indicator={antIcon} spinning={loading}>
                        <Button
                          className={styles.modalButton}
                          style={
                            modalVisible.reason && reason === ''
                              ? {
                                  backgroundColor: '#ccc',
                                  boxShadow: '0px 3px 6px #00000029',
                                  color: '#fff'
                                }
                              : {
                                  backgroundColor: env.colors.primaryColor,
                                  color: '#fff'
                                }
                          }
                          onClick={handleYes}
                          disabled={modalVisible.reason && reason === ''}>
                          {t('profile.modal.delete.buttonYes')}
                        </Button>
                      </Spin>
                    </Col>
                    <Col xs={24} sm={18} md={18} lg={12} xl={12} xxl={12}>
                      <Button
                        style={{
                          backgroundColor: env.colors.primaryColor,
                          color: '#fff'
                        }}
                        className={styles.modalButton}
                        onClick={handleNo}>
                        {t('profile.modal.delete.buttonNo')}
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Modal>
            <AccessCodeModal
              visible={modalVisible.accessCode}
              close={handleNo}
              handleChange={handleChange}
              style={styles.inputs}
              code={values.accessCode}
              setValues={setValues}
              t={t}
              setOpenStatus={setOpenStatus}
              error={error}
              setError={setError}
              profile={profile}
            />
            <ConfirmModal
              visible={modalVisible.confirm}
              close={confirmClose}
              action={confirmAction}
              title={t('profile.modal.confirm.title')}
              width={850}
              gutter={24}
              yes={t('profile.modal.confirm.buttonYes')}
              no={t('profile.modal.confirm.buttonNo')}
              text={
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} style={{ minHeight: 90, marginTop: 20 }}>
                  <p
                    style={{
                      fontSize: 16,
                      lineHeight: '16px',
                      color: '#202124'
                    }}>
                    {t('profile.modal.confirm.text')}
                  </p>
                </Col>
              }
              loaded={false}
            />
            <StatusModal
              visible={openStatus}
              close={() => setOpenStatus(false)}
              title={t('profile.modal.status.title_success')}
              text={<div style={{ lineHeight: '16px' }}>{t('profile.modal.status.text.success')}</div>}
              button={t('profile.modal.status.text.button_success')}
            />
          </Spin>
        </Content>
      </Wrapper>
    </Layout>
  )
}

export default Profile
