import React, { useState, useEffect } from 'react'
import { Row, Col, Form, Input, Button, Select, Spin, Tooltip } from 'antd'
import { LoadingOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { getCfg } from '../../../env.config'
import axios from 'axios'
import * as qs from 'query-string'

const PersonalizationForm = ({ setVisible, values, setValues, styles, t, hospitals, doctors, condition, matrix, setHospitals, header, profile }) => {
  const { Option } = Select
  const [form] = Form.useForm()
  const [, forceUpdate] = useState()
  const [numbers, setNumbers] = useState([])
  const [loaded, setLoaded] = useState(false)

  const address = `https://${window.location.href.split('/')[2]}`
  const env = getCfg(address)
  const antIcon = <LoadingOutlined style={{ fontSize: 24, color: env.colors.primaryColor }} spin />

  // const ask_hcp = false
  // const ask_hospital = false
  const ask_hcp = matrix.data.Ask_HCP !== undefined ? matrix.data.Ask_HCP : true
  const ask_hospital = matrix.data.Ask_Hospital !== undefined ? matrix.data.Ask_Hospital : true

  const handlePost = () => {
    setLoaded(true)
    setTimeout(() => {
      setLoaded(false)
      setVisible(true)
    }, 1000)
  }

  useEffect(() => {
    forceUpdate({})
  }, [])

  useEffect(() => {
    const array = []
    for (let i = new Date().getFullYear(); i > 1900; i--) {
      array.push(i)
    }
    setNumbers(array)
  }, [])

  const handleSelectDoctor = (value) => {
    const search = window.location.search
    const queryString = qs.parse(search)
    let lang
    let country
    if (queryString.culture !== undefined) {
      lang = queryString.culture.split('-')[0]
      country = queryString.culture.split('-')[1]
    } else {
      lang = profile.data.Language
      country = profile.data.Country
    }

    const headerWithCountry = {
      ...header,
      'x-country': country,
      language: lang
    }
    const filteredDoctors = doctors.data.filter((doctor) => doctor.Id === value)

    const doctorName = filteredDoctors[0] ? filteredDoctors[0].LastName + ' ' + filteredDoctors[0].FirstName : ''
    if (value !== undefined) {
      setValues((oldValues) => ({
        ...oldValues,
        doctor: value
      }))
    } else {
      setValues((oldValues) => ({
        ...oldValues,
        doctor: null
      }))
    }
    setLoaded(true)
    axios
      .get(env.appRoot + 'hospitals/all', {
        params: { doctorName: doctorName, offset: 0, limit: 1000 },
        headers: headerWithCountry
      })
      .then(function (response) {
        setLoaded(false)
        setHospitals({
          loaded: true,
          data: response.data
        })
        setValues((oldValues) => ({
          ...oldValues,
          hospital: null
        }))
      })
      .catch(function (error) {
        setLoaded(false)
        console.log('api get', error.response)
      })
  }
  const handleSelectChange = (value, name) => {
    if (value !== undefined) {
      setValues((oldState) => ({
        ...oldState,
        [name]: value
      }))
    } else {
      setValues((oldState) => ({
        ...oldState,
        [name]: null
      }))
    }
  }

  const handleChange = (event) => {
    event.persist()
    if (event.target.value === '') {
      setValues((oldState) => ({
        ...oldState,
        [event.target.name]: null
      }))
    } else {
      setValues((oldState) => ({
        ...oldState,
        [event.target.name]: event.target.value
      }))
    }
  }

  useEffect(() => {
    form.setFieldsValue({
      year: values.year,
      month: values.month,
      condition: values.condition,
      hospital: values.hospital,
      doctor: values.doctor,
      mobile: values.mobile
    })
  }, [form, values])

  const validate = () => {
    if (ask_hcp && ask_hospital && env.profile.mobilePhone) {
      if (
        values.year !== null &&
        values.month !== null &&
        values.condition !== null &&
        values.hospital !== null &&
        values.doctor !== null &&
        values.mobile !== null &&
        isDateValid()
      ) {
        return true
      } else {
        return false
      }
    } else if (!ask_hcp && !ask_hospital && !env.profile.mobilePhone) {
      if (values.year !== null && values.month !== null && values.condition !== null && isDateValid()) {
        return true
      } else {
        return false
      }
    } else if (!ask_hcp && !ask_hospital && env.profile.mobilePhone) {
      if (values.year !== null && values.month !== null && values.condition !== null && values.mobile !== null && isDateValid()) {
        return true
      } else {
        return false
      }
    } else if (ask_hcp && ask_hospital && !env.profile.mobilePhone) {
      if (values.year !== null && values.month !== null && values.condition !== null && values.doctor !== null && values.hospital !== null && isDateValid()) {
        return true
      } else {
        return false
      }
    } else if (ask_hcp && !ask_hospital && env.profile.mobilePhone) {
      if (values.year !== null && values.month !== null && values.condition !== null && values.doctor !== null && values.mobile !== null && isDateValid()) {
        return true
      } else {
        return false
      }
    } else if (!ask_hcp && ask_hospital && env.profile.mobilePhone) {
      if (values.year !== null && values.month !== null && values.condition !== null && values.hospital !== null && values.mobile !== null && isDateValid()) {
        return true
      } else {
        return false
      }
    } else if (!ask_hcp && ask_hospital && !env.profile.mobilePhone) {
      if (values.year !== null && values.month !== null && values.condition !== null && values.hospital !== null && isDateValid()) {
        return true
      } else {
        return false
      }
    } else if (ask_hcp && !ask_hospital && !env.profile.mobilePhone) {
      if (values.year !== null && values.month !== null && values.condition !== null && values.doctor !== null && isDateValid()) {
        return true
      } else {
        return false
      }
    } else {
      return true
    }
  }

  const isDateValid = () => {
    const currentYear = new Date().getFullYear()
    const currentMonth = new Date().getMonth() + 1
    const difference = currentYear - values.year
    if (difference < 18) return false
    if (difference === 18 && currentMonth > parseInt(values.month)) return false
    return true
  }

  const isDisabled = () => {
    if (values.month === null) return true
    if (values.year === null) return true
    if (!isDateValid()) return true
    return false
  }

  return (
    <Spin indicator={antIcon} spinning={loaded}>
      <Row>
        <Col xs={24} sm={17} md={15} lg={8} xl={8} xxl={9} className={styles.form}>
          <Form form={form}>
            <p className={styles.label}>
              {t('personalization.form.date_birth')}
              <Tooltip
                placement='rightBottom'
                title={t('personalization.form.notification')}
                trigger='click'
                overlayClassName='personalization-info'
                overlayStyle={{
                  fontSize: 14,
                  lineHeight: '20px',
                  paddingLeft: 0,
                  maxWidth: '100%'
                }}>
                <InfoCircleOutlined className={styles.infoIcon} style={{ color: env.colors.primaryColor }} />
              </Tooltip>
            </p>
            <Form.Item
              name='month'
              style={{
                display: 'inline-block',
                width: 'calc(50% - 10px)',
                marginRight: 20
              }}
              rules={[
                {
                  required: true,
                  message: t('errors.v_firstname_required')
                }
              ]}>
              <Select
                size='large'
                placeholder={t('personalization.form.mm')}
                defaultValue={values.month}
                className={styles.personalizationSelect}
                allowClear
                onChange={(value) => handleSelectChange(value, 'month')}
                style={{
                  fontSize: '18px',
                  width: '100%'
                }}
                dropdownClassName={env.countryClass}>
                {Array.from(new Array(12)).map((_, i) => {
                  const value = i + 1 < 10 ? `0${i + 1}` : `${i + 1}`
                  return (
                    <Option key={i} value={value}>
                      {t(`calendar.months.${i}`)}
                    </Option>
                  )
                })}
              </Select>
            </Form.Item>
            <Form.Item
              name='year'
              style={{ display: 'inline-block', width: 'calc(50% - 10px)' }}
              validateStatus={isDateValid() ? '' : 'error'}
              rules={[
                {
                  required: true,
                  message: t('errors.v_firstname_required')
                }
              ]}>
              <Select
                showSearch
                size='large'
                placeholder={t('personalization.form.yyyy')}
                className={styles.personalizationSelect}
                defaultValue={values.year}
                allowClear
                onChange={(value) => handleSelectChange(value, 'year')}
                style={{
                  fontSize: '18px',
                  width: '100%'
                }}
                dropdownClassName={env.countryClass}>
                {numbers.map((val, i) => {
                  return (
                    <Option key={i} value={val} title={val}>
                      {val}
                    </Option>
                  )
                })}
              </Select>
            </Form.Item>

            <p className={styles.label}>{t('personalization.form.condition')}</p>
            <Form.Item
              name='condition'
              rules={[
                {
                  required: true,
                  message: t('errors.v_condition')
                }
              ]}>
              <Select
                size='large'
                className={styles.personalizationSelect}
                allowClear
                defaultValue={values.condition === '' ? null : values.condition}
                onChange={(value) => handleSelectChange(value, 'condition')}
                style={{
                  fontSize: '18px',
                  width: '100%'
                }}
                dropdownClassName={env.countryClass}
                disabled={isDisabled()}>
                {condition.map((val) => {
                  return (
                    <Option key={val.apiname} value={val.apiname} title={val.name}>
                      {val.name}
                    </Option>
                  )
                })}
              </Select>
            </Form.Item>
            <div style={!env.profile.search ? { display: 'none' } : null}>
              <p className={styles.label} style={!ask_hcp ? { display: 'none' } : null}>
                {t('personalization.form.doctor')}
              </p>
              <Form.Item
                name='doctor'
                className={env.profile.search ? 'autoComplete' : ''}
                rules={[
                  {
                    required: true,
                    message: t('errors.v_doctor')
                  }
                ]}
                style={!ask_hcp ? { display: 'none' } : null}>
                <Select
                  size='large'
                  className={`${env.profile.search ? 'search' : styles.personalizationSelect}`}
                  showSearch={env.profile.search ? true : false}
                  allowClear
                  defaultValue={values.doctor === '' ? null : values.doctor}
                  onChange={(value) => (env.profile.search ? handleSelectDoctor(value) : handleSelectChange(value, 'doctor'))}
                  filterOption={(input, option) => {
                    return option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }}
                  style={{
                    fontSize: '18px',
                    width: '100%'
                  }}
                  dropdownClassName={env.countryClass}
                  disabled={isDisabled()}>
                  {Array.isArray(doctors.data)
                    ? doctors.data
                        .sort((a, b) => {
                          return a.LastName.localeCompare(b.LastName)
                        })
                        .map((val, i) => {
                          return (
                            <Option key={i} value={val.Id} title={val.LastName + ' ' + val.FirstName}>
                              {val.LastName + ' ' + val.FirstName}
                            </Option>
                          )
                        })
                    : null}
                </Select>
              </Form.Item>
            </div>

            <div style={env.profile.search ? { display: 'none' } : null}>
              <p className={styles.label} style={!ask_hospital ? { display: 'none' } : null}>
                {t('personalization.form.hospital')}
              </p>
              <Form.Item
                name='hospital'
                rules={[
                  {
                    required: true,
                    message: t('errors.v_hospital')
                  }
                ]}
                style={!ask_hospital ? { display: 'none' } : null}>
                <Select
                  size='large'
                  defaultValue={values.hospital === '' ? null : values.hospital}
                  className={styles.personalizationSelect}
                  allowClear
                  onChange={(value) => handleSelectChange(value, 'hospital')}
                  style={{
                    fontSize: '18px',
                    width: '100%'
                  }}
                  dropdownClassName={env.countryClass}
                  disabled={isDisabled()}>
                  {Array.isArray(hospitals.data)
                    ? hospitals.data
                        .sort((a, b) => {
                          return a.Name.localeCompare(b.Name)
                        })
                        .map((val, i) => {
                          return (
                            <Option key={i} value={val.Id} title={val.Name}>
                              {val.Name}
                            </Option>
                          )
                        })
                    : null}
                </Select>
              </Form.Item>
            </div>
            {/* <p className={styles.label} style={!ask_hospital ? { display: 'none' } : null}>
              {t('personalization.form.hospital')}
            </p>
            <Form.Item
              name='hospital'
              rules={[
                {
                  required: true,
                  message: t('errors.v_hospital')
                }
              ]}
              style={!ask_hospital ? { display: 'none' } : null}>
              <Select
                size='large'
                defaultValue={values.hospital === '' ? null : values.hospital}
                className={styles.personalizationSelect}
                allowClear
                onChange={(value) => handleSelectChange(value, 'hospital')}
                style={{
                  fontSize: '18px',
                  width: '100%'
                }}
                dropdownClassName={env.countryClass}
                disabled={isDisabled()}>
                {Array.isArray(hospitals.data)
                  ? hospitals.data
                      .sort((a, b) => {
                        return a.Name.localeCompare(b.Name)
                      })
                      .map((val, i) => {
                        return (
                          <Option key={i} value={val.Id} title={val.Name}>
                            {val.Name}
                          </Option>
                        )
                      })
                  : null}
              </Select>
            </Form.Item> */}
            <Form.Item shouldUpdate className={styles.button} style={env.profile.mobilePhone || ask_hcp ? { display: 'none' } : null}>
              {() => (
                <Button
                  className={env.countryClass}
                  style={
                    validate()
                      ? {
                          backgroundColor: env.colors.primaryColor,
                          color: '#fff'
                        }
                      : {
                          backgroundColor: '#ccc',
                          border: '1px solid #ccc',
                          color: '#fff'
                        }
                  }
                  htmlType='submit'
                  onClick={handlePost}
                  disabled={!validate()}>
                  {t('personalization.form.button')}
                </Button>
              )}
            </Form.Item>
          </Form>
        </Col>
        <Col span={2} />
        <Col xs={24} sm={17} md={15} lg={8} xl={8} xxl={8} className={styles.form}>
          <Form form={form}>
            <div style={env.profile.search ? { display: 'none' } : null}>
              <p className={styles.label} style={!ask_hcp ? { display: 'none' } : null}>
                {t('personalization.form.doctor')}
              </p>
              <Form.Item
                name='doctor'
                className={env.profile.search ? 'autoComplete' : ''}
                rules={[
                  {
                    required: true,
                    message: t('errors.v_doctor')
                  }
                ]}
                style={!ask_hcp ? { display: 'none' } : null}>
                <Select
                  size='large'
                  className={`${env.profile.search ? 'search' : styles.personalizationSelect}`}
                  showSearch={env.profile.search ? true : false}
                  allowClear
                  defaultValue={values.doctor === '' ? null : values.doctor}
                  onChange={(value) => (env.profile.search ? handleSelectDoctor(value) : handleSelectChange(value, 'doctor'))}
                  filterOption={(input, option) => {
                    return option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }}
                  style={{
                    fontSize: '18px',
                    width: '100%'
                  }}
                  dropdownClassName={env.countryClass}
                  disabled={isDisabled()}>
                  {Array.isArray(doctors.data)
                    ? doctors.data
                        .sort((a, b) => {
                          return a.LastName.localeCompare(b.LastName)
                        })
                        .map((val, i) => {
                          return (
                            <Option key={i} value={val.Id} title={val.LastName + ' ' + val.FirstName}>
                              {val.LastName + ' ' + val.FirstName}
                            </Option>
                          )
                        })
                    : null}
                </Select>
              </Form.Item>
            </div>

            <div style={!env.profile.search ? { display: 'none' } : null}>
              <p className={styles.label} style={!ask_hospital ? { display: 'none' } : null}>
                {t('personalization.form.hospital')}
              </p>
              <Form.Item
                name='hospital'
                rules={[
                  {
                    required: true,
                    message: t('errors.v_hospital')
                  }
                ]}
                style={!ask_hospital ? { display: 'none' } : null}>
                <Select
                  size='large'
                  defaultValue={values.hospital === '' ? null : values.hospital}
                  className={styles.personalizationSelect}
                  allowClear
                  onChange={(value) => handleSelectChange(value, 'hospital')}
                  style={{
                    fontSize: '18px',
                    width: '100%'
                  }}
                  dropdownClassName={env.countryClass}
                  disabled={isDisabled() || values.doctor === null}>
                  {Array.isArray(hospitals.data)
                    ? hospitals.data
                        .sort((a, b) => {
                          return a.Name.localeCompare(b.Name)
                        })
                        .map((val, i) => {
                          return (
                            <Option key={i} value={val.Id} title={val.Name}>
                              {val.Name}
                            </Option>
                          )
                        })
                    : null}
                </Select>
              </Form.Item>
            </div>
            <p style={!env.profile.mobilePhone ? { display: 'none' } : null} className={styles.label}>
              {t('personalization.form.number')}
            </p>
            <Form.Item
              name='mobile'
              style={!env.profile.mobilePhone ? { display: 'none' } : null}
              rules={[
                {
                  required: true,
                  message: t('errors.v_doctor')
                },
                {
                  max: 40,
                  message: t('errors.v_phonenumber_maxlength')
                }
              ]}>
              <Input
                name='mobile'
                defaultValue={values.mobile}
                className={styles.inputs}
                onChange={handleChange}
                allowClear
                style={{ borderColor: env.colors.borderColor }}
                disabled={isDisabled()}
              />
            </Form.Item>

            <Form.Item shouldUpdate className={styles.button} style={!env.profile.mobilePhone && !ask_hcp ? { display: 'none' } : null}>
              {() => (
                <Button
                  className={env.countryClass}
                  style={
                    validate()
                      ? {
                          backgroundColor: env.colors.primaryColor,
                          color: '#fff'
                        }
                      : {
                          backgroundColor: '#ccc',
                          border: '1px solid #ccc',
                          color: '#fff'
                        }
                  }
                  htmlType='submit'
                  onClick={handlePost}
                  disabled={!validate()}>
                  {t('personalization.form.button')}
                </Button>
              )}
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Spin>
  )
}

export default PersonalizationForm
