import React, { useState, useContext, useEffect } from 'react'
import { Row, Col, Card, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import styles from './Article.module.css'
import ArticleDetail from './../ArticleDetail'
import { AppContext } from '../../contexts/AppContext'
import { get, logActionUserRecord } from '../ApiCall'
import { getCfg } from '../../env.config'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const Article = ({ articles, location, favorites, setFavorites }) => {
  const { t } = useTranslation()

  const [state, setState] = useState([])
  const [articleOpenTime, setArticleOpenTime] = useState('')

  const [pinnedArticles, setPinnedArticles] = useState({
    loaded: false,
    data: []
  })
  const address = `https://${window.location.href.split('/')[2]}`
  const env = getCfg(address)
  const antIcon = <LoadingOutlined style={{ fontSize: 24, color: env.colors.primaryColor }} spin />

  const contextValue = useContext(AppContext)
  const { subcategory, setSubcategory, content, setContent, loaded, setLoaded } = contextValue
  // console.log(window.location)
  useEffect(() => {
    setSubcategory('')
    return () => setSubcategory('')
  }, [setSubcategory])

  const handleToggle = () => {
    contextValue.setContextData((oldValue) => ({
      ...oldValue,
      show: !contextValue.contextData.show
    }))
    setContent({ loaded: false, data: [] })
  }

  useEffect(() => {
    setFavorites(pinnedArticles)
  }, [setFavorites, pinnedArticles])

  useEffect(() => {
    if (!pinnedArticles.loaded) {
      get('carrousel/all', {}, setPinnedArticles)
    }
  }, [pinnedArticles.loaded])

  const config = {
    offset: 0,
    limit: 1000
  }

  useEffect(() => {
    if (!location.includes('articles/videos')) {
      if (Array.isArray(articles.data)) {
        if (articles.data.length > 0) {
          const tempState = []
          if (subcategory === '') {
            articles.data.map((value) => {
              return value.articles.map((x) => tempState.push(x))
            })
            articles.data.map((value) => {
              if (value.subCategories !== null && value.subCategories !== undefined) {
                return value.subCategories.map((subcat) => {
                  return subcat.articles.map((article) => tempState.push(article))
                })
              }
              return null
            })
          } else {
            articles.data[0].subCategories
              .filter((cat) => {
                return cat.subcategory === subcategory
              })[0]
              .articles.map((article) => tempState.push(article))
          }
          setState(tempState)
        }
      }
    } else {
      setState(articles.data)
    }
  }, [articles, location, setState, subcategory])

  useEffect(() => {
    if (content.loaded && contextValue.contextData.show) {
      setLoaded(false)
    }
  }, [content.loaded, contextValue.contextData.show, setLoaded])

  const handleCardClick = (urlName) => {
    setArticleOpenTime(new Date())
    setLoaded(true)
    handleToggle()
    if (!location.includes('articles/videos')) {
      get(`articles/detail?UrlName=${urlName}`, config, setContent)
    } else {
      get(`videos/detail?UrlName=${urlName}`, config, setContent)
    }
  }

  const getArticlesView = () => {
    return state
      .filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i)
      .sort((a, b) => {
        const aCode = a.approvalCode !== null && a.approvalCode !== undefined ? a.approvalCode : ''
        const bCode = b.approvalCode !== null && b.approvalCode !== undefined ? b.approvalCode : ''
        return aCode.localeCompare(bCode)
      })
      .map((article, i) => {
        return (
          <Col key={i} xs={12} sm={12} md={12} lg={8} xl={8} xxl={8}>
            <Card
              className={`${styles.card} article-card`}
              hoverable
              cover={<img alt={article.title} src={article.imageUrl ? article.imageUrl : 'http://placeimg.com/300/300/any'} className={styles.cardAvatar} />}
              onClick={() => handleCardClick(article.urlName)}>
              <h3 title={article.title}>{article.title}</h3>
              <p title={article.summary}>{article.summary}</p>
              {/* <span>
                <ClockCircleOutlined /> 2m ago
              </span> */}
            </Card>
          </Col>
        )
      })
  }
  const handleCardLog = () => {
    console.log(content)
    // handle card open log action
    const payload = {
      // account: localStorage.getItem('username'),
      dateEnd: new Date(),
      dateStart: articleOpenTime, 
      articleID: content.data[0].Id , 
      articleTitle: content.data[0].Title, 
      platform: "Web", 
      type: "Article_read", 
      name: content.data[0].name, 
    }
    // log user record article open
    logActionUserRecord(payload)
  }

  const getVideosView = () => {
    return state.map((video) => {
      return (
        <Col key={video.Id} xs={12} sm={12} md={12} lg={8} xl={8} xxl={8}>
          <Card
            className={`${styles.card} article-card`}
            hoverable
            cover={<img alt={video.Title} src={video.Image_URL__c ? video.Image_URL__c : 'http://placeimg.com/300/300/any'} className={styles.cardAvatar} />}
            onClick={() => handleCardClick(video.UrlName)}>
            <h3 title={video.Title}>{video.Title}</h3>
            <p style={!video.Summary ? { display: 'none' } : null} title={video.Summary}>
              {video.Summary}
            </p>
            {/* <span>
              <ClockCircleOutlined /> 2m ago
            </span> */}
          </Card>
        </Col>
      )
    })
  }
  
  return (
    <Spin indicator={antIcon} spinning={loaded} wrapperClassName={`${styles.spinner}`}>
      <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <Row gutter={[20, 20]} className={styles.rowEntyvio} style={contextValue.contextData.show && content.loaded ? { display: 'none' } : { margin: 0 }}>
          <Col span={24} className={styles.entyvioText} style={location.includes('entyvio') ? { margin: 0 } : { display: 'none' }}>
            <p>{t('entyvio.text.1')}</p>
            <p>{t('entyvio.text.2')}</p>
          </Col>
          <Col span={24} style={location.includes('entyvio') && env.entyvioLeaflets ? { margin: 0 } : { display: 'none' }}>
            <Row gutter={[20, 20]}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8} style={contextValue.contextData.show && content.loaded ? { display: 'none' } : null}>
                <Link
                  to='/files/Package leaflet_information for the patient_Entyvio 108mg solution for injection in pre-filled syringe.pdf'
                  target='_blank'
                  download>
                  <p className={styles.entyvioLeaflet}>
                    <span>{t('entyvio.button.syringe.1')}</span>
                    <span>{t('entyvio.button.syringe.2')}</span>
                  </p>
                </Link>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8} style={contextValue.contextData.show && content.loaded ? { display: 'none' } : null}>
                <Link
                  to='/files/Package leaflet_information for the patient_Entyvio 108mg solution for injection in pre-filled pen.pdf'
                  target='_blank'
                  download>
                  <p className={styles.entyvioLeaflet}>
                    <span>{t('entyvio.button.pre_pen.1')}</span>
                    <span>{t('entyvio.button.pre_pen.2')}</span>
                  </p>
                </Link>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8} style={contextValue.contextData.show && content.loaded ? { display: 'none' } : null}>
                <Link
                  to='/files/Package leaflet_information for the patient_Entyvio 300mg powder for concentrate for solution for infusion.pdf'
                  target='_blank'
                  download>
                  <p className={styles.entyvioLeaflet}>
                    <span>{t('entyvio.button.pen.1')}</span>
                    <span>{t('entyvio.button.pen.2')}</span>
                  </p>
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
        {contextValue.contextData.show && content.loaded ? (
          <Row style={{ margin: 0 }}>
            <ArticleDetail
              content={content}
              setContent={setContent}
              handleClose={handleToggle}
              handleUnmount={handleCardLog}
              location={location}
              setPinnedArticles={setPinnedArticles}
              pinnedArticles={pinnedArticles}
            />
          </Row>
        ) : (
          <Row style={{ height: '100%' }} className={styles.scrollContent} gutter={[20, 20]}>
            {location.includes('articles/videos') ? getVideosView() : getArticlesView()}
          </Row>
        )}
      </Col>
    </Spin>
  )
}

export default Article
