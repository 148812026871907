import React, { useState, useRef, useContext, useEffect } from 'react'
import { Row, Col, Button, Spin } from 'antd'
import {
  CloseCircleOutlined,
  LeftCircleOutlined,
  RightCircleOutlined,
  LoadingOutlined,
} from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import Disk from '../Disk/Disk'
import styles from './IBDQuestionare.module.css'
import QuestionareResult from './QuestionareResult'
import { AppContext } from '../../../contexts/AppContext'
import ConfirmModal from './../../ConfirmationModal'
import { post, get, logActionUserRecord } from '../../ApiCall'

const IBDQuestionare = ({ setVisible, callbackFunc, loaded, setLoaded, ibdStartTime }) => {
  const { t } = useTranslation()
  const context = useContext(AppContext)
  const [follow, setFollow] = useState({ loaded: false, data: [] })
  const [btnClicked, setBtnClicked] = useState(false)
  const btnClickedRef = useRef(null)
  // #2 cache current text value
  btnClickedRef.current = btnClicked
  const closeModals = () => {
    setVisible(false)
    context.setContextData((oldState) => ({ ...oldState, ibdModal: false }))
    context.setContextData((oldState) => ({ ...oldState, showResult: false }))
    context.setWheelValues([])
  }

  const [postData, setPostData] = useState({
    AbdominalPain: null,
    BodyImage: null,
    EducationAndWork: null,
    Emotions: null,
    Energy: null,
    InterpersonalInteractions: null,
    JointPain: null,
    RegulatingDefecation: null,
    SexualFunctions: null,
    Sleep: null,
    CreatedDate: new Date(),
  })
  useEffect(() => {
    const namesArray = [
      'AbdominalPain',
      'BodyImage',
      'EducationAndWork',
      'Emotions',
      'Energy',
      'InterpersonalInteractions',
      'JointPain',
      'RegulatingDefecation',
      'SexualFunctions',
      'Sleep',
    ]
    context.wheelValues.map((val) => {
      return namesArray.map((x) => {
        if (x.toLowerCase().includes(val.id.toLowerCase())) {
          return setPostData((oldValues) => ({
            ...oldValues,
            [x]: parseInt(val.value),
          }))
        } else {
          return null
        }
      })
    })
  }, [context.wheelValues])

  useEffect(() => {
    if (!follow.loaded) {
      get('follow/status', {}, setFollow)
    }
  }, [follow.loaded])

  const handlePost = () => {
    setLoaded(true)
    setBtnClicked(() => !btnClicked)
    handleLogUser('Ibddisk_complete')
    post('ibd', postData, callbackFunc)
  }

  const [spinState, setSpinState] = useState(0)
  const [confirm, setConfirm] = useState(false)

  const [data] = useState([
    {
      id: 'education',
      name: t('disk.questionare.wheel.education'),
      value: 10,
      color: '#58b8b9',
    },
    {
      id: 'sleep',
      name: t('disk.questionare.wheel.sleep'),
      value: 10,
      color: '#4d89bd',
    },
    {
      id: 'energy',
      name: t('disk.questionare.wheel.energy'),
      value: 10,
      color: '#5e6ead',
    },
    {
      id: 'emotions',
      name: t('disk.questionare.wheel.emotions'),
      value: 10,
      color: '#7a64a5',
    },
    {
      id: 'body',
      name: t('disk.questionare.wheel.body'),
      value: 10,
      color: '#b163a2',
    },
    {
      id: 'sexual',
      name: t('disk.questionare.wheel.sexual'),
      value: 10,
      color: '#cd7275',
    },
    {
      id: 'joint',
      name: t('disk.questionare.wheel.joint'),
      value: 10,
      color: '#e28551',
    },
    {
      id: 'abdominal',
      name: t('disk.questionare.wheel.abdominal'),
      value: 10,
      color: '#d0b44c',
    },
    {
      id: 'defecation',
      name: t('disk.questionare.wheel.defecation'),
      value: 10,
      color: '#acd075',
    },
    {
      id: 'interactions',
      name: t('disk.questionare.wheel.interactions'),
      value: 10,
      color: '#74bb67',
    },
  ])
  // create ref to access child spin method
  const childRef = useRef()
  const disableButton = () => {
    if (context.wheelValues !== undefined) {
      if (context.wheelValues.length === 10) {
        return false
      } else {
        return true
      }
    } else {
      return true
    }
  }
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />
  const getAction =
    context.wheelValues.length < 1 ? closeModals : () => setConfirm(true)

  const getButtonText = () => {
    if (context.wheelValues.length < 1) {
      return t('disk.questionare.button.save')
    } else {
      return `${context.wheelValues.length} ${t(
        'disk.questionare.button.new_entry'
      )}`
    }
  }
  const handleLogUser = (type) => {
    const payload = {
      // account: localStorage.getItem('username'),
      dateEnd: new Date(),
      dateStart: ibdStartTime,
      platform: "Web",
      type: type
    }
    // log user record article open
    logActionUserRecord(payload)
  }
  // component will unmount
  useEffect(() => {
    return () => {
      btnClickedRef.current === false && handleLogUser('Ibddisk_init')
    }
  }, [])
  if (context.contextData.showResult) {
    return (
      <QuestionareResult
        data={context.wheelValues}
        setVisible={closeModals}
        follow={follow}
      />
    )
  } else {
    return (
      <Row align='top' className={styles.modalRoot}>
        <Col span={24}>
          <CloseCircleOutlined
            className={styles.closeIcon}
            onClick={getAction}
          />
        </Col>
        <Col span={24} className={styles.root}>
          <Row justify='center'>
            <Col
              xs={24}
              sm={24}
              md={22}
              lg={22}
              xl={22}
              xxl={20}
              className={styles.questions}>
              <p>{t('disk.questionare.title')}</p>
              <p>{t(`disk.questions.${spinState % 10}`)}</p>
            </Col>
          </Row>
          <Row justify='center'>
            <Col
              xs={24}
              sm={24}
              md={22}
              lg={22}
              xl={22}
              xxl={20}
              className={styles.diskPlaceholder}>
              <Disk
                data={data}
                ref={childRef}
                setWheelValues={context.setWheelValues}
                spinState={spinState}
                setSpinState={setSpinState}
              />
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <LeftCircleOutlined
                onClick={() => {
                  childRef.current.spin('decrement')
                }}
                className={styles.arrows}
              />
            </Col>
            <Col span={12} className={styles.rightArrow}>
              <RightCircleOutlined
                onClick={() => {
                  childRef.current.spin('increment')
                }}
                className={styles.arrows}
              />
            </Col>
          </Row>
          <Row justify='center' className={styles.labelColumn}>
            <Col xs={24} sm={24} md={16} lg={16} xl={16} xxl={16}>
              <p className={styles.label}>{t('disk.questionare.label')}</p>
            </Col>
          </Row>
          <Row justify='center'>
            <Col
              xs={24}
              sm={24}
              md={16}
              lg={16}
              xl={17}
              xxl={16}
              className={styles.buttonModal}>
              <Spin indicator={antIcon} spinning={loaded}>
                <Button
                  type='primary'
                  className={styles.button}
                  onClick={handlePost}
                  disabled={disableButton()}>
                  {!disableButton()
                    ? t('disk.questionare.button.save')
                    : getButtonText()}
                </Button>
              </Spin>
            </Col>
          </Row>
        </Col>
        <ConfirmModal
          visible={confirm}
          close={() => setConfirm(false)}
          action={closeModals}
          title={t('disk.cancel.title')}
          width={651}
          gutter={60}
          yes={t('disk.cancel.yes')}
          no={t('disk.cancel.no')}
        />
      </Row>
    )
  }
}

export default IBDQuestionare
