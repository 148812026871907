import React, { useState, useEffect, useContext } from 'react'
import { Layout, Row, Col, message } from 'antd'
import { Link } from 'react-router-dom'
import styles from './Survey.module.css'
import { useTranslation } from 'react-i18next'
import Wrapper from '../../LayoutWrapper'
import { LoadingOutlined, DoubleLeftOutlined } from '@ant-design/icons'
import Form from './Form'
import { getCfg } from '../../../env.config'
import Confirmation from './Confirmation'
import { AppContext } from '../../../contexts/AppContext'
import { post, get } from '../../ApiCall'
/* global localStorage */
const Survey = ({ survey, setSurvey }) => {
  const { t } = useTranslation()
  const { Content } = Layout
  const address = `https://${window.location.href.split('/')[2]}`
  const env = getCfg(address)
  const [loaded, setLoaded] = useState(false)
  const antIcon = <LoadingOutlined style={{ fontSize: 24, color: env.colors.primaryColor }} spin />
  const [visible, setVisible] = useState(false)
  const [answers, setAnswers] = useState([])
  const { setShow } = useContext(AppContext)

  const handleClose = () => {
    setShow((show) => ({
      ...show,
      survey: { show: false, data: null }
    }))
  }

  useEffect(() => {
    return function cleanup() {
      setShow((show) => ({
        ...show,
        survey: { show: false, data: null }
      }))
    }
  }, [setShow])

  const handlePost = (taker) => {
    const postData = {
      TakerId: taker,
      Answers: answers
    }
    setLoaded(true)
    post('survey', postData, (val) => {
      setLoaded(false)
      if (val) {
        if (val[0].message) {
          message.error(t('survey.message.error'))
        } else {
          message.success(t('survey.message.success'))
          const config = {
            offset: 0,
            limit: 100
          }
          get('survey/all', config, (values) => {
            if (values.loaded) {
              setSurvey(values)
              handleClose()
            }
          })
        }
      }
    })
  }
  const supportedCulture = () => {
    if (localStorage.getItem('i18nextLng') !== '') return localStorage.getItem('i18nextLng')
    return env.supported_cultures[0]
  }
  return (
    <Layout>
      <Wrapper>
        <Content>
          <Row type='flex' align='top' justify='center' className={env.countryClass}>
            <Col span={20}>
              {env.countryClass === 'canada' ? (
                <Row gutter={24} type='flex' align='middle' className={styles.header}>
                  <Col span={24}>
                    <Link to={`/?culture=${supportedCulture()}`}>
                      <img src='/images/CA/onepath@2x.png' className={styles.onepath} alt='Onepath' />
                    </Link>
                  </Col>
                </Row>
              ) : (
                <Row gutter={24} type='flex' align='middle' className={styles.header}>
                  <Col xs={24} sm={4} md={3} lg={2} xl={2} xxl={2}>
                    <Link to={`/?culture=${supportedCulture()}`}>
                      <img src='/images/fywy-app-icon-1024x2.png' className={styles.takedaImg} alt='fywy' />
                    </Link>
                  </Col>
                  <Col xs={24} sm={20} md={21} lg={22} xl={22} xxl={22}>
                    <h1 className={styles.heading}>
                      <span>{t('header.1')},</span> <span>{t('header.2')}</span>
                    </h1>
                    <h3 className={styles.subheading}>{t('header.3')}</h3>
                  </Col>
                </Row>
              )}

              <Row className={styles.mobile}>
                {env.countryClass === 'canada' ? (
                  <Col span={14}>
                    <Row align='middle' style={{ paddingBottom: 20 }}>
                      <Col span={24}>
                        <Link to={`/?culture=${supportedCulture()}`}>
                          <img src='/images/CA/onepath@2x.png' className={styles.onepath} alt='Onepath' />
                        </Link>
                      </Col>
                    </Row>
                  </Col>
                ) : (
                  <Col span={14}>
                    <Row align='middle' style={{ paddingBottom: 20 }}>
                      <Col span={8}>
                        <Link to={`/?culture=${supportedCulture()}`}>
                          <img src='/images/fywy-app-icon-1024x2.png' alt='takeda logo' style={{ width: '40px', cursor: 'pointer' }} />
                        </Link>
                      </Col>
                      <Col span={16}>
                        <h1 className={styles.heading}>
                          <p>{t('header.1')},</p>
                          <p>{t('header.2')}</p>
                        </h1>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <h3 className={styles.msubheading}>{t('header.3')}</h3>
                      </Col>
                    </Row>
                  </Col>
                )}
              </Row>
              <Row>
                <Col span={24} className={styles.goHome}>
                  <div onClick={handleClose} style={{ color: env.colors.title, cursor: 'pointer' }}>
                    <DoubleLeftOutlined /> {t('survey.confirmationB')}
                  </div>
                </Col>
              </Row>

              <Row>
                <Col xs={24} sm={24} md={24} lg={16} xl={14} xxl={12}>
                  <h1 className={styles.questionTitle} style={{ color: env.colors.title }}>
                    {t('survey.title').toLowerCase()}
                  </h1>
                </Col>
              </Row>
            </Col>
            {visible ? (
              <Confirmation setVisible={setVisible} styles={styles} t={t} getCfg={getCfg} />
            ) : (
              <Form handleClick={handlePost} styles={styles} t={t} icon={antIcon} loaded={loaded} survey={survey} setAnswers={setAnswers} answers={answers} />
            )}
          </Row>
        </Content>
      </Wrapper>
    </Layout>
  )
}

export default Survey
