import React, { useState, useEffect, useRef } from 'react'
import { Row, Col, Button } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import styles from './QuestionareResult.module.css'
import Radar from './../RadarChart'
import SharingModal from './../../../SharingModal'
import * as qs from 'query-string'

const QuestionareResult = ({ data, setVisible, follow }) => {
  const { t } = useTranslation()
  const [size, setSize] = useState(0)
  const targetRef = useRef()
  const [open, setOpen] = useState(true)
  const [state, setState] = useState({
    abdominal: 0,
    regulating: 0,
    interpersonal: 0,
    education: 0,
    sleep: 0,
    energy: 0,
    emotions: 0,
    body: 0,
    sexual: 0,
    joint: 0
  })

  const updateSize = () => {
    setSize(targetRef.current.offsetWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])

  const getSize = () => {
    if (size < 577) {
      return size + 15
    } else {
      return size
    }
  }
  useEffect(() => {
    const tempData = {}
    data.map((score) => {
      const name = score.id === 'interactions' ? 'interpersonal' : score.id === 'defecation' ? 'regulating' : score.id
      return (tempData[name] = parseInt(score.value) * 0.1 + 0.05)
    })
    setState(tempData)
  }, [data])

  const radarData = [
    // data
    {
      data: state,
      meta: { color: '#000' }
    }
  ]

  const search = window.location.search
  const queryString = qs.parse(search)
  const lang = queryString
    ? queryString.culture !== '' && queryString.culture !== undefined
      ? queryString.culture.split('-')[0]
      : localStorage.getItem('i18nextLng').split('-')[0]
    : localStorage.getItem('i18nextLng').split('-')[0]

  const getImage = () => {
    if (lang === 'nl') return '/images/disk-DU.png'
    if (lang === 'fr') return '/images/disk-FR.png'
    if (lang === 'hr') return '/images/disk-HR.png'
    return '/images/questionare@2x.png'
  }

  return (
    <Row align='top' className={styles.modalRoot}>
      <Col span={24}>
        <CloseCircleOutlined className={styles.closeIcon} onClick={setVisible} />
      </Col>
      <Col span={24} className={styles.root}>
        <Row justify='center'>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} className={styles.title}>
            <p>{t('disk.questionare.result.title')}</p>
          </Col>
        </Row>
        <Row justify='center'>
          <Col xs={24} sm={24} md={18} lg={19} xl={16} xxl={16} className={styles.diskPlaceholder}>
            <img src={getImage()} alt='questionare' className={styles.disk} ref={targetRef} />
            <div className={styles.radar}>
              <Radar getSize={getSize} data={radarData} classDot={styles.dot} />
            </div>
          </Col>
        </Row>
        <Row justify='center'>
          <Col xs={24} sm={24} md={19} lg={19} xl={17} xxl={16} className={styles.buttonModal}>
            <Button type='primary' className={styles.button} onClick={setVisible}>
              {t('disk.questionare.result.button')}
            </Button>
          </Col>
        </Row>
      </Col>
      <SharingModal
        follow={follow}
        visible={follow.data[0].Follow_patient__pc ? open : false}
        close={() => setOpen(false)}
        field='IBD_DISK'
        isChecked={follow.data[0].Share__pc}
      />
    </Row>
  )
}

export default QuestionareResult
