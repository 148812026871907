import React from 'react'
import { Row, Col, Modal } from 'antd'
import { EditOutlined, DeleteOutlined, AlertOutlined, CalendarOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import styles from './AppointmentCard.module.css'
import moment from 'moment'
import { deleteC } from '../../ApiCall'
import { getCfg } from '../../../env.config'
import { useTranslation } from 'react-i18next'

const AppointmentCard = (props) => {
  const { t } = useTranslation()
  const { callback } = props
  const { confirm } = Modal

  const address = `https://${window.location.href.split('/')[2]}`
  const env = getCfg(address)

  const showConfirm = () => {
    confirm({
      title: props.t('appointment.card.delete'),
      icon: <ExclamationCircleOutlined />,
      okText: props.t('appointment.card.yes'),
      cancelText: props.t('appointment.card.no'),
      onOk() {
        return new Promise((resolve, reject) => {
          deleteC('appointments/change', config, (val) => {
            callback()
            Modal.destroyAll()
          })
        }).catch(() => console.log('Oops errors!'))
      },
      onCancel() {},
      centered: true,
      className: env.countryClass
    })
  }

  const config = {
    appointmentId: props.Id
  }

  // const formatName = (string) => {
  //   let splittedArray
  //   if (string.split('_').length < 2) {
  //     splittedArray = string.split(' ')
  //   } else {
  //     splittedArray = string.split('_')
  //   }

  //   const returnedArray = splittedArray.map((value) => {
  //     const capitalized =
  //       value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
  //     return capitalized
  //   })
  //   if (returnedArray.length > 2) {
  //     return returnedArray[0] + ' ' + returnedArray[1] + ' ' + returnedArray[2]
  //   } else {
  //     return returnedArray[0] + ' ' + returnedArray[1]
  //   }
  // }

  const getImage = (value) => {
    if (value.includes('GASTRO_SPECIALIST')) {
      return env.cardImages.gastro
    } else if (
      value.includes('MEDICAL_TEST') ||
      value.includes('MEDICAL_TREATMENT_AT_HOSPITAL') ||
      value.includes('RADIOLOGY_EXAMINATION_SCOPIC_EXAMINATION')
    ) {
      return env.cardImages.test
    } else if (
      value.includes('NURSE_SUPPORT_CALL') ||
      value.includes('GASTRO_SPECIALIST_CALL') ||
      value.includes('NUTRITIONIST_CALL') ||
      value.includes('COUNCELOR_OR_PSYCHOLOGIST_CALL')
    ) {
      return env.cardImages.call
    } else if (
      value.includes('NURSE_SUPPORT_VISIT') ||
      value.includes('GASTRO_SPECIALIST_VISIT') ||
      value.includes('NUTRITIONIST_VISIT') ||
      value.includes('COUNCELOR_OR_PSYCHOLOGIST_VISIT')
    ) {
      return env.cardImages.visit
    } else if (value.includes('GENERAL_PRACTITIONER')) {
      return env.cardImages.practitioner
    } else if (value.includes('INTRAVENOUS_INFUSION')) {
      return env.cardImages.intravenous
    } else {
      return env.cardImages.default
    }
  }

  return (
    <Row align='middle' className={styles.root} style={{ backgroundColor: env.colors.cardColor }}>
      <Col span={4} className={styles.iconColumn}>
        <img src={getImage(props.Appointment_Type__c)} alt='icon' />
      </Col>
      <Col span={18} className={styles.detailColumn}>
        <Row>
          <Col span={24}>
            <p title={t(`appointment.reminder.select.${props.Appointment_Type__c}`)} className={styles.title}>
              {t(`appointment.reminder.select.${props.Appointment_Type__c}`)}
            </p>
          </Col>
          <Col span={24}>
            <CalendarOutlined className={styles.icon} style={{ color: env.colors.cardIcon }} />
            <span className={styles.date}>{moment(props.Appointment_DateTime__c).format('dddd DD/MM/YYYY HH:mm')}</span>
          </Col>
          <Col span={24}>
            <AlertOutlined className={styles.icon} style={{ color: env.colors.cardIcon }} />
            <span className={styles.date}>
              {props.Reminder_DateTime__c ? moment(props.Reminder_DateTime__c).format('dddd DD/MM/YYYY HH:mm') : props.t('appointment.card.noappointment')}
            </span>
          </Col>
        </Row>
      </Col>
      <Col span={2} className={styles.buttonColumn}>
        <EditOutlined className={styles.edit} onClick={() => props.handleClick(props.Id)} />
        <DeleteOutlined className={styles.delete} onClick={showConfirm} style={props.Appointment_Type__c === 'INFUSION_CLINIC' ? { display: 'none' } : null} />
        <div className={styles.delete} style={props.Appointment_Type__c !== 'INFUSION_CLINIC' ? { display: 'none' } : null} />
      </Col>
    </Row>
  )
}

export default AppointmentCard
