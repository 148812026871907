import React, { useState } from 'react'
import { Layout, Col, Row, Button } from 'antd'
import styles from './Selector.module.css'
import { getCfg } from '../../env.config'
import { useTranslation } from 'react-i18next'
// import { ReactComponent as CheckedItem } from './checked.svg'
import { CheckOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
/* global localStorage */
const Selector = () => {
  const { Content } = Layout
  const { t } = useTranslation()
  const history = useHistory()
  const address = `https://${window.location.href.split('/')[2]}`
  const env = getCfg(address)
  const [selected, setSelected] = useState('')

  const handleClickCard = (code) => {
    setSelected(code)
    localStorage.setItem('i18nextLng', `${code.toLowerCase()}-BE`)
    history.push({
      pathname: '/selector',
      search: `?culture=${code.toLowerCase()}-BE`
    })
  }
  return (
    <Layout>
      <Content className={styles.root}>
        <Row className={`${env.countryClass} ${styles.rowRoot}`}>
          <div className={styles.container}>
            <Row className={styles.headerRoot} type='flex' justify='center'>
              <Col span={24}>
                {
                  env.countryClass === 'canada' ? (
                    <img src={env.logo} alt='.' className={styles.canadaLogo} />
                  ) : (
                    <Row>
                      <Col span={24} className={styles.header}>
                        <img
                          src='/images/fywy-app-icon-1024.png'
                          className={styles.logo}
                          alt='.'
                        />
                        <div>
                          <h1>{t('app.selector.header.1')}</h1>
                          <h1>{t('app.selector.header.2')}</h1>
                        </div>
                      </Col>
                    </Row>
                  )
                }
              </Col>
            </Row>
            <Row className={styles.contentRow} type='flex' justify='center'>
              <Col span={24} className={styles.contentRoot}>
                <div className={styles.selectTitle}>{t('app.selector.content.language')}</div>
                <hr className={styles.hr} />
                <div className={`${styles.card} ${selected === 'FR' ? styles.active : ''}`} onClick={() => handleClickCard('FR')}>
                  <img src='/images/flags/flag-french@2x.png' alt='.' />
                  <p>{t('app.selector.content.options.1')}</p>
                  {/* <CheckOutlined /> */}
                  <CheckOutlined style={selected === 'NL' || selected === '' ? { display: 'none' } : null} />
                </div>
                <div className={`${styles.card} ${selected === 'NL' ? styles.active : ''}`} onClick={() => handleClickCard('NL')}>
                  <img src='/images/flags/flag-dutch@2x.png' alt='.' />
                  <p>{t('app.selector.content.options.2')}</p>
                  <CheckOutlined style={selected === 'FR' || selected === '' ? { display: 'none' } : null} />
                </div>
              </Col>
            </Row>
            <Row type='flex' justify='center'>
              <Col span={24}>
                <Button
                  className={styles.button}
                  style={{ backgroundColor: env.colors.primaryColor, color: '#fff' }}
                >
                  {t('app.selector.button')}
                </Button>
              </Col>
            </Row>
          </div>
        </Row>
      </Content>
    </Layout>
  )
}

export default Selector
