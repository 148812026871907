import React from 'react'
import { Col, Dropdown, Menu } from 'antd'
import { useTranslation } from 'react-i18next'
import styles from './Dropdown.module.css'
import { DownOutlined } from '@ant-design/icons'
import { Link, useHistory } from 'react-router-dom'
import { getCfg } from '../../env.config'
import axios from 'axios'
/* global localStorage */
const DropdownComponent = ({ setProfile }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const address = `https://${window.location.href.split('/')[2]}`
  const env = getCfg(address)
  const handleLoginRedirect = () => {
    const supportedCulture = () => {
      if (localStorage.getItem('i18nextLng') !== '') return localStorage.getItem('i18nextLng')
      return env.supported_cultures[0]
    }
    // If session exists, remove it
    axios
      .get(env.activeSession, { withCredentials: true })
      .then((response) => {
        if (response.data.id) {
          axios
            .delete(env.activeSession, { withCredentials: true })
            .then((response) => console.log(response))
            .catch((error) => console.log(error.response))
        }
      })
      .catch((error) => console.log(error.response))

    localStorage.removeItem('token')
    setProfile({ loaded: false, data: {} })
    history.push(`/login?culture=${supportedCulture()}`)
  }

  const getCulture = () => {
    const address = `https://${window.location.href.split('/')[2]}`
    const env = getCfg(address)
    if (!env.supported_cultures.includes(localStorage.getItem('i18nextLng'))) {
      return env.supported_cultures[0]
    } else {
      return localStorage.getItem('i18nextLng')
    }
  }

  const menu = (
    <Menu>
      <Menu.Item key='1'>
        <Link to={`/profile?culture=${getCulture()}`}>{t('main.profile.options.1')}</Link>
      </Menu.Item>
      <Menu.Item key='2' onClick={handleLoginRedirect}>
        {t('main.profile.options.2')}
      </Menu.Item>
    </Menu>
  )
  return (
    <Col className={styles.root}>
      <Dropdown overlay={menu} trigger={['click']} className={`${styles.dropdown}`} placement='bottomLeft'>
        {/* eslint-disable-next-line */}
        <a style={{ color: env.colors.title }} onClick={(e) => e.preventDefault()}>
          {t('main.profile.dropdown')} <DownOutlined />
        </a>
      </Dropdown>
    </Col>
  )
}

export default DropdownComponent
