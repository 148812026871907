import React, { useState, useContext, useEffect } from 'react'
import { Row, Col, Button, Skeleton, message, Empty } from 'antd'
import Calendar from './../Calendar'
import styles from './Appointment.module.css'
import AppointmentCard from './AppointmentCard'
import AppointmentReminder from './AppointmentReminder'
import { useTranslation } from 'react-i18next'
import { AppContext } from '../../contexts/AppContext'
import { get } from '../ApiCall'
import moment from 'moment'
import { getCfg } from '../../env.config'

const AppointmentTracker = ({ appointments, setAppointments, treatment }) => {
  const { t } = useTranslation()
  const contextValue = useContext(AppContext)

  const [state, setState] = useState({
    currentMonth: moment(new Date()),
    open: false,
    content: {}
  })

  const [values, setValues] = useState({
    appointmentDate: null,
    appointmentHour: null,
    appointmentType: null,
    notification: false,
    reminderDate: null,
    reminderHour: null,
    id: null
  })
  const [appointmentsT, setAppointmentsT] = useState({
    loaded: false,
    data: []
  })
  const [start, setStart] = useState(false)
  const [dates, setDates] = useState([])

  useEffect(() => {
    setAppointmentsT(appointments)
  }, [appointments, setAppointmentsT])

  const address = `https://${window.location.href.split('/')[2]}`
  const env = getCfg(address)
  const config = {
    offset: 0,
    limit: 1000
  }

  useEffect(() => {
    if (!appointmentsT.loaded) {
      get('appointments/all', config, setAppointmentsT)
    }
  }, [appointmentsT.loaded, config])

  useEffect(() => {
    if (Array.isArray(appointmentsT.data)) {
      const array = []
      appointmentsT.data.map((val) => {
        return array.push(moment(val.Appointment_DateTime__c).format('YYYY-MM-DD'))
      })
      setDates(array)
    }
  }, [appointmentsT.data, setDates])

  const handleOpen = () => {
    contextValue.setContextData((oldValue) => ({
      ...oldValue,
      show: true
    }))
  }

  const handleClose = () => {
    contextValue.setContextData((oldValue) => ({
      ...oldValue,
      show: false
    }))
    setValues({
      appointmentDate: null,
      appointmentHour: null,
      appointmentType: null,
      notification: false,
      reminderDate: null,
      reminderHour: null,
      id: null
    })
  }

  const callbackFunc = (value) => {
    if (!value.Id) {
      setStart(false)
      message.info('It is not working!')
    } else {
      setStart(false)
      setAppointmentsT({
        ...appointmentsT,
        loaded: false
      })
      handleClose()
    }
  }

  const handleEditAppointment = (id) => {
    setValues({
      ...values,
      id: id
    })
    handleOpen()
  }

  const handleDateClick = (date) => {
    setValues((oldValues) => ({
      ...oldValues,
      appointmentDate: date
    }))
  }

  const getData = () => {
    if (Array.isArray(appointmentsT.data) && appointmentsT.loaded) {
      if (appointmentsT.data.length > 0) {
        const sameMonth = appointmentsT.data.filter((values) => {
          return moment(state.currentMonth).isSame(values.Appointment_DateTime__c, 'month')
        })
        if (sameMonth.length > 0) {
          return sameMonth.map((val) => {
            return (
              <AppointmentCard
                key={val.Id}
                {...val}
                handleClick={handleEditAppointment}
                callback={() => setAppointmentsT({ ...appointmentsT, loaded: false })}
                t={t}
              />
            )
          })
        } else {
          return (
            <div className={styles.empty} style={{ backgroundColor: env.colors.cardColor }}>
              {t('appointment.main.noentries')}
            </div>
          )
        }
      } else {
        return <Empty />
      }
    } else {
      return <Skeleton active loading />
    }
  }

  if (contextValue.contextData.show) {
    return (
      <AppointmentReminder
        values={values}
        setValues={setValues}
        setClose={handleClose}
        callback={callbackFunc}
        setStart={setStart}
        loaded={start}
        appointments={appointmentsT}
        treatment={treatment}
      />
    )
  } else {
    return (
      <Row align='bottom' justify='center' className={styles.appointmentRoot}>
        <Col xs={23} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <div className={styles.text}>{t('appointment.main.text')}</div>
        </Col>
        <Col xs={23} sm={24} md={24} lg={24} xl={24} xxl={24} className={styles.appointmentTracker}>
          <Row gutter={24} className={styles.trackerRow}>
            <Col xs={24} sm={24} md={11} lg={11} xl={11} xxl={11} className={styles.calendarPlaceholder}>
              <Calendar selected={values.appointmentDate} dates={dates} onDateClick={handleDateClick} state={state} setState={setState} />
            </Col>

            <Col xs={24} sm={24} md={13} lg={13} xl={13} xxl={13} className={styles.cardsCol}>
              <Row style={{ height: '100%' }}>
                <Col span={24} className={styles.cards}>
                  {getData()}
                </Col>
                <Col span={24} className={styles.buttonCol}>
                  <Button
                    style={{
                      backgroundColor: env.colors.primaryColor,
                      color: '#fff'
                    }}
                    className={styles.trackerButton}
                    onClick={handleOpen}>
                    {t('appointment.main.button')}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row justify='center' className={styles.tabletButton}>
            <Col xs={24} sm={16} md={11} lg={11} xl={11} xxl={11}>
              <Button
                style={{
                  backgroundColor: env.colors.primaryColor,
                  color: '#fff'
                }}
                className={styles.trackerButton}
                onClick={handleOpen}>
                {t('appointment.main.button')}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }
}

export default AppointmentTracker
