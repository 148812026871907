import React, { useState, useEffect } from 'react'
import { Layout, Row, Col, Spin } from 'antd'
import styles from './MainContent.module.css'
import SideNavBar from '../SideBar'
import Navigation from './../Navigation'
import Wrapper from './../LayoutWrapper'
import HomePage from './../HomePage'
import Header from '../Header'
import { withRouter } from 'react-router-dom'
import * as qs from 'query-string'
import Results from '../SearchResults'
import { LoadingOutlined } from '@ant-design/icons'
import { getCfg } from '../../env.config'
import axios from 'axios'
import { header } from '../ApiCall'
import { useTranslation } from 'react-i18next'
import PromoView from '../PromoView'
import { SEARCH_LANGUAGES } from '../../languages'
const { Content } = Layout
/* global localStorage */
const MainContent = (props) => {
  const { t } = useTranslation()
  const address = `https://${window.location.href.split('/')[2]}`
  const env = getCfg(address)

  const [visible, setVisible] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const antIcon = <LoadingOutlined style={{ fontSize: 24, color: env.colors.primaryColor }} spin />

  const [value, setValue] = useState('')
  const [searchValues, setSearchValues] = useState({ loaded: false, data: [] })

  useEffect(() => {
    const address = `https://${window.location.href.split('/')[2]}`
    const env = getCfg(address)
    const data = props.profile.data
    const supportedCulture = () => {
      if (localStorage.getItem('i18nextLng') !== '') return localStorage.getItem('i18nextLng')
      return env.supported_cultures[0]
    }
    if (props.profile.loaded) {
      if (!data.Consent_Checkbox) {
        window.location.href = `/personalization?culture=${supportedCulture()}`
      } else if (data.SurveyAssigned) {
        window.location.href = `/survey?culture=${supportedCulture()}`
      }
    }
  }, [props.profile.loaded, props.profile.data])

  const getLogic = () => {
    const data = props.profile.data
    if (props.profile.loaded) {
      if (!data.Consent_Checkbox) {
        return true
      } else if (data.SurveyAssigned) {
        return true
      }
      return false
    }
    return false
  }

  const search = window.location.search
  const queryString = qs.parse(search)

  let culture
  if (queryString.culture !== undefined) {
    culture = queryString.culture
  } else {
    culture = env.supported_cultures[0]
  }
  const searchLanguage = env.addLanguageParam ? SEARCH_LANGUAGES[culture] : null
  useEffect(() => {
    if (value !== '') {
      const address = `https://${window.location.href.split('/')[2]}`
      const env = getCfg(address)
      const language = searchLanguage !== null ? (searchLanguage !== undefined ? { language: searchLanguage } : { language: 'en_US' }) : ''
      const config = {
        searchterm: value,
        offset: 0,
        limit: 1000,
        ...language
      }
      axios
        .get(env.appRoot + 'search/all', { params: config, headers: header })
        .then(function (response) {
          setSearchValues({
            loaded: true,
            data: response.data
          })
          setLoaded(false)
        })
        .catch(function (error) {
          setSearchValues({
            loaded: true,
            data: error.response
          })
        })
    }
  }, [value, searchLanguage])

  if (localStorage.getItem('promo') && env.enhancements.includes('tutorial')) {
    return <PromoView />
  } else if (getLogic()) {
    return (
      <Layout style={{ minHeight: '100%', display: 'flex', justifyContent: 'center' }}>
        <Spin className={env.countryClass} tip={t('app.load')} />
      </Layout>
    )
  }
  return (
    <Layout>
      <Navigation treatment={props.treatment} survey={props.survey} videos={props.videos} />
      <Wrapper>
        <Content className={`${styles.container} ${env.countryClass}`}>
          <Header
            value={value}
            setValue={setValue}
            search={searchValues}
            setSearch={setSearchValues}
            setLoaded={setLoaded}
            loaded={loaded}
            profile={props.profile}
            setProfile={props.setProfile}
          />
          <Row className={styles.grid} type='flex' justify='center' align='bottom' style={env.homepageCustomStyle}>
            <Col xs={24} sm={24} md={24} lg={24} xl={17} xxl={17}>
              <Spin indicator={antIcon} spinning={loaded} className={env.countryClass}>
                <Row>
                  {searchValues.loaded ? (
                    <Results
                      articles={searchValues}
                      setArticles={setSearchValues}
                      location={props.path}
                      favorites={props.favorites}
                      setFavorites={props.setFavorites}
                      main
                    />
                  ) : (
                    <HomePage
                      articles={props.articles}
                      setArticles={props.setArticles}
                      categories={props.categories}
                      favorites={props.favorites}
                      setFavorites={props.setFavorites}
                      treatment={props.treatment}
                      videos={props.videos}
                      visible={visible}
                      setVisible={setVisible}
                    />
                  )}
                </Row>
              </Spin>
            </Col>
            <Col xs={20} sm={12} md={12} lg={12} xl={7} xxl={7} className={`${styles.columns} ${styles.mobileHidden} ${styles.tabletHidden}`}>
              <SideNavBar treatment={props.treatment} survey={props.survey} profile={props.profile} />
            </Col>
          </Row>
        </Content>
      </Wrapper>
    </Layout>
  )
}

export default withRouter(MainContent)
