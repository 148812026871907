import React, { useState, useContext, useEffect } from 'react'
import { Row, Col, Card, Spin } from 'antd'
import { ClockCircleOutlined, LoadingOutlined } from '@ant-design/icons'
import styles from './SearchResults.module.css'
import ArticleDetail from './../ArticleDetail'
import { AppContext } from '../../contexts/AppContext'
import { get } from '../ApiCall'

const SearchResults = ({ articles, favorites, setFavorites, location, main }) => {
  const [content, setContent] = useState({ loaded: false, data: [] })
  const [loaded, setLoaded] = useState(false)
  const [pinnedArticles, setPinnedArticles] = useState({ loaded: false, data: [] })

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />
  const contextValue = useContext(AppContext)
  const handleToggle = () => {
    contextValue.setContextData((oldValue) => ({
      ...oldValue,
      show: !contextValue.contextData.show
    }))
    setContent({ loaded: false, data: [] })
  }

  useEffect(() => {
    setFavorites(pinnedArticles)
  }, [setFavorites, pinnedArticles])

  useEffect(() => {
    if (!pinnedArticles.loaded) {
      get('carrousel/all', {}, setPinnedArticles)
    }
  }, [pinnedArticles.loaded])

  useEffect(() => {
    if (content.loaded && contextValue.contextData.show) {
      setLoaded(false)
    }
  }, [content.loaded, contextValue.contextData.show])

  const config = {
    offset: 0,
    limit: 1000
  }

  const handleCardClick = (urlName, videoID) => {
    setLoaded(true)
    handleToggle()
    if (!videoID) {
      get(`articles/detail?UrlName=${urlName}`, config, setContent)
    } else {
      get(`videos/detail?UrlName=${urlName}`, config, setContent)
    }
  }

  const getArticlesView = () => {
    return Array.isArray(articles.data)
      ? articles.data.map((article) => {
          return (
            <Col key={article.Id} xs={12} sm={12} md={12} lg={8} xl={8} xxl={8}>
              <Card
                className={`${styles.card} article-card`}
                hoverable
                cover={
                  <img
                    alt={article.Title}
                    src={article.Image_URL__c ? article.Image_URL__c : 'http://placeimg.com/300/300/any'}
                    className={styles.cardAvatar}
                  />
                }
                onClick={() => handleCardClick(article.UrlName, article.Video_Id__c)}>
                <h3 title={article.Title}>{article.Title}</h3>
                <p title={article.Summary}>{article.Summary}</p>
                <span>
                  <ClockCircleOutlined /> 2m ago
                </span>
              </Card>
            </Col>
          )
        })
      : null
  }

  return (
    <Spin indicator={antIcon} spinning={loaded} wrapperClassName={styles.spinner}>
      <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} className={main ? styles.resultRoot : ''}>
        {contextValue.contextData.show && content.loaded ? (
          <Row style={{ margin: 0 }}>
            <ArticleDetail
              content={content}
              setContent={setContent}
              handleClose={handleToggle}
              location={location}
              setPinnedArticles={setPinnedArticles}
              pinnedArticles={pinnedArticles}
            />
          </Row>
        ) : (
          <Row className={styles.scrollContent} gutter={[20, 20]}>
            {getArticlesView()}
          </Row>
        )}
      </Col>
    </Spin>
  )
}

export default SearchResults
