import React from 'react'
import { Row, Col, Checkbox, Modal } from 'antd'
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import styles from './DiskCard.module.css'
import Radar from './../IBDQuestionare/RadarChart'
import * as moment from 'moment'
import { deleteC } from '../../ApiCall'
import * as qs from 'query-string'

const DiskCard = (props) => {
  const radarData = [
    // data
    {
      data: {
        abdominal: props.Abdominal_Pain__c * 0.1,
        regulating: props.Regulating_Defecation__c * 0.1,
        interpersonal: props.Interpersonal_Interactions__c * 0.1,
        education: props.Education_and_Work__c * 0.1,
        sleep: props.Sleep__c * 0.1,
        energy: props.Energy__c * 0.1,
        emotions: props.Emotions__c * 0.1,
        body: props.Body_Image__c * 0.1,
        sexual: props.Sexual_Functions__c * 0.1,
        joint: props.Joint_Pain__c * 0.1
      },
      meta: { color: '#000' }
    }
  ]

  const { confirm } = Modal
  const config = {
    diskId: props.Id
  }
  const showConfirm = () => {
    confirm({
      title: props.t('disk.card.delete'),
      icon: <ExclamationCircleOutlined />,
      okText: props.t('disk.card.yes'),
      cancelText: props.t('disk.card.no'),
      onOk() {
        deleteC('ibd/change', config, props.callback)
        return new Promise((resolve, reject) => {
          setTimeout(Math.random() > 0.5 ? resolve : reject, 1000)
        }).catch(() => console.log('Oops errors!'))
      },
      onCancel() {},
      centered: true
    })
  }

  const search = window.location.search
  const queryString = qs.parse(search)
  const lang = queryString
    ? queryString.culture !== '' && queryString.culture !== undefined
      ? queryString.culture.split('-')[0]
      : localStorage.getItem('i18nextLng').split('-')[0]
    : localStorage.getItem('i18nextLng').split('-')[0]

  const getImage = () => {
    if (lang === 'nl') return '/images/disk-DU.png'
    if (lang === 'fr') return '/images/disk-FR.png'
    if (lang === 'hr') return '/images/disk-HR.png'
    return '/images/questionare@2x.png'
  }

  return (
    <Row align='middle' className={styles.root}>
      <Col span={2} className={styles.iconColumn}>
        <Checkbox onChange={(e) => props.handleChange(e, props)} />
      </Col>
      <Col span={10} className={styles.detailColumn}>
        <Row>
          <Col span={24}>
            <span className={styles.date}>{moment(props.Created_Date__c).format('DD, MMM YYYY')}</span>
          </Col>
        </Row>
      </Col>
      <Col span={10} style={{ textAlign: 'center' }}>
        <img src={getImage()} alt='disk' style={{ width: 56 }} />
        <div className={styles.radar}>
          <Radar
            getSize={() => {
              return 56 + 15
            }}
            data={radarData}
            classDot={styles.dotIcon}
          />
        </div>
      </Col>
      <Col span={2} className={styles.buttonColumn}>
        <DeleteOutlined className={styles.delete} onClick={showConfirm} />
      </Col>
    </Row>
  )
}

export default DiskCard
