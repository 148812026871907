export const prod = {
  /**
   * Allowed Values
   * ooa (order of articles), subcategories, still_entyvio,
   * ibd_chart, tutorial, message_board, opf (online patient forum)
   * * tested : subcategories, message_board, opf, tutorial, still_entyvio, ibd_chart
   */
  templateID: 'd-4b8459da5825462b94d15fa5afbdfa1f',
  enhancements: ['ooa', 'subcategories', 'still_entyvio', 'ibd_chart', 'tutorial', 'message_board', 'opf'],
  cookieOneTrust: '',
  cookieCdnScript: '',
  cookieId: '',
  loginRedirect: 'https://foryouwithyou.bg/login',
  loginRoot: 'https://api-us.takeda.com/security-takedaid-api',
  oktaClientId: '0oau4flsgqNTshMg3416',
  appRoot: 'https://api-eu-aws.takeda.com/ddb-entyvio-fywy-sf-pr-exp/v1/api/',
  registerRoot: 'https://api-eu-aws.takeda.com/ddb-entyvio-fywy-exp/v1/user/register',
  loginClientId: 'bef82bc7faa04532b315699f59495b48',
  loginClientSecret: '2090985D7a45458fa7441E5b109DaF11',
  clientId: 'ac969baf1d524003b21478bcb2226628',
  clientSecret: 'D3743803FfD642eb8EEEf60943f4f66e',
  categoryRoot: 'https://api-eu-aws.takeda.com/ddb-entyvio-fywy-exp/v1/',
  grantType: 'authorization_code',
  state: 'state-296bc9a0-a2a2-4a57-be1a-d0e2fd9bb00601',
  nonce: 'nonce-285shshkiooo989',
  xClient: 'mobile',
  redirectRoot: 'https://takedaext.okta.com/oauth2/default/v1/authorize',
  activeSession: 'https://takedaext.okta.com/api/v1/sessions/me',
  sendRoot: 'https://api-eu-aws.takeda.com/ddb-entyvio-fywy-exp/v1/email/send',
  recoveryPasswordRoot: 'https://api-us.takeda.com/security-takedaid-api/v1/user/recoveryPassword',
  relayStateRecovery: 'https://foryouwithyou.bg/password_reset/init',
  sendMailClientID: 'e674f42eb2ed41a9acd606cff14e0447',
  sendMailClientSecret: '75E4f02db5fC453a8Bf9dbE457412212',
  verifyToken: 'https://api-us.takeda.com/security-takedaid-api/v1/user/verifyToken', // 2
  resetPassword: 'https://api-us.takeda.com/security-takedaid-api/v1/user/resetPassword', // 2
  verifyUser: 'https://api-us.takeda.com/security-takedaid-api/v1/user/verify', // 2
  userLogRecord: 'https://api-eu-aws.takeda.com/ddb-entyvio-fywy-exp/v1/user/log/record', // 4
  mulesoft: {
    baseURI: 'https://api-eu-aws.takeda.com/ddb-entyvio-fywy-exp/v1/user/status?emailAddress=', // 4
    clientID: 'e674f42eb2ed41a9acd606cff14e0447',
    clientSecret: '75E4f02db5fC453a8Bf9dbE457412212'
  },
  // loginRedirect: 'https://foryouwithyou.bg',
  // loginRoot: 'https://api-us-np.takeda.com/dev/security-takedaid-api',
  // oktaClientId: '0oarslgx4eeFN9LWT0h7',
  // appRoot: 'https://api-eu-np.takeda.com/dev/entyvio-fywy-sf-api/api/',
  // registerRoot: 'https://api-eu-np.takeda.com/dev/entyvio-fywy-sf-api/user/register',
  // loginClientId: '6f4f27221ad449f591238fef21f18ae1',
  // loginClientSecret: '2f93e83F0e814B5E9C4Ae146b30d6610',
  // clientId: '35114b55a0d54f66b49d27b0caf04dfb',
  // clientSecret: '679fd4F26d7A49B98eB455F53B3A9F5f',
  // categoryRoot: 'https://api-eu-np.takeda.com/dev/entyvio-fywy-sf-api/',
  // grantType: 'authorization_code',
  // state: 'state-296bc9a0-a2a2-4a57-be1a-d0e2fd9bb00601',
  // nonce: 'nonce-285shshkiooo989',
  // xClient: 'mobile',
  // redirectRoot: 'https://takedaext.oktapreview.com/oauth2/default/v1/authorize',
  // activeSession: 'https://takedaext.oktapreview.com/api/v1/sessions/me',
  // sendRoot: 'https://api-eu-np.takeda.com/dev/entyvio-sendgrid-api/api/email/send',
  // recoveryPasswordRoot: 'https://api-us-np.takeda.com/dev/security-takedaid-api/v1/user/recoveryPassword',
  // relayStateRecovery: 'https://foryouwithyou.bg/password_reset/init',
  // sendMailClientID: '35114b55a0d54f66b49d27b0caf04dfb',
  // sendMailClientSecret: '679fd4F26d7A49B98eB455F53B3A9F5f',
  // verifyToken: 'https://api-us-np.takeda.com/dev/security-takedaid-api/v1/user/verifyToken',
  // resetPassword: 'https://api-us-np.takeda.com/dev/security-takedaid-api/v1/user/resetPassword',
  // verifyUser: 'https://api-us.takeda.com/dev/security-takedaid-api/v1/user/verify',

  supported_cultures: ['bg-BG'],
  countryClass: '',
  logo: '/images/fywy-app-icon-1024.png',
  entyvioLogo: '/images/entyvio@2x.png',
  leftArrow: '/images/left.png',
  rightArrow: '/images/right.png',
  pinImage: '/images/pinned.png',
  medicationIcon: '/images/icon-injection-reminder@2x.png',
  injectionIcon: '/images/icon-injection-tracker@2x.png',
  surveyIcon: '/images/survey_2x.png',
  homepageCustomStyle: {},
  country: 'BG',
  language: 'bg',
  colors: {
    primaryColor: '#51B1BF',
    borderColor: '#51B1BF',
    footer: 'rgb(81 177 191 / 30%)',
    iconColor: '#51B1BF',
    title: '#51B1BF',
    siderColor: '#e2f5f7',
    reminderTitle: '#0e6a7f',
    reminderIcon: '#ecaa00',
    cardColor: '#E2F5F7',
    cardTitle: '#0E6A7F',
    cardIcon: '#84d8e0',
    subtitle: '#4C4948',
    menuColor: '#E2F5F7',
    mobileNavigationColor: '#51B1BF',
    messageBoard: '#E2F5F7D9',
    mobileMessageBoard: '#E2F5F7',
    messageCard: '#E2F5F7',
    mobileMessageCard: '#F5FEFF',
    headerLandingText: '#106A7F',
    hcpLandingText: '#4B9AA9'
  },
  medicationDateFormat: 'HH:mm a',
  entyvioLeaflets: true,
  hideSelector: true,
  diskModal: false,
  profile: {
    treatmentFormat: 'YYYY-MM-DD',
    contactDetails: true,
    mobilePhone: true,
    address: true
  },
  landing: {
    selector: false,
    button: false,
    hcpportal: false,
    disclaimer: true,
    promoCode: null,
    video: {
      visible: false,
      image: null
    },
    googlePlay: 'https://play.google.com/store/apps/details?id=com.takeda.foryouwithyou.bg',
    appleStore: 'https://apps.apple.com/us/app/for-you-with-you/id1532239466',
    hcpLink: '#',
    logo: '/images/landing/fywy-logo@2x.png',
    background: '/images/landing/default-banner@2x.png',
    mobileBackground: '/images/landing/mobile_header@2x.png',
    phone: {
      en: '/images/landing/en-mobile@2x.png',
      bg: '/images/landing/bg-mobile@2x.png'
    },
    icons: {
      appointment: '/images/appointment_tracker_2x.png',
      disk: null,
      map: '/images/map-menu-icon@2x.png',
      recipes: '/images/Recipes@2x.png',
      videos: '/images/Videos@2x.png'
    },
    languages: {
      one: {
        code: '',
        culture: ''
      },
      two: {
        code: '',
        culture: ''
      }
    }
  },
  promo: {
    video: true,
    forum: true,
    stayConnected: true,
    images: {
      bg: {
        welcome: {
          picture: '/images/promo/desktop/desktop-1.png',
          mobile: '/images/promo/desktop/iphone.png'
        },
        content: '/images/promo/desktop/content.png',
        disk: '/images/promo/desktop/disk.png',
        trackers: '/images/promo/desktop/trackers.png',
        forum: '/images/promo/desktop/forum.png',
        connected: '/images/promo/desktop/connected.png'
      }
    }
  },
  footer: {
    privacy: 'https://www.takeda.com/bg-bg/website-privacy-notice/',
    terms: 'https://www.takeda.com/terms-of-use/'
  },
  cardImages: {
    gastro: '/images/Gastro_Doctor.png',
    test: '/images/medical_test.png',
    call: '/images/nurse_call.png',
    visit: '/images/nurse_visit.png',
    practitioner: '/images/Doctor.png',
    intravenous: '/images/IV.png',
    default: '/images/appointment_tracker_2x.png'
  },
  injectionCard: {
    leftabdomen: '/images/injection-tracker/HB_SMALL_Track_RSA.jpg',
    rightabdomen: '/images/injection-tracker/HB_SMALL_Track_LSA.jpg',
    leftarm: '/images/injection-tracker/HB_SMALL_Track_BRA.jpg',
    rightarm: '/images/injection-tracker/HB_SMALL_Track_BLA.jpg',
    leftleg: '/images/injection-tracker/HB_SMALL_Track_FRT.jpg',
    rightleg: '/images/injection-tracker/HB_SMALL_Track_FLT.jpg',
    default: '/images/injection-tracker/HB_SMALL_Track_Unconfirmed.jpg'
  },
  injectionTracker: {
    pickerValidation: true,
    leftabdomen: '/images/injection-tracker/HB_Track_RSA.jpg',
    rightabdomen: '/images/injection-tracker/HB_Track_LSA.jpg',
    leftarm: '/images/injection-tracker/HB_Track_BRA.jpg',
    rightarm: '/images/injection-tracker/HB_Track_BLA.jpg',
    leftleg: '/images/injection-tracker/HB_Track_FRT.jpg',
    rightleg: '/images/injection-tracker/HB_Track_FLT.jpg',
    default: '/images/injection-tracker/HB_Track_Default.jpg'
  },
  goHomeStyle: {
    display: 'none'
  },
  hcpStyle: {},
  types: {
    intravenous: [
      {
        code: 'INTRAVENOUS_INFUSION'
      },
      {
        code: 'GASTRO_SPECIALIST'
      },
      {
        code: 'GENERAL_PRACTITIONER'
      },
      {
        code: 'MEDICAL_TEST'
      },
      {
        code: 'NURSE_SUPPORT_CALL'
      },
      {
        code: 'NURSE_SUPPORT_VISIT'
      },
      {
        code: 'CALPROTECTIN_TEST'
      }
    ],
    subcutaneous: [
      {
        code: 'GASTRO_SPECIALIST'
      },
      {
        code: 'GENERAL_PRACTITIONER'
      },
      {
        code: 'MEDICAL_TEST'
      },
      {
        code: 'NURSE_SUPPORT_CALL'
      },
      {
        code: 'NURSE_SUPPORT_VISIT'
      },
      {
        code: 'CALPROTECTIN_TEST'
      }
    ],
    other: [
      {
        code: 'GASTRO_SPECIALIST'
      },
      {
        code: 'GENERAL_PRACTITIONER'
      },
      {
        code: 'MEDICAL_TEST'
      },
      {
        code: 'CALPROTECTIN_TEST'
      }
    ]
  }
}
