import React, { useContext, useState, useEffect } from 'react'
import { Row, Col, Button, Skeleton } from 'antd'
import Calendar from './../Calendar'
import styles from './Injection.module.css'
import { useTranslation } from 'react-i18next'
import { AppContext } from '../../contexts/AppContext'
import InjectionTracker from './InjectionTracker'
import InjectionCard from './InjectionTrackerCard'
import { get } from '../ApiCall'
import moment from 'moment'
import { getCfg } from '../../env.config'

const Injection = ({ injections, setInjections }) => {
  const { t } = useTranslation()
  const contextValue = useContext(AppContext)

  const [state, setState] = useState({
    currentMonth: moment(new Date()),
    open: false,
    content: {}
  })

  const [values, setValues] = useState({
    date: null,
    place: null
  })
  const [loaded, setLoaded] = useState(false)
  const [ID, setID] = useState(null)
  const [dates, setDates] = useState([])
  const [injectionsT, setInjectionsT] = useState({ loaded: false, data: [] })
  const address = `https://${window.location.href.split('/')[2]}`
  const env = getCfg(address)
  useEffect(() => {
    setInjectionsT(injections)
  }, [injections, setInjectionsT])

  useEffect(() => {
    const config = {
      offset: 0,
      limit: 1000
    }
    if (!injectionsT.loaded) {
      get('injectionTracker/all', config, setInjectionsT)
    }
  }, [injectionsT.loaded])

  useEffect(() => {
    if (Array.isArray(injectionsT.data)) {
      const array = []
      injectionsT.data.map((val) => {
        return array.push(moment(val.Date_Injected__c).format('YYYY-MM-DD'))
      })
      setDates(array)
    }
  }, [injectionsT.data, setDates])

  const handleClick = () => {
    contextValue.setContextData((oldValue) => ({
      ...oldValue,
      show: !contextValue.contextData.show
    }))
    setID(null)
  }
  useEffect(() => {
    if (!contextValue.contextData.show) {
      setValues({
        date: null,
        place: null
      })
    }
  }, [contextValue.contextData.show, setValues])

  const handleEdit = (id) => {
    handleClick()
    setID(id)
  }

  const handleDateClick = (date) => {
    if (moment(values.date).isSame(date, 'day')) {
      setValues((oldValues) => ({
        ...oldValues,
        date: null
      }))
    } else {
      setValues((oldValues) => ({
        ...oldValues,
        date: date
      }))
    }
    // setValues(oldValues => ({
    //   ...oldValues,
    //   date: date
    // }))
  }
  const callbackFunc = (value) => {
    if (value.Id) {
      setInjectionsT({
        ...injectionsT,
        loaded: false
      })
      setLoaded(false)
      handleClick()
    } else {
      setLoaded(false)
      window.alert('problem')
    }
  }

  const getData = () => {
    if (Array.isArray(injectionsT.data) && injectionsT.loaded) {
      const selectedResult = injectionsT.data.filter((result) => {
        return moment(values.date).isSame(result.Date_Injected__c, 'day')
      })

      const tempState = values.date !== null ? selectedResult : injectionsT.data
      if (tempState.length > 0) {
        return tempState.map((val) => {
          return (
            <InjectionCard
              key={val.Id}
              {...val}
              handleClick={handleClick}
              handleEdit={handleEdit}
              callback={() => setInjectionsT({ ...injectionsT, loaded: false })}
              t={t}
            />
          )
        })
      } else {
        // return <Skeleton active loading />
        return (
          <div className={styles.empty} style={{ backgroundColor: env.colors.cardColor }}>
            {t('injection.main.nocontent')}
          </div>
        )
      }
    } else {
      return <Skeleton active loading />
    }
  }

  if (contextValue.contextData.show) {
    return (
      <InjectionTracker
        setVisible={handleClick}
        value={values}
        setValue={setValues}
        ID={ID}
        injections={injectionsT.data}
        callback={callbackFunc}
        loaded={loaded}
        setLoaded={setLoaded}
      />
    )
  }
  return (
    <Row align='bottom' justify='center' className={styles.injectionRoot}>
      {/* <Col xs={23} sm={24} md={24} lg={24} xl={24} xxl={24}>
        <div
          className={styles.gamification}
          style={{
            borderColor: env.colors.primaryColor,
          }}>
          "gamification" placeholder
        </div>
      </Col> */}
      <Col xs={23} sm={24} md={24} lg={24} xl={24} xxl={24} className={styles.injectionTracker}>
        <Row gutter={24} className={styles.injectionRow}>
          <Col xs={24} sm={24} md={11} lg={11} xl={11} xxl={11} className={styles.calendarPlaceholder}>
            <Calendar selected={values.date} dates={dates} onDateClick={handleDateClick} state={state} setState={setState} />
          </Col>
          <Col xs={24} sm={24} md={13} lg={13} xl={13} xxl={13} className={styles.cardsCol}>
            <Row style={{ height: '100%' }}>
              <Col span={24} className={styles.cards}>
                {getData()}
              </Col>
              <Col span={24} className={styles.buttonCol}>
                <Button
                  style={{
                    backgroundColor: env.colors.primaryColor,
                    color: '#fff'
                  }}
                  className={styles.injectionButton}
                  onClick={handleClick}>
                  {t('injection.main.button')}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row justify='center' className={styles.tabletButton}>
          <Col xs={24} sm={16} md={12} lg={11} xl={11} xxl={11}>
            <Button
              style={{
                backgroundColor: env.colors.primaryColor,
                color: '#fff'
              }}
              className={styles.injectionButton}
              onClick={handleClick}>
              {t('injection.main.button')}
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default Injection
