import React, { useState, useEffect, useContext } from 'react'
import Navigation from '../Navigation'
import { Layout, Row, Col, Spin } from 'antd'
import Wrapper from '../LayoutWrapper'
import SideNavBar from '../SideBar'
import Header from '../Header'
import styles from './Wrapper.module.css'
import Results from '../SearchResults'
import { LoadingOutlined } from '@ant-design/icons'
import axios from 'axios'
import { getCfg } from '../../env.config'
import { AppContext } from '../../contexts/AppContext'
import PartialMayoSurvey from '../Partial-Mayo/Survey'
import HarveyBradshawSurvey from '../Harvey-Bradshaw/Survey'
import { header, get } from '../ApiCall'
import SurveyQuestion from '../Survey/Questions/Survey'
import ArticleDetail from '../ArticleDetail'
import { SEARCH_LANGUAGES } from '../../languages'
import * as qs from 'query-string'

const RouterWrapper = (props) => {
  const { Content } = Layout
  const context = useContext(AppContext)
  const address = `https://${window.location.href.split('/')[2]}`
  const env = getCfg(address)
  const { content, setContent, loaded, setLoaded } = context
  const [size, setSize] = useState(0)

  const [value, setValue] = useState('')
  const [searchValues, setSearchValues] = useState({ loaded: false, data: [] })
  const [pinnedArticles, setPinnedArticles] = useState({ loaded: false, data: [] })
  const { favorites, setFavorites } = props

  useEffect(() => {
    setFavorites(pinnedArticles)
  }, [setFavorites, pinnedArticles])

  useEffect(() => {
    if (!pinnedArticles.loaded) {
      get('carrousel/all', {}, setPinnedArticles)
    }
  }, [pinnedArticles.loaded])

  useEffect(() => {
    if (searchValues.loaded) setContent({ loaded: false, data: [] })
  }, [searchValues.loaded, setContent])

  const updateSize = () => {
    setSize(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])

  const getAlignmentProps = () => {
    if (size > 1199) {
      return 'end'
    } else {
      return 'center'
    }
  }
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

  const search = window.location.search
  const queryString = qs.parse(search)

  let culture
  if (queryString.culture !== undefined) {
    culture = queryString.culture
  } else {
    culture = env.supported_cultures[0]
  }
  const searchLanguage = env.addLanguageParam ? SEARCH_LANGUAGES[culture] : null

  useEffect(() => {
    if (value !== '') {
      const address = `https://${window.location.href.split('/')[2]}`
      const env = getCfg(address)
      const language = searchLanguage !== null ? (searchLanguage !== undefined ? { language: searchLanguage } : { language: 'en_US' }) : ''
      const config = {
        searchterm: value,
        offset: 0,
        limit: 100,
        ...language
      }
      axios
        .get(env.appRoot + 'search/all', { params: config, headers: header })
        .then(function (response) {
          setSearchValues({
            loaded: true,
            data: response.data
          })
          setLoaded(false)
        })
        .catch(function (error) {
          setSearchValues({
            loaded: true,
            data: error.response
          })
        })
    }
  }, [value, setLoaded, searchLanguage])

  const handleToggle = () => {
    setContent({ loaded: false, data: [] })
    context.setContextData((old) => ({
      ...old,
      ibdModal: false
    }))
  }

  // console.log(props)
  if (context.show.hbi) {
    return <HarveyBradshawSurvey state={props.state} setState={props.setState} />
  } else if (context.show.pmi) {
    return <PartialMayoSurvey state={props.state} setState={props.setState} />
  } else if (context.show.survey.show) {
    return <SurveyQuestion survey={context.show.survey.data} setSurvey={props.setSurvey} profile={props.profile} />
  }
  return (
    <Layout className={env.countryClass}>
      <Navigation treatment={props.treatment} videos={props.videos} />
      <Wrapper>
        <Content className={styles.container}>
          <Row>
            <Col span={24}>
              <Header
                value={value}
                setValue={setValue}
                search={searchValues}
                setSearch={setSearchValues}
                setLoaded={setLoaded}
                loaded={loaded}
                profile={props.profile}
                setProfile={props.setProfile}
                categories={props.articles}
              />
            </Col>
          </Row>
          <Row style={{ flexGrow: 1 }}>
            <Col xs={24} sm={24} md={24} lg={24} xl={17} xxl={17} className={styles.articleContent}>
              <Row style={{ width: '100%', height: '100%' }}>
                <Col span={24} style={{ height: '100%' }}>
                  <Row justify={getAlignmentProps()} style={{ height: '100%' }}>
                    <Col xs={21} sm={20} md={20} lg={20} xl={23} xxl={23} className={styles.root} style={{ height: '100%' }}>
                      <Spin indicator={antIcon} spinning={loaded} wrapperClassName={styles.spinner}>
                        <Row style={{ height: '100%' }}>
                          {content.board ? (
                            <ArticleDetail
                              content={content}
                              setContent={setContent}
                              handleClose={handleToggle}
                              location={window.location.pathname}
                              setPinnedArticles={setPinnedArticles}
                              pinnedArticles={pinnedArticles}
                            />
                          ) : searchValues.loaded ? (
                            <Results
                              articles={searchValues}
                              setArticles={setSearchValues}
                              location={props.path}
                              favorites={favorites}
                              setFavorites={setFavorites}
                            />
                          ) : (
                            props.children
                          )}
                        </Row>
                      </Spin>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={7} xxl={7} className={`${styles.articleContent} ${styles.mobileHidden} ${styles.tabletHidden}`}>
              <SideNavBar treatment={props.treatment} profile={props.profile} />
            </Col>
          </Row>
        </Content>
      </Wrapper>
    </Layout>
  )
}

export default RouterWrapper
