import React, { useState, useEffect } from 'react'
import { Row, Col, Button, Modal, Spin } from 'antd'
import styles from './ConfirmationModal.module.css'
import { getCfg } from '../../env.config'
import { LoadingOutlined } from '@ant-design/icons'

const ConfirmationModal = ({ visible, close, action, title, width, gutter, yes, no, text, app, loaded }) => {
  const [size, setSize] = useState(0)

  const updateSize = () => {
    setSize(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])
  const modalBodyStyle = size < 576 ? { padding: '0 20px 35px' } : { padding: '0 50px 35px' }
  const address = `https://${window.location.href.split('/')[2]}`
  const env = getCfg(address)
  const antIcon = <LoadingOutlined style={{ fontSize: 24, color: env.colors.primaryColor }} spin />
  return (
    <Modal
      title={title}
      visible={visible}
      onCancel={close}
      footer={null}
      width={width}
      maskClosable={false}
      bodyStyle={modalBodyStyle}
      className={styles.confirmationModal}
      closable={!app}
      centered
    >
      <Row className={styles.modalRoot}>
        <Col span={23}>
          <Row gutter={gutter} className={env.country === 'CA' ? 'canada' : ''}>
            {text}
            <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12} className={styles.buttonRoot}>
              <Spin indicator={antIcon} spinning={loaded === undefined ? false : loaded}>
                <Button
                  className={styles.modalButton}
                  style={{ backgroundColor: env.colors.primaryColor, color: '#fff' }}
                  onClick={action}
                >
                  {yes}
                </Button>
              </Spin>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12} className={styles.buttonRoot}>
              <Button
                className={styles.modalButton}
                style={{ backgroundColor: env.colors.primaryColor, color: '#fff' }}
                onClick={close}
              >
                {no}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  )
}

export default ConfirmationModal
