import React, { useEffect, useState } from 'react'
import { Row, Col, Button, Modal } from 'antd'
import { useTranslation } from 'react-i18next'
import { getCfg } from '../../../env.config'
import styles from './InfoModal.module.css'

const InfoModal = ({ visible, close }) => {
  const { t } = useTranslation()

  const [size, setSize] = useState(0)

  const address = `https://${window.location.href.split('/')[2]}`
  const env = getCfg(address)

  const updateSize = () => {
    setSize(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])
  const modalBodyStyle = size < 576 ? { padding: '0 20px 25px' } : { padding: '0 50px 40px' }
  return (
    <Modal
      title={
        <div>
          {t('disk.main.info.title')}
          <sup style={t('disk.main.info.reference') === '' ? { display: 'none' } : null}>1</sup>?
        </div>
      }
      visible={visible}
      onCancel={close}
      footer={null}
      width={980}
      bodyStyle={modalBodyStyle}
      className={`${env.countryClass} ${styles.root}`}
      closable={false}>
      <Row className={styles.modalRoot}>
        <Col xs={24} className={styles.modalText}>
          <div className={styles.modalDescription}>
            <p>{t('disk.main.info.content')}</p>
            <p style={t('disk.main.info.reference') === '' ? { display: 'none' } : null}>
              <sup>1</sup> {t('disk.main.info.reference')}
            </p>
          </div>
        </Col>
        <Col span={24}>
          <Row gutter={24}>
            <Col xs={16} sm={14} md={13} lg={12} xl={10} xxl={10}>
              <Button
                className={styles.modalButton}
                style={{
                  backgroundColor: env.colors.primaryColor,
                  color: '#fff'
                }}
                onClick={close}>
                {t('disk.main.info.button')}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  )
}

export default InfoModal
