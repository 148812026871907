import React, { useState } from 'react'
import styles from './PromoView.module.css'
import { Layout, Row, Col, Button } from 'antd'
import { getCfg } from '../../env.config'
import { DoubleRightOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import * as qs from 'query-string'

/* global localStorage */
const PromoView = () => {
  const { t } = useTranslation()
  const { Content } = Layout
  const [position, setPosition] = useState(0)
  const address = `https://${window.location.href.split('/')[2]}`
  const env = getCfg(address)

  const handleContinue = () => {
    if (position < 5) {
      if (!env.promo.forum && !env.promo.stayConnected) {
        if (position === 3) {
          localStorage.removeItem('promo')
          window.location.reload(false)
        } else {
          setPosition(position + 1)
        }
      } else if (!env.promo.forum) {
        if (position === 3) {
          setPosition(position + 2)
        } else {
          setPosition(position + 1)
        }
      } else if (!env.promo.stayConnected) {
        if (position === 4) {
          localStorage.removeItem('promo')
          window.location.reload(false)
        } else {
          setPosition(position + 1)
        }
      } else {
        setPosition(position + 1)
      }
    } else {
      localStorage.removeItem('promo')
      window.location.reload(false)
    }
  }
  const handleSkip = () => {
    localStorage.removeItem('promo')
    window.location.reload(false)
  }

  const search = window.location.search
  const queryString = qs.parse(search)
  const lang = queryString ? (queryString.culture !== '' ? queryString.culture.split('-')[0] : 'en') : 'en'
  const images = env.promo.images[lang] ? env.promo.images[lang] : env.promo.images['en']

  const getVideoLink = () => {
    if (lang === 'hr') return 'https://players.brightcove.net/2299575749001/default_default/index.html?videoId=6258930819001'
    return 'https://players.brightcove.net/2299575749001/mk5yNoQvbK_default/index.html?videoId=6272824921001'
  }
  const Iframe = (props) => {
    return <div dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : '' }} />
  }
  const iframe = `<iframe width="100%" height="100%" frameborder="0" src="${getVideoLink()}"></iframe>`
  const getTitle = (position) => {
    if (position === 0) {
      return (
        <h3 className={styles.title}>
          <span>{t('promo.content.1.title.1')}</span>
          <span>{t('promo.content.1.title.2')}</span>
        </h3>
      )
    } else if (position === 1) {
      return (
        <h3 className={styles.title}>
          <span>{t('promo.content.2.title')}</span>
        </h3>
      )
    } else if (position === 2) {
      return (
        <h3 className={!env.promo.video ? styles.title : ''}>
          <span>{t('promo.content.3.title')}</span>
        </h3>
      )
    } else if (position === 3) {
      return (
        <h3 className={styles.title}>
          <span>{t('promo.content.4.title')}</span>
        </h3>
      )
    } else if (position === 4) {
      return (
        <h3 className={styles.title}>
          <span>{t('promo.content.5.title.1')}</span>
          <span>{t('promo.content.5.title.2')}</span>
        </h3>
      )
    } else {
      return (
        <h3 className={styles.title}>
          <span>{t('promo.content.6.title')}</span>
        </h3>
      )
    }
  }

  const getContent = (position) => {
    if (position === 0) {
      return <p className={styles.contentP0}>{t('promo.content.1.text')}</p>
    } else if (position === 1) {
      return <p className={styles.contentP1}>{t('promo.content.2.text')}</p>
    } else if (position === 2) {
      return (
        <div>
          <p className={env.promo.video ? styles.contentP2 : styles.contentP1}>{t('promo.content.3.text')}</p>
          <p className={styles.intext} style={!env.promo.video ? { display: 'none' } : null}>
            {t('promo.content.3.video')}
          </p>
          <div className={styles.videoThumbnail} style={!env.promo.video ? { display: 'none' } : null}>
            <Iframe iframe={iframe} />
          </div>
        </div>
      )
    } else if (position === 3) {
      return <p className={styles.contentP3}>{t('promo.content.4.text')}</p>
    } else if (position === 4) {
      return <p className={styles.contentP4}>{t('promo.content.5.text')}</p>
    } else {
      return <p className={styles.contentP5}>{t('promo.content.6.text')}</p>
    }
  }

  const getImageContent = (position) => {
    if (position === 0) {
      return (
        <div className={styles.firstContainer}>
          <div className={styles.groupBackground}>
            <img src={images.welcome.picture} className={styles.firstImage} alt='.' />
            <img src={images.welcome.mobile} alt='.' className={styles.secondImage} />
          </div>
        </div>
      )
    } else if (position === 1) {
      return (
        <div className={styles.desktopContainer}>
          <div className={styles.groupContent}>
            <img src={images.content} alt='.' className={styles.desktopGroupImage} />
          </div>
        </div>
      )
    } else if (position === 2) {
      return (
        <div className={styles.desktopContainer}>
          <div className={styles.groupContent}>
            <img src={images.disk} alt='.' className={styles.desktopGroupImage} />
          </div>
        </div>
      )
    } else if (position === 3) {
      return (
        <div className={styles.desktopContainer}>
          <div className={styles.groupContent}>
            <img src={images.trackers} alt='.' className={styles.desktopGroupImage} />
          </div>
        </div>
      )
    } else if (position === 4) {
      return (
        <div className={styles.desktopContainer}>
          <div className={styles.duoContainer}>
            <img src={images.forum} alt='.' />
          </div>
        </div>
      )
    } else {
      return (
        <div className={styles.desktopContainer}>
          <div className={styles.groupContentB}>
            <img className={styles.lastGroupImage} src={images.connected} alt='.' />
          </div>
        </div>
      )
    }
  }

  const getImageMobile = (position) => {
    if (position === 0) {
      return (
        <Row>
          <Col span={24} className={styles.mobileImages}>
            <div className={styles.appTitleRoot}>
              <span className={styles.appTitle}>{t('promo.get_app')}</span>
            </div>
            <div className={styles.groupImage}>
              <div className={styles.groupBackground} />
              <div className={styles.groupContent}>
                <img src='/images/promo/mobile/mobile-1.png' alt='.' />
                <img src='/images/promo/mobile/iphone.png' alt='.' />
              </div>
            </div>

            <div className={styles.buttonGroup}>
              <img className={styles.appStore} src='/images/app-store-button.png' alt='' />
              <img className={styles.googlePlay} src='/images/google-play-button.png' alt='' />
            </div>
          </Col>
        </Row>
      )
    } else if (position === 1) {
      return (
        <Row>
          <Col span={24} className={styles.mobileImagesSingle}>
            <div className={styles.mobileBackground}>
              <img src='/images/promo/mobile/mobile-2.png' className={styles.mobileImage} alt='.' />
            </div>
          </Col>
        </Row>
      )
    } else if (position === 2) {
      return (
        <Row>
          <Col span={24} className={styles.mobileImagesSingle}>
            <div className={styles.mobileBackground}>
              <img src='/images/promo/mobile/mobile-3.png' className={styles.mobileImage} alt='.' />
            </div>
          </Col>
        </Row>
      )
    } else if (position === 3) {
      return (
        <Row>
          <Col span={24} className={styles.mobileImagesSingle}>
            <div className={styles.mobileBackground}>
              <img src='/images/promo/mobile/mobile-4.png' className={styles.mobileImage} alt='.' />
            </div>
          </Col>
        </Row>
      )
    } else if (position === 4) {
      return (
        <Row>
          <Col span={24} className={styles.mobileImages}>
            <div className={styles.duoImageContent}>
              <img src='/images/promo/mobile/mobile-5.1.png' alt='.' className={styles.duoFirstImage} />
              <img src='/images/promo/mobile/mobile-5.2.png' alt='.' className={styles.duoSecondImage} />
            </div>
          </Col>
        </Row>
      )
    } else {
      return (
        <Row>
          <Col span={24} className={styles.mobileImagesSingle}>
            <div className={styles.mobileBackground}>
              <img className={styles.mobileImage} src='/images/promo/mobile/mobile-6.png' alt='.' />
            </div>
          </Col>
        </Row>
      )
    }
  }

  const getImageTablet = (position) => {
    if (position === 0) {
      return (
        <Row>
          <Col span={24} className={styles.tabletImages}>
            <div className={styles.tabletImageRoot}>
              <div className={styles.tabletBackground} />
              <div className={styles.tabletContent}>
                <img className={styles.tabletImage} src='/images/promo/tablet/tablet-1.png' alt='.' />
                <img className={styles.tabletIphone} src='/images/promo/tablet/iphone.png' alt='.' />
              </div>
            </div>

            <div className={styles.buttonGroup}>
              <span className={styles.groupTitle}>{t('promo.get_app')}</span>
              <img className={styles.appStore} src='/images/app-store-button.png' alt='' />
              <img className={styles.googlePlay} src='/images/google-play-button.png' alt='' />
            </div>
          </Col>
        </Row>
      )
    } else if (position === 1) {
      return (
        <Row>
          <Col span={24} className={styles.tabletSingle}>
            <div className={styles.tabletImageRoot}>
              <div className={styles.tabletSingleBackground} />
              <div className={styles.tabletContent}>
                <img className={styles.tabletSingleImage} src='/images/promo/tablet/tablet-2.png' alt='.' />
              </div>
            </div>
            <div className={styles.buttonGroup} />
          </Col>
        </Row>
      )
    } else if (position === 2) {
      return (
        <Row>
          <Col span={24} className={styles.tabletSingle}>
            <div className={styles.tabletImageRoot}>
              <div className={styles.tabletSingleBackground} />
              <div className={styles.tabletContent}>
                <img className={styles.tabletSingleImage} src='/images/promo/tablet/tablet-3.png' alt='.' />
              </div>
            </div>
            <div className={styles.buttonGroup} />
          </Col>
        </Row>
      )
    } else if (position === 3) {
      return (
        <Row>
          <Col span={24} className={styles.tabletSingle}>
            <div className={styles.tabletImageRoot}>
              <div className={styles.tabletSingleBackground} />
              <div className={styles.tabletContent}>
                <img className={styles.tabletSingleImage} src='/images/promo/tablet/tablet-4.png' alt='.' />
              </div>
            </div>
            <div className={styles.buttonGroup} />
          </Col>
        </Row>
      )
    } else if (position === 4) {
      return (
        <Row className={styles.tabletDuoRoot}>
          <Col span={12} className={styles.tabletDuoImages}>
            <div className={styles.tabletImageRoot}>
              <div className={styles.tabletDuoBackground} />
            </div>
            <div className={styles.buttonDuoGroup} />
          </Col>
          <Col span={12} className={styles.tabletDuoImages}>
            <div className={styles.tabletDuoImageRootSecond}>
              <div className={styles.tabletDuoBackground} />
            </div>
            <div className={styles.buttonDuoGroup} />
          </Col>
          <Col span={24}>
            <img className={styles.tabletDuoFirst} src='/images/promo/tablet/tablet-5.1.png' alt='.' />
            <img className={styles.tabletDuoSecond} src='/images/promo/tablet/tablet-5.2.png' alt='.' />
          </Col>
        </Row>
      )
    } else {
      return (
        <Row>
          <Col span={24} className={styles.tabletSingle}>
            <div className={styles.tabletImageRoot}>
              <div className={styles.tabletSingleBackground} />
              <div className={styles.tabletContent}>
                <img className={styles.tabletSingleImage} src='/images/promo/tablet/tablet-6.png' alt='.' />
              </div>
            </div>
            <div className={styles.buttonGroup} />
          </Col>
        </Row>
      )
    }
  }

  const styleDesktop =
    position === 0
      ? {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          marginTop: 64
        }
      : { display: 'flex', alignItems: 'center' }

  return (
    <Layout>
      <Content className={styles.root}>
        <Row className={styles.contentRoot} justify='center'>
          <Col xs={20} sm={20} md={20} lg={20} xl={9} xxl={8}>
            <div className={styles.container}>
              <Row>
                <Col span={24} className={styles.dotRoot}>
                  <div
                    className={styles.dot}
                    style={
                      position === 0
                        ? {
                            backgroundColor: env.colors.primaryColor
                          }
                        : null
                    }
                  />
                  <div
                    className={styles.dot}
                    style={
                      position === 1
                        ? {
                            backgroundColor: env.colors.primaryColor
                          }
                        : null
                    }
                  />
                  <div
                    className={styles.dot}
                    style={
                      position === 2
                        ? {
                            backgroundColor: env.colors.primaryColor
                          }
                        : null
                    }
                  />
                  <div
                    className={styles.dot}
                    style={
                      position === 3
                        ? {
                            backgroundColor: env.colors.primaryColor
                          }
                        : null
                    }
                  />
                  <div
                    className={styles.dot}
                    style={
                      position === 4
                        ? {
                            backgroundColor: env.colors.primaryColor
                          }
                        : !env.promo.forum
                        ? { display: 'none' }
                        : null
                    }
                  />
                  <div
                    className={styles.dot}
                    style={
                      position === 5
                        ? {
                            backgroundColor: env.colors.primaryColor
                          }
                        : !env.promo.stayConnected
                        ? { display: 'none' }
                        : null
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col span={24} className={styles.titleRoot}>
                  {getTitle(position)}
                </Col>
              </Row>
              <Row>
                <Col span={24} className={styles.textRoot}>
                  {getContent(position)}
                </Col>
              </Row>
              {getImageMobile(position)}
              {getImageTablet(position)}
              <Row justify='center'>
                <Col xs={22} sm={20} md={12} lg={12} xl={24} xxl={24}>
                  <Button
                    className={styles.button}
                    style={{
                      backgroundColor: env.colors.primaryColor,
                      color: '#fff'
                    }}
                    onClick={handleContinue}>
                    {t('promo.button')}
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col span={24} className={styles.skipRoot}>
                  <span onClick={handleSkip}>
                    {t('promo.skip')} <DoubleRightOutlined className={styles.arrows} />
                  </span>
                </Col>
              </Row>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={15} xl={15} xxl={14} className={styles.desktop} style={styleDesktop}>
            {getImageContent(position)}
            {position === 0 ? (
              <div className={styles.buttonGroup}>
                <span className={styles.groupTitle}>{t('promo.get_app')}</span>
                <img className={styles.googlePlay} src='/images/google-play-button.png' alt='' />
                <img className={styles.appStore} src='/images/app-store-button.png' alt='' />
              </div>
            ) : null}
          </Col>
        </Row>
      </Content>
    </Layout>
  )
}

export default PromoView
