import React from 'react'
import { Row, Col, Button, Drawer } from 'antd'
import styles from './Cookies.module.css'
import { useTranslation } from 'react-i18next'

const Cookies = ({ open, setOpen }) => {
  const { t } = useTranslation()
  return (
    <Drawer
      title='cookies'
      placement='bottom'
      closable
      onClose={() => setOpen(false)}
      visible={open}
      mask={false}
      height='auto'
      headerStyle={{ display: 'none' }}
      bodyStyle={{ padding: 12, backgroundColor: '#F4F4F4' }}
    >
      <Row align='middle' justify='center'>
        <Col xs={22} sm={22} md={22} lg={21} xl={21} xxl={18}>
          <Row gutter={24} align='middle' justify='center'>
            <Col xs={24} sm={24} md={24} lg={12} xl={10} xxl={10} className={styles.text}>
              <span>{t('cookies.text.1')} <b>{t('cookies.text.2')}</b> </span>
              <span>{t('cookies.text.3')}</span>
            </Col>
            <Col xs={18} sm={18} md={10} lg={6} xl={7} xxl={7}>
              <Button
                type='primary'
                className={styles.button}
                onClick={() => setOpen(false)}
              >
                {t('cookies.button.accept')}
              </Button>
            </Col>
            <Col xs={18} sm={18} md={10} lg={6} xl={7} xxl={7}>
              <Button
                style={{
                  backgroundColor: '#C6C6C6'
                }}
                className={styles.button}
                onClick={() => setOpen(false)}
              >
                {t('cookies.button.reject')}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Drawer>
  )
}

export default Cookies
