import React from 'react'
import { Menu } from 'antd'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styles from './Items.module.css'

const Items = ({ menuItems, handleClick, treatment, getCfg, address, supportedCulture, isScrollBarVisible }) => {
  const { t } = useTranslation()
  const condition = treatment.data[0] ? treatment.data[0].Condition__c : 'CD'
  const env = getCfg(address)

  const categories =
    condition === 'CD'
      ? [
          {
            id: '1',
            name: t('navigation.categories.category1.code'),
            icon: t('navigation.categories.category1.icon'),
            text: t('navigation.categories.category1.name'),
            route: '/cd-me'
          },
          {
            id: '2',
            name: t('navigation.categories.category2.code'),
            icon: t('navigation.categories.category2.icon'),
            text: t('navigation.categories.category2.name'),
            route: '/wellbeing'
          },
          {
            id: '3',
            name: t('navigation.categories.category3.code'),
            icon: t('navigation.categories.category3.icon'),
            text: t('navigation.categories.category3.name'),
            route: '/nutrition'
          },
          {
            id: '4',
            name: t('navigation.categories.category4.code'),
            icon: t('navigation.categories.category4.icon'),
            text: t('navigation.categories.category4.name'),
            route: '/recipes'
          },
          {
            id: '5',
            name: t('navigation.categories.category5.code'),
            icon: t('navigation.categories.category5.icon'),
            text: t('navigation.categories.category5.name'),
            route: '/travel'
          }
        ]
      : [
          {
            id: '1',
            name: t('navigation.categories.category7.code'),
            icon: t('navigation.categories.category7.icon'),
            text: t('navigation.categories.category7.name'),
            route: '/uc-me'
          },
          {
            id: '2',
            name: t('navigation.categories.category2.code'),
            icon: t('navigation.categories.category2.icon'),
            text: t('navigation.categories.category2.name'),
            route: '/wellbeing'
          },
          {
            id: '3',
            name: t('navigation.categories.category3.code'),
            icon: t('navigation.categories.category3.icon'),
            text: t('navigation.categories.category3.name'),
            route: '/nutrition'
          },
          {
            id: '4',
            name: t('navigation.categories.category4.code'),
            icon: t('navigation.categories.category4.icon'),
            text: t('navigation.categories.category4.name'),
            route: '/recipes'
          },
          {
            id: '5',
            name: t('navigation.categories.category5.code'),
            icon: t('navigation.categories.category5.icon'),
            text: t('navigation.categories.category5.name'),
            route: '/travel'
          }
        ]

  return (
    <Menu
      mode='inline'
      style={{
        borderRight: 0,
        backgroundColor: 'rgba(81, 177, 191, 0)',
        color: '#51B1BF',
        textAlign: 'center',
        width: '100%'
      }}
      selectable={false}>
      {categories.map((item) => {
        return (
          <Menu.Item
            key={item.id}
            className={window.location.pathname === '/articles' + item.route ? `${styles.item} ant-menu-item-selected` : styles.itemW}
            style={
              menuItems.includes(item.name)
                ? {
                    marginBottom: 15,
                    justifyContent: 'left',
                    display: 'flex',
                    direction: 'ltr'
                  }
                : { display: 'none' }
            }
            title={item.text}
            onClick={() => handleClick(item.text)}>
            <div style={{ paddingLeft: isScrollBarVisible ? '10%' : '25%', fontSize: 16 }}>
              <Link to={`/articles${item.route}?culture=${supportedCulture()}`}>
                <img
                  src={item.icon}
                  alt={item.text}
                  style={{
                    width: 32,
                    verticalAlign: 'middle',
                    marginRight: 10
                  }}
                />
                <span>{item.text}</span>
              </Link>
            </div>
          </Menu.Item>
        )
      })}
      <Menu.Item
        className={window.location.pathname === '/articles/videos' ? `${styles.item} ant-menu-item-selected` : styles.itemW}
        style={
          menuItems.includes('videos')
            ? {
                marginBottom: 15,
                justifyContent: 'left',
                display: 'flex',
                direction: 'ltr'
              }
            : { display: 'none' }
        }
        title={t('navigation.items.6')}
        onClick={() => handleClick(t('navigation.items.6'))}>
        <div style={{ paddingLeft: isScrollBarVisible ? '10%' : '25%', fontSize: 16 }}>
          <Link to={`/articles/videos?culture=${supportedCulture()}`}>
            <img
              src={t('navigation.categories.category8.icon')}
              alt='v'
              style={{
                width: 32,
                marginRight: 10,
                verticalAlign: 'middle'
              }}
            />
            <span>{t('navigation.categories.category8.name')}</span>
          </Link>
        </div>
      </Menu.Item>
      <Menu.Item
        className={window.location.pathname === '/articles/sexuality' ? `${styles.item} ant-menu-item-selected` : styles.itemW}
        style={
          menuItems.includes(t('navigation.categories.category6.code'))
            ? {
                marginBottom: 15,
                justifyContent: 'left',
                display: 'flex',
                direction: 'ltr'
              }
            : { display: 'none' }
        }
        title={t('navigation.categories.category6.name')}
        onClick={() => handleClick(t('navigation.categories.category6.name'))}>
        <div style={{ paddingLeft: isScrollBarVisible ? '10%' : '25%', fontSize: 16 }}>
          <Link to={`/articles/sexuality?culture=${supportedCulture()}`}>
            <img
              src={t('navigation.categories.category6.icon')}
              alt={t('navigation.categories.category6.name')}
              style={{ width: 32, verticalAlign: 'middle', marginRight: 10 }}
            />
            <span>{t('navigation.categories.category6.name')}</span>
          </Link>
        </div>
      </Menu.Item>
      <Menu.Item
        className={window.location.pathname === '/articles/patient-organization' ? `${styles.item} ant-menu-item-selected` : styles.itemW}
        title={t('navigation.categories.category11.name')}
        onClick={() => handleClick(t('navigation.categories.category11.name'))}
        style={
          menuItems.includes(t('navigation.categories.category11.code'))
            ? {
                marginBottom: 15,
                justifyContent: 'left',
                display: 'flex',
                height: 'auto',
                lineHeight: '22px',
                direction: 'ltr'
              }
            : { display: 'none' }
        }>
        <Link
          to={`/articles/patient-organization?culture=${supportedCulture()}`}
          style={{ paddingLeft: isScrollBarVisible ? '10%' : '25%', fontSize: 16, color: 'inherit' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img
              src={t('navigation.categories.category11.icon')}
              alt={t('navigation.categories.category11.name')}
              style={{ width: 32, verticalAlign: 'middle', marginRight: 10 }}
            />
            <span style={{ whiteSpace: 'normal', textAlign: 'left' }}>{t('navigation.categories.category11.name')}</span>
          </div>
        </Link>
      </Menu.Item>

      {/* this is the faq */}
      <Menu.Item
        className={window.location.pathname === '/articles/faq' ? `${styles.item} ant-menu-item-selected` : styles.itemW}
        title={t('navigation.categories.category12.name')}
        onClick={() => handleClick(t('navigation.categories.category12.name'))}
        style={
          menuItems.includes(t('navigation.categories.category12.code'))
            ? {
                marginBottom: 15,
                justifyContent: 'left',
                display: 'flex',
                height: 'auto',
                lineHeight: '22px',
                direction: 'ltr'
              }
            : { display: 'none' }
        }>
        <Link to={`/articles/faq?culture=${supportedCulture()}`} style={{ paddingLeft: isScrollBarVisible ? '10%' : '25%', fontSize: 16, color: 'inherit' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img
              src={t('navigation.categories.category12.icon')}
              alt={t('navigation.categories.category12.name')}
              style={{ width: 32, verticalAlign: 'middle', marginRight: 10 }}
            />
            <span style={{ whiteSpace: 'normal', textAlign: 'left' }}>{t('navigation.categories.category12.name')}</span>
          </div>
        </Link>
      </Menu.Item>

      {/* this is the privacy */}
      <Menu.Item
        className={window.location.pathname === '/articles/privacy' ? `${styles.item} ant-menu-item-selected` : styles.itemW}
        title={t('navigation.categories.category13.name')}
        onClick={() => handleClick(t('navigation.categories.category13.name'))}
        style={
          menuItems.includes(t('navigation.categories.category13.code'))
            ? {
                marginBottom: 15,
                justifyContent: 'left',
                display: 'flex',
                height: 'auto',
                lineHeight: '22px',
                direction: 'ltr'
              }
            : { display: 'none' }
        }>
        <Link
          to={`/articles/privacy?culture=${supportedCulture()}`}
          style={{ paddingLeft: isScrollBarVisible ? '10%' : '25%', fontSize: 16, color: 'inherit' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img
              src={t('navigation.categories.category13.icon')}
              alt={t('navigation.categories.category13.name')}
              style={{ width: 32, verticalAlign: 'middle', marginRight: 10 }}
            />
            <span style={{ whiteSpace: 'normal', textAlign: 'left' }}>{t('navigation.categories.category13.name')}</span>
          </div>
        </Link>
      </Menu.Item>

      <Menu.Item
        title={t('navigation.categories.category10.name')}
        onClick={() => handleClick(t('navigation.categories.category10.name'))}
        style={
          env.enhancements.includes('opf')
            ? {
                marginBottom: 15,
                justifyContent: 'left',
                display: 'flex',
                minHeight: 40,
                height: 'auto',
                lineHeight: '22px',
                direction: 'ltr'
              }
            : { display: 'none' }
        }>
        <a
          href='https://stag-fywyforum.hartehanks.com/'
          style={{ paddingLeft: isScrollBarVisible ? '10%' : '25%', fontSize: 16, color: 'inherit' }}
          target='_blank'
          rel='noopener noreferrer'>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img
              src={t('navigation.categories.category10.icon')}
              alt={t('navigation.categories.category10.name')}
              style={{ width: 32, verticalAlign: 'middle', marginRight: 10 }}
            />
            <span style={{ whiteSpace: 'normal', textAlign: 'left' }}>{t('navigation.categories.category10.name')}</span>
          </div>
        </a>
      </Menu.Item>
    </Menu>
  )
}

export default Items
