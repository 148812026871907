import React, { useState } from 'react'
import { Row, Col, Spin } from 'antd'
import styles from './Card.module.css'
import { getCfg } from '../../../../env.config'
import { get } from '../../../ApiCall'
import { LoadingOutlined } from '@ant-design/icons'

const Card = (props) => {
  const address = `https://${window.location.href.split('/')[2]}`
  const env = getCfg(address)
  const { Title, Taker, context } = props
  const { setShow } = context
  const [loading, setLoading] = useState(false)
  const [active, setActive] = useState(false)
  const antIcon = <LoadingOutlined style={{ fontSize: 24, color: env.colors.primaryColor }} spin />

  const handleClickCard = () => {
    setLoading(true)
    setActive(true)
    get(`survey/detail?takerId=${Taker}`, {}, (value) => {
      // console.log(value)
      setLoading(false)
      setActive(false)
      if (value.data.length > 0) {
        setShow(show => ({
          ...show,
          survey: { show: true, data: value }
        }))
      }
    })
  }
  return (
    <Spin indicator={antIcon} spinning={loading} style={{ top: '-9px' }}>
      <Row
        align='middle'
        className={styles.root}
        style={active ? {
          backgroundColor: env.colors.cardColor,
          cursor: 'pointer',
          border: '1px solid #707070'
        } : {
          backgroundColor: env.colors.cardColor,
          cursor: 'pointer'
        }}
        onClick={handleClickCard}
      >
        <Col span={4} style={{ textAlign: 'center' }}>
          <img src={env.surveyIcon} alt='disk' style={{ width: 40, height: 40 }} />
        </Col>
        <Col span={20} className={styles.detailColumn}>
          {Title}
        </Col>
      </Row>
    </Spin>
  )
}

export default Card
