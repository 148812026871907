import React, { useState } from 'react'
import { Row, Col, Button, Checkbox, Spin, message } from 'antd'
import { CloseCircleOutlined, LoadingOutlined } from '@ant-design/icons'
import { post, logAction } from '../../ApiCall'
import { getCfg } from '../../../env.config'
import moment from 'moment'
import * as qs from 'query-string'

/* global localStorage */
const ConsentForm = ({ setVisible, styles, t, profile, values, treatment, consent, popup }) => {
  const [valid, setIsValid] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const address = `https://${window.location.href.split('/')[2]}`
  const env = getCfg(address)
  const antIcon = <LoadingOutlined style={{ fontSize: 24, color: env.colors.primaryColor }} spin />

  const queryString = qs.parse(window.location.search)

  const onChange = (e) => {
    setIsValid(e.target.checked)
  }

  const postData = {
    Country: profile.data.Country,
    Region: profile.data.Region,
    PersonHasOptedOutOfFax: profile.data.PersonHasOptedOutOfFax,
    PersonHasOptedOutOfEmail: profile.data.PersonHasOptedOutOfEmail,
    PersonDoNotCall: profile.data.PersonDoNotCall,
    Patient_unsubscribed: profile.data.Patient_unsubscribed,
    Language: profile.data.Language,
    Salutation: profile.data.Salutation,
    FirstName: profile.data.FirstName,
    LastName: profile.data.LastName,
    PersonEmail: profile.data.PersonEmail,
    PersonMobilePhone: values.mobile,
    Date_of_Birth: `${values.month}/${values.year}`,
    Consent_Checkbox: valid,
    PersonMailingStreet: profile.data.PersonMailingStreet,
    PersonMailingCity: profile.data.PersonMailingCity,
    PersonMailingState: profile.data.PersonMailingState,
    PersonMailingPostalCode: profile.data.PersonMailingPostalCode,
    PersonMailingCountry: profile.data.PersonMailingCountry,
    DoctorID: values.doctor,
    HospitalID: values.hospital,
    Latest_Device_Token: profile.data.Latest_Device_Token,
    Latest_Device_Platform: profile.data.Latest_Device_Platform,
    ContactPreferencesTimeFrame: profile.data.ContactPreferencesTimeFrame,
    ContactPreferencesDays: profile.data.ContactPreferencesDays,
    NotificationAgreement: profile.data.NotificationAgreement
  }

  const getPopupDate = () => {
    const treatmentType = treatment.data[0] ? (treatment.data[0].Treatment__c ? treatment.data[0].Treatment__c : '') : ''
    const treatmentDate = treatment.data[0] ? (treatment.data[0].Treatment_startdate__c ? treatment.data[0].Treatment_startdate__c : null) : null
    const filteredArray = popup.data.filter((item) => item.Treatment__c === treatmentType)[0]
    const tempDate = moment(treatmentDate).add(filteredArray.Number_of_days__c, 'd').format('YYYY-MM-DD')
    return tempDate
  }
  const isEntyvio = treatment.data[0] ? (treatment.data[0].Medication__c ? treatment.data[0].Medication__c.toLowerCase() === 'entyvio' : false) : false
  const postDataTreatment = isEntyvio
    ? {
        id: treatment.data[0].Id,
        condition: values.condition,
        medication: treatment.data[0].Medication__c,
        treatment_startdate: treatment.data[0].Treatment_startdate__c,
        treatment: treatment.data[0].Treatment__c,
        dateNextPopUp: getPopupDate()
      }
    : treatment.data[0]
    ? {
        id: treatment.data[0].Id,
        condition: values.condition,
        medication: 'Other'
      }
    : {
        condition: values.condition,
        medication: 'Other'
      }

  let culture
  if (queryString.culture !== undefined) {
    culture = queryString.culture
  } else {
    culture = env.supported_cultures[0]
  }

  const handlePost = () => {
    setLoaded(true)
    post('profile2', postData, (val) => {
      if (val.Id) {
        post('treatment', postDataTreatment, (value) => {
          if (value.Id) {
            setLoaded(false)
            message.success(t('personalization.confirmation'))
            localStorage.setItem('promo', true)
            window.location.href = `/?culture=${culture}`
          } else {
            const errorPayload = {
              LogTitle: 'Profile Error',
              username__c: profile.data.PersonEmail,
              country__c: profile.data.Country,
              profile_completed__c: 'null',
              profile_error__c: new Date().toISOString(),
              profile_error_msg__c: val.message
            }
            logAction('user/log', errorPayload, { 'x-okta-idToken': localStorage.getItem('token') })
            setLoaded(false)
            message.error('There was some issue, please check console')
          }
        })
      } else {
        setLoaded(false)
        const errorPayload = {
          LogTitle: 'Profile Error',
          username__c: profile.data.PersonEmail,
          country__c: profile.data.Country,
          profile_completed__c: 'null',
          profile_error__c: new Date().toISOString(),
          profile_error_msg__c: val.message
        }
        logAction('user/log', errorPayload, { 'x-okta-idToken': localStorage.getItem('token') })
        message.error('There was some issue, please check console')
      }
    })
  }
  const handleScroll = (e) => {
    const element = e.target
    if (element.scrollHeight - element.scrollTop <= 400) {
      setDisabled(false)
    }
  }
  const consentText = consent.data.Content__c.replace(/\n/g, '<br />')
  const getTextForConsent = () => {
    return <div dangerouslySetInnerHTML={{ __html: consentText }} />
  }

  return (
    <Row>
      <Col xs={24} sm={24} md={24} lg={14} xl={14} xxl={14} className={styles.consentRoot}>
        <Row justify='center'>
          <Col span={24} className={styles.closeDiv}>
            <CloseCircleOutlined className={styles.closeIcon} style={{ color: env.colors.primaryColor }} onClick={() => setVisible(false)} />
          </Col>
          <Col xs={24} sm={20} md={20} lg={21} xl={21} xxl={21}>
            <h3 style={{ fontSize: 20, color: '#202124' }}>{t('personalization.consent.terms')}</h3>
          </Col>
          <Col xs={24} sm={20} md={20} lg={21} xl={21} xxl={21} className={styles.consentPlaceholder} onScroll={handleScroll}>
            {getTextForConsent()}
          </Col>
          <Col xs={24} sm={20} md={20} lg={21} xl={21} xxl={21}>
            <hr className={styles.line} />
            <div className={styles.checkbox}>
              <Checkbox disabled={disabled} style={{ marginRight: 15 }} className={env.countryClass} onChange={onChange} />
              <span style={{ fontSize: 16, color: env.colors.primaryColor }}>{t('personalization.consent.checkbox')}</span>
            </div>
          </Col>
          <Col xs={24} sm={14} md={14} lg={14} xl={14} xxl={14} className={styles.buttonFinish}>
            <Spin indicator={antIcon} spinning={loaded}>
              <Button
                htmlType='submit'
                style={
                  valid
                    ? {
                        backgroundColor: env.colors.primaryColor,
                        color: '#fff'
                      }
                    : {
                        backgroundColor: '#ccc',
                        border: '1px solid #ccc',
                        pointerEvents: 'none',
                        color: '#fff'
                      }
                }
                onClick={handlePost}>
                {t('personalization.consent.button')}
              </Button>
            </Spin>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default ConsentForm
