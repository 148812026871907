import React from 'react'
import { Layout } from 'antd'
import styles from './Wrapper.module.css'

const Wrapper = ({ children }) => {
  const { Content } = Layout

  return (
    <Layout>
      <Content className={styles.root}>
        {children}
      </Content>
    </Layout>
  )
}

export default Wrapper
