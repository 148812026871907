import React, { useEffect, useState } from 'react'
import { Row, Col, Form, Button, Checkbox, Select, Spin } from 'antd'
import { getCfg } from '../../../../env.config'

const QuestionForm = ({ styles, handleClick, t, icon, loaded, survey, setAnswers, answers }) => {
  const { Option } = Select
  const [form] = Form.useForm()
  const address = `https://${window.location.href.split('/')[2]}`
  const env = getCfg(address)
  const [, forceUpdate] = useState()

  const { data } = survey
  const surveyData = data[0]

  useEffect(() => {
    forceUpdate({})
  }, [])

  const sortedQuestions = surveyData ? surveyData.Questions.sort((a, b) => a.OrderNumber - b.OrderNumber) : []

  const handleOnChange = (id, answer) => {
    const item = {
      Id: id,
      Answer: [answer]
    }
    const tempState = [...answers]
    const position = tempState.map((object) => object.Id).indexOf(id)

    if (position < 0) {
      tempState.push(item)
    } else {
      tempState.splice(position, 1)
      tempState.push(item)
    }

    setAnswers(tempState)
  }

  const handleCheckBox = (id, answer) => {
    // saving the state into a temporary array
    const tempState = [...answers]
    // check if temporary array has the question
    const filteredArray = tempState.filter((x) => x.Id === id)
    // setting the data to be inserted in state
    const item = {
      Id: id,
      Answer: [answer]
    }

    // check if filter method has results
    if (filteredArray.length === 0) {
      // do action in case filter method don't return an object
      tempState.push(item)
      setAnswers(tempState)
    } else {
      // do action in case filter method returns an object
      // save the answers from the array object
      const stateAnswers = [...filteredArray[0].Answer]
      const tempAnswers = [...stateAnswers]
      // check if the answer exists already
      const index = tempAnswers.indexOf(answer)
      if (index < 0) {
        // do action if the answer doesn't exist --- adding it
        tempAnswers.push(answer)
      } else {
        // do action if the answer exists --- removing it
        tempAnswers.splice(index, 1)
      }
      // setting the data to be inserted in state with the updated answers
      const item = {
        Id: id,
        Answer: tempAnswers
      }
      // find the position of the question in state
      const position = tempState.map((object) => object.Id).indexOf(id)
      // remove the old object from temp array
      tempState.splice(position, 1)
      // add the new object in temp array
      if (item.Answer.length !== 0) {
        tempState.push(item)
      }
      // update the state with updated values
      setAnswers(tempState)
    }
  }

  // console.log(surveyData)
  if (!surveyData) return <div style={{ fontSize: 14, color: '#000' }}>{t('survey.loading')}</div>
  return (
    <Col span={20} className='survey'>
      <Row>
        <Col>
          <h3
            className={styles.questionSubTitle}
            style={{
              color: env.colors.subtitle,
              borderColor: env.colors.primaryColor
            }}>
            {surveyData ? surveyData.Title : ''}
          </h3>
        </Col>
      </Row>

      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={22} xxl={20}>
          <p className={styles.description} style={{ color: '#4C4948' }} dangerouslySetInnerHTML={{ __html: surveyData ? surveyData.Header : '' }} />
        </Col>
      </Row>
      {sortedQuestions.map((value) => {
        const choices = value.Choices.split(/\n/g)
        const questionTitle = value.Question.split(/\n/g)
        if (value.Type === 'Single Select--Vertical') {
          return (
            <Row key={value.Id}>
              <Col xs={24} sm={24} md={24} lg={24} xl={22} xxl={20} className={styles.questionContent}>
                <div className={styles.communicationLabel} style={{ color: '#4C4948', fontSize: 18 }}>
                  <span style={{ fontWeight: 500 }}>{value.OrderNumber + 1}.</span> <span>{questionTitle[0]}</span>
                  <span style={questionTitle[2] ? { display: 'block', fontSize: 16, paddingLeft: 16 } : { display: 'none' }}>{questionTitle[2]}</span>
                  <span style={{ display: 'block', height: 16 }} />
                </div>
                <Select
                  size='large'
                  placeholder={t('survey.questions.options.select')}
                  className={styles.questionSelect}
                  allowClear
                  style={{
                    borderRadius: 15,
                    backgroundColor: '#fff',
                    marginBottom: 20,
                    marginLeft: 16
                  }}
                  onChange={(answer) => handleOnChange(value.Id, answer)}
                  dropdownClassName={env.countryClass}>
                  {choices.map((val, i) => {
                    return (
                      <Option key={i} value={val}>
                        {val}
                      </Option>
                    )
                  })}
                </Select>
              </Col>
            </Row>
          )
        } else {
          return (
            <Row key={value.Id}>
              <Col xs={24} sm={24} md={24} lg={24} xl={22} xxl={20} className={styles.questionContent}>
                <p className={styles.communicationLabel} style={{ color: '#4C4948', fontSize: 18 }}>
                  {value.OrderNumber + 1}. <span>{questionTitle[0]}</span>
                  <span style={questionTitle[2] ? { display: 'block', fontSize: 16, marginLeft: '16px' } : { display: 'none' }}>{questionTitle[2]}</span>
                  <span style={questionTitle[2] ? { display: 'block', height: 16 } : { display: 'bloc' }} />
                </p>
                <div style={{ display: 'flex' }}>
                  <div style={{ width: 16, minWidth: 16 }} />
                  <div>
                    {choices.map((val, i) => {
                      return (
                        <Checkbox key={i} className={`${env.countryClass}`} onChange={() => handleCheckBox(value.Id, val)}>
                          {val}
                        </Checkbox>
                      )
                    })}
                  </div>
                </div>
              </Col>
            </Row>
          )
        }
      })}
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={22} xxl={20}>
          <p
            className={styles.description}
            style={{ color: '#4C4948', paddingTop: 16 }}
            dangerouslySetInnerHTML={{ __html: surveyData ? surveyData.Footer : '' }}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={22} xxl={20}>
          <Form form={form} className={styles.buttonForm}>
            <Form.Item shouldUpdate>
              {() => (
                <Spin indicator={icon} spinning={loaded}>
                  <Button
                    htmlType='submit'
                    className={styles.button}
                    onClick={() => handleClick(surveyData.Taker)}
                    style={
                      answers.length !== surveyData.Questions.length
                        ? {
                            backgroundColor: '#ccc',
                            color: '#fff',
                            marginLeft: 16
                          }
                        : {
                            backgroundColor: env.colors.primaryColor,
                            color: '#fff',
                            marginLeft: 16
                          }
                    }
                    disabled={answers.length !== surveyData.Questions.length}>
                    {t('survey.questions.button')}
                  </Button>
                </Spin>
              )}
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Col>
  )
}

export default QuestionForm
