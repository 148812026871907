import React, { useState, useEffect } from 'react'
import { Layout, Row, Col, Form, Input, Button, message, notification } from 'antd'
import { useTranslation } from 'react-i18next'
import styles from './Login.module.css'
import { LockOutlined, UserOutlined } from '@ant-design/icons'
import Wrapper from './../LayoutWrapper'
import { Link } from 'react-router-dom'
import axios from 'axios'
import cryptoBrowserify from 'crypto-browserify'
import { getCfg } from '../../env.config'
import * as qs from 'query-string'
import NotAllowed from '../NotAllowed'
import { logAction, handleMuleSoft, getRecoveryToken, sendEmail } from '../ApiCall'
import './index.css'
import { Helmet } from 'react-helmet-async'
// import { useHistory } from 'react-router-dom'

/* global localStorage */
const Login = () => {
  const { t } = useTranslation()
  const { Content } = Layout
  const [form] = Form.useForm()
  const [, forceUpdate] = useState()
  const queryString = qs.parse(window.location.search)
  // const history = useHistory()
  useEffect(() => {
    const address = `https://${window.location.href.split('/')[2]}`
    const env = getCfg(address)
    const queryString = qs.parse(window.location.search)
    if (queryString.culture === undefined && queryString.code === undefined) {
      localStorage.setItem('i18nextLng', `${env.supported_cultures[0]}`)
      window.location.href = window.location.pathname + '?culture=' + env.supported_cultures[0]
    } else if (queryString.culture === 'hr' && queryString.code === undefined) {
      localStorage.setItem('i18nextLng', 'hr-HR')
      window.location.href = window.location.pathname + '?culture=hr-HR'
    }
  }, [])

  useEffect(() => {
    forceUpdate({})
  }, [])
  // state for user credentials
  const [credentials, setCredentials] = useState({
    username: '',
    password: ''
  })

  // const loginContextVal = React.useContext(LoginContext)
  const address = `https://${window.location.href.split('/')[2]}`
  const env = getCfg(address)

  useEffect(() => {
    const queryString = qs.parse(window.location.search)
    const _CODE = queryString.code
    if (_CODE) {
      const address = `https://${window.location.href.split('/')[2]}`
      const env = getCfg(address)
      const key = 'updatable'
      message.loading({ content: 'Login...', key })
      const config = {
        params: {
          redirect_uri: env.loginRedirect,
          code_verifier: `${localStorage.getItem('pkce_code_verifier')}`,
          grant_type: env.grantType,
          client_id: env.oktaClientId,
          code: `${_CODE}`
        },
        headers: {
          client_id: env.loginClientId,
          client_secret: env.loginClientSecret
        }
      }
      const supportedCulture = () => {
        if (localStorage.getItem('i18nextLng') !== '') {
          if (env.supported_cultures.includes(localStorage.getItem('i18nextLng'))) return localStorage.getItem('i18nextLng')
          return env.supported_cultures[0]
        }
        return env.supported_cultures[0]
      }

      axios
        .post(`${env.loginRoot}/v1/oauth2/token`, null, config)
        .then(function (response) {
          localStorage.setItem('token', response.data.id_token)
          localStorage.removeItem('pkce_code_verifier')
          // log user login activity here
          const payload = {
            LogTitle: 'Account Last Login',
            username__c: localStorage.getItem('username'),
            country__c: env.country,
            account_last_login__c: new Date().toISOString()
          }
          logAction('user/log', payload, {}, () => {
            localStorage.removeItem('username')
            window.location.href = address + `/?culture=${supportedCulture()}`
          })
          // history.push(`/?culture=${supportedCulture()}`)
        })
        .catch(function (error) {
          openNotification(key, t('login.incorrect'), 'top', 'error', false, '')
          console.log(error.response)
        })
    }
    // eslint-disable-next-line
  }, [t, credentials.username])

  const base64URLEncode = (str) => {
    return str.toString('base64').replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '')
  }

  const sha256 = (buffer) => {
    return cryptoBrowserify.createHash('sha256').update(buffer).digest()
  }

  const handleOnChange = (event) => {
    event.persist()
    setCredentials({
      ...credentials,
      [event.target.name]: event.target.value
    })
  }
  // custom notification
  const openNotification = (key, description, placement, type, showBtn, email, data, loadingBtn) => {
    const btn = (
      <Button
        type='primary'
        loading={loadingBtn}
        size='small'
        style={{ padding: 14, display: 'flex', alignItems: 'center', paddingLeft: loadingBtn ? 24 : 15 }}
        onClick={() => {
          // update notification with loading btn
          openNotification(key, t('login.mulesoft.validationExpired'), 'top', 'error', true, credentials.username, data, true)
          const details = {
            userEmail: email,
            firstName: data._embedded.user.profile.firstName,
            lastName: data._embedded.user.profile.lastName,
            country: country,
            language: language,
            recoveryToken: data.recoveryToken
          }
          const headers = {
            client_id: env.sendMailClientID,
            client_secret: env.sendMailClientSecret,
            'Content-Type': 'application/json',
            'account-name': 'takeda-entyvio'
          }
          sendEmail(env.sendRoot, details, headers).then(() => {
            openNotification(key, t('login.mulesoft.emailValidationSent'), 'top', 'success', false, credentials.username)
          }).catch(() => { openNotification(key, t('login.mulesoft.somethingWentWrong'), 'top', 'error', false, credentials.username) })
        }}
      >
        {t('login.mulesoft.validateEmailBtn')}
      </Button>
    )
    notification[type]({
      description,
      btn: showBtn && btn,
      placement,
      key,
      duration: 0
    })
  }

  let language
  let country
  if (queryString.culture !== undefined) {
    language = queryString.culture.split('-')[0]
    country = queryString.culture.split('-')[1]
  } else {
    language = env.language
    country = env.country
  }
  // axios post to outh
  const axiosPost = (key) => {
    const headersMule = {
      'x-country': queryString.culture.split('-')[1],
      client_id: env.mulesoft.clientID,
      client_secret: env.mulesoft.clientSecret
    }
    // first make an api call to mulesoft
    handleMuleSoft(env.mulesoft.baseURI, credentials.username, headersMule).then(async (res) => {
      // scenario 1
      if (!res.Registered && !res.Enrolled) {
        // destroy old message box
        message.destroy(key)
        // show new notification box
        openNotification(key, t('login.mulesoft.unknown'), 'top', 'error', false, credentials.username)
      } else if (!res.Registered && res.Enrolled) {
        // scenario 2
        // destroy old message box
        message.destroy(key)
        openNotification(key, t('login.mulesoft.registerAgain'), 'top', 'error', false, credentials.username)
      } else if (res.Registered && !res.Enrolled && !res.Validated) {
        // scenario 4
        // destroy old message box
        message.destroy(key)
        openNotification(key, t('login.mulesoft.registerAgain'), 'top', 'error', false, credentials.username)
      } else if (res.Registered && !res.Enrolled && res.Validated) {
        // scenario 5
        // destroy old message box
        message.destroy(key)
        openNotification(key, t('login.mulesoft.registerAgain'), 'top', 'error', false, credentials.username)
      } else if (res.Registered && res.Enrolled && !res.Validated) {
        // scenario 6
        const headers = {
          client_id: env.loginClientId,
          client_secret: env.loginClientSecret,
          'Content-Type': 'application/json'
        }
        // call to get recovery token
        const data = await getRecoveryToken(env.recoveryPasswordRoot, credentials.username, headers)
        // destroy old message box
        message.destroy(key)
        if (data) {
          openNotification(key, t('login.mulesoft.validationExpired'), 'top', 'error', true, credentials.username, data, false)
        }
      } else {
        // call to okta api
        axios
          .post(
            `${env.loginRoot}/v1/user/authenticate`,
            {
              username: credentials.username, // credentials.username,
              password: credentials.password, // credentials.password,
              relayState: env.loginRedirect
            },
            {
              headers: {
                // 'Content-Type': 'application/json',
                client_id: env.loginClientId,
                client_secret: env.loginClientSecret
              }
            }
          )
          .then((response) => {
            localStorage.setItem('username', credentials.username)
            setCredentials({
              username: '',
              password: ''
            })
            form.resetFields()
            const codeVerifier = base64URLEncode(cryptoBrowserify.randomBytes(32))
            localStorage.setItem('pkce_code_verifier', codeVerifier)
            const codeChallenge = base64URLEncode(sha256(codeVerifier))
            window.location.href = `${env.redirectRoot}?client_id=${env.oktaClientId}&response_type=code&scope=openid&sessionToken=${response.data.sessionToken}&redirect_uri=${env.loginRedirect}&state=${env.state}&nonce=${env.nonce}&code_challenge=${codeChallenge}&code_challenge_method=S256`
          })
          .catch(() => {
            message.destroy(key)
            openNotification(key, t('login.mulesoft.tryAgain'), 'top', 'error', false, credentials.username)
          })
      }
    }).catch(() => {
      openNotification(key, t('login.mulesoft.somethingWentWrong'), 'top', 'error', false, credentials.username)
    })
  }

  const handleSubmit = () => {
    const key = 'updatable'
    notification.destroy(key)
    message.loading({ content: 'Loading...', key, duration: 0 })
    axios
      .get(env.activeSession, { withCredentials: true })
      .then((response) => {
        if (response.data.id) {
          axios
            .delete(env.activeSession, { withCredentials: true })
            .then((response) => {
              axiosPost(key)
            })
            .catch((error) => console.log(error.response))
        } else {
          axiosPost(key)
        }
      })
      .catch((error) => {
        console.log('error:', error.response)
        axiosPost(key)
      })
  }
  const handleSelect = (option) => {
    localStorage.setItem('i18nextLng', option)
    window.location.href = `${window.location.pathname}?culture=${option}`
  }
  const supportedCulture = () => {
    if (localStorage.getItem('i18nextLng') !== '') {
      if (env.supported_cultures.includes(localStorage.getItem('i18nextLng'))) return localStorage.getItem('i18nextLng')
      return env.supported_cultures[0]
    }
    return env.supported_cultures[0]
  }
  if (!env.supported_cultures.includes(queryString.culture) && queryString.culture !== undefined) return <NotAllowed env={env} />
  if (!window.location.search.includes('culture')) {
    return (
      <Layout
        style={{
          minHeight: '100%',
          display: 'flex',
          justifyContent: 'center'
        }}
      />
    )
  }
  return (
    <>
     <Helmet>
        <title>{`${t('header.1')}  ${t('header.2')} ${t('header.3')}`}</title>
        <meta name='description' content={`${t('login.text.1')}`} />
      </Helmet>
      <Layout>
        <Wrapper>
          <Content>
            <Row type='flex' align='bottom' justify='center' className={env.countryClass}>
              <Col span={20}>
                {env.countryClass === 'canada' ? (
                  <Row gutter={24} type='flex' align='middle' className={styles.header}>
                    <Col span={24}>
                      <img src='/images/CA/onepath@2x.png' className={styles.onepath} alt='Onepath' />
                    </Col>
                    <Col span={24} className={styles.mobileSelectorRow}>
                      <div className={styles.mobileLanguageSelector} style={!env.landing.selector ? { display: 'none' } : null}>
                        <div
                          className={styles.languageButton}
                          style={
                            localStorage.getItem('i18nextLng') === env.landing.languages.one.culture
                              ? {
                                borderColor: env.colors.primaryColor,
                                backgroundColor: env.colors.primaryColor,
                                color: '#fff'
                              }
                              : { borderColor: env.colors.primaryColor }
                          }
                          onClick={() => handleSelect(env.landing.languages.one.culture)}
                        >
                          {env.landing.languages.one.code}
                        </div>
                        <div className={styles.buttonDivider} style={{ backgroundColor: env.colors.primaryColor }} />
                        <div
                          className={styles.languageButton}
                          style={
                            localStorage.getItem('i18nextLng') === env.landing.languages.two.culture
                              ? {
                                borderColor: env.colors.primaryColor,
                                backgroundColor: env.colors.primaryColor,
                                color: '#fff'
                              }
                              : { borderColor: env.colors.primaryColor }
                          }
                          onClick={() => handleSelect(env.landing.languages.two.culture)}
                        >
                          {env.landing.languages.two.code}
                        </div>
                      </div>
                    </Col>
                  </Row>
                ) : (
                  <Row gutter={24} type='flex' align='middle' className={styles.header}>
                    <Col xs={24} sm={4} md={3} lg={2} xl={2} xxl={2}>
                      <img src='/images/fywy-app-icon-1024x2.png' className={styles.takedaImg} alt='fywy' />
                    </Col>
                    <Col xs={24} sm={20} md={21} lg={22} xl={22} xxl={22}>
                      <h1 className={styles.heading}>
                        <span>{t('header.1')},</span> <span>{t('header.2')}</span>
                      </h1>
                      <h3 className={styles.subheading}>{t('header.3')}</h3>
                    </Col>
                    <Col span={24} className={styles.mobileSelectorRow}>
                      <div className={styles.mobileLanguageSelector} style={!env.landing.selector ? { display: 'none' } : null}>
                        <div
                          className={styles.languageButton}
                          style={
                            localStorage.getItem('i18nextLng') === env.landing.languages.one.culture
                              ? {
                                borderColor: env.colors.primaryColor,
                                backgroundColor: env.colors.primaryColor,
                                color: '#fff'
                              }
                              : { borderColor: env.colors.primaryColor }
                          }
                          onClick={() => handleSelect(env.landing.languages.one.culture)}
                        >
                          {env.landing.languages.one.code}
                        </div>
                        <div className={styles.buttonDivider} style={{ backgroundColor: env.colors.primaryColor }} />
                        <div
                          className={styles.languageButton}
                          style={
                            localStorage.getItem('i18nextLng') === env.landing.languages.two.culture
                              ? {
                                borderColor: env.colors.primaryColor,
                                backgroundColor: env.colors.primaryColor,
                                color: '#fff'
                              }
                              : { borderColor: env.colors.primaryColor }
                          }
                          onClick={() => handleSelect(env.landing.languages.two.culture)}
                        >
                          {env.landing.languages.two.code}
                        </div>
                      </div>
                    </Col>
                  </Row>
                )}

                <Row className={styles.mobile}>
                  {env.countryClass === 'canada' ? (
                    <Col span={14}>
                      <Row align='middle' style={{ paddingBottom: 20 }}>
                        <Col span={24}>
                          <img src='/images/CA/onepath@2x.png' className={styles.onepath} alt='Onepath' />
                        </Col>
                      </Row>
                    </Col>
                  ) : (
                    <Col span={14}>
                      <Row align='middle' style={{ paddingBottom: 20 }}>
                        <Col span={8}>
                          <img src='/images/fywy-app-icon-1024x2.png' alt='takeda logo' style={{ width: '40px' }} />
                        </Col>
                        <Col span={16}>
                          <h1 className={styles.heading}>
                            <p>{t('header.1')},</p>
                            <p>{t('header.2')}</p>
                          </h1>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <h3 className={styles.msubheading}>{t('header.3')}</h3>
                        </Col>
                      </Row>
                    </Col>
                  )}
                  <Col span={24} className={styles.mobileSelectorRow}>
                    <div className={styles.mobileLanguageSelector} style={!env.landing.selector ? { display: 'none' } : null}>
                      <div
                        className={styles.languageButton}
                        style={
                          localStorage.getItem('i18nextLng') === env.landing.languages.one.culture
                            ? {
                              borderColor: env.colors.primaryColor,
                              backgroundColor: env.colors.primaryColor,
                              color: '#fff'
                            }
                            : { borderColor: env.colors.primaryColor }
                        }
                        onClick={() => handleSelect(env.landing.languages.one.culture)}
                      >
                        {env.landing.languages.one.code}
                      </div>
                      <div className={styles.buttonDivider} style={{ backgroundColor: env.colors.primaryColor }} />
                      <div
                        className={styles.languageButton}
                        style={
                          localStorage.getItem('i18nextLng') === env.landing.languages.two.culture
                            ? {
                              borderColor: env.colors.primaryColor,
                              backgroundColor: env.colors.primaryColor,
                              color: '#fff'
                            }
                            : { borderColor: env.colors.primaryColor }
                        }
                        onClick={() => handleSelect(env.landing.languages.two.culture)}
                      >
                        {env.landing.languages.two.code}
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <div className={styles.languageSelector} style={!env.landing.selector ? { display: 'none' } : null}>
                      <div
                        className={styles.languageButton}
                        style={
                          localStorage.getItem('i18nextLng') === env.landing.languages.one.culture
                            ? {
                              borderColor: env.colors.primaryColor,
                              backgroundColor: env.colors.primaryColor,
                              color: '#fff'
                            }
                            : { borderColor: env.colors.primaryColor }
                        }
                        onClick={() => handleSelect(env.landing.languages.one.culture)}
                      >
                        {env.landing.languages.one.code}
                      </div>
                      <div className={styles.buttonDivider} style={{ backgroundColor: env.colors.primaryColor }} />
                      <div
                        className={styles.languageButton}
                        style={
                          localStorage.getItem('i18nextLng') === env.landing.languages.two.culture
                            ? {
                              borderColor: env.colors.primaryColor,
                              backgroundColor: env.colors.primaryColor,
                              color: '#fff'
                            }
                            : { borderColor: env.colors.primaryColor }
                        }
                        onClick={() => handleSelect(env.landing.languages.two.culture)}
                      >
                        {env.landing.languages.two.code}
                      </div>
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={14} xl={16} xxl={16}>
                    <Row>
                      <Col xs={24} sm={24} md={24} lg={20} xl={17} xxl={17} className={styles.text}>
                        <p>{t('login.text.1')}</p>
                        <p>{t('login.text.2')}</p>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24} sm={17} md={15} lg={10} xl={8} xxl={8} className={styles.form}>
                    <Form form={form} className={env.countryClass}>
                      <Form.Item
                        name='username'
                        rules={[
                          {
                            required: true,
                            message: t('errors.v_email_required')
                          },
                          {
                            type: 'email',
                            message: t('errors.v_email_type')
                          },
                          {
                            max: 80,
                            message: t('errors.v_email_maxlength')
                          }
                        ]}
                        className={styles.username}
                      >
                        <Input
                          value={credentials.username}
                          name='username'
                          onChange={handleOnChange}
                          className={styles.inputs}
                          style={{ borderColor: env.colors.borderColor }}
                          allowClear
                          prefix={<UserOutlined className={styles.icon} style={{ color: env.colors.iconColor }} />}
                          placeholder={t('login.form.username')}
                          autoComplete='off'
                        />
                      </Form.Item>
                      <Form.Item
                        name='password'
                        rules={[
                          {
                            required: true,
                            message: t('errors.v_password_required')
                          },
                          {
                            min: 8,
                            message: t('errors.v_password_minlength')
                          },
                          {
                            max: 80,
                            message: t('errors.v_password_maxlength')
                          }
                        ]}
                        className={styles.password}
                      >
                        <Input
                          value={credentials.password}
                          name='password'
                          onChange={handleOnChange}
                          className={styles.inputs}
                          allowClear
                          style={{ borderColor: env.colors.borderColor }}
                          prefix={<LockOutlined className={styles.icon} style={{ color: env.colors.iconColor }} />}
                          type='password'
                          placeholder={t('login.form.password')}
                          autoComplete='off'
                        />
                      </Form.Item>
                      <Form.Item shouldUpdate>
                        {() => (
                          <div>
                            <Button
                              htmlType='submit'
                              style={
                                form.isFieldsTouched(true) && !form.getFieldsError().filter(({ errors }) => errors.length).length
                                  ? {
                                    backgroundColor: env.colors.primaryColor,
                                    color: '#fff'
                                  }
                                  : { backgroundColor: 'rgba(0, 0, 0, 0.25)', color: '#fff' }
                              }
                              onClick={handleSubmit}
                              disabled={!form.isFieldsTouched(true) || form.getFieldsError().filter(({ errors }) => errors.length).length}
                            >
                              {t('login.form.button')}
                            </Button>
                            <div className={styles.links}>
                              <Link to={`/register?culture=${supportedCulture()}`}>
                                <span>{t('login.form.register')}</span>
                              </Link>
                              |
                              <Link to={`/forgot-password?culture=${supportedCulture()}`}>
                                <span>{t('login.form.forgot')}</span>
                              </Link>
                            </div>
                          </div>
                        )}
                      </Form.Item>
                    </Form>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Content>
        </Wrapper>
      </Layout>
    </>
  )
}

export default Login
