import React from 'react'
import { Row, Col, Progress } from 'antd'
import styles from './Enrolment.module.css'
import { useTranslation } from 'react-i18next'
import { PhoneOutlined, MailOutlined } from '@ant-design/icons'
// import { get } from '../ApiCall'
import { getCfg } from '../../env.config'

const Enrolment = ({ profile }) => {
  const { t } = useTranslation()
  const address = `https://${window.location.href.split('/')[2]}`
  const env = getCfg(address)

  const colors = ['orange', '#ebfa3e', '#ebfa3e', '#ebfa3e', 'green', 'red']

  const reimbursementNO = profile.data.Reimbursement_Status
  // const reimbursementNO = 4
  const fillPercent = [100, 25, 50, 75, 100, 100]
  return (
    <Row align='bottom' justify='left' className={styles.enrolRoot}>
      <Col xs={23} sm={24} md={24} lg={24} xl={24} xxl={22}>
        <div className={styles.text}>{t('enrolment.description')}</div>
      </Col>
      <Col xs={23} sm={24} md={24} lg={24} xl={22} xxl={21} className={styles.contentRoot}>
        <Row>
          <Col>
            <h3
              style={{
                color: env.colors.primaryColor
              }}>
              {t('enrolment.status')}
            </h3>
          </Col>
        </Row>
        <Row>
          <Col span={24} className={styles.progressBarDiv}>
            <div
              className={styles.progressText}
              style={
                reimbursementNO === 0 ? { display: 'none' } : reimbursementNO < 4 && reimbursementNO > 0 ? { right: '3%' } : { left: '3%', color: '#fff' }
              }>
              {reimbursementNO === 5 ? (
                <span>{t('enrolment.hold')}</span>
              ) : (
                <>
                  <span>{t('enrolment.step')}</span>
                  <span>{reimbursementNO}</span>
                  <span>{t('enrolment.points')}</span>
                </>
              )}
            </div>
            <Progress
              percent={fillPercent[reimbursementNO]}
              className={reimbursementNO > 0 && reimbursementNO < 4 ? 'left-bordered' : ''}
              showInfo={false}
              strokeColor={colors[reimbursementNO]}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>{t('enrolment.text.1')}</Col>
        </Row>
        <Row className={styles.contactDetails}>
          <Col span={24}>
            <PhoneOutlined style={{ color: '#000', marginRight: 15 }} /> <a href={`tel:${t('enrolment.contact.phone')}`}>{t('enrolment.contact.phone')}</a>
          </Col>
          <Col span={24}>
            <MailOutlined style={{ color: '#000', marginRight: 15 }} />
            <a href={`mailto:${t('enrolment.contact.mail')}`}>{t('enrolment.contact.mail')}</a>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default Enrolment
