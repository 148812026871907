import React, { useState, useEffect } from 'react'
import styles from './LandingPage.module.css'
import { Layout, Row, Col, Button, Modal } from 'antd'
import { Link } from 'react-router-dom'
import CookieDrawer from './../Cookies'
import { useTranslation } from 'react-i18next'
import { getCfg } from '../../env.config'
import * as qs from 'query-string'
import NotAllowed from '../NotAllowed'
import { ReactComponent as PlayIcon } from './play-icon.svg'
import { RightOutlined } from '@ant-design/icons'
import StandardPath from './path_1.svg'
import HRPath from './path_hr.svg'
import SecondPath from './path_2.svg'
import { Helmet } from 'react-helmet-async'

/* global localStorage */
const LandingPage = () => {
  const { t } = useTranslation()
  const { Content } = Layout
  const [open, setOpen] = useState(false)
  const [isVisible, setVisible] = useState(false)
  const [isVisiblePopup, setVisiblePopup] = useState(false)
  const address = `https://${window.location.href.split('/')[2]}`
  const env = getCfg(address)

  const [size, setSize] = useState(window.innerWidth)
  useEffect(() => {
    function handleResize () {
      setSize(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    const address = `https://${window.location.href.split('/')[2]}`
    const env = getCfg(address)
    const queryString = qs.parse(window.location.search)
    if (queryString.culture === undefined) {
      localStorage.setItem('i18nextLng', `${env.supported_cultures[0]}`)
      window.location.href = window.location.pathname + '?culture=' + env.supported_cultures[0]
    }
  }, [])

  const handleSelect = (option) => {
    localStorage.setItem('i18nextLng', option)
    window.location.href = `${window.location.pathname}?culture=${option}`
  }

  const search = window.location.search
  const queryString = qs.parse(search)
  const lang = queryString
    ? queryString.culture !== '' && queryString.culture !== undefined
      ? queryString.culture.split('-')[0]
      : localStorage.getItem('i18nextLng').split('-')[0]
    : localStorage.getItem('i18nextLng').split('-')[0]

  const image = env.landing.phone[lang]

  const videoImage = env.landing.video.image !== null ? env.landing.video.image[lang] : null

  const getCampaignLink = () => {
    if (lang === 'nl') return '/images/MAIN_NL.mp4'
    if (lang === 'fr') return '/images/MAIN_FR.mp4'
  }
  const getCampaignLinkPopup = () => {
    if (lang === 'nl') return 'https://players.brightcove.net/2299575749001/default_default/index.html?videoId=6332177132112&autoplay'
    if (lang === 'fr') return 'https://players.brightcove.net/2299575749001/default_default/index.html?videoId=6332174698112&autoplay'
  }

  const getVideoLink = () => {
    if (lang === 'nl') return 'https://players.brightcove.net/2299575749001/default_default/index.html?videoId=6248503821001'
    if (lang === 'fr') return 'https://players.brightcove.net/2299575749001/default_default/index.html?videoId=6248502751001'
    if (lang === 'hr') return 'https://players.brightcove.net/2299575749001/default_default/index.html?videoId=6258929812001'
    if (lang === 'en') return 'https://players.brightcove.net/2299575749001/mk5yNoQvbK_default/index.html?videoId=6272824921001'
    if (lang === 'sr') return 'https://players.brightcove.net/2299575749001/mk5yNoQvbK_default/index.html?videoId=6299880711001'
    return 'https://players.brightcove.net/2299575749001/default_default/index.html?videoId=6248502751001'
  }

  if (!env.supported_cultures.includes(queryString.culture) && queryString.culture !== undefined) return <NotAllowed env={env} />
  if (!window.location.search.includes('culture')) {
    return (
      <Layout
        style={{
          minHeight: '100%',
          display: 'flex',
          justifyContent: 'center'
        }}
      />
    )
  }

  const getSideNavNoVideo = () => {
    return (
      <Col xs={24} sm={24} md={11} lg={9} xl={8} xxl={7} className={styles.mobilehidden}>
        <Row>
          <Col span={24} className={styles.sideNav} style={{ backgroundColor: env.colors.menuColor }}>
            <Row>
              <Col className={styles.title}>
                <span>{t('landing.sidenav.1')}</span>
                <span>
                  {t('landing.sidenav.2')}
                  <b>{t('landing.sidenav.3')}</b>
                  {t('landing.sidenav.4') !== null ? t('landing.sidenav.4') : ''}
                  <b>{t('landing.sidenav.5') !== null ? t('landing.sidenav.5') : ''}</b>
                </span>
              </Col>
            </Row>
            <Row>
              <Col className={styles.applestore}>
                <a href={env.landing.appleStore} target='_blank' rel='noopener noreferrer'>
                  <img src='/images/app-store.png' alt='' style={env.landing.button ? { height: 43 } : null} />
                </a>
              </Col>
            </Row>
            <Row>
              <Col className={styles.googleplay}>
                <a href={env.landing.googlePlay} target='_blank' rel='noopener noreferrer'>
                  <img src='/images/google-play.png' alt='' style={env.landing.button ? { height: 43 } : null} />
                </a>
              </Col>
            </Row>
            <Row style={!env.landing.button ? { display: 'none' } : null}>
              <Col span={22} xs={23} sm={23} className={styles.websiteCol}>
                <Link to='/'>
                  <Button
                    className={styles.button}
                    style={{
                      color: '#fff'
                    }}
                    type='primary'>
                    {t('landing.sidenav.button')}
                  </Button>
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row style={t('landing.address') === null ? { display: 'none' } : null}>
          <Col span={24} className={styles.addressColumn}>
            <div>{t('landing.address.1')}</div>
            <div>{t('landing.address.2')}</div>
            <div>{t('landing.address.3')}</div>
            <div>{t('landing.address.4')}</div>
          </Col>
        </Row>
      </Col>
    )
  }

  const getSideNavVideo = () => {
    return (
      <Col xs={24} sm={24} md={11} lg={9} xl={8} xxl={7} className={styles.mobilehidden}>
        <Row>
          <Col span={24} className={styles.videoRoot}>
            {
              env.country === 'BE' ? (
                <Row className={styles.videoRow}>
                  <Col span={24} className={styles.snippet} onClick={() => setVisiblePopup(true)} style={{ display:'flex', padding:0 }}>
                    {/* <img src={()} alt='thumbnail' /> */}
                    <video autoPlay muted loop style={{width:'100%', height: '100%'}}>
                      <source src={getCampaignLink()} type='video/mp4' />
                    </video>
                    <PlayIcon className={styles.play} />
                  </Col>
                </Row>
              ) : (
                <Row className={styles.videoRow}>
                  <Col span={11} className={styles.snippet} onClick={() => setVisible(true)}>
                    <img src={videoImage !== undefined ? videoImage : env.landing.video.image.en} alt='thumbnail' />
                    <PlayIcon className={styles.play} />
                  </Col>
                  <Col span={13} className={styles.videoTitle}>
                    <p style={env.landing.bolderVideoText ? { fontWeight: 'bold' } : null}>
                      {t('landing.sidenav.video.title.1')}
                      <br /> {t('landing.sidenav.video.title.2')}
                    </p>
                    <p onClick={() => setVisible(true)}>{t('landing.sidenav.video.text')}</p>
                  </Col>
                </Row>)
            }
          </Col>
          <Col span={24} className={styles.videoSideNav} style={{ backgroundColor: env.colors.menuColor }}>
            <Row>
              <Col className={styles.title}>
                <span style={env.landing.buttonBlock ? { fontSize: 21 } : null}>
                  {t('landing.sidenav.1')} {t('landing.sidenav.2')}
                  <b>{t('landing.sidenav.3')}</b>
                  {t('landing.sidenav.4') !== null ? t('landing.sidenav.4') : ''}
                  <b>{t('landing.sidenav.5') !== null ? t('landing.sidenav.5') : ''}</b>
                </span>
              </Col>
            </Row>
            <Row style={env.landing.buttonBlock ? { flexDirection: 'column' } : null}>
              <Col
                span={env.landing.buttonBlock ? 24 : 12}
                className={styles.applestore}
                style={env.landing.buttonBlock ? { paddingRight: 0, marginBottom: 20 } : null}>
                <a href={env.landing.appleStore} target='_blank' rel='noopener noreferrer'>
                  <img src='/images/app-store.png' alt='' style={env.landing.buttonBlock ? { height: 58, width: 'auto' } : null} />
                </a>
              </Col>
              <Col span={env.landing.buttonBlock ? 24 : 12} className={styles.googleplay} style={env.landing.buttonBlock ? { paddingLeft: 0 } : null}>
                <a href={env.landing.googlePlay} target='_blank' rel='noopener noreferrer'>
                  <img src='/images/google-play.png' alt='' style={env.landing.buttonBlock ? { height: 58, width: 'auto' } : null} />
                </a>
              </Col>
            </Row>
            <Row style={!env.landing.button ? { display: 'none' } : null}>
              <Col span={24} className={styles.websiteCol}>
                <Link to='/'>
                  <Button
                    className={styles.button}
                    style={{
                      color: '#fff'
                    }}
                    type='primary'>
                    {t('landing.sidenav.button')}
                  </Button>
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row style={t('landing.address') === null ? { display: 'none' } : null}>
          <Col span={24} className={styles.addressColumn}>
            <div>{t('landing.address.1')}</div>
            <div>{t('landing.address.2')}</div>
            <div>{t('landing.address.3')}</div>
            <div>{t('landing.address.4')}</div>
          </Col>
        </Row>
      </Col>
    )
  }

  const Iframe = (props) => {
    return <div style={{ height: '100%' }} dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : '' }} />
  }
  const iframe = `<iframe width="100%" height="100%" style="background: #FFFFFF;" frameborder="0" src="${getVideoLink()}"></iframe>`
  const iframeCampaign = `<iframe allow="autoplay" width="100%" height="100%" style="background: #FFFFFF;" frameborder="0" src="${getCampaignLinkPopup()}"></iframe>`

  const modalWidth = () => {
    if (size < 576) return '92vw'
    if (size > 576 && size < 1024) return '82vw'
    if (size > 1024 && size < 1366) return '65vw'
    return '65vw'
  }

  const modalHeight = () => {
    if (size < 576) return '57vw'
    if (size > 576 && size < 1024) return '49vw'
    if (size > 1024 && size < 1366) return '38.8vw'
    return '38.1vw'
  }

  const _language = queryString.culture.split('-')[0]

  const getTitle = () => {
    if (_language === 'hr') return t('landing.title.2').trim() + ' ' + t('landing.title.3').trim()
    return t('landing.title.1').trim() + ' ' + t('landing.title.2').trim() + ' ' + t('landing.title.3').trim()
  }

  return (
    <>
      <Helmet>
        <title>{`${t('landing.title.1')}  ${t('landing.title.2')} ${t('landing.title.3')}`}</title>
        <meta name='description' content={`${t('landing.introduction.1')}`} />
        <link rel='alternate' href={env.landing.appleStore} hreflang={queryString.culture.toLowerCase()} />
        <link rel='alternate' href={env.landing.googlePlay} hreflang={queryString.culture.toLowerCase()} />
        <link rel='alternate' href={window.location.origin} hreflang={queryString.culture.toLowerCase()} />
        <link rel='alternate' href={`${env.landing.hcpLink}${queryString.culture}`} hreflang={queryString.culture.toLowerCase()} />
        <link rel='alternate' href={env.footer.privacy} hreflang={queryString.culture.toLowerCase()} />
        <link rel='alternate' href={env.footer.terms} hreflang={queryString.culture.toLowerCase()} />
      </Helmet>
      <Layout>
        <Content className={`${styles.root} ${env.countryClass}`}>
          <Row style={{ display: 'block' }}>
            <Col span={24} className={`${styles.header}`} style={{ position: 'relative' }}>
              <img src={env.landing.background} className={`${styles.banner} ${styles.mobilehidden}`} alt={t('landing.altTags.header')} />
              <img src={env.landing.mobileBackground} className={`${styles.banner} ${styles.tabletHidden}`} alt={t('landing.altTags.header')} />
              <img
                src={env.landing.logo}
                className={`${styles.bannerLogo} `}
                alt={t('landing.altTags.logo')}
                style={env.countryClass === 'canada' ? { maxHeight: 56, height: '4vw' } : env.landing.bigLogo ? { maxHeight: '9vw', height: '100%' } : null}
              />
              {/* tablet hide */}
              <img
                src={_language === 'hr' || _language === 'hu' ? HRPath : StandardPath}
                alt='.'
                className={styles.tabletHidden}
                style={{ width: '100%', position: 'absolute', left: 0, bottom: '-11.5vw' }}
              />
              <img className={styles.tabletHidden} src={SecondPath} alt='.' style={{ width: '100%', position: 'absolute', left: 0, bottom: '-8vw' }} />

              <h1>
                {/* desktop */}
                <span
                  className={`${styles.imageText} ${styles.mobilehidden}`}
                  style={env.countryClass === 'canada' ? { color: env.colors.headerLandingText, top: '15%' } : { color: env.colors.headerLandingText }}
                >
                  <span style={env.landing.bolderTitle ? { fontWeight: 'bold', display: 'block' } : null}>{t('landing.title.1')}</span>
                  <span style={{ display: 'block' }}>{t('landing.title.2')}</span>
                  <span style={{ display: 'block' }}>{t('landing.title.3')}</span>
                </span>
                <span
                  className={`${styles.tabletHidden} ${_language === 'lt' || _language === 'lv' ? styles.customStyle : styles.imageText}`}
                  style={
                    _language === 'lt' || _language === 'lv'
                      ? { width: '62vw', color: env.colors.headerLandingText }
                      : _language === 'hr'
                        ? { fontWeight: 'normal', color: env.colors.headerLandingText }
                        : env.countryClass === 'canada'
                          ? { color: env.colors.headerLandingText, top: '15%' }
                          : { color: env.colors.headerLandingText }
                  }
                >
                  <span style={_language !== 'hr' ? { display: 'none' } : env.landing.bolderTitle ? { fontWeight: 'bold' } : { fontWeight: 500 }}>
                    {t('landing.title.1')}
                  </span>
                  <span>{getTitle()}</span>
                </span>
              </h1>
              <div className={styles.huText} style={t('landing.headerText') === null ? { display: 'none' } : null}>
                {t('landing.headerText')}
              </div>
            </Col>
          </Row>
          <Row type='flex' justify='center' className={!env.landing.selector ? styles.customContainer : styles.container}>
            <Col xs={19} sm={20} md={22} lg={21} xl={20} xxl={18}>
              <Row justify='end' className={styles.languageSelectorRow} style={!env.landing.selector ? { display: 'none' } : null}>
                <Col className={styles.languageSelectorCol}>
                  <div
                    className={styles.languageButton}
                    style={
                      localStorage.getItem('i18nextLng') === env.landing.languages.one.culture
                        ? {
                          borderColor: env.colors.primaryColor,
                          backgroundColor: env.colors.primaryColor,
                          color: '#fff'
                        }
                        : {
                          borderColor: env.colors.primaryColor
                        }
                    }
                    onClick={() => handleSelect(env.landing.languages.one.culture)}>
                    {env.landing.languages.one.code}
                  </div>
                  <div className={styles.buttonDivider} style={{ backgroundColor: env.colors.primaryColor }} />
                  <div
                    className={styles.languageButton}
                    style={
                      localStorage.getItem('i18nextLng') === env.landing.languages.two.culture
                        ? {
                          borderColor: env.colors.primaryColor,
                          backgroundColor: env.colors.primaryColor,
                          color: '#fff'
                        }
                        : {
                          borderColor: env.colors.primaryColor
                        }
                    }
                    onClick={() => handleSelect(env.landing.languages.two.culture)}>
                    {env.landing.languages.two.code}
                  </div>
                </Col>
              </Row>
              <Row
                gutter={24}
                justify='center'
                className={styles.row}
                style={
                  t('landing.address') !== null || size <= 768 ? { marginBottom: 0, marginLeft: -24, marginRight: -24 } : { marginLeft: -24, marginRight: -24 }
                }>
                <Col xs={24} sm={24} md={size === 768 ? 22 : 13} lg={15} xl={16} xxl={17} className={styles.column}>
                  <Row>
                    <Col className={styles.paragraph}>
                      <p>{t('landing.introduction.1')}</p>
                      <p>{t('landing.introduction.2')}</p>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={7} xl={6} xxl={5} className={styles.imageColumn}>
                      <img src={image !== undefined ? image : env.landing.phone.en} alt={t('landing.altTags.mobileImage')} style={{ maxWidth: 162 }} />
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={17} xl={18} xxl={19} className={styles.list}>
                      <Row type='flex' align='middle' className={styles.item}>
                        <Col span={3} xs={4} sm={3} lg={4} xl={3} className={styles.colitem}>
                          <img src={env.landing.icons.appointment} alt={t('landing.altTags.categories.calendar')} />
                        </Col>
                        <Col span={19}>
                          <h2 className={styles.item}>
                            <b>{t('landing.options.1.bold')}</b>
                            {t('landing.options.1.normal')}
                          </h2>
                        </Col>
                      </Row>
                      {
                        _language !== 'hr' ? (
                          <Row type='flex' align='middle' className={styles.item} style={env.landing.icons.disk === null ? { display: 'none' } : null}>
                            <Col span={3} xs={4} sm={3} lg={4} xl={3} className={styles.colitem}>
                              <img src={env.landing.icons.disk} alt={t('landing.altTags.categories.wheel')} />
                            </Col>
                            <Col span={19}>
                              <h2 className={styles.item}>
                                <b>{t('landing.options.2.bold')}</b>
                                {t('landing.options.2.normal')}
                              </h2>
                            </Col>
                          </Row>
                        ) : null
                      }
                      <Row type='flex' align='middle' className={styles.item}>
                        <Col span={3} xs={4} sm={3} lg={4} xl={3} className={styles.colitem}>
                          <img src={env.landing.icons.recipes} alt={t('landing.altTags.categories.chef')} className={styles.sIcon} />
                        </Col>
                        <Col span={19}>
                          <h2 className={styles.item}>
                            <b>{t('landing.options.3.bold')}</b>
                            {t('landing.options.3.normal')}
                          </h2>
                        </Col>
                      </Row>
                      <Row type='flex' align='middle' className={styles.item}>
                        <Col span={3} xs={4} sm={3} lg={4} xl={3} className={styles.colitem}>
                          <img src={env.landing.icons.map} alt={t('landing.altTags.categories.map')} />
                        </Col>
                        <Col span={19}>
                          <h2 className={styles.item}>
                            <b>{t('landing.options.4.bold')}</b>
                            {t('landing.options.4.normal')}
                          </h2>
                        </Col>
                      </Row>
                      <Row type='flex' align='middle' className={styles.item}>
                        <Col span={3} xs={4} sm={3} lg={4} xl={3} className={styles.colitem}>
                          <img src={env.landing.icons.videos} alt={t('landing.altTags.categories.video')} className={styles.sIcon} />
                        </Col>
                        <Col span={19}>
                          <h2 className={styles.item}>
                            <b>{t('landing.options.5.bold')}</b>
                            {t('landing.options.5.normal')}
                          </h2>
                        </Col>
                      </Row>
                      {/* show extra list item for poland */}
                      {
                        _language === 'pl' ? (
                          <Row type='flex' align='middle' className={styles.item}>
                            <Col span={3} xs={4} sm={3} lg={4} xl={3} className={styles.colitem}>
                              <img src={env.landing.icons.clipboard} alt={t('landing.altTags.categories.video')} className={styles.plsIcon} />
                            </Col>
                            <Col span={19}>
                              <h2 className={styles.item}>
                                <b>{t('landing.options.6.bold')}</b>
                                {t('landing.options.6.normal')}
                              </h2>
                            </Col>
                          </Row>)
                          : null
                      }
                      {/* moved to bottom */}
                      {
                        env.country === 'BE' ? (
                          <Row type='flex' align='middle' className={styles.item}>
                            <Col span={24} style={{ paddingTop: 20 }} className={styles.videoRoot}>
                              <Row className={styles.videoRow}>
                                <Col span={6} className={`${styles.snippet} ${styles.videoMargin}`} onClick={() => setVisible(true)}>
                                  <img src={videoImage !== undefined ? videoImage : env.landing.video.image.en} alt='thumbnail' />
                                  <PlayIcon className={styles.play} />
                                </Col>
                                <Col span={13} className={styles.videoTitle}>
                                  <p style={env.landing.bolderVideoText ? { fontWeight: 'bold' } : null}>
                                    {t('landing.sidenav.video.title.1')}
                                    <br /> {t('landing.sidenav.video.title.2')}
                                  </p>
                                  <p onClick={() => setVisible(true)}>{t('landing.sidenav.video.text')}</p>
                                </Col>
                              </Row>
                            </Col>
                          </Row>) : null
                      }
                    </Col>
                  </Row>
                </Col>
                {env.landing.video.visible ? getSideNavVideo() : getSideNavNoVideo()}
              </Row>
              <Row
                gutter={24}
                className={styles.bottomContainer}
                type='flex'
                align='middle'
                style={!env.landing.disclaimer ? { display: 'none' } : t('landing.address') !== null ? { marginTop: 30 } : null}>
                <Col span={24}>
                  <p className={styles.disclaimer}>{t('landing.disclaimer')}</p>
                </Col>
              </Row>
              <Row
                gutter={24}
                className={styles.bottomContainer}
                type='flex'
                align='middle'
                style={!env.landing.hcpportal ? { display: 'none' } : t('landing.address') !== null ? { marginTop: 30 } : null}>
                <Col
                  className={styles.logo}
                  xs={24}
                  sm={24}
                  md={10}
                  lg={7}
                  xl={env.landing.hcpBolderText ? 6 : 7}
                  xxl={env.landing.hcpBolderText || env.landing.hcpButton ? 5 : 6}>
                  <img src={env.landing.logo} alt={t('landing.altTags.logo')} style={env.countryClass === 'canada' ? { height: 36 } : null} />
                  <div
                    style={
                      env.landing.hcpBolderText ? { color: env.colors.hcpLandingText, fontWeight: 600, maxWidth: 150 } : { color: env.colors.hcpLandingText }
                    }>
                    <p style={env.landing.hcpBolderText ? { lineHeight: '21px', fontSize: 20 } : null}>{t('landing.bottom.left.1')}</p>
                    <p style={env.landing.hcpBolderText ? { lineHeight: '21px', fontSize: 20 } : null}>{t('landing.bottom.left.2')}</p>
                  </div>
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={14}
                  lg={env.landing.hcpButton ? 10 : 12}
                  xl={env.landing.hcpBolderText ? 12 : 11}
                  xxl={env.landing.hcpButton ? 14 : env.landing.hcpBolderText ? 13 : 12}>
                  <p className={styles.text}>{t('landing.bottom.center')}</p>
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={env.landing.hcpButton ? 7 : 5}
                  xl={6}
                  xxl={env.landing.hcpButton ? 5 : 6}
                  className={env.landing.hcpButton ? styles.hcpButtonStyle : ''}>
                  {env.landing.hcpButton && (
                    <a
                      href={`${env.landing.hcpLink}${queryString.culture}`}
                      target='_blank'
                      rel='noopener noreferrer'
                      className={styles.link}
                      style={{
                        backgroundColor: env.colors.primaryColor,
                        color: '#fff',
                        paddingTop: 12,
                        paddingBottom: 12,
                        paddingLeft: 20,
                        paddingRight: 20,
                        borderRadius: 10,
                        boxShadow: '0px 2px 3px #00000029',
                        fontWeight: 'normal',
                        textAlign: 'left',
                        lineHeight: '26px',
                        display: 'flex',
                        alignItems: 'center',
                        maxWidth: 230
                      }}>
                      <span>{t('landing.bottom.right')}</span>
                      <RightOutlined style={{ marginLeft: 12 }} />
                    </a>
                  )}
                  <p
                    className={styles.link}
                    style={
                      env.landing.hcpButton
                        ? {
                          display: 'none'
                        }
                        : null
                    }>
                    <span>
                      <a href={`${env.landing.hcpLink}${queryString.culture}`} target='_blank' rel='noopener noreferrer'>
                        {t('landing.bottom.right')}
                      </a>
                    </span>
                  </p>
                </Col>
              </Row>
            </Col>
            <Col xs={19} sm={20} className={styles.tabletHidden} style={!env.landing.video.visible ? { display: 'none' } : { marginTop: 30 }}>
              {
                env.country === 'BE' ? (
                  <Row gutter={24} className={styles.mobilevideoRow}>
                    <Col span={24} className={styles.mobileVideoRoot}>
                      <Row style={{ padding: '0 12px' }}>
                        <Col span={24} className={styles.snippet} onClick={() => setVisiblePopup(true)}>
                          <video autoPlay muted loop style={{width:'100%', height: '100%'}}>
                            <source src={getCampaignLink()} type='video/mp4' />
                          </video>
                          <PlayIcon className={styles.play} />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                ) : (
                  <Row gutter={24} className={styles.mobilevideoRow}>
                    <Col span={24} className={styles.mobileVideoRoot}>
                      <Row style={{ padding: '0 12px' }}>
                        <Col span={10} className={styles.snippet} onClick={() => setVisible(true)}>
                          <img src={videoImage !== undefined ? videoImage : env.landing.video.image.en} alt='thumbnail' />
                          <PlayIcon className={styles.play} />
                        </Col>
                        <Col span={14} className={styles.videoTitle}>
                          <p style={env.landing.bolderVideoText ? { fontWeight: 'bold', marginBottom: 5 } : { marginBottom: 5 }}>
                            {t('landing.sidenav.video.title.1')}
                            <br /> {t('landing.sidenav.video.title.2')}
                          </p>
                          <p onClick={() => setVisible(true)}>{t('landing.sidenav.video.text')}</p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )
              }
           
            </Col>
            <Col
              xs={24}
              sm={24}
              md={22}
              lg={21}
              xl={20}
              xxl={18}
              className={styles.mobileButton}
              style={{
                backgroundColor: env.colors.menuColor,
                boxShadow: `0px 0px 15px 10px ${env.colors.menuColor}`,
                margin: '42px 0',
                padding: '42px 0'
              }}>
              <Row justify='center'>
                <Col xs={18} sm={17} md={22} lg={21} xl={20} xxl={18} className={styles.mobileArea}>
                  <Row>
                    <Col className={styles.title}>
                      <span style={env.landing.buttonBlock ? { fontSize: 21 } : null}>
                        {t('landing.sidenav.1')} {t('landing.sidenav.2')}
                        <b>{t('landing.sidenav.3')}</b>
                        {t('landing.sidenav.4') !== null ? t('landing.sidenav.4') : ''}
                        <b>{t('landing.sidenav.5') !== null ? t('landing.sidenav.5') : ''}</b>
                      </span>
                    </Col>
                  </Row>
                  <Row justify='space-between' style={env.landing.buttonBlock ? { flexDirection: 'column', margin: '20px 0' } : { margin: '20px 0' }}>
                    <Col
                      span={env.landing.buttonBlock ? 24 : 12}
                      className={styles.applestore}
                      style={env.landing.buttonBlock ? { paddingRight: 0, marginBottom: 20 } : null}>
                      <a href={env.landing.appleStore} target='_blank' rel='noopener noreferrer'>
                        <img src='/images/app-store.png' alt='' style={env.landing.buttonBlock ? { height: 36, width: 'auto' } : null} />
                      </a>
                    </Col>
                    <Col
                      span={env.landing.buttonBlock ? 24 : 12}
                      className={styles.googleplay}
                      style={env.landing.buttonBlock ? { paddingLeft: 0 } : { display: 'flex', justifyContent: 'flex-end' }}>
                      <a href={env.landing.googlePlay} target='_blank' rel='noopener noreferrer'>
                        <img src='/images/google-play.png' alt='' style={env.landing.buttonBlock ? { height: 36, width: 'auto' } : null} />
                      </a>
                    </Col>
                  </Row>
                  <Row style={!env.landing.button ? { display: 'none' } : null}>
                    <Col span={24} className={styles.websiteCol}>
                      <Link to='/'>
                        <Button
                          className={styles.button}
                          style={{
                            color: '#fff'
                          }}
                          type='primary'>
                          {t('landing.sidenav.button')}
                        </Button>
                      </Link>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col xs={19} sm={20} md={22} lg={21} xl={20} xxl={18} className={styles.mobilehcp}>
              <Row
                gutter={24}
                className={styles.mobilebottomContainer}
                type='flex'
                align='middle'
                style={!env.landing.disclaimer ? { display: 'none' } : { marginBottom: 30 }}>
                <Col span={24}>
                  <p className={styles.disclaimer}>{t('landing.disclaimer')}</p>
                </Col>
              </Row>
              <Row
                gutter={24}
                className={styles.mobilebottomContainer}
                type='flex'
                align='middle'
                style={!env.landing.hcpportal ? { display: 'none' } : t('landing.address') !== null ? { marginBottom: 30 } : { marginBottom: 10 }}>
                <Col
                  className={styles.mobileTitle}
                  xs={24}
                  sm={24}
                  md={10}
                  lg={7}
                  xl={env.landing.hcpBolderText ? 6 : 7}
                  xxl={env.landing.hcpBolderText || env.landing.hcpButton ? 5 : 6}>
                  <div
                    style={
                      env.landing.hcpBolderText ? { color: env.colors.hcpLandingText, fontWeight: 600, maxWidth: 150 } : { color: env.colors.hcpLandingText }
                    }>
                    <p style={env.landing.hcpBolderText ? { lineHeight: '21px', fontSize: 20 } : null}>
                      <span>{t('landing.bottom.left.1')} </span>
                      <span>{t('landing.bottom.left.2')}</span>
                    </p>
                  </div>
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={14}
                  lg={env.landing.hcpButton ? 10 : 12}
                  xl={env.landing.hcpBolderText ? 12 : 11}
                  xxl={env.landing.hcpButton ? 14 : env.landing.hcpBolderText ? 13 : 12}>
                  <p className={styles.mobiletext}>{t('landing.bottom.center')}</p>
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={env.landing.hcpButton ? 7 : 5}
                  xl={6}
                  xxl={env.landing.hcpButton ? 5 : 6}
                  className={env.landing.hcpButton ? styles.hcpButtonStyle : ''}>
                  {env.landing.hcpButton && (
                    <a
                      href={`${env.landing.hcpLink}${queryString.culture}`}
                      target='_blank'
                      rel='noopener noreferrer'
                      className={styles.link}
                      style={{
                        backgroundColor: env.colors.primaryColor,
                        color: '#fff',
                        paddingTop: 12,
                        paddingBottom: 12,
                        paddingLeft: 20,
                        paddingRight: 20,
                        borderRadius: 10,
                        boxShadow: '0px 2px 3px #00000029',
                        fontWeight: 'normal',
                        textAlign: 'left',
                        lineHeight: '26px',
                        display: 'flex',
                        alignItems: 'center',
                        maxWidth: 230
                      }}>
                      <span>{t('landing.bottom.right')}</span>
                      <RightOutlined style={{ marginLeft: 12 }} />
                    </a>
                  )}
                  <p
                    className={styles.link}
                    style={
                      env.landing.hcpButton
                        ? {
                          display: 'none'
                        }
                        : null
                    }>
                    <span>
                      <a href={`${env.landing.hcpLink}${queryString.culture}`} target='_blank' rel='noopener noreferrer'>
                        {t('landing.bottom.right')}
                      </a>
                    </span>
                  </p>
                </Col>
              </Row>

              <Row gutter={24} style={t('landing.address') === null ? { display: 'none' } : null}>
                <Col span={24} className={styles.mobileaddressColumn}>
                  <div>{t('landing.address.1')}</div>
                  <div>{t('landing.address.2')}</div>
                  <div>{t('landing.address.3')}</div>
                  <div>{t('landing.address.4')}</div>
                </Col>
              </Row>
            </Col>
            <CookieDrawer open={open} setOpen={setOpen} />
            <Modal
              className='video'
              width={modalWidth()}
              closable={false}
              footer={null}
              visible={isVisible}
              onCancel={() => setVisible(false)}
              style={{ height: modalHeight() }}>
              <Iframe iframe={iframe} />
            </Modal>
            <Modal
              className='video'
              width={modalWidth()}
              closable={false}
              footer={null}
              visible={isVisiblePopup}
              onCancel={() => setVisiblePopup(false)}
              style={{ height: modalHeight() }}>
              <Iframe iframe={iframeCampaign} />
            </Modal>
          </Row>
        </Content>
      </Layout>
    </>
  )
}

export default LandingPage
