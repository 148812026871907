import React from 'react'
import { Row, Col, Button } from 'antd'
import { Link } from 'react-router-dom'
/* global localStorage */
const Confirmation = ({ setVisible, t, styles, getCfg }) => {
  const address = `https://${window.location.href.split('/')[2]}`
  const env = getCfg(address)
  const supportedCulture = () => {
    if (localStorage.getItem('i18nextLng') !== '') return localStorage.getItem('i18nextLng')
    return env.supported_cultures[0]
  }
  return (
    <Col
      span={20}
    >
      <Row justify='start'>
        <Col
          xs={24} sm={20} md={20} lg={15} xl={14} xxl={13}
          className={styles.confirmationRoot}
        >

          <Row justify='center'>
            <Col
              xs={24} sm={20} md={20} lg={20} xl={20} xxl={20}
            >
              <p style={{ fontSize: 20, fontWeight: 500, color: '#4C4948', lineHeight: '23px', margin: 0, paddingBottom: 30 }}>
                {t('confirmation.title')}
              </p>
              <p style={{ fontSize: 20, color: '#4C4948', lineHeight: '27px', margin: 0 }}>
                {t('confirmation.text')}
              </p>
            </Col>
          </Row>

          <Row justify='start'>
            <Col span={2} />
            <Col
              xs={24} sm={15} md={14} lg={14} xl={13} xxl={13}
              className={`${styles.confirmButton}`}
            >
              <Link to={`/?culture=${supportedCulture()}`}>
                <Button
                  style={{
                    backgroundColor: env.colors.primaryColor,
                    border: '1px solid',
                    borderColor: env.colors.primaryColor,
                    color: '#fff'
                  }}
                  htmlType='submit'
                  onClick={() => setVisible(false)}
                >
                  {t('survey.confirmationB')}
                </Button>
              </Link>
            </Col>
          </Row>
        </Col>

      </Row>
    </Col>
  )
}

export default Confirmation
