import React, { createContext, useState } from 'react'

export const LoginContext = createContext()

const LoginContextProvider = (props) => {
  const [authCode, setAuthCode] = useState('')

  return (
    <LoginContext.Provider value={{ authCode, setAuthCode }}>
      {props.children}
    </LoginContext.Provider>
  )
}

export default LoginContextProvider
