import React from 'react'
import { Row, Col, Modal } from 'antd'
import { EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import styles from './TrackerCard.module.css'
import moment from 'moment'
import { deleteC } from '../../ApiCall'
import { getCfg } from '../../../env.config'
import { useTranslation } from 'react-i18next'

const { confirm } = Modal

const address = `https://${window.location.href.split('/')[2]}`
const env = getCfg(address)
const getImageSource = (value) => {
  switch (value) {
    case 'LEFT_ARM':
      return env.injectionCard.leftarm
    case 'RIGHT_ARM':
      return env.injectionCard.rightarm
    case 'UPPER_THIGHS_LEFT':
      return env.injectionCard.leftleg
    case 'UPPER_THIGHS_RIGHT':
      return env.injectionCard.rightleg
    case 'BELLY_LEFT':
      return env.injectionCard.leftabdomen
    case 'BELLY_RIGHT':
      return env.injectionCard.rightabdomen
    default:
      return env.injectionCard.default
  }
}

const InjectionTrackerCard = (props) => {
  const { t } = useTranslation()
  const config = {
    trackerId: props.Id
  }

  const formatLocation = (string) => {
    let vacPosition
    switch (string) {
      case 'LEFT_ARM':
        vacPosition = t('injection.tracker.options.1')
        break
      case 'RIGHT_ARM':
        vacPosition = t('injection.tracker.options.2')
        break
      case 'UPPER_THIGHS_LEFT':
        vacPosition = t('injection.tracker.options.5')
        break
      case 'UPPER_THIGHS_RIGHT':
        vacPosition = t('injection.tracker.options.6')
        break
      case 'BELLY_LEFT':
        vacPosition = t('injection.tracker.options.3')
        break
      case 'BELLY_RIGHT':
        vacPosition = t('injection.tracker.options.4')
        break
      default:
        return ''
    }

    // const splittedArray = string ? vacPosition.split(' ') : []
    // const returnedArray = splittedArray.map((value) => {
    //   const capitalized =
    //     value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
    //   return capitalized
    // })
    // if (returnedArray.length > 2) {
    //   return returnedArray[0] + ' ' + returnedArray[1] + ' ' + returnedArray[2]
    // } else {
    //   return returnedArray[0] + ' ' + returnedArray[1]
    // }
    return vacPosition
  }

  const showConfirm = () => {
    confirm({
      title: props.t('injection.confirm.delete'),
      icon: <ExclamationCircleOutlined />,
      okText: props.t('injection.confirm.yes'),
      cancelText: props.t('injection.confirm.no'),
      onOk() {
        deleteC('injectionTracker/change', config, props.callback)
        return new Promise((resolve, reject) => {
          deleteC('partialMayo/change', config, (val) => {
            Modal.destroyAll()
            props.callback(val)
          })
        }).catch(() => console.log('Oops errors!'))
      },
      onCancel() {},
      className: env.countryClass,
      centered: true
    })
  }

  return (
    <Row
      align='middle'
      className={styles.root}
      style={{
        backgroundColor: env.colors.cardColor
      }}>
      <Col span={14} className={styles.textColumn}>
        <Row>
          <Col span={24}>
            <div className={styles.dateDiv}>
              <p className={styles.dateParagraph}>{moment(props.Date_Injected__c).format('dddd, DD/MM/YYYY')}</p>
            </div>
          </Col>
          <Col span={24}>
            <p className={styles.locationParagraph}>{formatLocation(props.Location__c)}</p>
          </Col>
        </Row>
      </Col>
      <Col span={8}>
        <img className={styles.image} src={getImageSource(props.Location__c)} alt='location' />
      </Col>
      <Col span={2} className={styles.iconPlaceholder}>
        <EditOutlined className={styles.edit} onClick={() => props.handleEdit(props.Id)} />
        <DeleteOutlined className={styles.delete} onClick={showConfirm} />
      </Col>
    </Row>
  )
}

export default InjectionTrackerCard
