import React, { createContext, useState } from 'react'

export const AppContext = createContext()

const AppContextProvider = (props) => {
  const [contextData, setContextData] = useState({
    showResult: false,
    show: false,
    visible: false,
    showChartView: false,
    values: [],
    ibdModal: ''
  })
  const [loaded, setLoaded] = useState(false)
  const [content, setContent] = useState({ loaded: false, data: [] })
  const [collapsed, setCollapsed] = useState(false)
  const [wheelValues, setWheelValues] = useState([])
  const [show, setShow] = useState({
    hbi: false,
    pmi: false,
    survey: { show: false, data: null }
  })
  const [subcategory, setSubcategory] = useState('')
  const [showBoard, setShowBoard] = useState(false)
  const [showPromo, setShowPromo] = useState(false)
  const [tree, setTree] = useState({ loaded: false, data: [] })
  const [matrix, setMatrix] = useState({ loaded: false, data: {} })
  const [linkedArticle, setLinkedArticle] = useState({ loaded: false, data: [] })

  return (
    <AppContext.Provider
      value={{
        contextData,
        setContextData,
        collapsed,
        setCollapsed,
        wheelValues,
        setWheelValues,
        show,
        setShow,
        subcategory,
        setSubcategory,
        showBoard,
        setShowBoard,
        loaded,
        setLoaded,
        content,
        setContent,
        showPromo,
        setShowPromo,
        tree,
        setTree,
        matrix,
        setMatrix,
        linkedArticle,
        setLinkedArticle
      }}>
      {props.children}
    </AppContext.Provider>
  )
}

export default AppContextProvider
