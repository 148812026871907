import React, { useState, useEffect } from 'react'
import { Layout, Row, Col, Form } from 'antd'
import { useTranslation } from 'react-i18next'
import styles from './Register.module.css'
import Wrapper from './../LayoutWrapper'
import ConsentForm from './Consent'
import ConfirmationForm from './Confirmation'
import RegisterForm from './RegisterForm'
import { CheckOutlined } from '@ant-design/icons'
import { getCfg } from '../../env.config'
import axios from 'axios'
import * as qs from 'query-string'
import NotAllowed from '../NotAllowed'

const Register = () => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const { Content } = Layout
  const [values, setValues] = useState({
    salutation: null,
    firstname: null,
    lastname: null,
    email: null,
    confirmedEmail: null,
    password: null,
    confirmedPassword: null
  })

  const [consent, setConsent] = useState({ loaded: false, data: {} })
  const [salutation, setSalutation] = useState({ loaded: false, data: [] })
  const [visible, setVisible] = useState({
    consent: false,
    confirmation: false
  })

  const queryString = qs.parse(window.location.search)

  useEffect(() => {
    const address = `https://${window.location.href.split('/')[2]}`
    const env = getCfg(address)
    const queryString = qs.parse(window.location.search)
    if (queryString.culture === undefined) {
      localStorage.setItem('i18nextLng', `${env.supported_cultures[0]}`)
      window.location.href = window.location.pathname + '?culture=' + env.supported_cultures[0]
    } else if (queryString.culture === 'hr') {
      localStorage.setItem('i18nextLng', `hr-HR`)
      window.location.href = window.location.pathname + '?culture=hr-HR'
    }
  }, [])

  const address = `https://${window.location.href.split('/')[2]}`
  const env = getCfg(address)

  useEffect(() => {
    const address = `https://${window.location.href.split('/')[2]}`
    const env = getCfg(address)

    const header = {
      'x-client': 'web',
      client_id: env.clientId,
      client_secret: env.clientSecret
    }

    const get = (path, params, setValues) => {
      axios
        .get(env.appRoot + path, { params: params, headers: header })
        .then(function (response) {
          setValues({
            loaded: true,
            data: response.data
          })
        })
        .catch(function (error) {
          console.log('api get', error.response)
          setValues({
            loaded: true,
            data: { Digital_ID_Consent__c: error.response.data.error }
          })
        })
    }

    const search = window.location.search
    const queryString = qs.parse(search)

    let lang
    let country
    if (queryString.culture !== undefined) {
      lang = queryString.culture.split('-')[0]
      country = queryString.culture.split('-')[1]
    } else {
      lang = env.language
      country = env.country
    }

    const treatmentType = ''
    const config = {
      language: lang,
      country: country,
      medication: treatmentType
    }

    if (!consent.loaded) {
      get('consent/patient', config, setConsent)
    }
  }, [consent.loaded])

  useEffect(() => {
    const address = `https://${window.location.href.split('/')[2]}`
    const env = getCfg(address)

    const search = window.location.search
    const queryString = qs.parse(search)

    let lang
    let country
    if (queryString.culture !== undefined) {
      lang = queryString.culture.split('-')[0]
      country = queryString.culture.split('-')[1]
    } else {
      lang = env.language
      country = env.country
    }

    const header = {
      'x-client': 'web',
      client_id: env.clientId,
      client_secret: env.clientSecret,
      'x-country': country,
      language: lang
    }

    const get = (path, params, setValues) => {
      axios
        .get(env.appRoot + path, { params: params, headers: header })
        .then(function (response) {
          setValues({
            loaded: true,
            data: response.data
          })
        })
        .catch(function (error) {
          console.log('api get', error.response)
          setValues({
            loaded: true,
            data: [
              {
                Id: 'error',
                API_name__c: 'error',
                Value__c: 'error'
              }
            ]
          })
        })
    }

    if (!salutation.loaded) {
      get('salutation', {}, setSalutation)
    }
  }, [salutation.loaded])

  if (!env.supported_cultures.includes(queryString.culture) && queryString.culture !== undefined && queryString.culture !== 'hr')
    return <NotAllowed env={env} />
  if (!window.location.search.includes('culture')) {
    return (
      <Layout
        style={{
          minHeight: '100%',
          display: 'flex',
          justifyContent: 'center'
        }}
      />
    )
  }
  return (
    <Layout>
      <Wrapper>
        <Content>
          <Row type='flex' align='bottom' justify='center' className={env.countryClass}>
            <Col span={20}>
              {env.countryClass === 'canada' ? (
                <Row gutter={24} type='flex' align='middle' className={styles.header}>
                  <Col span={24}>
                    <img src='/images/CA/onepath@2x.png' className={styles.onepath} alt='Onepath' />
                  </Col>
                </Row>
              ) : (
                <Row gutter={24} type='flex' align='middle' className={styles.header}>
                  <Col xs={24} sm={4} md={3} lg={2} xl={2} xxl={2}>
                    <img src='/images/fywy-app-icon-1024x2.png' className={styles.takedaImg} alt='fywy' />
                  </Col>
                  <Col xs={24} sm={20} md={21} lg={22} xl={22} xxl={22}>
                    <h1 className={styles.heading}>
                      <span>{t('header.1')},</span> <span>{t('header.2')}</span>
                    </h1>
                    <h3 className={styles.subheading}>{t('header.3')}</h3>
                  </Col>
                </Row>
              )}

              <Row className={styles.mobile}>
                {env.countryClass === 'canada' ? (
                  <Col span={14}>
                    <Row align='middle' style={{ paddingBottom: 20 }}>
                      <Col span={24}>
                        <img src='/images/CA/onepath@2x.png' className={styles.onepath} alt='Onepath' />
                      </Col>
                    </Row>
                  </Col>
                ) : (
                  <Col span={14}>
                    <Row align='middle' style={{ paddingBottom: 20 }}>
                      <Col span={8}>
                        <img src='/images/fywy-app-icon-1024x2.png' alt='takeda logo' style={{ width: '40px' }} />
                      </Col>
                      <Col span={16}>
                        <h1 className={styles.heading}>
                          <p>{t('header.1')},</p>
                          <p>{t('header.2')}</p>
                        </h1>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <h3 className={styles.msubheading}>{t('header.3')}</h3>
                      </Col>
                    </Row>
                  </Col>
                )}
              </Row>

              <Row>
                <Col span={24}>
                  <h1 className={styles.registerTitle} style={{ color: env.colors.title }}>
                    {visible.consent ? ' ' : t('register.title.1')}
                  </h1>
                  <div className={styles.progress} style={visible.confirmation ? { display: 'none' } : null}>
                    <div
                      className={styles.progressDot}
                      style={{
                        backgroundColor: env.colors.primaryColor,
                        color: '#fff',
                        borderColor: env.colors.primaryColor
                      }}>
                      {visible.consent ? <CheckOutlined /> : '1'}
                    </div>
                    <hr className={styles.dash} style={{ backgroundColor: env.colors.primaryColor }} />
                    <div
                      className={styles.progressDot}
                      style={
                        visible.consent
                          ? {
                              backgroundColor: env.colors.primaryColor,
                              color: '#fff',
                              borderColor: env.colors.primaryColor
                            }
                          : {
                              color: env.colors.primaryColor,
                              borderColor: env.colors.primaryColor
                            }
                      }>
                      2
                    </div>
                  </div>
                </Col>
              </Row>
              {visible.consent ? (
                <ConsentForm values={values} setVisible={setVisible} consent={consent} t={t} styles={styles} getCfg={getCfg} />
              ) : visible.confirmation ? (
                <ConfirmationForm setVisible={setVisible} t={t} styles={styles} getCfg={getCfg} />
              ) : (
                <RegisterForm
                  values={values}
                  setValues={setValues}
                  setVisible={setVisible}
                  t={t}
                  styles={styles}
                  getCfg={getCfg}
                  form={form}
                  salutation={salutation}
                />
              )}
            </Col>
          </Row>
        </Content>
      </Wrapper>
    </Layout>
  )
}

export default Register
