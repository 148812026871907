import React, { useState, useContext, useEffect } from 'react'
import { Row, Col, Button, Empty, Skeleton, Tooltip } from 'antd'
import styles from './Main.module.css'
import { useTranslation } from 'react-i18next'
import Card from './Card'
import Calendar from '../../Calendar'
import { AppContext } from '../../../contexts/AppContext'
import { get } from '../../ApiCall'
import { getCfg } from '../../../env.config'
import * as moment from 'moment'

const Main = ({ data, setData }) => {
  const { t } = useTranslation()
  const address = `https://${window.location.href.split('/')[2]}`
  const env = getCfg(address)
  const context = useContext(AppContext)
  const [results, setResults] = useState({ loaded: false, data: [] })
  const [selected, setSelected] = useState(null)
  const [state, setState] = useState({
    currentMonth: moment(new Date()),
    open: false,
    content: {}
  })
  useEffect(() => {
    setResults(data)
  }, [data, setResults])

  const config = {
    offset: 0,
    limit: 1000
  }

  useEffect(() => {
    if (!results.loaded) {
      get('partialMayo/all', config, setResults)
    }
  }, [results.loaded, config])

  const disableButton = () => {
    const tempArray = []
    results.data.map((value) => {
      const isToday = moment(value.Created_Date__c).isSame(new Date(), 'day')
      return tempArray.push(isToday)
    })
    if (tempArray.includes(true)) return true
    return false
  }

  const getData = () => {
    if (Array.isArray(results.data) && results.loaded) {
      if (results.data.length > 0) {
        const sameMonth = results.data.filter((values) => {
          return moment(state.currentMonth).isSame(values.Created_Date__c, 'month')
        })
        const selectedDate = selected !== null ? moment(selected).format() : selected
        const selectedResult = sameMonth.filter((result) => {
          return moment(selectedDate).isSame(result.Created_Date__c, 'day')
        })
        const tempArray = selectedDate !== null ? selectedResult : sameMonth
        if (tempArray.length > 0) {
          return tempArray.map((val) => {
            return <Card key={val.Id} id={val.Id} {...val} t={t} setResults={setResults} />
          })
        } else {
          return (
            <div className={styles.empty} style={{ backgroundColor: env.colors.cardColor }}>
              There are no entries
            </div>
          )
        }
      } else {
        return <Empty />
      }
    } else {
      return <Skeleton active loading />
    }
  }

  const handleDateClick = (date) => {
    if (moment(selected).isSame(date, 'day')) {
      setSelected(null)
    } else {
      setSelected(date)
    }
  }

  const text = (
    <p>
      {t('mayo.main.warning.1')}
      <br />
      {t('mayo.main.warning.2')}
    </p>
  )
  return (
    <Row align='bottom' justify='center' className={styles.diskRoot}>
      <Col xs={23} sm={24} md={24} lg={24} xl={24} xxl={24}>
        <div className={styles.text}>{t('mayo.main.description')}</div>
      </Col>
      <Col xs={23} sm={24} md={24} lg={24} xl={24} xxl={24} className={styles.contentRoot}>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={11} lg={11} xl={11} xxl={11} className={styles.diskPlaceholder}>
            <Calendar
              selected={selected}
              dates={[]}
              disableFutureDates={moment(state.currentMonth).isSame(new Date(), 'month')}
              onDateClick={handleDateClick}
              state={state}
              setState={setState}
            />
          </Col>

          <Col xs={24} sm={24} md={13} lg={13} xl={13} xxl={13}>
            <Row className={styles.cardRow}>
              <Col span={24} className={styles.cardPlaceholder}>
                {getData()}
              </Col>
              <Col span={24} className={styles.cardButton}>
                {disableButton() ? (
                  <Tooltip
                    placement='top'
                    title={text}
                    overlayClassName={env.countryClass}
                    overlayStyle={{
                      maxWidth: 350,
                      marginTop: 10
                    }}>
                    <Button
                      style={{
                        backgroundColor: '#ccc',
                        color: '#fff',
                        boxShadow: '0px 3px 6px #00000029',
                        height: 51,
                        width: '100%',
                        borderRadius: 15,
                        fontSize: 25,
                        textTransform: 'uppercase'
                      }}
                      className={styles.button}
                      disabled={disableButton()}>
                      {t('mayo.main.button')}
                    </Button>
                  </Tooltip>
                ) : (
                  <Button
                    style={{
                      backgroundColor: env.colors.primaryColor,
                      color: '#fff',
                      height: 51,
                      width: '100%',
                      borderRadius: 15,
                      fontSize: 25,
                      textTransform: 'uppercase'
                    }}
                    className={styles.button}
                    onClick={() =>
                      context.setShow((oldValues) => ({
                        ...oldValues,
                        pmi: true
                      }))
                    }
                    disabled={disableButton()}>
                    {t('mayo.main.button')}
                  </Button>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default Main
