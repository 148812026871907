import React, { useState, useEffect, useContext } from 'react'
import { Layout, Row, Col } from 'antd'
import { useTranslation } from 'react-i18next'
import styles from './Personalization.module.css'
import Wrapper from './../LayoutWrapper'
import PersonalizationForm from './PersonalizationForm'
import ConsentForm from './ConsentForm'
import { get, header, logAction } from '../ApiCall'
import { getCfg } from '../../env.config'
import * as qs from 'query-string'
import { AppContext } from '../../contexts/AppContext'

const Personalization = ({ hospitals, doctors, profile, treatment, popUp, config, setHospitals }) => {
  const { t } = useTranslation()
  const { Content } = Layout
  const [values, setValues] = useState({
    year: null,
    month: null,
    condition: null,
    conditionID: null,
    hospital: null,
    hospitalID: null,
    doctor: null,
    doctorID: null,
    mobile: null
  })

  const [visible, setVisible] = useState(false)
  const [consent, setConsent] = useState({ loaded: false, data: {} })
  const [condition, setCondition] = useState([])
  const [edit, setEdit] = useState(false)
  const { matrix } = useContext(AppContext)

  useEffect(() => {
    if (config.loaded) {
      const tempCondition = []
      config.data.condition.map((x) => {
        return config.data.mapping.map((y) => {
          if (x === y.name) return tempCondition.push(y)
          return null
        })
      })
      setCondition(tempCondition)
    }
  }, [config, setCondition])
  const address = `https://${window.location.href.split('/')[2]}`
  const env = getCfg(address)
  useEffect(() => {
    const YEAR = profile.data.Date_of_Birth !== null ? profile.data.Date_of_Birth.split('/')[1] : null
    const MONTH = profile.data.Date_of_Birth !== null ? profile.data.Date_of_Birth.split('/')[0] : null

    const ask_hcp = matrix.data.Ask_HCP !== undefined ? matrix.data.Ask_HCP : true
    const ask_hospital = matrix.data.Ask_Hospital !== undefined ? matrix.data.Ask_Hospital : true

    if (YEAR !== null || MONTH !== null) {
      if (values.year === parseInt(YEAR) && values.month === MONTH) {
        if (!edit) {
          setEdit(true)
          setValues((oldState) => ({
            ...oldState,
            hospitalID: ask_hospital ? profile.data.HospitalID : null,
            hospital: ask_hospital ? profile.data.HospitalName : null,
            doctorID: ask_hcp ? profile.data.DoctorID : null,
            doctor: ask_hcp ? profile.data.DoctorName : null,
            condition: treatment.data[0] ? treatment.data[0].Condition__c : null
          }))
        }
      } else {
        if ((values.year !== YEAR || values.month !== MONTH) && values.hospitalID !== null) {
          setEdit(false)
          setValues((oldState) => ({
            ...oldState,
            hospitalID: null,
            hospital: null,
            doctorID: null,
            doctor: null,
            condition: null
          }))
        }
      }
    }
  }, [profile.data, treatment.data, values, edit, matrix.data])

  useEffect(() => {
    if (profile.loaded) {
      const address = `https://${window.location.href.split('/')[2]}`
      const env = getCfg(address)
      const search = window.location.search
      const queryString = qs.parse(search)
      let country
      if (queryString.culture !== undefined) {
        country = queryString.culture.split('-')[1]
      } else {
        country = env.country
      }

      const startPayload = {
        LogTitle: 'Profile Initialized',
        username__c: profile.data.PersonEmail,
        country__c: country,
        profile_initialized__c: new Date().toISOString(),
        profile_completed__c: 'null',
        profile_error__c: 'null',
        profile_error_msg__c: 'null'
      }
      logAction('user/log', startPayload, { 'x-okta-idToken': localStorage.getItem('token') })
    }
  }, [profile])

  useEffect(() => {
    const address = `https://${window.location.href.split('/')[2]}`
    const env = getCfg(address)
    const treatmentType = Array.isArray(treatment.data) ? (treatment.data.length > 0 ? treatment.data[0].Medication__c : '') : ''

    const search = window.location.search
    const queryString = qs.parse(search)
    let lang
    let country
    if (queryString.culture !== undefined) {
      lang = queryString.culture.split('-')[0]
      country = queryString.culture.split('-')[1]
    } else {
      lang = env.language
      country = env.country
    }

    const config = {
      language: lang,
      country: country,
      medication: treatmentType
    }
    if (!consent.loaded && treatment.loaded) {
      get('consent/patient', config, setConsent)
    }
  }, [consent.loaded, treatment.loaded, treatment.data, profile.data])

  return (
    <Layout>
      <Wrapper>
        <Content>
          <Row type='flex' align='bottom' justify='center' className={env.countryClass}>
            <Col span={20}>
              {env.countryClass === 'canada' ? (
                <Row gutter={24} type='flex' align='middle' className={styles.header}>
                  <Col span={24}>
                    <img src='/images/CA/onepath@2x.png' className={styles.onepath} alt='Onepath' />
                  </Col>
                </Row>
              ) : (
                <Row gutter={24} type='flex' align='middle' className={styles.header}>
                  <Col xs={24} sm={4} md={3} lg={2} xl={2} xxl={2}>
                    <img src='/images/fywy-app-icon-1024x2.png' className={styles.takedaImg} alt='fywy' />
                  </Col>
                  <Col xs={24} sm={20} md={21} lg={22} xl={22} xxl={22}>
                    <h1 className={styles.heading}>
                      <span>{t('header.1')},</span> <span>{t('header.2')}</span>
                    </h1>
                    <h3 className={styles.subheading}>{t('header.3')}</h3>
                  </Col>
                </Row>
              )}

              <Row className={styles.mobile}>
                {env.countryClass === 'canada' ? (
                  <Col span={14}>
                    <Row align='middle' style={{ paddingBottom: 20 }}>
                      <Col span={24}>
                        <img src='/images/CA/onepath@2x.png' className={styles.onepath} alt='Onepath' />
                      </Col>
                    </Row>
                  </Col>
                ) : (
                  <Col span={14}>
                    <Row align='middle' style={{ paddingBottom: 20 }}>
                      <Col span={8}>
                        <img src='/images/fywy-app-icon-1024x2.png' alt='takeda logo' style={{ width: '40px' }} />
                      </Col>
                      <Col span={16}>
                        <h1 className={styles.heading}>
                          <p>{t('header.1')},</p>
                          <p>{t('header.2')}</p>
                        </h1>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <h3 className={styles.msubheading}>{t('header.3')}</h3>
                      </Col>
                    </Row>
                  </Col>
                )}
              </Row>

              <Row>
                <Col>
                  <h1 className={styles.personalizationTitle} style={{ color: env.colors.title }}>
                    {t('personalization.title')}
                  </h1>
                </Col>
              </Row>
              <Row style={visible ? { display: 'none' } : {}}>
                <Col xs={24} sm={24} md={24} lg={18} xl={18} xxl={18}>
                  <p className={styles.welcome}>{t('personalization.form.welcome')}</p>
                </Col>
              </Row>
              {visible ? (
                <ConsentForm
                  values={values}
                  setVisible={setVisible}
                  t={t}
                  styles={styles}
                  profile={profile}
                  treatment={treatment}
                  consent={consent}
                  popup={popUp}
                />
              ) : (
                <PersonalizationForm
                  doctors={doctors}
                  profile={profile}
                  hospitals={hospitals}
                  values={values}
                  setValues={setValues}
                  setVisible={setVisible}
                  t={t}
                  styles={styles}
                  condition={condition}
                  matrix={matrix}
                  setHospitals={setHospitals}
                  header={header}
                />
              )}
            </Col>
          </Row>
        </Content>
      </Wrapper>
    </Layout>
  )
}

export default Personalization
