import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'

import './i18n'
import { getCfg } from '../src/env.config'

const address = `https://${window.location.href.split('/')[2]}`
const env = getCfg(address)

ReactDOM.render(<Suspense fallback={<div />}>{env.redirectToNothing ? <div /> : <App />}</Suspense>, document.getElementById('root'))
