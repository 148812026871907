import { ProdBE, StagBE, UatBE } from './url-configs/BE'
import { ProdBG, StagBG, UatBG } from './url-configs/BG'
import { Default } from './url-configs/DEFAULT'
import { ProdHR, StagHR, UatHR } from './url-configs/HR'
import { ProdHU, StagHU, UatHU } from './url-configs/HU'
import { ProdIE, StagIE, UATIE } from './url-configs/IE'
import { DevConfig } from './url-configs/LOCALHOST/dev.config'
import { ProdLT, StagLT, UATLT } from './url-configs/LT'
import { ProdLU, StagLU, UatLU } from './url-configs/LU'
import { ProdLV, StagLV, UATLV } from './url-configs/LV'
import { ProdRO, UatRO, StagRO } from './url-configs/RO'
import { DevTemp } from './url-configs/TEMPL'
import { dev } from './url-configs/DEV/dev.config'
import { stag } from './url-configs/STAG/stag.config'
import { ProdCA, StagCA, UatCA } from './url-configs/CA'
import { ProdRS, StagRS, UatRS } from './url-configs/RS'
import { ProdPL, StagPL, UatPL } from './url-configs/PL'

const cfgMap = {
  //canada
  'https://stag-ca-onepathpatientportal.hartehanks.com': StagCA,
  'https://stage-v1.webapp.onepath.ca': UatCA,
  'https://webapp.onepath.ca': ProdCA,
  //stag
  'https://stag-fywypatientportal.hartehanks.com': stag,
  //dev
  'https://dev-fywypatientportal.hartehanks.com': dev,
  //belgium
  'https://stag-be-fywypatientportal.hartehanks.com': StagBE,
  'https://stage-v1.ibd.foryouwithyou.be': UatBE,
  'https://ibd.foryouwithyou.be': ProdBE,
  //luxembourg
  'https://stag-lu-fywypatientportal.hartehanks.com': StagLU,
  'https://stage-v1.ibd.foryouwithyou.lu': UatLU,
  'https://ibd.foryouwithyou.lu': ProdLU,
  //romania
  'https://stag-ro-fywypatientportal.hartehanks.com': StagRO,
  'https://stage-v1.foryouwithyou.ro': UatRO,
  'https://foryouwithyou.ro': ProdRO,
  //lithuania
  'https://stag-lt-fywypatientportal.hartehanks.com': StagLT,
  'https://stage-v1.foryouwithyou.lt': UATLT,
  'https://foryouwithyou.lt': ProdLT,
  //bulgaria
  'https://stag-bg-fywypatientportal.hartehanks.com': StagBG,
  'https://stage-v1.foryouwithyou.bg': UatBG,
  'https://foryouwithyou.bg': ProdBG,
  //croatia
  'https://stag-hr-fywypatientportal.hartehanks.com': StagHR,
  'https://stage-v1.ibd.foryouwithyou.hr': UatHR,
  'https://ibd.foryouwithyou.hr': ProdHR,
  //hungary
  'https://stag-hu-fywypatientportal.hartehanks.com': StagHU,
  'https://stage-v1.custom.ibdoktor.hu': UatHU,
  'https://custom.ibdoktor.hu': ProdHU,
  //latvia
  'https://stag-lv-fywypatientportal.hartehanks.com': StagLV,
  'https://stage-v1.foryouwithyou.lv': UATLV,
  'https://foryouwithyou.lv': ProdLV,
  //ireland
  'https://stag-ie-fywypatientportal.hartehanks.com': StagIE,
  'https://stage-v1.foryouwithyou.ie': UATIE,
  'https://foryouwithyou.ie': ProdIE,
  // serbia
  'https://stag-sr-fywypatientportal.hartehanks.com': StagRS,
  'https://stage-v1.foryouwithyou.rs': UatRS,
  'https://foryouwithyou.rs': ProdRS,
  // poland // this need to be changed when we publish the landing
  'https://stag-pl-fywypatientportal.hartehanks.com': StagPL,
  'https://stage-v1.twojeibd.pl': UatPL,
  'https://twojeibd.pl': ProdPL,
  //templ
  'https://templ-fywypatientportal.hartehanks.com': DevTemp,
  //default
  default: Default,
  //localhost
  'https://localhost:8080': DevConfig
}

export const getCfg = (baseUrl) => {
  if (!cfgMap[baseUrl]) return cfgMap.default
  return cfgMap[baseUrl]
}
