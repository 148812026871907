import React, { useEffect, useState } from 'react'
import { Col, message, Modal, Row, Spin } from 'antd'
import styles from './ArticleDetails.module.css'
import { CloseCircleOutlined, LoadingOutlined, PushpinFilled, PushpinOutlined } from '@ant-design/icons'
import { get, post } from '../ApiCall'
import { getCfg } from '../../env.config'
import { useTranslation } from 'react-i18next'

const ArticleDetail = (props) => {
  const { t } = useTranslation()
  const [load, setLoad] = useState(false)
  const [favorites, setFavorites] = useState([])
  const [linkedArticle, setLinkedArticle] = useState({ loaded: false, data: [] })

  const ref = React.useRef(null)
  const executeScroll = () => {
    window.scrollTo({ behavior: 'smooth', top: ref.current?.offsetTop })
  }
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />
  const [size, setSize] = useState(window.innerWidth)

  useEffect(() => {
    function handleResize() {
      setSize(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    if (props.pinnedArticles.data) {
      if (Array.isArray(props.pinnedArticles.data)) {
        const tempState = props.pinnedArticles.data.map((result) => {
          return result.Related_id__c
        })
        setFavorites(tempState)
      }
    }
  }, [props.pinnedArticles.data, setFavorites])

  const address = `https://${window.location.href.split('/')[2]}`
  const env = getCfg(address)
  const handlePin = () => {
    if (props.pinnedArticles.data.length < 8) {
      const pinned = props.pinnedArticles.data
        .map((val) => {
          return val
        })
        .map((x) => {
          return {
            Related_id: x.Related_id__c,
            ctype: x.Type__c
          }
        })
      const postData = {
        CIS: [
          {
            Related_id: linkedArticle.data.length > 0 ? linkedArticle.data[0].Id : props.content.data[0].Id,
            ctype: props.location !== '/articles/videos' ? 'ARTICLE' : 'VIDEO'
          },
          ...pinned
        ]
      }
      console.log(postData)
      setLoad(true)
      post('carrousel', postData, (val) => {
        props.setPinnedArticles((oldState) => ({
          ...oldState,
          loaded: false
        }))
        setTimeout(() => {
          setLoad(false)
          message.success(t('article.pinned'))
        }, 1000)
      })
    } else {
      setLoad(true)
      setTimeout(() => {
        setLoad(false)
        Modal.warning({
          title: t('errors.e_pin_limit_exceeded'),
          content: t('errors.e_pin_limit_content'),
          icon: ''
        })
      }, 1000)
    }
  }
  const handleUnpin = () => {
    const pinned = props.pinnedArticles.data
      .map((val) => {
        return val
      })
      .map((x) => {
        return {
          Related_id: x.Related_id__c,
          ctype: x.Type__c
        }
      })
      .filter((value) => {
        const ID = linkedArticle.data.length > 0 ? linkedArticle.data[0].Id : props.content.data[0].Id
        return value.Related_id !== ID
      })

    const postData = {
      CIS: [...pinned]
    }
    setLoad(true)
    post('carrousel', postData, (val) => {
      props.setPinnedArticles((oldState) => ({
        ...oldState,
        loaded: false
      }))
      setTimeout(() => {
        setLoad(false)
        message.success(t('article.unpinned'))
      }, 1000)
    })
  }

  useEffect(() => {
    executeScroll()
  }, [])

  window.clickHandler = function (link) {
    const config = {
      offset: 0,
      limit: 1000
    }

    if (link.includes('/articles/')) {
      setLoad(true)
      const splittedLink = link.split('/')
      const urlName = splittedLink[splittedLink.length - 1]
      console.log(urlName)
      get(`articles/detail?UrlName=${urlName}`, config, (values) => {
        setLoad(false)
        setLinkedArticle(values)
      })
    } else {
      const win = window.open(link, '_blank')
      if (win != null) {
        win.focus()
      }
    }
  }
  const [content, setContent] = useState('')

  useEffect(() => {
    if (!props.content.data[0].Video_Id__c) {
      const COPYTEST = linkedArticle.loaded ? linkedArticle.data[0].Content__c : props.content.data[0].Content__c
      const test = document.createElement('div')
      test.innerHTML = COPYTEST.trim()
      const all = test.getElementsByTagName('A')
      let copy = COPYTEST
      for (const link of all) {
        // copy = copy.replace(
        //   link.outerHTML.toString(),
        //   `<a onClick="window.clickHandler('${link.href}')" style='text-decoration: underline'>${link.textContent}</a>`
        // )
        const splittedLink = link.href.split('/articles')[1]
        copy = copy.replace(
          `<a href="/articles${splittedLink}" target="_self">`,
          `<a onClick="window.clickHandler('${link.href}')" style='text-decoration: underline'>`
        )
      }
      setContent(copy)
    }
  }, [props.content.data, linkedArticle])

  const Iframe = (props) => {
    return <div dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : '' }} />
  }
  useEffect(() => {
    return () => {
      props.handleUnmount()
    }
  }, [])

  const height = size < 576 ? 250 : 385
  const getVideoDetail = () => {
    const iframe = `<iframe width="100%" height=${height} frameborder="0" src="https://players.brightcove.net/2299575749001/default_default/index.html?videoId=${props.content.data[0].Video_Id__c}"></iframe>`
    return (
      <Col xs={24} sm={24} md={24} lg={22} xl={22} xxl={22} className={styles.videoRoot}>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} className={styles.titleCol}>
            <Row justify='center'>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <h1 className={styles.title} style={!props.content.data[0].Image_URL__c ? { marginBottom: 0 } : null}>
                  {props.content.data[0].Title}
                </h1>
              </Col>
              <Col xs={24} sm={10} md={10} lg={24} xl={24} xxl={24} style={!props.content.data[0].Summary ? { display: 'none' } : null}>
                <p className={styles.subtitle}>{props.content.data[0].Summary}</p>
              </Col>
            </Row>
          </Col>
          <Col span={24} style={props.content.data[0].Image_URL__c && props.content.data[0].Summary ? { paddingTop: 32 } : { paddingTop: 8 }}>
            <Iframe iframe={iframe} />
          </Col>
        </Row>
      </Col>
    )
  }

  const getArticleDetail = () => {
    return (
      <Col xs={24} sm={24} md={24} lg={22} xl={22} xxl={22} ref={ref} className={styles.articleRoot}>
        {/* {linkedArticle.data.length > 0 ? (
          <div dangerouslySetInnerHTML={{ __html: linkedArticle.data[0].Content__c }} />
        ) : (
          <div dangerouslySetInnerHTML={{ __html: content }} />
        )} */}
        {<div dangerouslySetInnerHTML={{ __html: content }} />}
      </Col>
    )
  }

  return (
    <Col span={24}>
      <div style={{ position: 'absolute', visibility: 'hidden', top: 0 }} />
      <Spin indicator={antIcon} spinning={load}>
        {favorites.includes(linkedArticle.data.length > 0 ? linkedArticle.data[0].Id : props.content.data[0].Id) ? (
          <PushpinFilled
            className={styles.tabletPinnedBtnSelected}
            onClick={handleUnpin}
            style={{
              borderColor: env.colors.primaryColor,
              backgroundColor: env.colors.primaryColor
            }}
          />
        ) : (
          <PushpinOutlined
            className={styles.tabletPinnedBtn}
            onClick={handlePin}
            style={{
              borderColor: env.colors.primaryColor,
              color: env.colors.primaryColor
            }}
          />
        )}
        <CloseCircleOutlined className={styles.tabletCloseIcon} onClick={props.handleClose} style={{ color: env.colors.primaryColor }} />
        <Row justify='center' className={styles.root}>
          <CloseCircleOutlined className={styles.closeIcon} onClick={props.handleClose} style={{ color: env.colors.primaryColor }} />
          {favorites.includes(linkedArticle.data.length > 0 ? linkedArticle.data[0].Id : props.content.data[0].Id) ? (
            <PushpinFilled
              style={{
                borderColor: env.colors.primaryColor,
                backgroundColor: env.colors.primaryColor
              }}
              className={styles.pinnedButtonSelected}
              onClick={handleUnpin}
            />
          ) : (
            <PushpinOutlined
              style={{
                borderColor: env.colors.primaryColor,
                color: env.colors.primaryColor
              }}
              className={styles.pinnedButton}
              onClick={handlePin}
            />
          )}

          {props.content.data[0].Video_Id__c ? getVideoDetail() : getArticleDetail()}
        </Row>
      </Spin>
    </Col>
  )
}

export default ArticleDetail
