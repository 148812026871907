import React from 'react'
import RadarChart from 'react-svg-radar-chart'

const RadarChartComponent = ({ getSize, data, classDot }) => {
  return (
    <RadarChart
      captions={{
        // columns
        abdominal: '',
        regulating: '',
        interpersonal: '',
        education: '',
        sleep: '',
        energy: '',
        emotions: '',
        body: '',
        sexual: '',
        joint: ''
      }}
      data={data}
      size={getSize()}
      options={{
        captions: false,
        dots: true,
        dotProps: () => ({
          className: classDot
        })
      }}
    />
  )
}

export default RadarChartComponent
