import React, { useState, useEffect } from 'react'
import { Row, Col, Spin, message, Modal } from 'antd'
import styles from './ArticleModal.module.css'
import { CloseCircleOutlined, LoadingOutlined, PushpinOutlined, PushpinFilled } from '@ant-design/icons'
import { post, get, logActionUserRecord } from '../ApiCall'
import { getCfg } from '../../env.config'

const ArticleModal = ({ setVisible, t, content, favorites, setFavorites, articleTime }) => {
  const [load, setLoad] = useState(false)
  const [pinned, setPinned] = useState([])
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />
  const [size, setSize] = useState(window.innerWidth)
  const [linkedArticle, setLinkedArticle] = useState({ loaded: false, data: [] })
  const ref = React.useRef(null)
  const executeScroll = () => {
    window.scrollTo({ behavior: 'smooth', top: ref.current?.offsetTop })
  }

  useEffect(() => {
    executeScroll()
  }, [])

  useEffect(() => {
    function handleResize () {
      setSize(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    if (favorites.data) {
      if (Array.isArray(favorites.data)) {
        const tempState = favorites.data.map((result) => {
          return result.Related_id__c
        })
        setPinned(tempState)
      }
    }
  }, [favorites.data, setFavorites])
  const address = `https://${window.location.href.split('/')[2]}`
  const env = getCfg(address)

  const handlePin = () => {
    if (favorites.data.length < 8) {
      const pinned = favorites.data
        .map((val) => {
          return val
        })
        .map((x) => {
          return {
            Related_id: x.Related_id__c,
            ctype: 'ARTICLE'
          }
        })
      const postData = {
        CIS: [
          {
            Related_id: linkedArticle.data.length > 0 ? linkedArticle.data[0].Id : content.data[0].Id,
            ctype: content.data[0].Video_Id__c ? 'VIDEO' : 'ARTICLE'
          },
          ...pinned
        ]
      }
      setLoad(true)
      post('carrousel', postData, (val) => {
        get('carrousel/all', {}, setFavorites)
        setTimeout(() => {
          setLoad(false)
          message.success(t('article.pinned'))
        }, 1000)
      })
    } else {
      setLoad(true)
      setTimeout(() => {
        setLoad(false)
        Modal.warning({
          title: t('errors.e_pin_limit_exceeded'),
          content: t('errors.e_pin_limit_content'),
          icon: ''
        })
      }, 1000)
    }
  }

  const handleUnpin = () => {
    const pinned = favorites.data
      .map((val) => {
        return val
      })
      .map((x) => {
        return {
          Related_id: x.Related_id__c,
          ctype: x.Type__c
        }
      })
      .filter((value) => {
        const ID = linkedArticle.data.length > 0 ? linkedArticle.data[0].Id : content.data[0].Id
        return value.Related_id !== ID
      })

    const postData = {
      CIS: [...pinned]
    }
    setLoad(true)
    post('carrousel', postData, (val) => {
      get('carrousel/all', {}, setFavorites)
      setTimeout(() => {
        setLoad(false)
        message.success(t('article.unpinned'))
      }, 1000)
    })
  }
  window.clickHandler = function (link) {
    const config = {
      offset: 0,
      limit: 1000
    }

    if (link.includes('/articles/')) {
      setLoad(true)
      // setLinkedArticle({ loaded: false, data: [] })
      const splittedLink = link.split('/')
      const urlName = splittedLink[splittedLink.length - 1]
      get(`articles/detail?UrlName=${urlName}`, config, (values) => {
        setLoad(false)
        setLinkedArticle(values)
      })
    } else {
      const win = window.open(link, '_blank')
      if (win != null) {
        win.focus()
      }
    }
  }
  const [contentLink, setContentLink] = useState('')
  // read article time
  useEffect(() => {
   
    if (!content.data[0].Video_Id__c) {
      const COPYTEST = linkedArticle.loaded ? linkedArticle.data[0].Content__c : content.data[0].Content__c
      const test = document.createElement('div')
      test.innerHTML = COPYTEST.trim()
      const all = test.getElementsByTagName('A')
      let copy = COPYTEST
      for (const link of all) {
        // const replacedText = copy.replace(
        //   link.outerHTML.toString(),
        //   `<a onClick="window.clickHandler('${link.href}')" style='text-decoration: underline'>${link.textContent}</a>`
        // )
        const splittedLink = link.href.split('/articles')[1]
        const newReplacedText = copy.replace(
          `<a href="/articles${splittedLink}" target="_self">`,
          `<a onClick="window.clickHandler('${link.href}')" style='text-decoration: underline'>`
        )
        copy = newReplacedText
      }
      setContentLink(copy)
    }
  }, [content.data, linkedArticle])

  const Iframe = (props) => {
    return <div dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : '' }} />
  }
 
  // component will unmount
  useEffect(() => {
    return () => {
      const payload = {
        // account: localStorage.getItem('username'),
        dateEnd: new Date(),
        dateStart: articleTime, 
        articleID: content.data[0].Id , 
        articleTitle: content.data[0].Title, 
        platform: "Web", 
        type: "Article_read", 
        name: content.data[0].name, 
        // digitalID: "099ec7c3-5a71-446c-bf8b-ee1f817daddc"
      }
      // log user record article open
      logActionUserRecord(payload)
    }
  }, [])
  const height = size < 576 ? 250 : 385
  const getVideoDetail = () => {
    const iframe = `<iframe width="100%" height=${height} frameborder="0" src="https://players.brightcove.net/2299575749001/default_default/index.html?videoId=${content.data[0].Video_Id__c}"></iframe>`
    return (
      <Col xs={20} sm={24} md={24} lg={22} xl={22} xxl={22} className={styles.videoRoot}>
        <Spin indicator={antIcon} spinning={load} className={env.countryClass}>
          <Row>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12} className={styles.titleCol}>
              <Row justify='center'>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <h1 className={styles.title}>{content.data[0].Title}</h1>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} className={styles.desktopHidden}>
                  <img
                    style={{
                      width: '100%',
                      height: '100%',
                      maxHeight: 207,
                      minHeight: 150,
                      backgroundColor: '#A6ADB4'
                    }}
                    src={content.data[0].Image_URL__c}
                    alt={content.data[0].Title}
                  />
                </Col>
                <Col xs={24} sm={10} md={10} lg={24} xl={24} xxl={24}>
                  <p className={styles.subtitle}>{content.data[0].Summary}</p>
                </Col>
                <Col xs={24} sm={14} md={14} lg={12} xl={12} xxl={12} className={styles.tabletContent}>
                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      maxHeight: 207,
                      minHeight: 150,
                      backgroundColor: '#A6ADB4'
                    }}>
                    <img
                      style={{
                        width: '100%',
                        height: '100%',
                        maxHeight: 207,
                        minHeight: 150,
                        backgroundColor: '#A6ADB4'
                      }}
                      src={content.data[0].Image_URL__c}
                      alt={content.data[0].Title}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} className={styles.mobileHidden}>
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  maxHeight: 207,
                  minHeight: 150,
                  backgroundColor: '#A6ADB4'
                }}>
                <img
                  style={{
                    width: '100%',
                    height: '100%',
                    maxHeight: 207,
                    minHeight: 150,
                    backgroundColor: '#A6ADB4'
                  }}
                  src={content.data[0].Image_URL__c}
                  alt={content.data[0].Title}
                />
              </div>
            </Col>
            <Col span={24} style={{ paddingTop: 32 }}>
              <Iframe iframe={iframe} />
            </Col>
          </Row>
        </Spin>
      </Col>
    )
  }

  return (
    <Col
      span={24}
      style={{
        height: '50vh',
        minHeight: '453px',
        position: 'absolute',
        top: 0,
        zIndex: 999,
        width: '100%'
      }}>
      {pinned.includes(linkedArticle.data.length > 0 ? linkedArticle.data[0].Id : content.data[0].Id) ? (
        <PushpinFilled
          className={styles.tabletPinnedBtnSelected}
          onClick={handleUnpin}
          style={{
            borderColor: env.colors.primaryColor,
            backgroundColor: env.colors.primaryColor
          }}
        />
      ) : (
        <PushpinOutlined
          className={styles.tabletPinnedBtn}
          onClick={handlePin}
          style={{
            borderColor: env.colors.primaryColor,
            color: env.colors.primaryColor
          }}
        />
      )}
      <CloseCircleOutlined className={styles.tabletCloseIcon} onClick={() => setVisible(false)} style={{ color: env.colors.primaryColor }} />
      <Row justify='center' className={styles.root}>
        <CloseCircleOutlined className={styles.closeIcon} onClick={() => setVisible(false)} style={{ color: env.colors.primaryColor }} />
        {pinned.includes(linkedArticle.data.length > 0 ? linkedArticle.data[0].Id : content.data[0].Id) ? (
          <PushpinFilled
            style={{
              borderColor: env.colors.primaryColor,
              backgroundColor: env.colors.primaryColor
            }}
            className={styles.pinnedButtonSelected}
            onClick={handleUnpin}
          />
        ) : (
          <PushpinOutlined
            style={{
              borderColor: env.colors.primaryColor,
              color: env.colors.primaryColor
            }}
            className={styles.pinnedButton}
            onClick={handlePin}
          />
        )}
        {content.data[0].Video_Id__c ? (
          getVideoDetail()
        ) : (
          <Col xs={20} sm={24} md={24} lg={22} xl={22} xxl={22} ref={ref} className={styles.articleRoot}>
            <Spin indicator={antIcon} spinning={load} className={env.countryClass}>
              {/* {linkedArticle.data.length > 0 ? (
                <div dangerouslySetInnerHTML={{ __html: linkedArticle.data[0].Content__c }} />
              ) : (
                <div dangerouslySetInnerHTML={{ __html: contentLink }} />
              )} */}
              {<div dangerouslySetInnerHTML={{ __html: contentLink }} />}
            </Spin>
          </Col>
        )}
      </Row>
    </Col>
  )
}

export default ArticleModal
