import React, { useEffect } from 'react'
import {
  Row,
  Col,
  Button,
  Form,
  Checkbox,
  DatePicker,
  TimePicker,
  Select,
  Spin,
} from 'antd'
import {
  AlertOutlined,
  CloseCircleOutlined,
  LoadingOutlined,
} from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import * as moment from 'moment'
import styles from './../../Injection.module.css'
import { post } from '../../../ApiCall'
import { getCfg } from '../../../../env.config'

const InjectionReminder = ({
  setVisible,
  values,
  setValues,
  injection,
  callback,
  setLoaded,
  loaded,
}) => {
  const { t } = useTranslation()
  const dateFormat = 'DD/MM/YYYY'
  const { Option } = Select
  const address = `https://${window.location.href.split('/')[2]}`
  const env = getCfg(address)
  const antIcon = (
    <LoadingOutlined
      style={{ fontSize: 24, color: env.colors.primaryColor }}
      spin
    />
  )
  useEffect(() => {
    if (injection.data[0]) {
      if (injection.data[0].Id) {
        const reminderDate = injection.data[0].On_the_day__pc
          ? moment(injection.data[0].Next_Injection_Datetime__pc)
          : moment(injection.data[0].Next_Injection_Datetime__pc).subtract(
              1,
              'days'
            )

        setValues((oldValues) => ({
          ...oldValues,
          id: injection.data[0].Id,
          injectionDate: moment(injection.data[0].Next_Injection_Datetime__pc),
          frequency: injection.data[0].Injection_Frequency__pc,
          emailNotification: injection.data[0].Email_notification__pc,
          oneDayOption: injection.data[0].Email_notification__pc
            ? injection.data[0].One_day_before__pc
            : false,
          sameDayOption: injection.data[0].Email_notification__pc
            ? injection.data[0].On_the_day__pc
            : false,
          reminderTime: injection.data[0].Email_notification__pc
            ? reminderDate
            : null,
          appNotification: injection.data[0].App_Notification__pc,
          diffFrequency: injection.data[0].Different_Frequency__pc,
        }))
      }
    }
  }, [injection.data, setValues])

  useEffect(() => {
    if (injection.data[0]) {
      if (!values.emailNotification && values.injectionDate !== null) {
        setValues((oldValues) => ({
          ...oldValues,
          oneDayOption: false,
          sameDayOption: false,
          reminderTime: null,
        }))
      }
    }
  }, [
    values.emailNotification,
    setValues,
    injection.data,
    values.injectionDate,
  ])

  const handleChange = (value, name) => {
    setValues((oldState) => ({
      ...oldState,
      [name]: value,
    }))
  }

  const handleClose = () => {
    if (setVisible) {
      setVisible()
    }
    setValues({
      injectionDate: null,
      frequency: 14,
      emailNotification: false,
      oneDayOption: false,
      sameDayOption: false,
      reminderTime: null,
      id: null,
      appNotification: false,
      diffFrequency: false,
    })
  }
  const getDateTime = () => {
    const DATE = moment(values.injectionDate).format('YYYY-MM-DD')
    const TIME = moment(values.reminderTime).format('HH:mm')
    const combinedDateTime = moment(DATE + ' ' + TIME, 'YYYY-MM-DD HH:mm')
    return combinedDateTime
  }

  const postData = {
    nextInjectionDatetime: new Date(getDateTime()),
    OnTheDay: values.sameDayOption,
    OneDayBefore: values.oneDayOption,
    DifferentFrequency: values.diffFrequency,
    AppNotification: values.appNotification,
    EmailNotification: values.emailNotification,
    InjectionFrequency: values.diffFrequency ? values.frequency : 14,
  }

  const handleReminder = () => {
    setLoaded(true)
    post('injectionReminder', postData, callback)
  }

  const disabledOption = () => {
    if (values.emailNotification) {
      if (values.oneDayOption) {
        if (values.reminderTime === null) {
          return true
        } else {
          return false
        }
      } else if (values.sameDayOption) {
        if (values.reminderTime === null) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    } else {
      return false
    }
  }

  const disabledDate = (current) => {
    return (
      current < moment().subtract(1, 'days').endOf('day') ||
      current > moment().add(12, 'months')
    )
  }
  return (
    <Row align='top' className={styles.modalRoot}>
      <Col span={24}>
        <CloseCircleOutlined
          className={styles.closeIcon}
          onClick={handleClose}
          style={{ color: env.colors.primaryColor }}
        />
      </Col>
      <Col span={24} className={styles.root}>
        <Row>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            xxl={12}
            className={styles.rootColLeft}>
            <Row>
              <Col span={24}>
                <p
                  className={styles.paragraph}
                  style={{ color: env.colors.primaryColor }}>
                  {t('injection.reminder.description')}
                </p>
              </Col>
              <Col span={24}>
                <Form name='basic' id='injection-reminder-form'>
                  <Form.Item>
                    <p className={styles.date}>
                      {t('injection.reminder.injection_date')}
                    </p>
                    <DatePicker
                      id='injection-date'
                      className={styles.datePicker}
                      onChange={(date) => handleChange(date, 'injectionDate')}
                      format={dateFormat}
                      disabledDate={disabledDate}
                      value={values.injectionDate}
                      inputReadOnly
                      style={{
                        width: '100%',
                        border: '1px solid',
                        borderColor: env.colors.borderColor,
                        height: 38,
                        borderRadius: 10,
                        fontSize: 18,
                      }}
                      placeholder={t('injection.reminder.dateLabel')}
                      dropdownClassName={env.countryClass}
                    />
                  </Form.Item>
                  <Form.Item>
                    <p className={styles.frequency}>
                      {t('injection.reminder.frequency')}
                    </p>
                    <Select
                      id='injection-frequency'
                      value={values.diffFrequency ? values.frequency : 14}
                      disabled={!values.diffFrequency}
                      size='large'
                      bordered={false}
                      placeholder={t('injection.reminder.injection_frequency')}
                      className={styles.salutationSelect}
                      allowClear
                      onChange={(value) => handleChange(value, 'frequency')}
                      style={{
                        fontSize: '18px',
                        width: '100%',
                        backgroundColor: '#fff',
                        height: 38,
                      }}>
                      {Array.from(new Array(30)).map((_, i) => {
                        const value = i + 1
                        return (
                          <Option key={i} value={value}>
                            {t(`injection.reminder.select.${value}`)}
                          </Option>
                        )
                      })}
                    </Select>
                    <div className={styles.choice}>
                      <Checkbox
                        checked={values.diffFrequency}
                        onChange={(e) =>
                          handleChange(e.target.checked, 'diffFrequency')
                        }
                      />
                      <span className={styles.choiceText}>
                        {t('injection.reminder.frequency_option')}
                      </span>
                    </div>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            xxl={12}
            className={styles.rootColRight}>
            <div className={styles.title}>
              <AlertOutlined
                className={styles.icon}
                style={{ color: env.colors.cardIcon }}
              />
              <span style={{ color: env.colors.cardTitle }}>
                {t('injection.reminder.settings')}
              </span>
            </div>
            <p
              className={styles.typeHeader}
              style={{ color: env.colors.cardTitle }}>
              {t('injection.reminder.type')}
            </p>
            <div className={styles.type}>
              <Checkbox
                checked={values.emailNotification}
                className={styles.checkbox}
                onChange={(e) =>
                  handleChange(e.target.checked, 'emailNotification')
                }
              />
              <span className={styles.text}>
                {t('injection.reminder.email')}
              </span>
            </div>
            <p
              className={styles.timeHeader}
              style={{ color: env.colors.cardTitle }}>
              {t('injection.reminder.time')}
            </p>
            <div className={styles.reminder}>
              <Checkbox
                checked={values.oneDayOption}
                disabled={!values.emailNotification}
                className={styles.checkbox}
                onChange={(e) => handleChange(e.target.checked, 'oneDayOption')}
              />
              <span
                className={styles.reminderText}
                style={{ color: env.colors.cardTitle }}>
                {t('injection.reminder.option1')}
              </span>
            </div>
            <div className={styles.reminder}>
              <Checkbox
                checked={values.sameDayOption}
                disabled={!values.emailNotification}
                className={styles.checkbox}
                onChange={(e) =>
                  handleChange(e.target.checked, 'sameDayOption')
                }
              />
              <span
                className={styles.reminderText}
                style={{ color: env.colors.cardTitle }}>
                {t('injection.reminder.option2')}
              </span>
            </div>
            <TimePicker
              value={
                values.oneDayOption || values.sameDayOption
                  ? values.reminderTime
                  : null
              }
              className={styles.timePicker}
              placeholder={t('injection.reminder.timeLabel')}
              onChange={(date) => handleChange(date, 'reminderTime')}
              format='HH:mm'
              size='large'
              disabled={!values.oneDayOption && !values.sameDayOption}
              style={{
                marginTop: 10,
                marginBottom: 25,
                border: '1px solid',
                borderColor: env.colors.borderColor,
                borderRadius: 10,
                fontSize: '18px',
                width: '100%',
                height: '38px',
              }}
              dropdownClassName={env.countryClass}
            />
          </Col>
          <Col span={24} className={styles.notice} />
          <Col span={24} style={{ textAlign: 'center' }}>
            <Spin
              indicator={antIcon}
              spinning={loaded}
              className={env.countryClass}>
              <Button
                style={
                  values.injectionDate === null ||
                  values.frequency === null ||
                  disabledOption()
                    ? {
                        backgroundColor: '#ccc',
                        color: '#fff',
                      }
                    : {
                        backgroundColor: env.colors.primaryColor,
                        color: '#fff',
                      }
                }
                className={styles.button}
                onClick={handleReminder}
                disabled={
                  values.injectionDate === null ||
                  values.frequency === null ||
                  disabledOption()
                }>
                {t('injection.reminder.button')}
              </Button>
            </Spin>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default InjectionReminder
