import React, { useState, useEffect } from 'react'
import { Row, Col, Form, Select, DatePicker } from 'antd'
import { getCfg } from '../../../env.config'

const EntyvioView = ({
  form,
  styles,
  values,
  handleSelectValue,
  t,
  doctors,
  hospitals,
  condition,
  configMedication,
  configTreatment,
  matrix,
  handleSelectDoctor
}) => {
  const { Option } = Select
  const [medication, setMedication] = useState('Entyvio')

  useEffect(() => {
    setMedication(values.medication ? values.medication.toLowerCase() : values.medication)
  }, [values.medication])

  useEffect(() => {
    form.setFieldsValue({
      medication: medication
    })
  }, [form, medication])

  const ask_hcp = matrix.data.Ask_HCP !== undefined ? matrix.data.Ask_HCP : true
  const ask_hospital = matrix.data.Ask_Hospital !== undefined ? matrix.data.Ask_Hospital : true

  const address = `https://${window.location.href.split('/')[2]}`
  const env = getCfg(address)
  return (
    <Row>
      <Col xs={24} sm={24} md={24} lg={11} xl={11} xxl={11}>
        <Form form={form}>
          <p className={styles.label}>{t('profile.treatment.labels.condition')}</p>
          <Form.Item name='condition' className={styles.formItem}>
            <Select
              size='large'
              className={styles.profileSelect}
              allowClear
              defaultValue={values.condition}
              style={{
                width: '100%',
                backgroundColor: '#fff',
                borderRadius: 15
              }}
              onChange={(value) => handleSelectValue(value, 'condition')}
              dropdownClassName={env.countryClass}>
              {condition.map((val) => {
                return (
                  <Option key={val.apiname} value={val.apiname}>
                    {val.name}
                  </Option>
                )
              })}
            </Select>
          </Form.Item>
          <p className={styles.label} style={!ask_hcp ? { display: 'none' } : null}>
            {t('profile.treatment.labels.doctor')}
          </p>
          <Form.Item
            name='doctor'
            className={`${styles.formItem} ${env.profile.search ? 'autoComplete' : ''}`}
            rules={[
              {
                required: true,
                message: t('errors.v_doctor')
              }
            ]}
            style={!ask_hcp ? { display: 'none' } : null}>
            <Select
              size='large'
              className={`${env.profile.search ? 'search' : styles.profileSelect}`}
              showSearch={env.profile.search ? true : false}
              allowClear
              defaultValue={values.doctor === '' ? null : values.doctor}
              onChange={(value) => (env.profile.search ? handleSelectDoctor(value) : handleSelectValue(value, 'doctor'))}
              filterOption={(input, option) => {
                return option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }}
              style={{
                fontSize: '18px',
                width: '100%',
                backgroundColor: '#fff',
                borderRadius: 15
              }}
              dropdownClassName={env.countryClass}>
              {Array.isArray(doctors.data)
                ? doctors.data
                    .sort((a, b) => {
                      return a.LastName.localeCompare(b.LastName)
                    })
                    .map((val, i) => {
                      return (
                        <Option key={i} value={val.Id} title={val.LastName + ' ' + val.FirstName}>
                          {val.LastName + ' ' + val.FirstName}
                        </Option>
                      )
                    })
                : null}
            </Select>
          </Form.Item>
          <p className={styles.label} style={!ask_hospital ? { display: 'none' } : null}>
            {t('profile.treatment.labels.hospital')}
          </p>
          <Form.Item
            name='hospital'
            className={`${styles.formItem} autoComplete`}
            rules={[
              {
                required: true,
                message: t('errors.v_hospital')
              }
            ]}
            style={!ask_hospital ? { display: 'none' } : null}>
            <Select
              size='large'
              defaultValue={values.hospital === '' ? null : values.hospital}
              className={styles.profileSelect}
              name='hospital'
              allowClear
              onChange={(value) => handleSelectValue(value, 'hospital')}
              style={{
                fontSize: '18px',
                width: '100%',
                backgroundColor: '#fff',
                borderRadius: 15
              }}
              dropdownClassName={env.countryClass}>
              {Array.isArray(hospitals.data)
                ? hospitals.data
                    .sort((a, b) => {
                      return a.Name.localeCompare(b.Name)
                    })
                    .map((val, i) => {
                      return (
                        <Option key={i} value={val.Id} title={val.Name}>
                          {val.Name}
                        </Option>
                      )
                    })
                : null}
            </Select>
          </Form.Item>
        </Form>
      </Col>
      <Col span={2} />
      <Col className={styles.otherDesktop} xs={24} sm={24} md={24} lg={11} xl={11} xxl={11}>
        <Form form={form}>
          <p className={styles.label} style={!env.profile.contactDetails ? { display: 'none' } : null}>
            {t('profile.treatment.labels.medication')}
          </p>
          <Form.Item name='medication' className={styles.formItem} shouldUpdate style={!env.profile.contactDetails ? { display: 'none' } : null}>
            <Select
              size='large'
              name='medication'
              className={styles.profileSelect}
              allowClear
              style={{
                width: '100%',
                backgroundColor: '#fff',
                borderRadius: 15
              }}
              defaultValue={medication}
              onChange={(value) => handleSelectValue(value, 'medication')}
              dropdownClassName={env.countryClass}>
              {configMedication.map((medication) => (
                <Option key={medication.apiname} value={medication.apiname.toLowerCase()} title={medication.name}>
                  {medication.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <p className={styles.label}>{t('profile.treatment.labels.treatment')}</p>
          <Form.Item
            name='treatment'
            className={styles.formItem}
            rules={[
              {
                required: true,
                message: t('errors.v_treatment')
              }
            ]}>
            <Select
              size='large'
              name='treatment'
              className={styles.profileSelect}
              allowClear
              defaultValue={values.treatment === '' ? null : values.treatment}
              value={values.treatment === '' ? null : values.treatment}
              style={{
                borderRadius: 15,
                width: '100%',
                backgroundColor: '#fff'
              }}
              dropdownClassName={env.countryClass}
              onChange={(value) => handleSelectValue(value, 'treatment')}>
              {configTreatment.map((treatment) => (
                <Option key={treatment.apiname} value={treatment.apiname} title={treatment.name}>
                  {treatment.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <p className={styles.label} style={!env.profile.contactDetails ? { display: 'none' } : null}>
            {t('profile.treatment.labels.start-date')}
          </p>
          <Form.Item
            name='start_date'
            className={styles.formItem}
            style={!env.profile.contactDetails ? { display: 'none' } : null}
            rules={[
              {
                required: true,
                message: t('errors.v_treatment_startdate')
              }
            ]}>
            <DatePicker
              className={`${styles.datepicker}`}
              dropdownClassName={env.countryClass}
              name='start_date'
              format={env.profile.treatmentFormat}
              placeholder=''
              defaultValue={values.start_date}
              inputReadOnly
              style={{
                width: '100%',
                borderRadius: 15,
                borderColor: env.colors.borderColor
              }}
              onChange={(date) => handleSelectValue(date, 'start_date')}
            />
          </Form.Item>
        </Form>
      </Col>
    </Row>
  )
}

export default EntyvioView
