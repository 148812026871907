import React from 'react'
import styles from './MessageCard.module.css'

const MessageCard = ({ icon, text, t, env, size, handleClick, urlName }) => {
  const background = size < 578 ? env.colors.mobileMessageCard : env.colors.messageCard
  return (
    <div className={styles.root} style={{ backgroundColor: background }}>
      <div className={styles.icon}>
        <img src={icon} alt='.' />
      </div>
      <div onClick={handleClick} className={styles.text}>{text}<span>{t('message-board.more')}</span></div>
    </div>
  )
}

export default MessageCard
