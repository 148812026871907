import React, { useState, useEffect } from 'react'
import { Row, Col, Button, Modal, Switch, Spin, message } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import styles from './SharingModal.module.css'
import { useTranslation } from 'react-i18next'
import { post } from '../ApiCall'
import { getCfg } from '../../env.config'

const SharingModal = ({ visible, close, field, isChecked }) => {
  const { t } = useTranslation()
  const [size, setSize] = useState(0)
  const [checked, setChecked] = useState(false)
  const [load, setLoad] = useState(false)
  const address = `https://${window.location.href.split('/')[2]}`
  const env = getCfg(address)
  const antIcon = <LoadingOutlined style={{ fontSize: 24, color: env.colors.iconColor }} spin />

  const postData = {
    bool: checked,
    field: field
  }

  useEffect(() => {
    setChecked(isChecked)
  }, [isChecked])

  const handlePost = () => {
    setLoad(true)
    if (isChecked !== checked) {
      post('follow', postData, (value) => {
        if (value.Id) {
          setLoad(false)
          close()
        } else {
          setLoad(false)
          message.error('You cannot share your results, check the console')
        }
      })
    } else {
      setTimeout(() => {
        setLoad(false)
        close()
      }, 1500)
    }
  }

  const updateSize = () => {
    setSize(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])
  const modalBodyStyle = size < 576 ? { padding: '0 20px 25px' } : { padding: '0 50px 25px' }
  const value = field === 'IBD_DISK' ? 'disk' : 'tracker'
  const getText = () => {
    if (checked) {
      return (
        <div>
          <p className={styles.text}>
            {t(`sharing.${value}.value.2.text.1`)}
          </p>
          <p className={styles.text}>{t(`sharing.${value}.value.2.text.2`)}</p>
        </div>
      )
    } else {
      return (
        <div>
          <p className={styles.text}>
            {t(`sharing.${value}.value.1.text.1`)}
          </p>
          <p className={styles.text}>{t(`sharing.${value}.value.1.text.2`)}</p>

        </div>
      )
    }
  }

  return (
    <Modal
      title={t(`sharing.${value}.title`)}
      visible={visible}
      onCancel={close}
      footer={null}
      width={651}
      maskClosable={false}
      bodyStyle={modalBodyStyle}
      className={`${styles.sharingModal} ${env.countryClass}`}
      closable={false}
    >
      <Row className={styles.modalRoot}>
        <Col
          xs={24} sm={24} md={24} lg={22} xl={22} xxl={22}
          className={styles.modalText}
        >
          <div className={styles.modalDescription}>
            <p className={styles.title} style={checked ? { display: 'none' } : null}>
              {t(`sharing.${value}.subtitle`)}
            </p>
            <div className={styles.switch}>
              <span>{t(`sharing.${value}.value.1.label`)} </span>
              <Switch
                checked={checked}
                onChange={(val) => setChecked(val)}
                style={checked ? {
                  backgroundColor: env.colors.primaryColor
                } : {
                  backgroundColor: 'rgba(0, 0, 0, 0.25)'
                }}
              />
              <span>{t(`sharing.${value}.value.2.label`)}</span>
            </div>
            {getText()}
          </div>
        </Col>
        <Col span={24}>
          <Row gutter={24}>
            <Col xs={24} sm={18} md={18} lg={18} xl={18} xxl={18}>
              <Spin indicator={antIcon} spinning={load}>
                <Button
                  className={styles.modalButton}
                  style={{
                    backgroundColor: env.colors.primaryColor,
                    color: '#fff'
                  }}
                  onClick={handlePost}
                >
                  {t('sharing.button')}
                </Button>
              </Spin>
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  )
}

export default SharingModal
