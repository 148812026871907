import React, { useState, useEffect, useContext } from 'react'
import './App.css'
import { Layout, Result, Button, Spin, Col, ConfigProvider } from 'antd'
import Content from './components/MainContent'
import Footer from './components/Footer'
import Login from './components/Login'
import { BrowserRouter as Router, Switch, Route, useHistory } from 'react-router-dom'
import LandingPage from './components/LandingPage'
import AppContextProvider, { AppContext } from './contexts/AppContext'
import LoginContextProvider from './contexts/LoginContext'
import Article from './components/Article'
import Wrapper from './components/RouteWrapper'
import Register from './components/Register'
import Appointments from './components/AppointmentTracker'
import Injection from './components/Injection'
import InjectionReminder from './components/Injection/InjectionReminder'
import Medication from './components/MedicationTracker'
import Personalization from './components/Personalization'
import Profile from './components/Profile'
import IBDDisk from './components/IBDDisk'
import HarveyBradshaw from './components/Harvey-Bradshaw'
import PartialMayo from './components/Partial-Mayo'
import EnrolmentTracker from './components/Enrolment'
import Survey from './components/Survey'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import { getCfg } from './env.config'
import { header, post } from './components/ApiCall'
import Validation from './components/Register/ValidationPage'
import MailView from './components/Register/ResetPassword/MailView'
import PasswordView from './components/Register/ResetPassword/PasswordView'
import ConfirmModal from './components/ConfirmationModal'
import StatusModal from './components/ErrorHandlerModal'
import PromoView from './components/PromoView'
import Selector from './components/LanguageSelector'
import moment from 'moment'
import * as qs from 'query-string'
import 'moment/min/locales.min'
import frFR from 'antd/lib/locale/fr_FR'
import frBE from 'antd/lib/locale/fr_BE'
import nlBE from 'antd/lib/locale/nl_BE'
import roRO from 'antd/lib/locale/ro_RO'
import hrHR from 'antd/lib/locale/hr_HR'
import bgBG from 'antd/lib/locale/bg_BG'
import huHU from 'antd/lib/locale/hu_HU'
// import ltLT from 'antd/lib/locale/lt_LT'
import lvLV from 'antd/lib/locale/lv_LV'
import enUS from 'antd/lib/locale/en_US'
import srRS from 'antd/lib/locale/sr_RS'
import plPL from 'antd/lib/locale/pl_PL'

/* global localStorage */
import { Helmet, HelmetProvider } from 'react-helmet-async'
const NotFound = () => {
  const { t } = useTranslation()
  const handleClick = () => {
    const address = `https://${window.location.href.split('/')[2]}`
    localStorage.setItem('i18nextLng', `${getCfg(address).supported_cultures[0]}`)
    window.location.href = `/?culture=${getCfg(address).supported_cultures[0]}`
  }

  return (
    <Layout
      style={{
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: '#fff'
      }}>
      <Result
        status='404'
        title='404'
        subTitle={t('app.text')}
        extra={
          <Button type='primary' onClick={handleClick}>
            {t('app.notfound')}
          </Button>
        }
      />
    </Layout>
  )
}

const NotAllowed = ({ profile }) => {
  // const handleClick = () => {
  //   localStorage.setItem('i18nextLng', `${profile.data.Language}-${profile.data.Country}`)
  //   window.location.href = `${window.location.pathname}?culture=${profile.data.Language}-${profile.data.Country}`
  // }

  return (
    <Layout
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#fff'
      }}>
      <h1>You are not allowed to see this part of the application</h1>
      <h3>
        Please close all browser windows and try again!
        {/* Click{' '}
        <span
          style={{
            color: 'blue',
            textDecoration: 'underline',
            cursor: 'pointer'
          }}
          onClick={handleClick}>
          here
        </span>{' '}
        to go to your application */}
      </h3>
    </Layout>
  )
}

const ProtectedRoutes = (props) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { setContent, setLoaded, setTree, matrix, setMatrix } = useContext(AppContext)

  history.listen((location) => {
    setContent({ loaded: false, data: [] })
    setLoaded(false)
    props.setLocation(location.pathname)
    props.setState((oldState) => ({
      ...oldState,
      loaded: false,
      data: []
    }))
    props.setValid((oldValues) => ({
      ...oldValues,
      loaded: false
    }))
  })

  useEffect(() => {
    window.onpopstate = () => {
      window.location.href = history.location.pathname
    }
  })
  useEffect(() => {
    const address = `https://${window.location.href.split('/')[2]}`
    const env = getCfg(address)
    const search = window.location.search
    const queryString = qs.parse(search)
    if (!queryString.culture && !queryString.code) {
      if (!env.supported_cultures.includes(localStorage.getItem('i18nextLng'))) {
      
      
        history.push(`${history.location.pathname}?culture=${env.supported_cultures[0]}`)
        localStorage.setItem('i18nextLng', `${env.supported_cultures[0]}`)
      } else {
        history.push(`${history.location.pathname}?culture=${localStorage.getItem('i18nextLng')}`)
      }
    } else if (queryString.culture === 'hr') {
      history.push(`${history.location.pathname}?culture=hr-HR`)
      localStorage.setItem('i18nextLng', 'hr-HR')
    }
  }, [history])

  const {
    profile,
    state,
    path,
    search,
    setState,
    setProfile,
    setLocation,
    setSearch,
    setFavorites,
    setCategories,
    treatment,
    setTreatment,
    setHospitals,
    setVideos,
    setPopupSettings,
    setConfig
  } = props

  useEffect(() => {
    const address = `https://${window.location.href.split('/')[2]}`
    const env = getCfg(address)
    axios
      .get(env.appRoot + 'profile2/all', { headers: header })
      .then(function (response) {
        // console.log(response)
        setProfile({
          loaded: true,
          data: response.data
        })
      })
      .catch(function (error) {
        if (error.response !== undefined) {
          setProfile({
            loaded: true,
            data: error.response
          })
        } else {
          setProfile({
            loaded: true,
            data: { error: 'not working!' }
          })
        }
      })
  }, [setProfile])

  useEffect(() => {
    const address = `https://${window.location.href.split('/')[2]}`
    const env = getCfg(address)
    const search = window.location.search
    const queryString = qs.parse(search)
    let lang
    let country
    if (queryString.culture !== undefined) {
      lang = queryString.culture.split('-')[0]
      country = queryString.culture.split('-')[1]
    } else {
      lang = env.language
      country = env.country
    }
    const configHeader = {
      ...header,
      'x-country': country,
      language: lang,
      'x-client': 'web'
    }
    axios
      .get(env.appRoot + 'config/condition', { headers: configHeader })
      .then(function (response) {
        // console.log(response.data)
        setConfig({
          loaded: true,
          data: response.data
        })
      })
      .catch(function (error) {
        if (error.response !== undefined) {
          setConfig({
            loaded: true,
            data: error.response
          })
        } else {
          setConfig({
            loaded: true,
            data: { error: 'undefined' }
          })
        }
      })
  }, [setConfig])

  useEffect(() => {
    const address = `https://${window.location.href.split('/')[2]}`
    const env = getCfg(address)
    if (profile.loaded) {
      const popupHeader = { ...header, 'x-country': profile.data.Country }
      axios
        .get(env.appRoot + 'popupsettings', { headers: popupHeader })
        .then(function (response) {
          setPopupSettings({
            loaded: true,
            data: response.data
          })
        })
        .catch(function (error) {
          console.log('api get', error.response)
          setPopupSettings({
            loaded: true,
            data: error.response
          })
        })
    }
  }, [profile.loaded, profile.data.Country, setPopupSettings])

  useEffect(() => {
    const address = `https://${window.location.href.split('/')[2]}`
    const env = getCfg(address)
    axios
      .get(env.appRoot + 'treatment/all', { headers: header })
      .then(function (response) {
        // console.log(response.data)
        setTreatment({
          loaded: true,
          data: response.data
        })
      })
      .catch(function (error) {
        setTreatment({
          loaded: true,
          data: []
        })
      })
  }, [setTreatment])
  useEffect(() => {
    const address = `https://${window.location.href.split('/')[2]}`
    const env = getCfg(address)
    axios
      .get(env.categoryRoot + 'categories/tree', { headers: header })
      .then(function (response) {
        localStorage.removeItem('isScrollBarVisible')
        setTree({
          loaded: true,
          data: response.data
        })
      })
      .catch(function () {
        const queryString = qs.parse(window.location.search)
        let culture
        if (queryString.culture !== undefined) {
          culture = queryString.culture
        } else {
          culture = env.supported_cultures[0]
        }
        window.location.href = `login/?culture=${culture}`
        setTree({
          loaded: true,
          data: []
        })
      })
  }, [setTree])

  useEffect(() => {
    setLocation(history.location.pathname)
    setSearch(history.location.search)
  }, [setLocation, setSearch, history.location])

  useEffect(() => {
    const address = `https://${window.location.href.split('/')[2]}`
    const env = getCfg(address)
    // const config = {
    //   // country: env.country,
    // }
    // const headerTralala = { ...header, 'x-platform': 'web' }
    if (!matrix.loaded) {
      axios
        .get(env.appRoot + 'matrix2/all', { params: {}, headers: header })
        .then(function (response) {
          // console.log('matrix frate', response)
          setMatrix({
            loaded: true,
            data: response.data
          })
        })
        .catch(function (error) {
          setMatrix({
            loaded: true,
            data: error.response.data
          })
        })
    }
  }, [setMatrix, matrix.loaded])

  useEffect(() => {
    const address = `https://${window.location.href.split('/')[2]}`
    const env = getCfg(address)
    const config = {
      offset: 0,
      limit: 1000
    }
    axios
      .get(env.appRoot + 'categories/all', { params: config, headers: header })
      .then(function (response) {
        setCategories({
          loaded: true,
          data: response.data
        })
      })
      .catch(function (error) {
        setCategories({
          loaded: true,
          data: error.response
        })
      })
  }, [setCategories])

  useEffect(() => {
    const address = `https://${window.location.href.split('/')[2]}`
    const env = getCfg(address)

    axios
      .get(env.appRoot + 'carrousel/all', { headers: header })
      .then(function (response) {
        setFavorites({
          loaded: true,
          data: response.data
        })
      })
      .catch(function (error) {
        setFavorites({
          loaded: true,
          data: error.response
        })
      })
  }, [setFavorites])

  useEffect(() => {
    const address = `https://${window.location.href.split('/')[2]}`
    const env = getCfg(address)
    const config = {
      offset: 0,
      limit: 1000
    }
    if (matrix.data.Video) {
      axios
        .get(env.appRoot + 'videos/all', { params: config, headers: header })
        .then(function (response) {
          // console.log('tralalal video', response)
          setVideos({
            loaded: true,
            data: response.data
          })
          // console.log('this is videos',response.data)
        })
        .catch(function (error) {
          setVideos({
            loaded: true,
            data: error.response
          })
        })
    }
  }, [setVideos, matrix.data.Video])

  useEffect(() => {
    const address = `https://${window.location.href.split('/')[2]}`
    const env = getCfg(address)
    const supportedCulture = () => {
      if (localStorage.getItem('i18nextLng') !== '') return localStorage.getItem('i18nextLng')
      return env.supported_cultures[0]
    }
    const getCategories = (path, headers, setValues) => {
      axios
        .get(env.categoryRoot + path, {
          headers: headers
        })
        .then(function (response) {
          console.log(response)
          setValues({
            loaded: true,
            data: response.data
          })
        })
        .catch(function (error) {
          console.log(error.response)
          console.log(error)
          if (error.response !== undefined) {
            setValues({
              loaded: true,
              data: error.response
            })
          } else {
            setValues({
              loaded: true,
              data: { status: 500 }
            })
          }
          window.location.href = `/login?culture=${supportedCulture()}`
        })
    }
    const getData = (path, params, headers, setValues) => {
      const address = `https://${window.location.href.split('/')[2]}`
      axios
        .get(getCfg(address).appRoot + path, {
          params: params,
          headers: headers
        })
        .then(function (response) {
          // console.log(response)
          setValues({
            loaded: true,
            data: response.data
          })
        })
        .catch(function (error) {
          console.log(error.response)
          setValues({
            loaded: true,
            data: error.response
          })
          window.location.href = `/login?culture=${supportedCulture()}`
        })
    }

    const config = {
      offset: 0,
      limit: 1000
    }

    const headerWithCountry = {
      ...header,
      'x-country': profile.data.Country,
      language: profile.data.Language
    }

    const location = window.location.pathname
    const getCategoryName = () => {
      if (location === '/articles/cd-me') {
        return t('navigation.categories.category1.code')
      } else if (location === '/articles/uc-me') {
        return t('navigation.categories.category7.code')
      } else if (location === '/articles/wellbeing') {
        return t('navigation.categories.category2.code')
      } else if (location === '/articles/nutrition') {
        return t('navigation.categories.category3.code')
      } else if (location === '/articles/recipes') {
        return t('navigation.categories.category4.code')
      } else if (location === '/articles/travel') {
        return t('navigation.categories.category5.code')
      } else if (location === '/articles/entyvio') {
        return t('navigation.categories.category9.code')
      } else if (location === '/articles/sexuality') {
        return t('navigation.categories.category6.code')
      } else if (location === '/articles/patient-organization') {
        return t('navigation.categories.category11.code')
      } else if (location === '/articles/faq') {
        return t('navigation.categories.category12.code')
      } else if (location === '/articles/privacy') {
        return t('navigation.categories.category13.code')
      } else {
        return null
      }
    }

    if (profile.data.Id && !state.loaded) {
      if (path.includes('appointments')) {
        getData('appointments/all', config, header, setState)
      } else if (path.includes('injections')) {
        getData('injectionTracker/all', config, header, setState)
      } else if (path.includes('medications')) {
        getData('medicationReminder/detail', config, header, setState)
      } else if (path.includes('injection-reminder')) {
        getData('injectionReminder/detail', {}, header, setState)
      } else if (path.includes('ibd-disk')) {
        getData('ibd/all', config, header, setState)
      } else if (path.includes('articles/') && path !== '/articles/videos') {
        if (path !== '/articles/videos' && getCategoryName() !== null) {
          getCategories(`categories/${getCategoryName()}/articles`, header, setState)
        }
      } else if (path === '/articles/videos') {
        getData('videos/all', config, header, setState)
      } else if (path === '/personalization') {
        getData('doctors/all', config, headerWithCountry, setState)
        getData('hospitals/all', config, headerWithCountry, setHospitals)
      } else if (path === '/profile') {
        getData('doctors/all', config, headerWithCountry, setState)
        getData('hospitals/all', config, headerWithCountry, setHospitals)
      } else if (path === '/harvey-bradshaw-index') {
        getData('hbi/all', config, header, setState)
      } else if (path === '/partial-mayo-index') {
        getData('partialMayo/all', config, header, setState)
      } else if (path === '/enrolment-tracker') {
        getData('doctors/all', config, headerWithCountry, setState)
      } else if (path === '/survey') {
        getData('survey/all', config, header, setState)
      } else if (path === '/') {
        if (search.includes('culture')) {
          getData('articles/all', config, header, setState)
        } else if (search !== '') {
          console.log('nu fac nimic boss')
        } else {
          getData('articles/all', config, header, setState)
        }
      }
    }
  }, [profile.data, state.loaded, path, setState, search, setHospitals, t])

  useEffect(() => {
    const address = `https://${window.location.href.split('/')[2]}`
    const env = getCfg(address)
    const supportedCulture = () => {
      if (localStorage.getItem('i18nextLng') !== '') return localStorage.getItem('i18nextLng')
      return env.supported_cultures[0]
    }
    if (profile.loaded && !profile.data.Id) {
      window.location.href = `/login?culture=${supportedCulture()}`
    } else if (state.data.status === 500) {
      window.location.href = `/login?culture=${supportedCulture()}`
    }
  }, [profile.loaded, profile.data, t, state.data])

  const address = `https://${window.location.href.split('/')[2]}`
  const env = getCfg(address)

  const isRouteValid = props.children.map((val) => {
    const path = history.location.pathname
    if (val.props.path === path) {
      return true
    } else {
      if (val.props.path === '/articles/:name') {
        if (path === '/articles/cd-me') return true
        else if (path === '/articles/uc-me') return true
        else if (path === '/articles/wellbeing') return true
        else if (path === '/articles/nutrition') return true
        else if (path === '/articles/recipes') return true
        else if (path === '/articles/travel') return true
        else if (path === '/articles/videos') return true
        else if (path === '/articles/entyvio') return true
        else if (path === '/articles/sexuality') return true
        else if (path === '/articles/patient-organization') return true
        else if (path === '/articles/faq') return true
        else if (path === '/articles/privacy') return true
        else if (state.loaded) return true
      } else {
        return false
      }
      return false
    }
  })

  const isQueryParamValid = (value) => {
    const queryString = qs.parse(value)
    if (queryString.culture) {
      if (queryString.culture.includes(profile.data.Country)) {
        if (env.supported_cultures.includes(queryString.culture)) return true
        return false
      } else {
        return false
      }
    } else {
      return false
    }
  }

  const hasUserAccess = (route) => {
    if (!matrix.data.HBI && route === '/harvey-bradshaw-index') {
      return false
    } else if (!matrix.data.PartialMayo && route === '/partial-mayo-index') {
      return false
    } else if (!matrix.data.EnrollmentTracker && route === '/enrolment-tracker') {
      return false
    } else if (!matrix.data.Injection_Reminder && route === '/injection-reminder') {
      return false
    } else if (!matrix.data.IBD && route === '/ibd-disk') {
      return false
    } else if (!matrix.data.Injection_Tracker && route === '/injections') {
      return false
    } else if (!matrix.data.Medication_Reminder && route === '/medications') {
      return false
    } else if (!matrix.data.Appointments && route === '/appointments') {
      return false
    } else if (!matrix.data.Survey && route === '/survey') {
      return false
    } else if (!matrix.data.Video && route === '/articles/videos') {
      return false
    } else if (treatment.data[0]) {
      if (treatment.data[0].Medication__c === 'Other') {
        if (route === '/articles/entyvio') {
          return false
        } else {
          return true
        }
      } else {
        return true
      }
    } else {
      if (route === '/articles/entyvio') {
        return false
      } else {
        return true
      }
    }
  }

  if (profile.loaded) {
    if (!profile.data.Id) {
      axios
        .get(env.activeSession, { withCredentials: true })
        .then((response) => {
          if (response.data.id) {
            axios
              .delete(env.activeSession, { withCredentials: true })
              .then((response) => console.log(response))
              .catch((error) => console.log(error.response))
          }
        })
        .catch((error) => console.log(error.response))
      return (
        <Layout
          style={{
            minHeight: '100%',
            display: 'flex',
            justifyContent: 'center'
          }}
        />
      )
    } else {
      if (state.loaded) {
        if (state.data.status === 500) {
          return (
            <Layout
              style={{
                minHeight: '100%',
                display: 'flex',
                justifyContent: 'center'
              }}
            />
          )
        } else {
          if (!isQueryParamValid(search)) {
            return <NotAllowed profile={profile} />
          } else if (isRouteValid.includes(true) && isQueryParamValid(search) && hasUserAccess(path)) {
            return props.children
          } else {
            return <NotFound profile={profile} />
          }
        }
      } else {
        if (!isRouteValid.includes(true)) {
          return <NotFound profile={profile} />
        } else {
          return (
            <Layout
              style={{
                minHeight: '100%',
                display: 'flex',
                justifyContent: 'center'
              }}>
              <Spin className={env.countryClass} tip={t('app.load')} />
            </Layout>
          )
        }
      }
    }
  } else {
    if (search.includes('culture')) {
      return (
        <Layout
          style={{
            minHeight: '100%',
            display: 'flex',
            justifyContent: 'center'
          }}>
          <Spin className={env.countryClass} tip={t('app.load')} />
        </Layout>
      )
    } else if (search !== '' && !search.includes('culture')) {
      return props.children
    } else {
      return (
        <Layout
          style={{
            minHeight: '100%',
            display: 'flex',
            justifyContent: 'center'
          }}>
          <Spin className={env.countryClass} tip={t('app.load')} />
        </Layout>
      )
    }
  }
}

const App = () => {
  const [valid, setValid] = useState({ loaded: false, authorized: false })
  const [location, setLocation] = useState('')
  const [search, setSearch] = useState('')
  const [categories, setCategories] = useState({ loaded: false, data: [] })
  const [state, setState] = useState({ loaded: false, data: [] })
  const [favorites, setFavorites] = useState({ loaded: false, data: [] })
  const [profile, setProfile] = useState({ loaded: false, data: {} })
  const [treatment, setTreatment] = useState({ loaded: false, data: [] })
  const [hospitals, setHospitals] = useState({ loaded: false, data: [] })
  const [videos, setVideos] = useState({ loaded: false, data: [] })
  const [popupSettings, setPopupSettings] = useState({
    loaded: false,
    data: []
  })
  const [config, setConfig] = useState({ loaded: false, data: {} })
  const [show, setShow] = useState(true)
  const [open, setOpen] = useState(false)
  const [openStatus, setOpenStatus] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const { t } = useTranslation()
  const [langState, setLangState] = useState('')
  useEffect(() => {
    const search = window.location.search
    const queryString = qs.parse(search)
    let lang
    if (queryString.culture !== undefined) {
      lang = queryString.culture.split('-')[0]
      setLangState(queryString.culture.split('-')[0])
    } else {
      lang = 'en'
      setLangState('en')
    }
    moment.locale(lang)
  }, [])

  useEffect(() => {
    const address = `https://${window.location.href.split('/')[2]}`
    const env = getCfg(address)
    if (treatment.data) {
      if (treatment.data[0]) {
        if (treatment.data[0].Medication__c.toLowerCase() === 'entyvio') {
          const showModal = moment(new Date()).isSameOrAfter(treatment.data[0].Date_Next_Pop_Up__c)
          if (showModal && env.enhancements.includes('still_entyvio')) setShow(true)
        } else {
          setShow(false)
        }
      } else {
        setShow(false)
      }
    } else {
      setShow(false)
    }
  }, [treatment.data])

  const handleYes = () => {
    const postData = {
      id: treatment.data[0].Id,
      condition: treatment.data[0].Condition__c,
      medication: treatment.data[0].Medication__c,
      treatment_startdate: treatment.data[0].Treatment_startdate__c,
      treatment: treatment.data[0].Treatment__c,
      dateNextPopUp: getPopupDate()
    }
    setLoaded(true)
    post('treatment', postData, (value) => {
      if (value.Id) {
        setLoaded(false)
        setShow(false)
        setOpenStatus(true)
      } else {
        setLoaded(false)
        console.log('there is an error')
      }
    })
  }
  const handleNo = () => {
    const postData = {
      id: treatment.data[0].Id,
      condition: treatment.data[0].Condition__c,
      medication: 'Other'
    }
    setLoaded(true)
    post('treatment', postData, (value) => {
      if (value.Id) {
        setLoaded(false)
        setShow(false)
        setOpenStatus(true)
      } else {
        setLoaded(false)
        console.log('there is an error')
      }
    })
  }
  const handleClickStatus = () => {
    setOpenStatus(false)
    window.location.reload()
  }
  const getPopupDate = () => {
    const treatmentType = treatment.data[0] ? (treatment.data[0].Treatment__c ? treatment.data[0].Treatment__c : '') : ''
    const filteredArray = popupSettings.data.filter((item) => item.Treatment__c === treatmentType)[0]
    let tempDate
    if (treatmentType !== '' && filteredArray) {
      tempDate = moment(new Date()).add(filteredArray.Number_of_days__c, 'd').format('YYYY-MM-DD')
    }
    return tempDate
  }

  const confirmClose = () => {
    if (open) {
      handleNo()
    } else {
      handleYes()
    }
  }

  const confirmAction = () => {
    if (open) {
      setOpen(false)
    } else {
      setOpen(true)
    }
  }

  const getText = () => {
    if (open) {
      return (
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          xxl={24}
          style={{
            marginTop: 10,
            minHeight: 60,
            fontSize: 20,
            lineHeight: 'normal',
            color: '#202124'
          }}>
          <p>{t('confirm.not.text.1')}</p>
          <p>{t('confirm.not.text.2')}</p>
        </Col>
      )
    } else {
      return (
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} style={{ marginTop: 10, minHeight: 60 }}>
          <p style={{ fontSize: 20, lineHeight: '24px', color: '#202124' }}>{t('confirm.text')}</p>
        </Col>
      )
    }
  }

  const getLocale = () => {
    const search = window.location.search
    const queryString = qs.parse(search)
    let locale
    if (queryString.culture !== undefined) {
      locale = queryString.culture
    } else {
      locale = null
    }

    if (locale === 'fr-BE') return frBE
    if (locale === 'fr-FR') return frFR
    if (locale === 'nl-BE') return nlBE
    if (locale === 'ro-RO') return roRO
    if (locale === 'hr-HR') return hrHR
    if (locale === 'bg-BG') return bgBG
    if (locale === 'hu-HU') return huHU
    if (locale === 'lv-LV') return lvLV
    if (locale === 'fr-LU') return frFR
    if (locale === 'fr-CA') return frFR
    if (locale === 'en-BE') return enUS
    if (locale === 'en') return enUS
    if (locale === 'en-CH') return enUS
    if (locale === 'sr-RS') return srRS
    if (locale === 'pl-PL') return plPL
    return locale
  }
  const address = `https://${window.location.href.split('/')[2]}`
  const env = getCfg(address)
  return (
    <HelmetProvider>
      <Helmet htmlAttributes={{ lang: langState }}>
        <script type='text/javascript' src={`${env.cookieOneTrust}`} />
        <script src={`${env.cookieCdnScript}`} data-document-language='true' type='text/javascript' charset='UTF-8' data-domain-script={`${env.cookieId}`} />
        <script type='text/javascript'>
          {
            `function OptanonWrapper() { }`
          }
        </script>
      </Helmet>
      <LoginContextProvider>
        <AppContextProvider>
          <Layout style={{ minHeight: '100vh' }}>
            <ConfigProvider locale={getLocale()}>
              <Router>
                <Switch>
                  <Route exact path='/promo'>
                    <PromoView />
                  </Route>
                  <Route exact path='/selector'>
                    <Selector />
                  </Route>
                  <Route exact path='/login'>
                    <Login />
                  </Route>
                  <Route exact path='/register'>
                    <Register />
                  </Route>
                  <Route exact path='/landing'>
                    <LandingPage />
                  </Route>
                  <Route exact path='/account-validation'>
                    <Validation />
                  </Route>
                  <Route exact path='/forgot-password'>
                    <MailView />
                  </Route>
                  <Route exact path='/password_reset/init'>
                    <PasswordView />
                  </Route>
                  <ProtectedRoutes
                    valid={valid}
                    setValid={setValid}
                    setLocation={setLocation}
                    path={location}
                    setSearch={setSearch}
                    search={search}
                    state={state}
                    setState={setState}
                    categories={categories}
                    setCategories={setCategories}
                    favorites={favorites}
                    setFavorites={setFavorites}
                    profile={profile}
                    setProfile={setProfile}
                    treatment={treatment}
                    setTreatment={setTreatment}
                    setHospitals={setHospitals}
                    setVideos={setVideos}
                    setPopupSettings={setPopupSettings}
                    setConfig={setConfig}>
                    <Route exact path='/'>
                      <Content
                        articles={state}
                        setArticles={setState}
                        categories={categories}
                        favorites={favorites}
                        setFavorites={setFavorites}
                        treatment={treatment}
                        path={location}
                        profile={profile}
                        setProfile={setProfile}
                        videos={videos}
                      />
                    </Route>
                    <Route exact path='/articles/:name'>
                      <Wrapper
                        treatment={treatment}
                        favorites={favorites}
                        setFavorites={setFavorites}
                        path={location}
                        profile={profile}
                        setProfile={setProfile}
                        videos={videos}
                        articles={state}>
                        <Article articles={state} setArticles={setState} location={location} favorites={favorites} setFavorites={setFavorites} />
                      </Wrapper>
                    </Route>
                    <Route exact path='/ibd-disk'>
                      <Wrapper
                        treatment={treatment}
                        favorites={favorites}
                        setFavorites={setFavorites}
                        path={location}
                        profile={profile}
                        setProfile={setProfile}
                        videos={videos}>
                        <IBDDisk disk={state} setDisk={setState} />
                      </Wrapper>
                    </Route>
                    <Route exact path='/appointments'>
                      <Wrapper
                        treatment={treatment}
                        favorites={favorites}
                        setFavorites={setFavorites}
                        path={location}
                        profile={profile}
                        setProfile={setProfile}
                        videos={videos}>
                        <Appointments appointments={state} setAppointments={setState} treatment={treatment} profile={profile} />
                      </Wrapper>
                    </Route>
                    <Route exact path='/injections'>
                      <Wrapper
                        treatment={treatment}
                        favorites={favorites}
                        setFavorites={setFavorites}
                        path={location}
                        profile={profile}
                        setProfile={setProfile}
                        videos={videos}>
                        <Injection injections={state} setInjections={setState} />
                      </Wrapper>
                    </Route>
                    <Route exact path='/injection-reminder'>
                      <Wrapper
                        treatment={treatment}
                        favorites={favorites}
                        setFavorites={setFavorites}
                        path={location}
                        profile={profile}
                        setProfile={setProfile}
                        videos={videos}>
                        <InjectionReminder injection={state} setInjection={setState} />
                      </Wrapper>
                    </Route>
                    <Route exact path='/profile'>
                      <Profile
                        profile={profile}
                        setProfile={setProfile}
                        treatment={treatment}
                        setTreatment={setTreatment}
                        doctors={state}
                        setDoctors={setState}
                        hospitals={hospitals}
                        setHospitals={setHospitals}
                        setCategories={setCategories}
                        popupSettings={popupSettings}
                        config={config}
                      />
                    </Route>

                    <Route exact path='/harvey-bradshaw-index'>
                      <Wrapper
                        treatment={treatment}
                        favorites={favorites}
                        setFavorites={setFavorites}
                        path={location}
                        state={state}
                        setState={setState}
                        profile={profile}
                        setProfile={setProfile}
                        videos={videos}>
                        <HarveyBradshaw
                          profile={profile}
                          setProfile={setProfile}
                          treatment={treatment}
                          setTreatment={setTreatment}
                          data={state}
                          setData={setState}
                          setCategories={setCategories}
                        />
                      </Wrapper>
                    </Route>
                    <Route exact path='/partial-mayo-index'>
                      <Wrapper
                        treatment={treatment}
                        favorites={favorites}
                        setFavorites={setFavorites}
                        path={location}
                        state={state}
                        setState={setState}
                        profile={profile}
                        setProfile={setProfile}
                        videos={videos}>
                        <PartialMayo
                          profile={profile}
                          setProfile={setProfile}
                          treatment={treatment}
                          setTreatment={setTreatment}
                          data={state}
                          setData={setState}
                          setCategories={setCategories}
                        />
                      </Wrapper>
                    </Route>
                    <Route exact path='/medications'>
                      <Wrapper
                        treatment={treatment}
                        favorites={favorites}
                        setFavorites={setFavorites}
                        path={location}
                        profile={profile}
                        setProfile={setProfile}
                        videos={videos}>
                        <Medication medication={state} setMedication={setState} />
                      </Wrapper>
                    </Route>
                    <Route exact path='/personalization'>
                      <Personalization
                        profile={profile}
                        treatment={treatment}
                        hospitals={hospitals}
                        setHospitals={setHospitals}
                        doctors={state}
                        popUp={popupSettings}
                        config={config}
                      />
                    </Route>
                    <Route exact path='/survey'>
                      <Wrapper
                        treatment={treatment}
                        favorites={favorites}
                        setFavorites={setFavorites}
                        path={location}
                        profile={profile}
                        setProfile={setProfile}
                        setSurvey={setState}
                        videos={videos}>
                        <Survey survey={state} setSurvey={setState} profile={profile} setProfile={setProfile} />
                      </Wrapper>
                    </Route>
                    <Route exact path='/enrolment-tracker'>
                      <Wrapper
                        treatment={treatment}
                        favorites={favorites}
                        setFavorites={setFavorites}
                        path={location}
                        state={state}
                        setState={setState}
                        profile={profile}
                        setProfile={setProfile}
                        videos={videos}>
                        <EnrolmentTracker
                          profile={profile}
                          setProfile={setProfile}
                          treatment={treatment}
                          setTreatment={setTreatment}
                          doctors={state}
                          setDoctors={setState}
                          setCategories={setCategories}
                        />
                      </Wrapper>
                    </Route>
                    <ConfirmModal
                      app
                      visible={show}
                      close={confirmAction}
                      action={confirmClose}
                      title={open ? t('confirm.not.title') : t('confirm.title')}
                      width={850}
                      gutter={24}
                      yes={open ? t('confirm.not.button.1') : t('confirm.yes')}
                      no={open ? t('confirm.not.button.2') : t('confirm.no')}
                      text={getText()}
                      loaded={loaded}
                    />
                    <StatusModal
                      visible={openStatus}
                      close={handleClickStatus}
                      title={t('confirm.status.title')}
                      text={
                        <div
                          style={{
                            lineHeight: 'normal',
                            fontSize: 18,
                            paddingBottom: 12
                          }}>
                          <p>{t('confirm.status.text.1')}</p>
                          <p>{t('confirm.status.text.2')}</p>
                        </div>
                      }
                      button={t('confirm.status.close')}
                    />
                  </ProtectedRoutes>
                  <Route exact path='*'>
                    <NotFound />
                  </Route>
                </Switch>
              </Router>
            </ConfigProvider>
            <Footer lang={langState} />
          </Layout>
        </AppContextProvider>
      </LoginContextProvider>
    </HelmetProvider>
  )
}

export default App
