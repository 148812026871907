import React, { useState, useContext, useEffect, useRef } from 'react'
import { Layout } from 'antd'
import Items from './Items'
import { AppContext } from '../../contexts/AppContext'
import { Link } from 'react-router-dom'
import MobileItems from './Items/MobileItems'
import { getCfg } from '../../env.config'
import { DoubleLeftOutlined } from '@ant-design/icons'
const { Sider } = Layout
/* global localStorage */
const Navigation = (props) => {
  const [absolute, setAbsolute] = useState(false)
  const contextValue = useContext(AppContext)
  const [menuItems, setMenuItems] = useState([])
  const [size, setSize] = useState([100000, 100000])
  const ref = useRef({ current: undefined })

  const { tree } = contextValue
  const { videos } = props
  const updateSize = () => {
    setSize([window.innerWidth, window.innerHeight])
  }
  useEffect(() => {
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])

  useEffect(() => {
    const treeData = tree.data || []
    const tempArray = treeData.map((val) => {
      return val.category
    })
    if (videos.data) {
      if (videos.data.length > 0) {
        tempArray.push('videos')
      }
    }
    setMenuItems(tempArray)
  }, [tree, videos.data])
  const address = `https://${window.location.href.split('/')[2]}`
  const env = getCfg(address)

  const supportedCulture = () => {
    if (localStorage.getItem('i18nextLng') !== '') return localStorage.getItem('i18nextLng')
    return env.supported_cultures[0]
  }

  const styleDesktop = {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    textAlign: 'center'
  }

  const styleTablet = {
    background: `${env.colors.menuColor} 0% 0% no-repeat padding-box`,
    position: 'fixed',
    zIndex: '4',
    top: 78,
    maxHeight: 'calc(100% - 112px)',
    overflow: 'auto'
  }

  const styleMobile = {
    background: `${env.colors.menuColor} 0% 0% no-repeat padding-box`,
    position: 'fixed',
    zIndex: '4',
    top: env.enhancements.includes('message_board') ? 60 : 48,
    maxHeight: 'calc(100% - 47px)',
    overflow: 'auto'
  }

  const handleMenuClick = (item) => {
    if (contextValue.contextData.page === item) {
      contextValue.setContextData((oldValue) => ({
        ...oldValue,
        show: false
      }))
    }
    if (contextValue.contextData.show) {
      contextValue.setContextData((oldValue) => ({
        ...oldValue,
        show: false
      }))
    }
    if (contextValue.contextData.ibdModal !== '') {
      contextValue.setContextData((oldValue) => ({
        ...oldValue,
        ibdModal: ''
      }))
    }
    if (contextValue.contextData.visible) {
      contextValue.setContextData((oldValue) => ({
        ...oldValue,
        visible: false
      }))
    }
    contextValue.setCollapsed(!contextValue.collapsed)
  }

  const logoStyle =
    env.countryClass === 'canada'
      ? {
          width: '100%',
          maxWidth: '132px',
          cursor: 'pointer',
          marginTop: 30
        }
      : {
          width: '100%',
          maxWidth: '125px',
          marginBottom: 10,
          marginTop: 20,
          cursor: 'pointer'
        }
  const logo =
    env.countryClass === 'canada'
      ? {
          width: '100%',
          height: 145,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }
      : null

  const medicationStyle = props.treatment.data[0] ? (props.treatment.data[0].Medication__c !== 'Entyvio' ? { display: 'none' } : null) : { display: 'none' }

  let scrollBarVisible = ref.current?.offsetWidth !== ref.current?.clientWidth
  const isVisible = localStorage.getItem('isScrollBarVisible') !== 'true' ? false : true
  useEffect(() => {
    if (scrollBarVisible && !isVisible) {
      localStorage.setItem('isScrollBarVisible', true)
    }
  }, [tree.loaded, scrollBarVisible, isVisible])

  if (size[0] < 576) {
    return (
      <Sider
        collapsible
        collapsed={!contextValue.collapsed}
        width={260}
        collapsedWidth={absolute ? 0 : 260}
        style={styleMobile}
        onBreakpoint={(broken) => {
          setAbsolute(broken)
        }}
        zeroWidthTriggerStyle={{ display: 'none' }}
        className={env.countryClass}>
        <MobileItems
          treatment={props.treatment}
          handleClick={handleMenuClick}
          menuItems={menuItems}
          supportedCulture={supportedCulture}
          medicationStyle={medicationStyle}
        />
      </Sider>
    )
  } else if (size[0] < 1200) {
    return (
      <Sider
        collapsible
        collapsed={!contextValue.collapsed}
        width={409}
        collapsedWidth={absolute ? 0 : 409}
        style={styleTablet}
        onBreakpoint={(broken) => {
          setAbsolute(broken)
        }}
        zeroWidthTriggerStyle={{ display: 'none' }}
        className={env.countryClass}>
        <MobileItems
          treatment={props.treatment}
          handleClick={handleMenuClick}
          menuItems={menuItems}
          supportedCulture={supportedCulture}
          medicationStyle={medicationStyle}
        />
      </Sider>
    )
  } else {
    return (
      <Sider
        collapsed={false}
        width={224}
        collapsedWidth={absolute ? 0 : 112}
        breakpoint='xxl'
        style={styleDesktop}
        onBreakpoint={(broken) => {
          setAbsolute(broken)
        }}
        zeroWidthTriggerStyle={{
          backgroundColor: '#81c4cf',
          opacity: '0.7',
          display: 'none'
        }}
        className={env.countryClass}>
        <Link to={`/?culture=${supportedCulture()}`} style={window.location.pathname !== '/' ? env.goHomeStyle : { display: 'none' }}>
          <DoubleLeftOutlined /> Go Home
        </Link>
        <div style={logo}>
          <Link to={`/?culture=${supportedCulture()}`}>
            <img src={env.logo} alt='' style={logoStyle} onClick={() => handleMenuClick('')} />
          </Link>
        </div>
        <div
          ref={ref}
          style={{
            marginLeft: isVisible ? 16 : 0,
            maxHeight: 450,
            overflowX: 'hidden',
            overflowY: 'auto',
            direction: 'rtl'
          }}>
          <Items
            treatment={props.treatment}
            handleClick={handleMenuClick}
            menuItems={menuItems}
            getCfg={getCfg}
            address={address}
            supportedCulture={supportedCulture}
            isScrollBarVisible={isVisible}
          />
        </div>

        <Link to={`/articles/entyvio?culture=${supportedCulture()}`} style={medicationStyle}>
          <img
            src={env.entyvioLogo}
            alt='entyvio'
            style={{
              width: 130,
              verticalAlign: 'middle',
              marginTop: 20,
              cursor: 'pointer',
              padding: '6px 20px 6px',
              boxShadow: '0px 0px 5px #0000004D',
              borderRadius: 15
            }}
          />
        </Link>
        <div style={env.onepathButton ? { width: '100%', display: 'flex', justifyContent: 'center' } : { display: 'none' }}>
          <a href='https://onepath.ca' target='_blank' rel='noopener noreferrer'>
            <div
              style={{
                width: 130,
                verticalAlign: 'middle',
                marginTop: 20,
                cursor: 'pointer',
                padding: '6px 20px 6px',
                boxShadow: '0px 0px 5px #0000004D',
                borderRadius: 15,
                display: 'flex',
                flexDirection: 'column',
                lineHeight: 'normal'
              }}>
              <span style={{ fontWeight: 500, fontSize: 18 }}>Onepath</span>
              <span style={{ fontSize: 12 }}>website</span>
            </div>
          </a>
        </div>
      </Sider>
    )
  }
}

export default Navigation
