import React from 'react'
import { Row, Col, Button } from 'antd'
import { getCfg } from '../../../../env.config'
import * as moment from 'moment'
const ResultModal = ({ t, styles, context, state, setState, result }) => {
  const address = `https://${window.location.href.split('/')[2]}`
  const env = getCfg(address)
  const handleClick = () => {
    context.setShow(oldValue => ({
      ...oldValue,
      pmi: false
    }))
    setState({ ...state, loaded: false })
  }
  return (
    <Col
      span={20}
    >
      <Row>
        <Col>
          <h3
            className={styles.questionSubTitle}
            style={{
              color: env.colors.subtitle,
              borderColor: env.colors.primaryColor
            }}
          >
            {t('mayo.result.title')}
          </h3>
        </Col>
      </Row>
      <Row>
        <Col
          xs={24} sm={24} md={24} lg={14} xl={12} xxl={12}
          className={styles.modalRoot}
        >
          <Row />
          <Row>
            <Col xs={6} sm={6} md={6} lg={6} xl={5} xxl={4}>
              <img
                src='/images/CA/questionare@2x.png'
                alt='icon'
                style={{
                  width: '100%',
                  maxWidth: 100,
                  height: '100%',
                  maxHeight: 100
                }}
              />
            </Col>
            <Col
              xs={18} sm={18} md={18} lg={18} xl={19} xxl={20}
              style={{
                color: '#4C4948',
                display: 'flex',
                alignItems: 'center',
                fontSize: 20
              }}
            >
              <Row gutter={[0, 16]} style={{ margin: 0 }}>
                <Col span={24}>
                  <span>{t('mayo.result.score')}</span>{result.score}<span>{t('mayo.result.points')}</span>
                </Col>
                <Col span={24}>
                  <span>{moment(result.date).format('dddd MMMM DD, YYYY')}</span>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col
              span={24}
              style={{
                color: '#4C4948',
                fontWeight: 500,
                lineHeight: '28px',
                fontSize: 20,
                paddingTop: 30
              }}
            >
              <span>{t('mayo.result.text')}
              </span>
            </Col>
          </Row>
          <Row justify='center'>
            <Col
              span={18}
              style={{
                color: '#4C4948',
                fontWeight: 500,
                lineHeight: '28px',
                fontSize: 20,
                paddingTop: 30
              }}
            >
              <Button
                htmlType='submit'
                className={styles.button}
                style={{
                  backgroundColor: env.colors.primaryColor,
                  color: '#fff'
                }}
                onClick={handleClick}
              >
                {t('mayo.result.button')}
              </Button>

            </Col>
          </Row>
        </Col>
      </Row>

    </Col>
  )
}

export default ResultModal
