import React, { useContext } from 'react'
import { Row, Col, Menu } from 'antd'
import { useTranslation } from 'react-i18next'
import styles from './SideBar.module.css'
import { AppContext } from '../../contexts/AppContext'
import { Link } from 'react-router-dom'
import { getCfg } from '../../env.config'
// import { InfoCircleOutlined } from '@ant-design/icons'

/* global localStorage */
const SideBar = ({ profile }) => {
  const contextValue = useContext(AppContext)
  const { t } = useTranslation()
  const address = `https://${window.location.href.split('/')[2]}`
  const env = getCfg(address)
  const supportedCulture = () => {
    if (localStorage.getItem('i18nextLng') !== '') return localStorage.getItem('i18nextLng')
    return env.supported_cultures[0]
  }
  const enrolmentNo = profile.data.Reimbursement_Status

  const { matrix } = contextValue
  const trackerItems = [
    {
      id: '0',
      icon: '/images/CA/enrollment@2x.png',
      text: t('sidebar.items.0'),
      route: '/enrolment-tracker',
      visible: matrix.data.EnrollmentTracker && enrolmentNo !== 0
    },
    {
      id: '1',
      icon: '/images/CA/bradshaw@2x.png',
      text: t('sidebar.items.8'),
      route: '/harvey-bradshaw-index',
      visible: matrix.data.HBI
    },
    {
      id: '2',
      icon: '/images/CA/bradshaw@2x.png',
      text: t('sidebar.items.7'),
      route: '/partial-mayo-index',
      visible: matrix.data.PartialMayo
    },
    {
      id: '3',
      icon: '/images/icon-IBD-disk@2x.png',
      text: t('sidebar.items.1.main'),
      route: '/ibd-disk',
      visible: matrix.data.IBD
    },
    {
      id: '4',
      icon: matrix.data.Country === 'CA' ? '/images/CA/appointments@2x.png' : '/images/appointment_tracker_2x.png',
      text: t('sidebar.items.2'),
      route: '/appointments',
      visible: matrix.data.Appointments
    },
    {
      id: '5',
      icon: matrix.data.Country === 'CA' ? '/images/CA/medication@2x.png' : '/images/appointment_tracker_2x.png',
      text: t('sidebar.items.3'),
      route: '/medications',
      visible: matrix.data.Medication_Reminder
    },
    {
      id: '6',
      icon: matrix.data.Country === 'CA' ? '/images/CA/injection-tracker@2x.png' : '/images/icon-injection-tracker@2x.png',
      text: t('sidebar.items.4'),
      route: '/injections',
      visible: matrix.data.Injection_Tracker
    },
    {
      id: '7',
      icon: matrix.data.Country === 'CA' ? '/images/CA/injection-reminder@2x.png' : '/images/icon-injection-reminder@2x.png',
      text: t('sidebar.items.5'),
      route: '/injection-reminder',
      visible: matrix.data.Injection_Reminder
    },
    {
      id: '8',
      icon: matrix.data.Country === 'CA' ? '/images/CA/Survey@2x.png' : '/images/survey_2x.png',
      text: t('sidebar.items.6'),
      route: '/survey',
      visible: matrix.data.Survey
    }
  ]

  const handleMenuClick = (item) => {
    if (contextValue.contextData.page === item) {
      contextValue.setContextData((oldValue) => ({
        ...oldValue,
        show: false
      }))
    }
    if (contextValue.contextData.show) {
      contextValue.setContextData((oldValue) => ({
        ...oldValue,
        show: false
      }))
    }
    if (contextValue.contextData.ibdModal !== '') {
      contextValue.setContextData((oldValue) => ({
        ...oldValue,
        ibdModal: ''
      }))
    }
    if (contextValue.contextData.visible) {
      contextValue.setContextData((oldValue) => ({
        ...oldValue,
        visible: false
      }))
    }
  }

  return (
    <Menu mode='inline' className={`${styles.root} ${env.countryClass}`} style={{ backgroundColor: env.colors.siderColor }}>
      {trackerItems.map((item) => (
        <Menu.Item
          key={item.id}
          className={window.location.pathname === item.route ? `${styles.menu} ant-menu-item-selected` : `${styles.menu}`}
          style={!item.visible ? { display: 'none' } : null}
          onClick={() => handleMenuClick(item.text)}>
          <Link to={`${item.route}?culture=${supportedCulture()}`}>
            <Row className={styles.item}>
              <Col span={5}>
                <img src={item.icon} alt='item' className={styles.image} />
              </Col>
              <Col span={19}>
                <span className={styles.description}>{` ${item.text}`}</span>
              </Col>
            </Row>
          </Link>
        </Menu.Item>
      ))}
    </Menu>
  )
}

export default SideBar
