import React, { useEffect, useState } from 'react'
import { Row, Col, Form, Button, Checkbox, Select, Spin } from 'antd'
import { getCfg } from '../../../../env.config'
import { InfoCircleOutlined } from '@ant-design/icons'

const QuestionForm = ({ styles, handleClick, t, icon, loaded, values, setValues }) => {
  const { Option } = Select
  const [form] = Form.useForm()
  const address = `https://${window.location.href.split('/')[2]}`
  const env = getCfg(address)
  const [, forceUpdate] = useState()

  useEffect(() => {
    forceUpdate({})
  }, [])

  const handleChange = (name, value) => {
    const insertedValue = value !== undefined ? value : null
    setValues({
      ...values,
      [name]: insertedValue
    })
  }

  const handleChangeComplications = (value) => {
    const tempComplications = []
    const index = values.complications.indexOf(value)
    if (index !== -1) {
      tempComplications.push(...values.complications.filter(x => {
        return x !== value
      }))
    } else {
      tempComplications.push(...values.complications, value)
    }

    setValues({
      ...values,
      complications: tempComplications
    })
  }

  const validateInputs = () => {
    if (values.wellbeing !== null && values.abdominalMass !== null &&
      values.abdominalPain !== null && values.stools !== null &&
      values.complications.length !== 0) {
      return true
    } else {
      return false
    }
  }

  return (
    <Col span={20} className='survey'>
      <Row>
        <Col xs={24} sm={24} md={24} lg={16} xl={13} xxl={12}>
          <p
            className={styles.description}
            style={{ color: '#4C4948' }}
          >
            {t('harvey.description')}
          </p>
        </Col>
      </Row>

      <Row>
        <Col>
          <h3
            className={styles.questionSubTitle}
            style={{
              color: env.colors.subtitle,
              borderColor: env.colors.primaryColor
            }}
          >
            {t('harvey.subtitle')}
          </h3>
        </Col>
      </Row>

      <Row>
        <Col className={styles.questionContent}>
          <p
            className={styles.communicationLabel}
            style={{ color: env.colors.title, fontWeight: 500, fontSize: 18 }}
          >1. <span>{t('harvey.questions.1.bold')}</span> <span style={{ fontWeight: 'normal' }}>{t('harvey.questions.1.regular')}</span>
            <InfoCircleOutlined style={{ color: env.colors.primaryColor, marginLeft: 10 }} />
          </p>
          <Checkbox
            className={env.countryClass}
            checked={values.wellbeing === 0}
            onChange={() => handleChange('wellbeing', 0)}
          >
                      0 = {t('harvey.questions.options.wellbeing.1')}
          </Checkbox>
          <Checkbox
            className={env.countryClass}
            checked={values.wellbeing === 1}
            onChange={() => handleChange('wellbeing', 1)}
          >
                    1 = {t('harvey.questions.options.wellbeing.2')}
          </Checkbox>
          <Checkbox
            className={env.countryClass}
            checked={values.wellbeing === 2}
            onChange={() => handleChange('wellbeing', 2)}
          >
                    2 = {t('harvey.questions.options.wellbeing.3')}
          </Checkbox>
          <Checkbox
            className={env.countryClass}
            checked={values.wellbeing === 3}
            onChange={() => handleChange('wellbeing', 3)}
          >
                    3 = {t('harvey.questions.options.wellbeing.4')}
          </Checkbox>
          <Checkbox
            className={env.countryClass}
            checked={values.wellbeing === 4}
            onChange={() => handleChange('wellbeing', 4)}
          >
                    4 = {t('harvey.questions.options.wellbeing.5')}
          </Checkbox>
        </Col>
      </Row>
      <Row>
        <Col className={styles.questionContent}>
          <p
            className={styles.communicationLabel}
            style={{ color: env.colors.title, fontWeight: 500, fontSize: 18 }}
          >2. <span>{t('harvey.questions.2.bold')}</span> <span style={{ fontWeight: 'normal' }}>{t('harvey.questions.2.regular')}</span>
            <InfoCircleOutlined style={{ color: env.colors.primaryColor, marginLeft: 10 }} />
          </p>
          <Checkbox
            className={env.countryClass}
            checked={values.abdominalPain === 0}
            onChange={() => handleChange('abdominalPain', 0)}
          >
                      0 = {t('harvey.questions.options.pain.1')}
          </Checkbox>
          <Checkbox
            className={env.countryClass}
            checked={values.abdominalPain === 1}
            onChange={() => handleChange('abdominalPain', 1)}
          >
                    1 = {t('harvey.questions.options.pain.2')}
          </Checkbox>
          <Checkbox
            className={env.countryClass}
            checked={values.abdominalPain === 2}
            onChange={() => handleChange('abdominalPain', 2)}
          >
                    2 = {t('harvey.questions.options.pain.3')}
          </Checkbox>
          <Checkbox
            className={env.countryClass}
            checked={values.abdominalPain === 3}
            onChange={() => handleChange('abdominalPain', 3)}
          >
                    3 = {t('harvey.questions.options.pain.4')}
          </Checkbox>
        </Col>
      </Row>
      <Row>
        <Col
          xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}
          className={styles.questionContent}
        >
          <p
            className={styles.communicationLabel}
            style={{ color: env.colors.title, fontWeight: 500, fontSize: 18 }}
          >3. <span>{t('harvey.questions.3.bold')}</span> <span style={{ fontWeight: 'normal' }}>{t('harvey.questions.3.regular')}</span>
            <InfoCircleOutlined style={{ color: env.colors.primaryColor, marginLeft: 10 }} />
          </p>
          <Select
            size='large'
            name='stools'
            placeholder={t('harvey.questions.options.select')}
            className={styles.questionSelect}
            value={values.stools !== null ? parseInt(values.stools) : values.stools}
            allowClear
            style={{
              borderRadius: 15,
              width: '50%',
              backgroundColor: '#fff',
              marginBottom: 20
            }}
            onChange={(val) => handleChange('stools', val)}
            dropdownClassName={env.countryClass}
          >
            {Array.from(new Array(26)).map((val, i) => {
              return (
                <Option key={i} value={val}>{val}</Option>
              )
            })}
          </Select>
        </Col>
      </Row>
      <Row>
        <Col
          className={styles.questionContent}
        >
          <p
            className={styles.communicationLabel}
            style={{ color: env.colors.title, fontWeight: 500, fontSize: 18 }}
          >4. <span style={{ fontWeight: 500 }}>{t('harvey.questions.4.bold')}</span>
            <InfoCircleOutlined style={{ color: env.colors.primaryColor, marginLeft: 10 }} />
          </p>
          <Checkbox
            className={env.countryClass}
            checked={values.abdominalMass === 0}
            onChange={() => handleChange('abdominalMass', 0)}
          >
                      0 = {t('harvey.questions.options.mass.1')}
          </Checkbox>
          <Checkbox
            className={env.countryClass}
            checked={values.abdominalMass === 1}
            onChange={() => handleChange('abdominalMass', 1)}
          >
                    1 = {t('harvey.questions.options.mass.2')}
          </Checkbox>
          <Checkbox
            className={env.countryClass}
            checked={values.abdominalMass === 2}
            onChange={() => handleChange('abdominalMass', 2)}
          >
                    2 = {t('harvey.questions.options.mass.3')}
          </Checkbox>
          <Checkbox
            className={env.countryClass}
            checked={values.abdominalMass === 3}
            onChange={() => handleChange('abdominalMass', 3)}
          >
                    3 = {t('harvey.questions.options.mass.4')}
          </Checkbox>
        </Col>
      </Row>

      <Row>
        <Col
          xs={24} sm={24} md={24} lg={18} xl={16} xxl={14}
          className={styles.questionContent}
        >
          <p
            className={styles.communicationLabel}
            style={{ color: env.colors.title, fontWeight: 500, fontSize: 18 }}
          >5. <span>{t('harvey.questions.5.bold')}</span><span style={{ fontWeight: 'normal' }}>{t('harvey.questions.5.regular')}</span>
            <InfoCircleOutlined style={{ color: env.colors.primaryColor, marginLeft: 10 }} />
          </p>
          <Checkbox
            className={env.countryClass}
            onChange={() => handleChangeComplications('arthralgia')}
          >
            <span>{t('harvey.questions.5.options.1.regular')}</span><span style={{ fontWeight: 500, paddingLeft: 6 }}>{t('harvey.questions.5.options.1.bold')}</span>
          </Checkbox>
          <Checkbox
            className={env.countryClass}
            onChange={() => handleChangeComplications('uveitis')}
          >
            <span>{t('harvey.questions.5.options.2.regular')}</span><span style={{ fontWeight: 500, paddingLeft: 6 }}>{t('harvey.questions.5.options.2.bold')}</span>
          </Checkbox>
          <Checkbox
            onChange={() => handleChangeComplications('erythema_nodosum')}
            className={env.countryClass}
          >
            <span>{t('harvey.questions.5.options.3.regular')}</span><span style={{ fontWeight: 500, paddingLeft: 6 }}>{t('harvey.questions.5.options.3.bold')}</span>
          </Checkbox>
          <Checkbox
            onChange={() => handleChangeComplications('aphthous_ulcer')}
            className={env.countryClass}
          >
            <span>{t('harvey.questions.5.options.4.regular')}</span><span style={{ fontWeight: 500, paddingLeft: 6 }}>{t('harvey.questions.5.options.4.bold')}</span>
          </Checkbox>
          <Checkbox
            onChange={() => handleChangeComplications('pyoderma_gangrenosum')}
            className={env.countryClass}
          >
            <span>{t('harvey.questions.5.options.5.regular')}</span><span style={{ fontWeight: 500, paddingLeft: 6 }}>{t('harvey.questions.5.options.5.bold')}</span>
          </Checkbox>
          <Checkbox
            onChange={() => handleChangeComplications('anal_fissures')}
            className={env.countryClass}
          >
            <span>{t('harvey.questions.5.options.6.regular')}</span><span style={{ fontWeight: 500, paddingLeft: 6 }}>{t('harvey.questions.5.options.6.bold')}</span>
          </Checkbox>
          <Checkbox
            onChange={() => handleChangeComplications('appearance_fistula')}
            className={env.countryClass}
          >
            <span>{t('harvey.questions.5.options.7.regular')}</span><span style={{ fontWeight: 500, paddingLeft: 6 }}>{t('harvey.questions.5.options.7.bold')}</span>
          </Checkbox>
          <Checkbox
            onChange={() => handleChangeComplications('abscess')}
            className={env.countryClass}
          >
            <span>{t('harvey.questions.5.options.8.regular')}</span><span style={{ fontWeight: 500, paddingLeft: 6 }}>{t('harvey.questions.5.options.8.bold')}</span>
          </Checkbox>
        </Col>
      </Row>

      <Row>
        <Col xs={24} sm={24} md={24} lg={10} xl={8} xxl={6}>
          <Form form={form}>
            <Form.Item shouldUpdate>
              {() => (
                <Spin indicator={icon} spinning={loaded}>
                  <Button
                    htmlType='submit'
                    className={styles.button}
                    onClick={handleClick}
                    style={validateInputs() ? {
                      backgroundColor: env.colors.primaryColor,
                      color: '#fff'
                    } : {
                      backgroundColor: '#ccc',
                      color: '#fff'
                    }}
                    disabled={!validateInputs()}
                  >
                    {t('harvey.questions.button')}
                  </Button>
                </Spin>
              )}
            </Form.Item>
          </Form>
        </Col>
      </Row>

    </Col>
  )
}

export default QuestionForm
