import React from 'react'
import { Row, Col, Button } from 'antd'
import { Link } from 'react-router-dom'
import * as qs from 'query-string'

const Confirmation = ({ setVisible, t, styles, getCfg }) => {
  const address = `https://${window.location.href.split('/')[2]}`
  const env = getCfg(address)

  const search = window.location.search
  const queryString = qs.parse(search)
  let culture
  if (queryString.culture !== undefined) {
    culture = queryString.culture
  } else {
    culture = env.supported_cultures[0]
  }

  return (
    <Row>
      <Col xs={24} sm={24} md={24} lg={14} xl={14} xxl={14} className={styles.confirmationRoot}>
        <Row justify='start'>
          {env.countryClass !== 'canada' ? (
            <Col xs={24} sm={20} md={20} lg={19} xl={19} xxl={19}>
              <div className={styles.confirmationLogo}>
                <img src='/images/fywy-text@2x.png' alt='logo' />
              </div>
            </Col>
          ) : null}

          <Col xs={24} sm={20} md={20} lg={19} xl={19} xxl={19} className={styles.confirmationPlaceholder}>
            <p style={{ fontSize: 20, fontWeight: 500, color: '#4C4948', lineHeight: '23px', margin: 0, paddingBottom: 30 }}>{t('confirmation.title')}</p>
            <p style={{ fontSize: 20, color: '#4C4948', lineHeight: '27px', margin: 0 }}>{t('confirmation.text')}</p>
          </Col>
          <Col xs={24} sm={15} md={14} lg={16} xl={15} xxl={14} className={`${styles.buttonRegister} ${styles.confirmButton}`}>
            <Link to={`/login?culture=${culture}`}>
              <Button
                style={{
                  backgroundColor: env.colors.primaryColor,
                  border: '1px solid',
                  borderColor: env.colors.primaryColor,
                  color: '#fff'
                }}
                htmlType='submit'
                onClick={() =>
                  setVisible((oldValues) => ({
                    ...oldValues,
                    confirmation: false
                  }))
                }>
                {t('register.form.button.3')}
              </Button>
            </Link>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default Confirmation
