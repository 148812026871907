import React, { useEffect, useState } from 'react'
import { Layout, Col, Row, Form, Input, Button, Spin } from 'antd'
import { useTranslation } from 'react-i18next'
import styles from './PasswordView.module.css'
import { getCfg } from '../../../../env.config'
import { LoadingOutlined, LockOutlined } from '@ant-design/icons'
import { Link, useLocation } from 'react-router-dom'
import axios from 'axios'
import * as qs from 'query-string'
import NotAllowed from '../../../NotAllowed'
import { logAction } from '../../../ApiCall'

const PasswordView = () => {
  const { t } = useTranslation()
  const { Content } = Layout
  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState(false)
  const address = `https://${window.location.href.split('/')[2]}`
  const env = getCfg(address)
  const antIcon = <LoadingOutlined style={{ fontSize: 24, color: env.colors.primaryColor }} spin />
  const location = useLocation()
  const [form] = Form.useForm()
  const [, forceUpdate] = useState()
  const [values, setValues] = useState({
    password: '',
    firstname: '',
    lastname: ''
  })
  const [state, setState] = useState({ loaded: false, error: false, data: {} })
  const [countdown, setCountDown] = useState(5)
  const queryString = qs.parse(window.location.search)

  useEffect(() => {
    const address = `https://${window.location.href.split('/')[2]}`
    const env = getCfg(address)
    const queryString = qs.parse(window.location.search)

    let culture
    if (queryString.culture !== undefined) {
      culture = queryString.culture
    } else {
      culture = env.supported_cultures[0]
    }
    if (queryString.culture === undefined && queryString.recoveryToken === undefined) {
      localStorage.setItem('i18nextLng', `${culture}`)
      window.location.href = window.location.pathname + '?culture=' + culture
    } else if (queryString.culture === 'hr' && queryString.recoveryToken === undefined) {
      localStorage.setItem('i18nextLng', 'hr-HR')
      window.location.href = window.location.pathname + '?culture=hr-HR'
    } else if (queryString.recoveryToken !== undefined && queryString.culture === undefined) {
      localStorage.setItem('i18nextLng', `${culture}`)
      window.location.href = window.location.pathname + window.location.search + '&culture=' + culture
    } else if (queryString.recoveryToken !== undefined && queryString.culture === 'hr') {
      localStorage.setItem('i18nextLng', 'hr-HR')
      window.location.href = window.location.pathname + '?recoveryToken=' + queryString.recoveryToken + '&culture=hr-HR'
    }
  }, [])

  useEffect(() => {
    const address = `https://${window.location.href.split('/')[2]}`
    const env = getCfg(address)
    const tempArray = location.search.substring(1, location.search.length).split('&')
    const queryString = qs.parse(window.location.search)

    let country
    if (queryString.culture !== undefined) {
      country = queryString.culture.split('-')[1]
    } else {
      country = env.country
    }

    const tempObject = {}
    tempArray.map((value) => {
      const param = value.split('=')
      tempObject[param[0]] = param[1]
      return null
    })

    const startPayload = {
      LogTitle: 'Password Reset Clicked',
      okta_recovery_token__c: tempObject.recoveryToken,
      country__c: country,
      password_reset_clicked__c: new Date().toISOString(),
      password_reset_completed__c: 'null'
    }
    logAction('user/log', startPayload, {})
    const errorPayload = {
      LogTitle: 'Password Reset Error',
      okta_recovery_token__c: tempObject.recoveryToken,
      country__c: country,
      password_reset_error__c: new Date().toISOString(),
      password_reset_error_msg__c: '',
      password_reset_completed__c: 'null'
    }
    const headers = {
      client_id: env.loginClientId,
      client_secret: env.loginClientSecret,
      'Content-Type': 'application/json'
    }
    axios
      .post(
        env.verifyToken,
        {
          recoveryToken: tempObject.recoveryToken
        },
        {
          headers: headers
        }
      )
      .then(function (response) {
        if (response.status >= 200 && response.status <= 299) {
          setState({ loaded: true, error: false, data: response.data })
        } else {
          logAction('user/log', { ...errorPayload, password_reset_error_msg__c: JSON.stringify(response.data) }, {})
          setState({ loaded: true, error: true, data: response.data })
        }
      })
      .catch(function (error) {
        logAction('user/log', { ...errorPayload, password_reset_error_msg__c: error.response?.data?.errorSummary }, {})
        setState({ loaded: true, error: true, data: { error: true } })
      })
  }, [location.search])

  useEffect(() => {
    const address = `https://${window.location.href.split('/')[2]}`
    const env = getCfg(address)
    let interval
    if (state.loaded) {
      if (state.error) {
        interval = setInterval(() => {
          if (countdown > 1) {
            setCountDown(countdown - 1)
          } else {
            window.location.href = `/login?culture=${env.supported_cultures[0]}`
          }
        }, 1000)
      }
    }
    return () => clearInterval(interval)
  }, [countdown, state])

  useEffect(() => {
    if (state.loaded && !state.error) {
      setValues((oldValues) => ({
        ...oldValues,
        firstname: state.data._embedded.user.profile.firstName,
        lastname: state.data._embedded.user.profile.lastName
      }))
    }
  }, [state])

  useEffect(() => {
    forceUpdate({})
  }, [])

  const handleSubmit = () => {
    setLoading(true)
    const headers = {
      client_id: env.loginClientId,
      client_secret: env.loginClientSecret,
      'Content-Type': 'application/json'
    }
    const queryString = qs.parse(window.location.search)
    let country
    if (queryString.culture !== undefined) {
      country = queryString.culture.split('-')[1]
    } else {
      country = env.country
    }
    const successPayload = {
      LogTitle: 'Password Reset Completed',
      okta_recovery_token__c: queryString.recoveryToken,
      country__c: country,
      password_reset_error__c: 'null',
      password_reset_error_msg__c: 'null',
      password_reset_completed__c: new Date().toISOString()
    }

    const errorPayload = {
      LogTitle: 'Password Reset Error',
      okta_recovery_token__c: queryString.recoveryToken,
      country__c: country,
      password_reset_error__c: new Date().toISOString(),
      password_reset_error_msg__c: '',
      password_reset_completed__c: 'null'
    }
    axios
      .post(
        env.resetPassword,
        {
          stateToken: state.data.stateToken,
          newPassword: values.password
        },
        {
          headers: headers
        }
      )
      .then(function () {
        logAction('user/log', successPayload, {})
        setLoading(false)
        setShow(true)
      })
      .catch(function (error) {
        logAction('user/log', { ...errorPayload, password_reset_error_msg__c: error.response?.data?.errorSummary }, {})
        setLoading(false)
      })
  }

  const handleChange = (event, form) => {
    event.persist()
    if (event.target.name === 'password') {
      setValues((oldValues) => ({
        ...oldValues,
        ...validatePassword(event.target.value, form),
        password: event.target.value
      }))
    }
  }

  const validatePassword = (password, form) => {
    if (password.length === 0 && form.isFieldTouched('password')) {
      return {
        validateStatus: 'error',
        errorMsg: t('errors.v_password_required')
      }
    } else if (password.length < 8 && password.length > 0) {
      return {
        validateStatus: 'error',
        errorMsg: t('errors.v_password_minlength')
      }
    } else if (password.length > 50) {
      return {
        validateStatus: 'error',
        errorMsg: t('errors.v_password_maxlength')
      }
    } else if (password.search(/[a-z]/) < 0 && password.length > 0) {
      return {
        validateStatus: 'error',
        errorMsg: t('errors.v_password_complexity')
      }
    } else if (password.search(/[A-Z]/) < 0 && password.length > 0) {
      return {
        validateStatus: 'error',
        errorMsg: t('errors.v_password_complexity')
      }
    } else if (password.search(/[0-9]/) < 0 && password.length > 0) {
      return {
        validateStatus: 'error',
        errorMsg: t('errors.v_password_complexity')
      }
    } else if (values.firstname !== '' && values.lastname !== '') {
      const loweredPass = password.toLowerCase()
      const loweredFirstName = values.firstname.toLowerCase()
      const loweredLastName = values.lastname.toLowerCase()
      if (loweredPass.includes(loweredFirstName)) {
        return {
          validateStatus: 'error',
          errorMsg: t('errors.v_password_complexity')
        }
      } else if (loweredPass.includes(loweredLastName)) {
        return {
          validateStatus: 'error',
          errorMsg: t('errors.v_password_complexity')
        }
      } else {
        return {
          validateStatus: 'success',
          errorMsg: null
        }
      }
    } else if (values.firstname !== '') {
      const loweredPass = password.toLowerCase()
      const loweredLastName = values.firstname.toLowerCase()
      if (loweredPass.includes(loweredLastName.toLowerCase())) {
        return {
          validateStatus: 'error',
          errorMsg: t('errors.v_password_complexity')
        }
      } else {
        return {
          validateStatus: 'success',
          errorMsg: null
        }
      }
    } else if (values.lastname !== '') {
      const loweredPass = password.toLowerCase()
      const loweredLastName = values.lastname.toLowerCase()
      if (loweredPass.includes(loweredLastName)) {
        return {
          validateStatus: 'error',
          errorMsg: t('errors.v_password_complexity')
        }
      } else {
        return {
          validateStatus: 'success',
          errorMsg: null
        }
      }
    } else {
      return {
        validateStatus: 'success',
        errorMsg: null
      }
    }
  }

  const getFormView = () => {
    return (
      <div className={styles.formRoot}>
        <div className={styles.description}>
          <p>{t('reset_password.password.description.1')}</p>
          <p>{t('reset_password.password.description.2')}</p>
        </div>
        <Form form={form} className={env.countryClass}>
          <Form.Item name='password' validateStatus={values.validateStatus} help={values.errorMsg} className={styles.passwordField}>
            <Input.Password
              name='password'
              className={styles.input}
              style={{ borderColor: env.colors.borderColor }}
              prefix={<LockOutlined className={styles.icon} style={{ color: env.colors.primaryColor }} />}
              allowClear
              type='password'
              placeholder={t('register.form.password')}
              autoComplete='on'
              onChange={(e) => handleChange(e, form)}
            />
          </Form.Item>
          <Form.Item
            name='confirmedPassword'
            dependencies={['password']}
            rules={[
              {
                required: true,
                message: t('errors.v_password_required')
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve()
                  }
                  return Promise.reject(new Error(t('errors.v_password_mismatch')))
                }
              })
            ]}>
            <Input.Password
              name='confirmedPassword'
              className={styles.input}
              style={{ borderColor: env.colors.borderColor }}
              onChange={(e) => handleChange(e, form)}
              prefix={<LockOutlined className={styles.icon} style={{ color: env.colors.primaryColor }} />}
              allowClear
              type='password'
              placeholder={t('register.form.confirmP')}
              autoComplete='on'
            />
          </Form.Item>
          <Form.Item shouldUpdate>
            {() => (
              <Spin indicator={antIcon} spinning={loading}>
                <Button
                  htmlType='submit'
                  className={styles.button}
                  style={
                    form.isFieldsTouched(true) && values.validateStatus !== 'error' && !form.getFieldsError().filter(({ errors }) => errors.length).length
                      ? {
                          backgroundColor: env.colors.primaryColor,
                          color: '#fff',
                          boxShadow: '0px 3px 6px #00000029'
                        }
                      : {
                          backgroundColor: 'rgba(0, 0, 0, 0.25)',
                          color: '#fff',
                          boxShadow: '0px 3px 6px #00000029'
                        }
                  }
                  onClick={handleSubmit}
                  disabled={
                    !form.isFieldsTouched(true) || values.validateStatus === 'error' || form.getFieldsError().filter(({ errors }) => errors.length).length
                  }>
                  {t('login.form.button')}
                </Button>
              </Spin>
            )}
          </Form.Item>
        </Form>
      </div>
    )
  }

  const getTextView = () => {
    return (
      <div className={styles.confirmRoot}>
        <p>
          {t('reset_password.password.text.1')}
          <Link to='/login' className={styles.link}>
            {t('reset_password.password.text.2')}
          </Link>
          {t('reset_password.password.text.3')}
        </p>
      </div>
    )
  }
  if (!env.supported_cultures.includes(queryString.culture) && queryString.culture !== undefined) {
    return <NotAllowed env={env} />
  } else if (!state.loaded) {
    return (
      <Layout
        style={{
          minHeight: '100%',
          display: 'flex',
          justifyContent: 'center'
        }}>
        <Spin className={env.countryClass} tip={t('app.load')} />
      </Layout>
    )
  } else if (state.error) {
    return (
      <Layout>
        <Content
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          <Row className={env.countryClass}>
            <Col span={24}>
              <h3>{t('app.password.title')}</h3>
              <h4>
                {t('app.password.text.1')} {countdown} {t('app.password.text.2')}
              </h4>
            </Col>
          </Row>
        </Content>
      </Layout>
    )
  }
  return (
    <Layout>
      <Content className={styles.root}>
        <Row className={env.countryClass}>
          <Col span={24}>
            <Row className={styles.headerRoot} type='flex' justify='center'>
              <Col xs={22} sm={22} md={22} lg={18} xl={18} xxl={16}>
                {env.countryClass === 'canada' ? (
                  <img src={env.logo} alt='.' className={styles.canadaLogo} />
                ) : (
                  <div
                    className={styles.logo}
                    style={{
                      borderColor: env.colors.primaryColor
                    }}>
                    <img src='/images/fywy-text@2x.png' alt='.' />
                  </div>
                )}
              </Col>
            </Row>
            <Row type='flex' justify='center'>
              <Col xs={22} sm={22} md={22} lg={18} xl={18} xxl={16} className={styles.content}>
                <div className={styles.text}>
                  <div className={styles.title}>{t('reset_password.password.title')}</div>
                  {show ? getTextView() : getFormView()}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Content>
    </Layout>
  )
}

export default PasswordView
