import React, { useState, useEffect } from 'react'
import { Row, Col, Form, Input, Button, Select, Tooltip, Spin } from 'antd'
import { InfoCircleOutlined, LoadingOutlined } from '@ant-design/icons'

const RegisterForm = ({ setVisible, values, setValues, styles, t, getCfg, form, salutation }) => {
  const { Option } = Select
  const [, forceUpdate] = useState()
  const address = `https://${window.location.href.split('/')[2]}`
  const env = getCfg(address)
  const [loaded, setLoaded] = useState(false)
  const antIcon = <LoadingOutlined style={{ fontSize: 24, color: env.colors.primaryColor }} spin />

  useEffect(() => {
    forceUpdate({})
  }, [])

  const validateInputs = () => {
    if (
      values.firstname !== null &&
      values.lastname !== null &&
      values.email !== null &&
      values.confirmedEmail !== null &&
      values.password !== null &&
      values.confirmedPassword !== null
    ) {
      return true
    } else {
      return false
    }
  }

  const handleSelectChange = (value) => {
    setValues((oldState) => ({
      ...oldState,
      salutation: value
    }))
  }

  const handleChange = (event, form) => {
    event.persist()
    if (event.target.name === 'password') {
      setValues((oldValues) => ({
        ...oldValues,
        ...validatePassword(event.target.value, form),
        password: event.target.value
      }))
    }
    setValues((oldState) => ({
      ...oldState,
      [event.target.name]: event.target.value
    }))
  }

  const handleClick = () => {
    setLoaded(true)
    setTimeout(() => {
      setLoaded(false)
      setVisible((oldValues) => ({
        ...oldValues,
        consent: true
      }))
    }, 1000)
  }

  const validatePassword = (password, form) => {
    if (password.length === 0 && form.isFieldTouched('password')) {
      return {
        validateStatus: 'error',
        errorMsg: t('errors.v_password_required')
      }
    } else if (password.length < 8 && password.length > 0) {
      return {
        validateStatus: 'error',
        errorMsg: t('errors.v_password_minlength')
      }
    } else if (password.length > 50) {
      return {
        validateStatus: 'error',
        errorMsg: t('errors.v_password_maxlength')
      }
    } else if (password.search(/[a-z]/) < 0 && password.length > 0) {
      return {
        validateStatus: 'error',
        errorMsg: t('errors.v_password_complexity')
      }
    } else if (password.search(/[A-Z]/) < 0 && password.length > 0) {
      return {
        validateStatus: 'error',
        errorMsg: t('errors.v_password_complexity')
      }
    } else if (password.search(/[0-9]/) < 0 && password.length > 0) {
      return {
        validateStatus: 'error',
        errorMsg: t('errors.v_password_complexity')
      }
    } else if (values.firstname !== null && values.lastname !== null) {
      const loweredPass = password.toLowerCase()
      const loweredFirstName = values.firstname.toLowerCase()
      const loweredLastName = values.lastname.toLowerCase()
      if (loweredPass.includes(loweredFirstName)) {
        return {
          validateStatus: 'error',
          errorMsg: t('errors.v_password_complexity')
        }
      } else if (loweredPass.includes(loweredLastName)) {
        return {
          validateStatus: 'error',
          errorMsg: t('errors.v_password_complexity')
        }
      } else {
        return {
          validateStatus: 'success',
          errorMsg: null
        }
      }
    } else if (values.firstname !== null) {
      const loweredPass = password.toLowerCase()
      const loweredLastName = values.firstname.toLowerCase()
      if (loweredPass.includes(loweredLastName.toLowerCase())) {
        return {
          validateStatus: 'error',
          errorMsg: t('errors.v_password_complexity')
        }
      } else {
        return {
          validateStatus: 'success',
          errorMsg: null
        }
      }
    } else if (values.lastname !== null) {
      const loweredPass = password.toLowerCase()
      const loweredLastName = values.lastname.toLowerCase()
      if (loweredPass.includes(loweredLastName)) {
        return {
          validateStatus: 'error',
          errorMsg: t('errors.v_password_complexity')
        }
      } else {
        return {
          validateStatus: 'success',
          errorMsg: null
        }
      }
    } else {
      return {
        validateStatus: 'success',
        errorMsg: null
      }
    }
  }

  return (
    <Row>
      <Col xs={24} sm={17} md={15} lg={8} xl={8} xxl={9} className={`${styles.form}`}>
        <Form form={form} className={env.countryClass}>
          <Form.Item>
            <Select
              size='large'
              bordered={false}
              placeholder={t('register.form.salutation.hint')}
              className={styles.salutationSelect}
              value={values.salutation}
              allowClear
              onChange={handleSelectChange}
              style={{
                borderRadius: 15,
                fontSize: '18px',
                width: '100%',
                backgroundColor: '#fff'
              }}>
              {salutation.data.map((salut) => (
                <Option key={salut.Id} value={salut.API_name__c}>
                  {salut.Value__c}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name='firstname'
            rules={[
              {
                required: true,
                message: t('errors.v_firstname_required')
              },
              {
                max: 40,
                message: t('errors.v_firstname_maxlength')
              },
              () => ({
                validator(rule, value) {
                  if (!/\d/.test(value)) {
                    return Promise.resolve()
                  }
                  return Promise.reject(new Error(t('errors.v_firstname_type')))
                }
              })
            ]}>
            <Input
              name='firstname'
              className={styles.inputs}
              style={{ borderColor: env.colors.borderColor }}
              onChange={(e) => handleChange(e, form)}
              allowClear
              placeholder={t('register.form.firstname')}
              defaultValue={values.firstname}
            />
          </Form.Item>
          <Form.Item
            name='lastname'
            rules={[
              {
                required: true,
                message: t('errors.v_lastname_required')
              },
              {
                max: 80,
                message: t('errors.v_lastname_maxlength')
              },
              () => ({
                validator(rule, value) {
                  if (!/\d/.test(value)) {
                    return Promise.resolve()
                  }
                  return Promise.reject(new Error(t('errors.v_lastname_type')))
                }
              })
            ]}>
            <Input
              name='lastname'
              className={styles.inputs}
              style={{ borderColor: env.colors.borderColor }}
              onChange={(e) => handleChange(e, form)}
              allowClear
              placeholder={t('register.form.lastname')}
              defaultValue={values.lastname}
            />
          </Form.Item>
        </Form>
      </Col>
      <Col span={2} />
      <Col xs={24} sm={17} md={15} lg={8} xl={8} xxl={8} className={styles.form}>
        <Form form={form} className={env.countryClass}>
          <Form.Item
            className={styles.emailField}
            name='email'
            rules={[
              {
                required: true,
                message: t('errors.v_email_required')
              },
              {
                type: 'email',
                message: t('register.errors.valid')
              },
              {
                max: 80,
                message: t('errors.v_email_maxlength')
              }
            ]}>
            <Input
              name='email'
              className={styles.inputs}
              style={{ borderColor: env.colors.borderColor }}
              onChange={(e) => handleChange(e, form)}
              allowClear
              placeholder={t('register.form.email')}
              defaultValue={values.email}
            />
          </Form.Item>
          <Form.Item
            name='confirmedEmail'
            dependencies={['email']}
            rules={[
              {
                required: true,
                message: t('errors.v_email_required')
              },
              {
                type: 'email',
                message: t('register.errors.valid')
              },
              {
                max: 80,
                message: t('errors.v_email_maxlength')
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue('email') === value) {
                    return Promise.resolve()
                  }
                  return Promise.reject(new Error(t('register.errors.match')))
                }
              })
            ]}>
            <Input
              name='confirmedEmail'
              className={styles.inputs}
              style={{ borderColor: env.colors.borderColor }}
              onChange={(e) => handleChange(e, form)}
              allowClear
              placeholder={t('register.form.confirmE')}
              defaultValue={values.confirmedEmail}
            />
          </Form.Item>
          <div>
            <Tooltip placement='rightTop' className={styles.tooltip} title={t('register.tooltip')}>
              <InfoCircleOutlined style={{ color: env.colors.primaryColor }} />
            </Tooltip>
            <Form.Item name='password' validateStatus={values.validateStatus} help={values.errorMsg} className={styles.passwordField}>
              <Input.Password
                name='password'
                className={styles.inputs}
                style={{ borderColor: env.colors.borderColor }}
                allowClear
                onChange={(e) => handleChange(e, form)}
                type='password'
                placeholder={t('register.form.password')}
                autoComplete='on'
                defaultValue={values.password}
              />
            </Form.Item>
          </div>
          <div>
            <Tooltip placement='rightTop' className={styles.tooltip} title={t('register.tooltip')}>
              <InfoCircleOutlined style={{ color: env.colors.primaryColor }} />
            </Tooltip>
            <Form.Item
              name='confirmedPassword'
              dependencies={['password']}
              rules={[
                {
                  required: true,
                  message: t('errors.v_password_required')
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve()
                    }
                    return Promise.reject(new Error(t('errors.v_password_mismatch')))
                  }
                })
              ]}>
              <Input.Password
                name='confirmedPassword'
                className={styles.inputs}
                style={{ borderColor: env.colors.borderColor }}
                onChange={(e) => handleChange(e, form)}
                allowClear
                type='password'
                placeholder={t('register.form.confirmP')}
                autoComplete='on'
                defaultValue={values.confirmedPassword}
              />
            </Form.Item>
          </div>
          <Form.Item shouldUpdate className={styles.button} style={validateInputs() ? {} : { cursor: 'no-drop' }}>
            {() => (
              <Spin indicator={antIcon} spinning={loaded}>
                <Button
                  htmlType='submit'
                  className={env.countryClass}
                  style={
                    validateInputs() && !form.getFieldsError().filter(({ errors }) => errors.length).length
                      ? {
                          backgroundColor: env.colors.primaryColor,
                          color: '#fff'
                        }
                      : {
                          backgroundColor: 'rgba(0, 0, 0, 0.25)',
                          color: '#fff'
                        }
                  }
                  disabled={!validateInputs() || form.getFieldsError().filter(({ errors }) => errors.length).length || values.validateStatus === 'error'}
                  onClick={handleClick}>
                  {t('register.form.button.1')}
                </Button>
              </Spin>
            )}
          </Form.Item>
        </Form>
      </Col>
    </Row>
  )
}

export default RegisterForm
