import React, { useState, useEffect } from 'react'
import { Row, Col, Button, Modal } from 'antd'
import styles from './Handler.module.css'
import { getCfg } from '../../env.config'

const ErrorHandlerModal = ({ visible, close, title, text, button, width, validate }) => {
  const modalClass = title === 'DISCLAIMER' ? styles.disclaimerModal : styles.modal
  const [size, setSize] = useState(0)

  const updateSize = () => {
    setSize(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])
  const address = `https://${window.location.href.split('/')[2]}`
  const validation = validate !== undefined ? validate() : false
  const widthNo = width !== undefined ? width : 651
  const env = getCfg(address)
  const modalBodyStyle = size < 576 ? { padding: '0 20px 25px' } : { padding: '0 50px 25px' }
  return (
    <Modal
      title={title}
      visible={visible}
      onCancel={close}
      footer={null}
      width={widthNo}
      maskClosable={false}
      bodyStyle={modalBodyStyle}
      className={`${modalClass} ${env.countryClass}`}
      centered>
      <Row className={`${styles.modalRoot} `}>
        <Col xs={24} sm={24} md={24} lg={22} xl={22} xxl={22} className={styles.modalText}>
          <div className={styles.modalDescription}>{text}</div>
        </Col>
        <Col span={24}>
          <Row gutter={24}>
            <Col xs={24} sm={width ? 14 : 18} md={width ? 13 : 18} lg={width ? 12 : 18} xl={width ? 10 : 18} xxl={width ? 10 : 18}>
              <Button className={styles.modalButton} style={{ backgroundColor: env.colors.primaryColor, color: '#fff' }} onClick={close} disabled={validation}>
                {button}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  )
}

export default ErrorHandlerModal
