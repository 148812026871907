import React, { useContext, useState, useEffect } from 'react'
import styles from './MessageBoard.module.css'
import MessageCard from './MessageCard'
import { useLocation } from 'react-router-dom'
import { AppContext } from '../../contexts/AppContext'
import { get } from '../ApiCall'
import * as qs from 'query-string'

const MessageBoard = ({ env, t }) => {
  const { showBoard, setShowBoard, setLoaded, content, setContent, setContextData, contextData } = useContext(AppContext)
  const [latestArticles, setLatestArticles] = useState({
    loaded: false,
    data: []
  })

  const [latestVideos, setLatestVideos] = useState({ loaded: false, data: [] })
  const location = useLocation()
  const [size, setSize] = useState(100000)
  const updateSize = () => {
    setSize(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])

  useEffect(() => {
    const queryString = qs.parse(location.search)
    const config = {
      offset: 0,
      limit: 1000
    }
    if (!latestArticles.loaded && !queryString.code) {
      get('articles/highlighted', config, setLatestArticles)
    }
  }, [latestArticles.loaded, location.search])

  useEffect(() => {
    const queryString = qs.parse(location.search)
    const config = {
      offset: 0,
      limit: 1000
    }
    if (!latestVideos.loaded && !queryString.code) {
      get('videos/highlighted', config, setLatestVideos)
    }
  }, [latestVideos.loaded, location.search])

  const background = size < 578 ? env.colors.mobileMessageBoard : env.colors.messageBoard

  const truncate = (string, number) => {
    return string.split(' ').splice(0, number).join(' ')
  }

  const handleClick = (urlName, video) => {
    const config = {
      offset: 0,
      limit: 1000
    }
    setContent({ ...content, loaded: false })
    setLoaded(true)
    if (window.location.pathname.includes('/article')) {
      setContextData((oldValue) => ({
        ...oldValue,
        show: !contextData.show
      }))
    }
    if (video) {
      get(`videos/detail?UrlName=${urlName}`, config, ({ loaded, data }) => {
        setContent({ loaded: loaded, data: data, board: true })
        setLoaded(false)
      })
    } else {
      get(`articles/detail?UrlName=${urlName}`, config, ({ loaded, data }) => {
        setContent({ loaded: loaded, data: data, board: true })
        setLoaded(false)
      })
    }
  }

  const getContent = () => {
    if (latestArticles.loaded && latestVideos.loaded) {
      if (latestArticles.data.length === 0 && latestVideos.data.length === 0) {
        return t('message-board.nodata')
      } else {
        if (latestArticles.data.length > 0) {
          return latestArticles.data
            .sort((a, b) => Number(new Date(b.Highlighted_Start_Date__c)) - Number(new Date(a.Highlighted_Start_Date__c)))
            .map((data) => {
              const title = truncate(data.Highlighted_Notification_Text__c, 8)
              return (
                <MessageCard
                  key={data.Id}
                  urlName={data.UrlName}
                  size={size}
                  env={env}
                  t={t}
                  icon={data.Image_URL__c}
                  text={title}
                  handleClick={() => handleClick(data.UrlName)}
                />
              )
            })
        } else if (latestVideos.data.length > 0) {
          return latestArticles.data.map((data) => {
            const title = truncate(data.Highlighted_Notification_Text__c, 8)
            return (
              <MessageCard
                key={data.Id}
                urlName={data.UrlName}
                size={size}
                env={env}
                t={t}
                icon={data.Image_URL__c}
                text={title}
                handleClick={() => handleClick(data.UrlName, true)}
              />
            )
          })
        } else {
          return null
        }
      }
    } else {
      return t('message-board.loading')
    }
  }
  return (
    <div className={styles.root} style={!showBoard ? { backgroundColor: background } : { boxShadow: 'none' }}>
      <div
        style={{
          color: env.colors.title
        }}
        className={styles.title}
        onClick={() => setShowBoard(!showBoard)}>
        {t('message-board.title')}
      </div>
      <hr className={styles.hr} style={showBoard ? { display: 'none' } : null} />
      <div className={styles.content} style={showBoard ? { display: 'none' } : null}>
        {getContent()}
      </div>
    </div>
  )
}

export default MessageBoard
