export const stag = {
  /**
   * Allowed Values
   * ooa (order of articles), subcategories, still_entyvio,
   * ibd_chart, tutorial, message_board, opf (online patient forum)
   * * tested : subcategories, message_board, opf, tutorial, still_entyvio, ibd_chart
   */
  enhancements: [
    'ooa',
    'subcategories',
    'still_entyvio'
    // 'ibd_chart',
    // 'tutorial'
    // 'message_board',
    // 'opf'
  ],
  templateID: 'd-4b8459da5825462b94d15fa5afbdfa1f',
  cookieOneTrust: '',
  cookieCdnScript: '',
  cookieId: '',
  // loginRedirect: 'https://stag-ca-onepathpatientportal.hartehanks.com',
  // loginRoot: 'https://api-us-np.takeda.com/dev/security-takedaid-api',
  // oktaClientId: '0oarslgx4eeFN9LWT0h7',
  // appRoot: 'https://api-eu-np.takeda.com/dev/entyvio-fywy-sf-api/api/',
  // registerRoot: 'https://api-eu-np.takeda.com/dev/entyvio-fywy-sf-api/user/register',
  // loginClientId: '6f4f27221ad449f591238fef21f18ae1',
  // loginClientSecret: '2f93e83F0e814B5E9C4Ae146b30d6610',
  // clientId: '35114b55a0d54f66b49d27b0caf04dfb',
  // clientSecret: '679fd4F26d7A49B98eB455F53B3A9F5f',
  // categoryRoot: 'https://api-eu-np.takeda.com/dev/entyvio-fywy-sf-api/',
  // grantType: 'authorization_code',
  // state: 'state-296bc9a0-a2a2-4a57-be1a-d0e2fd9bb00601',
  // nonce: 'nonce-285shshkiooo989',
  // xClient: 'mobile',
  // redirectRoot:
  //   'https://takedaext.oktapreview.com/oauth2/default/v1/authorize',
  // activeSession: 'https://takedaext.oktapreview.com/api/v1/sessions/me',
  // sendRoot:
  //   'https://api-eu-np.takeda.com/dev/entyvio-sendgrid-api/api/email/send',
  //   recoveryPasswordRoot:
  //     'https://api-us-np.takeda.com/dev/security-takedaid-api/v1/user/recoveryPassword',
  //   relayStateRecovery:
  //     'https://dev-fywypatientportal.hartehanks.com/password_reset/init',
  //   sendMailClientID: '35114b55a0d54f66b49d27b0caf04dfb',
  //   sendMailClientSecret: '679fd4F26d7A49B98eB455F53B3A9F5f',
  //   verifyToken:
  //     'https://api-us-np.takeda.com/dev/security-takedaid-api/v1/user/verifyToken',
  //   resetPassword:
  //     'https://api-us-np.takeda.com/dev/security-takedaid-api/v1/user/resetPassword',
  // verifyUser: 'https://api-us-np.takeda.com/dev/security-takedaid-api/v1/user/verify',

  loginRedirect: 'https://stag-ca-onepathpatientportal.hartehanks.com',
  loginRoot: 'https://api-us-np.takeda.com/uat/security-takedaid-api',
  oktaClientId: '0oarslgx4eeFN9LWT0h7',
  appRoot: 'https://api-eu-np.takeda.com/uat/entyvio-fywy-sf-api/api/',
  registerRoot: 'https://api-eu-np.takeda.com/uat/entyvio-fywy-sf-api/user/register',
  loginClientId: 'e6d44a4b0d584677a7d5579629642a0d',
  loginClientSecret: '38B4108b71b2402A90E81af4ed91699A',
  clientId: '35114b55a0d54f66b49d27b0caf04dfb',
  clientSecret: '679fd4F26d7A49B98eB455F53B3A9F5f',
  categoryRoot: 'https://api-eu-np.takeda.com/uat/entyvio-fywy-sf-api/',
  grantType: 'authorization_code',
  state: 'state-296bc9a0-a2a2-4a57-be1a-d0e2fd9bb00601',
  nonce: 'nonce-285shshkiooo989',
  xClient: 'mobile',
  redirectRoot: 'https://takedaext.oktapreview.com/oauth2/default/v1/authorize',
  activeSession: 'https://takedaext.oktapreview.com/api/v1/sessions/me',
  sendRoot: 'https://api-eu-np.takeda.com/uat/entyvio-sendgrid-api/api/email/send',
  recoveryPasswordRoot: 'https://api-us-np.takeda.com/uat/security-takedaid-api/v1/user/recoveryPassword',
  relayStateRecovery: 'https://stag-ca-onepathpatientportal.hartehanks.com/password_reset/init',
  sendMailClientID: '35114b55a0d54f66b49d27b0caf04dfb',
  sendMailClientSecret: '679fd4F26d7A49B98eB455F53B3A9F5f',
  verifyToken: 'https://api-us-np.takeda.com/uat/security-takedaid-api/v1/user/verifyToken',
  resetPassword: 'https://api-us-np.takeda.com/uat/security-takedaid-api/v1/user/resetPassword',
  verifyUser: 'https://api-us-np.takeda.com/uat/security-takedaid-api/v1/user/verify',
  mulesoft: {
    baseURI: 'https://api-us-aws-dev2.takeda.com/ddb-entyvio-fywy-exp/v1/user/status?emailAddress=',
    clientID: 'bfcb04bbff624656bbf4bf6d061b0ab5',
    clientSecret: '66DE0c58EEc747a9B9D68F12AFe7b1f5'
  },
  supported_cultures: ['en-CA', 'fr-CA'],
  countryClass: 'canada',
  logo: '/images/CA/onepath@2x.png',
  entyvioLogo: '/images/CA/entyvio-logo@2x.png',
  leftArrow: '/images/CA/left.png',
  rightArrow: '/images/CA/right.png',
  pinImage: '/images/CA/pinned.png',
  medicationIcon: '/images/CA/injection-reminder@2x.png',
  injectionIcon: '/images/CA/injection-tracker@2x.png',
  surveyIcon: '/images/CA/Survey@2x.png',
  homepageCustomStyle: { minHeight: 515 },
  country: 'CA',
  language: 'en',
  colors: {
    primaryColor: '#839458',
    borderColor: '#839458',
    footer: '#F2F4EE',
    iconColor: '#CDD8B1',
    title: '#000',
    subtitle: '#4C4948',
    siderColor: '#82945833',
    reminderTitle: '#839458',
    reminderIcon: '#000',
    cardColor: '#82945833',
    cardTitle: '#4C4948',
    cardIcon: '#839458',
    menuColor: '#F2F4EE',
    mobileNavigationColor: '#4C4948',
    messageBoard: '#E2F5F7D9',
    messageCard: '#E2F5F7',
    mobileMessageBoard: '#E2F5F7',
    mobileMessageCard: '#F5FEFF',
    headerLandingText: '#000000',
    hcpLandingText: '#839458'
  },
  medicationDateFormat: 'HH:mm a',
  entyvioLeaflets: true,
  hideSelector: true,
  diskModal: false,
  onepathButton: true,
  profile: {
    treatmentFormat: 'YYYY-MM-DD',
    contactDetails: false,
    mobilePhone: false,
    address: true
  },
  landing: {
    selector: true,
    button: true,
    hcpportal: true,
    disclaimer: false,
    promoCode: null,
    video: {
      visible: false,
      image: null
    },
    googlePlay: '#',
    appleStore: '#',
    hcpLink: 'https://foryouwithyou.hartehanks.com/sessions/new?culture=',
    logo: '/images/landing/onepath.png',
    background: '/images/landing/ca-landing@2x.png',
    mobileBackground: '/images/landing/mobile_header@2x.png',
    phone: {
      en: '/images/landing/ca-mobile@2x.png',
      fr: '/images/landing/ca-mobile@2x.png'
    },
    icons: {
      appointment: '/images/landing/ca-appointment@2x.png',
      disk: '/images/landing/ca-disk@2x.png',
      map: '/images/landing/ca-map@2x.png',
      recipes: '/images/landing/ca-recipes@2x.png',
      videos: '/images/landing/ca-videos@2x.png'
    },
    languages: {
      one: {
        code: 'EN',
        culture: 'en-CA'
      },
      two: {
        code: 'FR',
        culture: 'fr-CA'
      }
    }
  },
  promo: {
    video: true,
    forum: true,
    stayConnected: true,
    images: {
      en: {
        welcome: {
          picture: '/images/promo/desktop/desktop-1.png',
          mobile: '/images/promo/desktop/iphone.png'
        },
        content: '/images/promo/desktop/content.png',
        disk: '/images/promo/desktop/disk.png',
        trackers: '/images/promo/desktop/trackers.png',
        forum: '/images/promo/desktop/forum.png',
        connected: '/images/promo/desktop/connected.png'
      },
      fr: {
        welcome: {
          picture: '/images/promo/desktop/BE/welcome-fr.png',
          mobile: '/images/promo/desktop/BE/iphone-fr.png'
        },
        content: '/images/promo/desktop/BE/content-fr.png',
        disk: '/images/promo/desktop/BE/disk-fr.png',
        trackers: '/images/promo/desktop/BE/trackers-fr.png',
        forum: null,
        connected: '/images/promo/desktop/BE/connected-fr.png'
      }
    }
  },
  footer: {
    privacy: '#',
    terms: '#'
  },
  cardImages: {
    gastro: '/images/CA/gastro@2x.png',
    test: '/images/CA/medical-test.png',
    call: '/images/CA/nurse_call@2x.png',
    visit: '/images/CA/Nurse-Visit.png',
    practitioner: '/images/CA/Doctor.png',
    intravenous: '/images/CA/infusion@2x.png',
    default: '/images/CA/Doctor.png'
  },
  injectionCard: {
    leftabdomen: '/images/CA/leftabdomen.png',
    rightabdomen: '/images/CA/rightabdomen.png',
    leftarm: '/images/CA/leftarm.png',
    rightarm: '/images/CA/rightarm.png',
    leftleg: '/images/CA/leftleg@3x.png',
    rightleg: '/images/CA/rightleg@3x.png',
    default: '/images/CA/noarea.png'
  },
  injectionTracker: {
    pickerValidation: true,
    leftabdomen: '/images/CA/human-leftabdomen.png',
    rightabdomen: '/images/CA/human-rightabdomen.png',
    leftarm: '/images/CA/human-backofleftarm.png',
    rightarm: '/images/CA/human-backofrightarm.png',
    leftleg: '/images/CA/human-leftleg.png',
    rightleg: '/images/CA/human-rightleg.png',
    default: '/images/CA/human.png'
  },
  goHomeStyle: {
    color: '#000',
    fontWeight: 500,
    position: 'absolute',
    top: '5%',
    left: '33%'
  },
  hcpStyle: {
    display: 'none'
  },
  types: {
    intravenous: [
      {
        code: 'INTRAVENOUS_INFUSION'
      },
      {
        code: 'GASTRO_SPECIALIST'
      },
      {
        code: 'GENERAL_PRACTITIONER'
      },
      {
        code: 'MEDICAL_TEST'
      },
      {
        code: 'NURSE_SUPPORT_CALL'
      },
      {
        code: 'NURSE_SUPPORT_VISIT'
      },
      {
        code: 'CALPROTECTIN_TEST'
      }
    ],
    subcutaneous: [
      {
        code: 'GASTRO_SPECIALIST'
      },
      {
        code: 'GENERAL_PRACTITIONER'
      },
      {
        code: 'MEDICAL_TEST'
      },
      {
        code: 'NURSE_SUPPORT_CALL'
      },
      {
        code: 'NURSE_SUPPORT_VISIT'
      },
      {
        code: 'CALPROTECTIN_TEST'
      }
    ],
    other: [
      {
        code: 'GASTRO_SPECIALIST'
      },
      {
        code: 'GENERAL_PRACTITIONER'
      },
      {
        code: 'MEDICAL_TEST'
      },
      {
        code: 'CALPROTECTIN_TEST'
      }
    ]
  }
}
