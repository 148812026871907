import React from 'react'
import { Row, Col } from 'antd'
import styles from './ReminderCard.module.css'
import moment from 'moment'
import { getCfg } from '../../../../env.config'
import { useTranslation } from 'react-i18next'

const InjectionReminderCard = (props) => {
  const { t } = useTranslation()
  const getReminderDate = () => {
    if (props.data[0].Email_notification__pc) {
      const reminderDate = props.data[0].On_the_day__pc
        ? moment(props.data[0].Next_Injection_Datetime__pc)
        : moment(props.data[0].Next_Injection_Datetime__pc).subtract(1, 'days')
      return (
        <p className={styles.date}>{reminderDate.format('dddd DD/MM/YYYY')}</p>
      )
    } else {
      return (
        <p className={styles.noReminderDate}>
          {t('injection.reminder.card.noreminder')}
        </p>
      )
    }
  }
  const address = `https://${window.location.href.split('/')[2]}`
  const env = getCfg(address)
  return (
    <Row
      align='middle'
      className={styles.root}
      style={{
        backgroundColor: env.colors.cardColor,
      }}>
      <Col span={24}>
        <Row>
          <Col span={24} className={styles.injectionRoot}>
            <img
              src={env.injectionIcon}
              alt='icon'
              className={styles.injectionIcon}
            />
            <div>
              <p
                className={styles.title}
                style={{ color: env.colors.cardTitle }}>
                {t('injection.reminder.card.nextinjection')}
              </p>
              <p className={styles.date}>
                {moment(props.data[0].Next_Injection_Datetime__pc).format(
                  'dddd DD/MM/YYYY'
                )}
              </p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24} className={styles.reminderRoot}>
            <img
              src={env.medicationIcon}
              alt='icon'
              className={styles.reminderIcon}
            />
            <div>
              <div>
                <p
                  className={styles.title}
                  style={{ color: env.colors.cardTitle }}>
                  {t('injection.reminder.card.nextreminder')}
                </p>
                {getReminderDate()}
              </div>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default InjectionReminderCard
