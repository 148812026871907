import React, { useEffect } from "react";
import { Layout, Row, Col } from "antd";
import styles from "./Footer.module.css";
import { useTranslation } from "react-i18next";
import { getCfg } from "../../env.config";
import * as qs from "query-string";

const { Footer } = Layout;

const FooterComponent = ({ lang }) => {
  const { t } = useTranslation()
  const address = `https://${window.location.href.split("/")[2]}`
  const env = getCfg(address)

  return (
    <Footer
      className={styles.root}
      style={{ backgroundColor: env.colors.footer }}
    >
      <Row
        justify='center'
        align='middle'
        className={env.countryClass}
        style={{ flex: 1 }}
      >
        <Col xs={20} sm={20} md={22} lg={23} xl={23} xxl={23} flex>
          <Row justify='center' align='middle'>
            <Col xs={24} sm={24} md={24} lg={24} xl={9} xxl={12}>
              <div
                className={`${styles.legend} ${
                  env.landing.promoCode === 'block' ? styles['block'] : ''
                }`}
              >
                {env.landing.promoCode === 'block' ? (
                  <>
                    <span
                      style={{
                        display: "block",
                        textAlign: "left",
                        paddingRight: 6,
                      }}
                    >
                      {t("login.text.3")}.
                    </span>
                    <span>{t("login.text.4")}</span>
                  </>
                ) : (
                  <>
                    <span>{t("login.text.3")}</span>{" "}
                    <span
                      style={
                        t("login.text.4") === "" ? { display: "none" } : null
                      }
                    >
                      -
                    </span>{" "}
                    <span>{t("login.text.4")}</span>
                  </>
                )}
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={15} xxl={12}>
              <div className={styles.content}>
                <div>
                  <a
                    href={env.footer.privacy}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {t("footer.1")}
                  </a>
                </div>
                <div>
                  <a
                    href={env.footer.terms}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {t("footer.2")}
                  </a>
                </div>
                <div className={styles.avatar}>
                  <div>{t("footer.3")}</div>
                  <div>
                    <img src="/images/logo/logo-takeda.png" alt="Takeda" />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          {lang === 'pl' ? (
            <Row justify='center' align='middle' className={styles.copyright}>
              <Col xs={24} sm={24} md={24} lg={24} xl={9} xxl={12}>
                <div>
                  Copyright © 2023 Takeda Pharmaceutical Company Limited.
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={15} xxl={12}>
                <div className={styles.content}>
                  <div>
                    Wszystkie prawa zastrzeżone. Wszystkie znaki handlowe są
                    własnościami ich prawowitych właścicieli.
                  </div>
                </div>
              </Col>
            </Row>
          ) : null}
        </Col>
      </Row>
    </Footer>
  )
}

export default FooterComponent
