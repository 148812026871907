import React from 'react'
import styles from './Calendar.module.css'
import { useTranslation } from 'react-i18next'
import { RightOutlined, LeftOutlined } from '@ant-design/icons'
import * as moment from 'moment'
import { getCfg } from '../../env.config'

const Calendar = ({ selected, onDateClick, dates, state, setState, disableFutureDates }) => {
  const { t } = useTranslation()
  const address = `https://${window.location.href.split('/')[2]}`
  const env = getCfg(address)
  const renderHeader = () => {
    const dateFormat = 'M YYYY'
    const currentMonthString = moment(state.currentMonth).format(dateFormat)
    const monthAndYear = currentMonthString.split(' ')
    return (
      <div className={`${styles.header} ${styles.row}`}>
        <div
          className={`${styles.col} ${styles.colStart}`}
          onClick={prevMonth}
        >
          <div
            className={`${styles.navigationIcons}`}
          >
            <LeftOutlined style={{ color: env.colors.primaryColor }} />
          </div>
        </div>
        <div className={`${styles.col} ${styles.colCenter}`}>
          <span
            className={styles.monthTitle}
          >
            {t(`calendar.months.${monthAndYear[0] - 1}`) + ' ' + monthAndYear[1]}
          </span>
        </div>
        <div
          className={`${styles.col} ${styles.colEnd}`}
          onClick={!disableFutureDates ? nextMonth : () => {}}
        >
          <div
            className={`${styles.navigationIcons}`}
            style={disableFutureDates ? { cursor: 'not-allowed' } : {}}
          >
            <RightOutlined style={disableFutureDates ? { color: '#ccc' } : { color: env.colors.primaryColor }} />
          </div>
        </div>
      </div>
    )
  }

  const renderDays = () => {
    const days = []
    for (let i = 0; i < 7; i++) {
      days.push(
        <div className={`${styles.col} ${styles.colCenter}`} key={i}>
          {t(`calendar.days.${i}`)}
        </div>
      )
    }
    return <div className={`${styles.days} ${styles.row}`}>{days}</div>
  }

  const dotStyle = {
    position: 'absolute',
    top: 1,
    left: '45%',
    width: 3,
    height: 3,
    borderRadius: 25,
    backgroundColor: 'orange'
  }

  const addDayandPopup = (day, monthStart) => {
    const dateFormat = 'D'
    let formattedDate = ''
    formattedDate = moment(day).format(dateFormat)
    const days = []

    const calendarDay = moment(day).format('YYYY-MM-DD')

    days.push(
      <div className={`${styles.col} ${styles.cell} ${!moment(day).isSame(monthStart, 'month') ? styles.disabled : ''}`} key={day}>
        <span
          key={day}
          onClick={() => onDateClick(day)}
          style={!selected ? {}
            : moment(day).isSame(selected, 'day') && moment(day).isSame(new Date(), 'day') ? { backgroundColor: env.colors.primaryColor }
              : moment(day).isSame(selected, 'day') ? { backgroundColor: env.colors.primaryColor } : {}}
          className={!selected ? ''
            : moment(day).isSame(selected, 'day') && moment(day).isSame(new Date(), 'day') ? styles.selected
              : moment(day).isSame(selected, 'day') ? styles.selected : ''}
        >
          <div
            className={`${styles.numberContainer} ${moment(day).isSame(new Date(), 'day') ? styles.currentDate : ''}`}
            style={moment(day).isSame(new Date(), 'day') ? { borderColor: env.colors.primaryColor } : {}}
          >
            <div style={dates !== undefined ? dates.includes(calendarDay) ? dotStyle : { display: 'none' } : {}} />
            <p>{formattedDate}</p>
          </div>
        </span>
      </div>
    )
    return days
  }

  const renderCells = () => {
    const { currentMonth } = state
    const monthStart = moment(currentMonth).startOf('month')
    const monthEnd = moment(monthStart).endOf('month')
    const startDate = moment(monthStart).startOf('isoWeek')
    const endDate = moment(monthEnd).endOf('week')

    const rows = []

    let days = []
    let day = startDate

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        days.push(addDayandPopup(day, monthStart))
        day = moment(day).add(1, 'days')
      }
      rows.push(
        <div className={styles.row} key={day}>
          {days}
        </div>
      )
      days = []
    }
    return <div className={styles.body}>{rows}</div>
  }

  const nextMonth = () => {
    setState({
      ...state,
      currentMonth: moment(state.currentMonth).add(1, 'months')
    })
  }

  const prevMonth = () => {
    setState({
      ...state,
      currentMonth: moment(state.currentMonth).subtract(1, 'months')
    })
  }

  return (
    <div className={styles.calendar}>
      {renderHeader()}
      {renderDays()}
      {renderCells()}
    </div>
  )
}

export default Calendar
