import React, { useState } from 'react'
import { Row, Col, Modal, Checkbox, Button } from 'antd'
import styles from './ConsentModal.module.css'
import { useTranslation } from 'react-i18next'

const ConsentModal = ({ visible, close, bodyStyle, accept, checked, setChecked, getCfg, modalText }) => {
  const { t } = useTranslation()
  const address = `https://${window.location.href.split('/')[2]}`
  const env = getCfg(address)
  const [disabled, setDisabled] = useState(true)

  const handleScroll = (e) => {
    const element = e.target
    if (element.scrollHeight - element.scrollTop <= 204) {
      setDisabled(false)
    }
  }
  return (
    <Modal
      title={t('personalization.consent.terms')}
      visible={visible}
      onCancel={close}
      footer={null}
      width={651}
      // maskClosable={false}
      bodyStyle={bodyStyle}
      className={styles.modal}
      centered
      closable={false}>
      <Row className={`${styles.modalRoot} ${env.countryClass}`} justify='center'>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} className={styles.modalText} onScroll={handleScroll}>
          <div className={styles.modalDescription}>
            <p
              dangerouslySetInnerHTML={{
                __html: modalText.replace(/\n/g, '<br />')
              }}
            />
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <hr className={styles.line} />
          <div className={styles.checkbox}>
            <Checkbox
              disabled={disabled}
              checked={checked}
              style={{ marginRight: 15 }}
              className={env.countryClass}
              onChange={(e) => setChecked(e.target.checked)}
            />
            <span style={{ fontSize: 16, color: env.colors.primaryColor }}>{t('consent.text')}</span>
          </div>
        </Col>
        <Col xs={24} sm={18} md={18} lg={16} xl={16} xxl={16} className={styles.buttonCol}>
          <Button
            style={
              checked
                ? {
                    backgroundColor: env.colors.primaryColor,
                    color: '#fff'
                  }
                : {
                    backgroundColor: '#ccc',
                    color: '#fff',
                    border: '1px solid #ccc'
                  }
            }
            className={styles.button}
            disabled={!checked}
            onClick={() => accept(true)}>
            {t('consent.button')}
          </Button>
        </Col>
      </Row>
    </Modal>
  )
}

export default ConsentModal
