import React, { useState, useEffect, useContext } from 'react'
import { Select } from 'antd'
import styles from './SubCategorySelect.module.css'
import { useTranslation } from 'react-i18next'
import { getCfg } from '../../env.config'
import { AppContext } from '../../contexts/AppContext'

const SubCategory = ({ categories }) => {
  const { Option } = Select
  const { t } = useTranslation()
  const [options, setOptions] = useState([])
  const { subcategory, setSubcategory } = useContext(AppContext)

  useEffect(() => {
    let tempCategories = []
    if (categories) {
      if (categories.data[0]) {
        if (categories.data[0].subCategories !== null && categories.data[0].subCategories !== undefined && !categories.data[0].Video_Id__c) {
          tempCategories = categories.data[0].subCategories.map((subcategories) => subcategories)
        }
      }
    }
    setOptions(tempCategories)
  }, [categories, setOptions])

  const address = `https://${window.location.href.split('/')[2]}`
  const env = getCfg(address)

  const handleSelect = (value) => {
    setSubcategory(value === undefined ? '' : value)
  }

  return (
    <Select
      size='large'
      name='treatment'
      className={styles.select}
      allowClear
      style={
        options.length === 0
          ? { display: 'none' }
          : {
              width: '100%',
              maxWidth: 330,
              backgroundColor: '#fff'
            }
      }
      placeholder={t('header.subcategory')}
      dropdownClassName={`${env.countryClass} ${styles.menu}`}
      onChange={(value) => handleSelect(value)}
      value={subcategory !== '' ? subcategory : null}>
      {options.length > 0 ? (
        options.map((option) => {
          const name = t(`subcategories.${option.subcategory}`)
          return (
            <Option key={option.subcategory} value={option.subcategory} className={styles.option}>
              {name}
            </Option>
          )
        })
      ) : (
        <Option value={null} className={styles.option}>
          {t('subcategory')}
        </Option>
      )}
    </Select>
  )
}

export default SubCategory
